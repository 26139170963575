import { ColumnsType } from "antd/es/table";
import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { TitleComponent } from "../../components/title";
import { Button, message, Skeleton, Table } from "antd";
import divisionService from "../../services/divisionService";
import subDivisionService from "../../services/subDivisionService";

export function DivisionSubDivisionView() {
  const nvigate = useNavigate();
  const [loading, setLoading] = React.useState(true);
  const [items, setItems] = React.useState<any[]>([]);
  const [division, setDivision] = React.useState<any>({});
  const params = useParams();

  const columns: ColumnsType<any> = [
    { title: "Nom de la sous-division", dataIndex: "name" },
    { title: "Responsable", dataIndex: "leader" },
  ];

  function handleClick(data: any) {
    nvigate(`/admin/sub_division/${data.id}`);
  }

  async function init() {
    const id = params.id;
    setLoading(true);
    await divisionService
      .get(`${id}`)
      .then((response) => {
        setDivision(response.data);
      })
      .catch((reason) => {
        message.error({ content: "Cette division n'existe pas" });
      });
    await subDivisionService
      .getByKey(`division=${id}`)
      .then((response) => {
        const data = subDivisionService.getData(response);
        setItems(data);
      })
      .catch(() => {});
    setLoading(false);
  }

  React.useEffect(() => {
    init();
  }, [params.id]);

  return (
    <React.Fragment>
      {!loading && (
        <React.Fragment>
          <TitleComponent title={division.name}>
            {/*<Button className="btn--round">
              <Link to={`/admin/sub_division/create?division=${params.id}`}>
                {`Ajouter une sous-province éducationnelle`}
              </Link>
          </Button>*/}
          </TitleComponent>
          <section className="container">
            <Table
              className="table"
              columns={columns}
              dataSource={items}
              onRow={(data: any, index?: number) => {
                return {
                  onClick: () => handleClick(data),
                };
              }}
            />
          </section>
        </React.Fragment>
      )}
      {loading && (
        <React.Fragment>
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
