import React from "react";
import { CenterIdentificationPDF } from "../components/pdf/centerPDF";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { Skeleton } from "antd";
import { useParams } from "react-router-dom";
import centerService from "../services/centerService";
import { setPlug } from "../redux/centerReducer";
import { BuildingPDF } from "../components/pdf/buildingPdf";
import centerPlugService from "../services/centerPlugService";
import { EstablishmentPDF } from "../components/pdf/establishmentPdf";
import { NewLearnerPDF } from "../components/pdf/newLearnerPdf";

export function PDFFileScreen() {
  const [loading, setLoading] = React.useState(true);
  const plug = useSelector((state: RootState) => state.center.plug);
  const params = useParams();
  const dispatch = useDispatch();

  React.useEffect(() => {
    init();
  }, [params.id]);

  async function init() {
    const id = params.id;
    await centerPlugService
      .get(`${id}`)
      .then((response) => {
        const data = response.data;
        dispatch(setPlug(data));
      })
      .catch(() => {});
    setLoading(false);
  }

  return (
    <React.Fragment>
      <main className="main main yc_container_main">
        {loading && (
          <React.Fragment>
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
          </React.Fragment>
        )}
        {!loading && (
          <React.Fragment>
            {plug.plug === "center" && <CenterIdentificationPDF />}
            {plug.plug === "building" && <BuildingPDF />}
            {plug.plug === "establishment" && <EstablishmentPDF />}
            {plug.plug === "register" && <NewLearnerPDF />}
          </React.Fragment>
        )}
      </main>
    </React.Fragment>
  );
}
