import { useFormik } from "formik";
import React from "react";
import { TitleComponent } from "../../components/title";
import { DillInput, DillSelect, DillTextarea } from "../../components/input";
import { Button, Divider } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import userHelper from "../../utils/helpers/userHelper";

export function CreateMailScreen() {
  const admins = useSelector((state: RootState) => state.admin.all);
  const [loading, setLoading] = React.useState(false);
  const [destinators, setDestinators] = React.useState<any[]>([]);

  const formik = useFormik({
    initialValues: {
      title: "",
      content: "",
      destinator: "",
    },
    onSubmit: save,
  });

  React.useEffect(() => {
    const elements: any[] = [];
    admins.forEach((p) => {
      elements.push({
        title: userHelper.getUserName(p?.user),
        value: `${p.id}`,
      });
    });
    elements.unshift({ value: "", title: "Séléctionner un destinataire" });
    setDestinators(elements);
  }, [admins]);

  async function save() {}

  return (
    <React.Fragment>
      <section className="box">
        <div className="card box__login">
          <TitleComponent title="Création de message" type="creation" />
          <div className="card">
            <DillSelect
              formik={formik}
              name="destinator"
              list={destinators}
              label="Destinataire"
            />
            <Divider style={{ margin: "0.8rem 0" }} />
            <DillInput
              formik={formik}
              name="title"
              label={"Titre de message"}
              placeholder={""}
            />
            <DillTextarea
              formik={formik}
              name="content"
              label={"Contenu de message"}
              placeholder={""}
            />

            <div
              className="row"
              style={{
                alignItems: "center",
                justifyContent: "center",
                padding: "10px 0 0",
              }}
            >
              <Button
                disabled={loading}
                loading={loading}
                onClick={() => formik.handleSubmit()}
                type="primary"
              >{`Enregistrer`}</Button>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
