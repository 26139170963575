import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { Table, Tag } from "antd";
import { useNavigate, useNavigation } from "react-router-dom";
import { isEmpty } from "lodash";
import { getStatus } from "../../utils/helpers/title";

export function SessionScreen() {
  const editions = useSelector((state: RootState) => state.edition.all);
  const [items, setItems] = React.useState<any[]>([]);
  const navigation = useNavigate();

  React.useEffect(() => {
    setItems(editions);
  }, [editions]);

  function onRow(data: any, index?: number) {
    navigation(`/admin/session/${data.id}`);
  }

  return (
    <React.Fragment>
      <Table
        dataSource={items}
        columns={[
          { dataIndex: "edition", title: "Edition", align: "center" },
          {
            dataIndex: "exams",
            title: "Les examens",
            render(value, record, index) {
              return value?.length > 0
                ? `${value?.length} examen${value?.length > 1 ? "s" : ""}`
                : "Aucun examen";
            },
          },
          {
            title: "Etablissement",
            align: "center",
            dataIndex: "center",
            render(value, record, index) {
              return value?.name ?? "---";
            },
          },
          {
            title: "Filière",
            align: "center",
            dataIndex: "training",
            render(value, record, index) {
              let result: string = "";
              if (!isEmpty(value)) {
                if (!isEmpty(value.sector)) {
                  result += `${value.sector?.name} (${value.duration} ${
                    value.type ?? "mois"
                  })`;
                }
              }
              return !isEmpty(result) ? result : "---";
            },
          },
          {
            title: "Status de la session",
            align: "center",
            dataIndex: "status",
            render(value, record, index) {
              const result = getStatus(value);
              return <Tag color={result.color}>{result.title}</Tag>;
            },
          },
        ]}
        className="table"
        onRow={(data: any, index?: number) => {
          return {
            onClick: () => onRow(data, index),
          };
        }}
      />
    </React.Fragment>
  );
}
