import React from "react";
import { TitleComponent } from "../components/title";
import { Button, List, Table } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { useFormik } from "formik";
import { DillInput, DillSelect, selectItem } from "../components/input";
import divisionService from "../services/divisionService";
import { isEmpty, toNumber } from "lodash";
import subDivisionService from "../services/subDivisionService";
import centerService from "../services/centerService";
import { CreateExam } from "./exam/create";
import editionService from "../services/editionService";
import { useLocation, useNavigate } from "react-router-dom";
import examService from "../services/examService";
import dateHelpers from "../utils/helpers/dateHelpers";

export function ExamScreen() {
  const exams = useSelector((state: RootState) => state.exam.all);
  const [loading, setLoading] = React.useState(false);
  const [allOptions, setAllOptions] = React.useState(false);
  const [loadScreen, setLoadScreen] = React.useState(false);
  const [items, setItems] = React.useState<any[]>([]);
  const route = useLocation();
  const navigate = useNavigate();
  const inactivates = useSelector(
    (state: RootState) => state.learner.inactivates
  );
  const [divisions, setDivisions] = React.useState<
    { title: string; value: string }[]
  >([]);
  const [subDivisions, setSubDivisions] = React.useState<
    { title: string; value: string }[]
  >([]);
  const [centers, setCenters] = React.useState<selectItem[]>([]);
  const [sectors, setSectors] = React.useState<selectItem[]>([]);
  const [editions, setEditions] = React.useState<selectItem[]>([]);

  const [loadDivision, setLoadDivision] = React.useState(false);
  const [loadSubDivision, setSubLoadDivision] = React.useState(false);
  const [loadCenter, setLoadCenter] = React.useState(false);
  const [loadEdition, setLoadEdition] = React.useState(false);
  const [searchEdition, setSearchEdition] = React.useState("");
  const formik = useFormik({
    initialValues: {
      title: "",
      sector: "",
      center: "",
      division: "",
      subDivision: "",
      edition: "",
    },
    onSubmit: () => {},
  });

  const navigation = useNavigate();

  async function getAllDivisions() {
    const list: selectItem[] = [];
    setLoadDivision(true);
    await divisionService
      .getByKey(``)
      .then((response) => {
        const data = divisionService.getData(response);
        data.forEach((p) => {
          list.push({
            title: p.name,
            value: p.id,
          });
        });
        if (list.length > 0) {
          list.unshift({
            title: "Séléctionner une province éducationnelle",
            value: "",
          });
        } else {
          list.unshift({
            title: "Aucune division",
            value: "",
          });
        }
        setDivisions(list);
      })
      .catch(() => {});
    setLoadDivision(false);
  }

  async function getSubDivisions() {
    const { division } = formik.values;
    if (isEmpty("" + division)) {
      setSubDivisions([{ title: "Aucune sous-division", value: "" }]);
      return;
    }
    const list: selectItem[] = [];
    setSubLoadDivision(true);
    await subDivisionService
      .getByKey(`division=${division}`)
      .then((response) => {
        const data = subDivisionService.getData(response);
        data.forEach((p) => {
          list.push({
            title: p.name,
            value: p.id,
          });
        });
        if (list.length > 0) {
          list.unshift({
            title: "Séléctionner une sous-province éducationnelle",
            value: "",
          });
        } else {
          list.unshift({
            title: "Aucune sous-division",
            value: "",
          });
        }
        setSubDivisions(list);
      })
      .catch(() => {});
    setSubLoadDivision(false);
  }

  async function getCenters() {
    const { subDivision } = formik.values;
    if (isEmpty("" + subDivision)) {
      setCenters([{ title: "Aucun établissement", value: "" }]);
      return;
    }
    const list: selectItem[] = [];
    setLoadCenter(true);
    await centerService
      .getByKey(`subDivision=${subDivision}`)
      .then((response) => {
        const data = centerService.getData(response);
        data.forEach((p) => {
          list.push({
            title: p.name,
            value: p.id,
          });
        });
        if (list.length > 0) {
          list.unshift({
            title: "Séléctionner un établissement",
            value: "",
          });
        } else {
          list.unshift({
            title: "Aucun établissement",
            value: "",
          });
        }
        setCenters(list);
      })
      .catch(() => {});
    setLoadCenter(false);
  }

  async function onSearchEdition() {
    let query = `edition=${searchEdition}`;
    setLoadEdition(true);
    await editionService.getByKey(query).then((response) => {
      const data = editionService.getData(response);
      const elements: selectItem[] = [];
      data.forEach((p) => {
        elements.push({
          title: `${p.edition}`,
          value: `${p.id}`,
        });
      });
      elements.unshift({ value: "", title: "Séléctionner la session" });
      setEditions(elements);
    });
    setLoadEdition(false);
  }

  React.useEffect(() => {
    onSearchEdition();
  }, [searchEdition]);

  React.useEffect(() => {
    getAllDivisions();
  }, []);

  React.useEffect(() => {
    getSubDivisions();
  }, [formik.values.division]);

  React.useEffect(() => {
    getCenters();
  }, [formik.values.subDivision]);

  React.useEffect(() => {
    init();
  }, [route]);

  async function init() {
    const args = new URLSearchParams(route.search);
    const year = toNumber(args.get("year"));
    const month = toNumber(args.get("month"));
    const day = toNumber(args.get("day"));
    let query = ``;
    if (year > 0 && month > 0 && day > 0) {
      setAllOptions(false);
      setLoadScreen(true);
      const date = new Date(year, month - 1, day);
      const date1 = dateHelpers.getDate(date, "YYYY-MM-DD 00:00:00");
      const date2 = dateHelpers.getDate(date, "YYYY-MM-DD 23:59:59");
      query += `date[after]=${date1}&date[before]=${date2}`;
      setLoading(true);
    } else if (year > 0 && month > 0) {
      setAllOptions(false);
      setLoadScreen(true);
      const date1 = dateHelpers.getDate(
        new Date(year, month - 1, 1),
        "YYYY-MM-DD 00:00:00"
      );
      const limit = dateHelpers.getDayInMonth(year, month);
      const date2 = dateHelpers.getDate(
        new Date(year, month - 1, limit),
        "YYYY-MM-DD 23:59:59"
      );
      query += `date[after]=${date1}&date[before]=${date2}`;
      setLoading(true);
    } else {
      setAllOptions(true);
      setItems(exams);
    }
    if (!isEmpty(query)) {
      await examService
        .getByKey(query)
        .then((response) => {
          const data = examService.getData(response);
          setItems(data);
        })
        .catch((reason) => {
          const error = examService.getError(reason);
        });
    }
    setLoadScreen(false);
    setLoading(false);
  }

  React.useEffect(() => {}, [formik.values.center]);

  const renderItem = (item: any, index: number) => {
    return <List.Item></List.Item>;
  };
  return (
    <React.Fragment>
      <main className="main main yc_container_main">
        <TitleComponent title="Liste des examens">
          <CreateExam />
        </TitleComponent>
        {allOptions && (
          <section className="container fadeIn">
            <div className="row">
              <div className="col">
                <DillSelect
                  formik={formik}
                  name="division"
                  list={divisions}
                  loading={loadDivision}
                  key="division"
                />
              </div>
              <div className="col">
                <DillSelect
                  formik={formik}
                  name="subDivision"
                  list={subDivisions}
                  loading={loadSubDivision}
                  key="subdivision"
                />
              </div>
              <div className="col">
                <DillSelect
                  formik={formik}
                  name="center"
                  list={centers}
                  loading={loadCenter}
                  key="center"
                />
              </div>
              <div className="col">
                <DillInput
                  formik={formik}
                  name="title"
                  key="title"
                  placeholder={"Titre"}
                />
              </div>
              <div className="col">
                <DillSelect
                  formik={formik}
                  name="edition"
                  list={editions}
                  loading={loadEdition}
                  key="edition"
                  search
                  setSearch={setSearchEdition}
                />
              </div>
            </div>
          </section>
        )}
        <section className="container">
          <Table
            dataSource={items}
            loading={loading}
            columns={[
              {
                title: "N°",
                render(value, record, index) {
                  return index + 1;
                },
              },
              {
                title: "Titre de l'examen",
                dataIndex: "title",
                align: "center",
              },
              {
                title: "Edition",
                dataIndex: "examsSession",
                align: "center",
                render(value, record, index) {
                  return value?.edition;
                },
                onCell: (data: any, index?: number) => {
                  return {
                    onClick: () => {
                      navigation(`/admin/session/${data.id}`);
                    },
                  };
                },
              },
              {
                title: "Filière",
                dataIndex: "training",
                align: "center",
                render(value, record, index) {
                  let result = `${value?.sector?.name ?? "ds"} `;
                  if (!isEmpty(value?.sector)) {
                    result += `(${value.duration} mois)`;
                  }
                  return result;
                },
              },
              {
                title: "Etablissement",
                dataIndex: "center",
                align: "center",
                render(value, record, index) {
                  return value?.name;
                },
              },
              {
                title: "Date de l'examen",
                dataIndex: "date",
                align: "center",
                render(value, record, index) {
                  let result = ``;
                  if (dateHelpers.isSameDay(value, new Date())) {
                    result = `Aujourd'hui`;
                  } else if (
                    dateHelpers.isSameMoment(value, new Date(), "week")
                  ) {
                    result = dateHelpers.getDate(value, "[ce] dddd");
                  } else if (
                    dateHelpers.isSameMoment(value, new Date(), "year")
                  ) {
                    result = dateHelpers.getDate(value, "DD MMMM");
                  } else {
                    result = dateHelpers.getDate(value, "DD MMM YYYY");
                  }
                  return result;
                },
              },
            ]}
            className="table"
            onRow={(data: any, index?: number) => {
              return {
                onClick: () => {
                  navigate(`/admin/exam/${data.id}`);
                },
              };
            }}
          />
        </section>
      </main>
    </React.Fragment>
  );
}
