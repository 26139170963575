import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface resultData {
  current: any;
  list: any[];
  all: any[];
}

const initialState: resultData = {
  current: {},
  all: [],
  list: [],
};

export const resultSlice = createSlice({
  name: "result",
  initialState,
  reducers: {
    setResult: (state, action: PayloadAction<any>) => {
      state.current = action.payload;
    },
    setResults: (state, action: PayloadAction<any>) => {
      state.list = action.payload;
    },
    setAllResults: (state, action: PayloadAction<any[]>) => {
      state.all = action.payload;
    },
  },
});
export const { setAllResults, setResult, setResults } = resultSlice.actions;
export default resultSlice.reducer;
