import { isEmpty, upperCase, upperFirst } from "lodash";
import React from "react";
import { ElementCard } from "../../components/element";
import { getUid } from "../../utils/helpers/title";
import { Divider, Typography } from "antd";
import dateHelpers from "../../utils/helpers/dateHelpers";
import { ProfileUpdateName } from "./updateName";
import { ProfileUpdatePhone } from "./updatePhoneNumber";
import { ProfileUpdateGender } from "./updateGender";
import { ProfileUpdateBirthDate } from "./updateBirthDate";
import { ProfileUpdateBirthPlace } from "./updateBirthPlace";
import { ProfileAccessView } from "./accessibility";
import { ProfileUpdatePassword } from "./updatePassword";

export const ProfileIndentityView: React.FC<{ admin: any }> = ({ admin }) => {
  return (
    <React.Fragment>
      <div className="row">
        <div className="col-md-12" style={{ marginTop: "1.6rem" }}>
          <div className="row" style={{ alignItems: "center" }}>
            <div className="flex--1">
              <Typography.Title style={{ marginBottom: 0 }}>
                {`${upperFirst(admin?.user?.name)} ${upperCase(
                  admin?.user?.lastname
                )} ${upperCase(admin?.user?.postname)}`}
              </Typography.Title>
            </div>
            <div>
              <ProfileUpdateName />
            </div>
          </div>
          <Divider style={{ margin: "0.5rem 0" }} />
          <div className="row card--stretch">
            <div className="col-md-4 item">
              <ElementCard
                subtitle="date de naissance"
                title={
                  dateHelpers.getDate(admin?.user?.bornDate, "DD MMMM YYYY") ??
                  ""
                }
                icon=""
              >
                <ProfileUpdateBirthDate />
              </ElementCard>
            </div>
            {!isEmpty(dateHelpers.getAge(admin?.user?.bornDate) ?? "") && (
              <div className="col-md-4 item">
                <ElementCard
                  subtitle="Age"
                  title={dateHelpers.getAge(admin?.user?.bornDate) ?? ""}
                  icon=""
                />
              </div>
            )}
            <div className="col-md-4 item">
              <ElementCard
                subtitle="Lieu de naissance"
                title={admin?.user?.bornPlace}
                icon=""
              >
                <ProfileUpdateBirthPlace />
              </ElementCard>
            </div>
            <div className="col-md-4 item">
              <ElementCard
                subtitle="Genre"
                title={admin?.user?.sex === "f" ? "Femme" : "Homme"}
                icon=""
              >
                <ProfileUpdateGender />
              </ElementCard>
            </div>
            <div className="col-md-4 item">
              <ElementCard
                subtitle="Téléphone"
                title={admin?.user?.phoneNumber}
                icon=""
              >
                <ProfileUpdatePhone />
              </ElementCard>
            </div>
            <div className="col-md-4 item">
              <ElementCard
                subtitle="E-mail"
                title={admin?.user?.email}
                icon=""
              />
            </div>
          </div>
          <Divider />
          <ProfileAccessView admin={admin} />
          <Divider />
          <div className="row">
            <div className="col-md-6 col-xs-12">
              <ProfileUpdatePassword />
            </div>
            <div className="col-md-6 col-xs-12"></div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
