import { routeInterface } from "../routes";
import { DivisionScreen } from "../screens/divisions";
import { DivisionView } from "../screens/division/view";
import { CreateDivisionScreen } from "../screens/division/create";
import { ExamScreen } from "../screens/exams";
import { ViewExam } from "../screens/exam/view";
import { DiplomaScreen } from "../screens/diploma";
import { CertificateView } from "../screens/diploma/certificate";
export const examRoutes: routeInterface[] = [
  {
    component: ExamScreen,
    icon: "fa-regular fa-graduation-cap",
    isMenu: true,
    label: "Examens",
    layout: "/admin",
    name: "exams",
    path: "/exams",
    subscribeable: false,
  },
  {
    component: DiplomaScreen,
    icon: "fa-sharp fa-solid fa-diploma",
    isMenu: true,
    label: "Diplômes",
    layout: "/admin",
    name: "proclaim",
    path: "/diploma",
    subscribeable: false,
  },
  {
    component: ViewExam,
    icon: "",
    isMenu: false,
    label: "Exam",
    layout: "/admin",
    name: "exam-view",
    path: "/exam/:id",
    subscribeable: false,
  },
  {
    component: CertificateView,
    icon: "",
    isMenu: false,
    label: "Certificate",
    layout: "/admin",
    name: "certificate",
    path: "/certificate",
    subscribeable: false,
  },
  /*{
    component: CreateDivisionScreen,
    icon: "fa-solid fa-location-plus",
    isMenu: true,
    label: "Ajouter une division",
    layout: "/admin",
    name: "create_division",
    path: "/division/create",
    subscribeable: false,
  },*/
];
