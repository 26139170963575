import { Button, Table, Tag } from "antd";
import { ColumnsType } from "antd/es/table";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import userHelper from "../../utils/helpers/userHelper";
import { groupBy } from "lodash";

export function ProclaimSession() {
  const [columns, setColumns] = React.useState<ColumnsType<any>>([]);
  const exams = useSelector((state: RootState) => state.exam.list);
  const learners = useSelector((state: RootState) => state.learner.list);
  const results = useSelector((state: RootState) => state.result.list);
  const proclaims = useSelector((state: RootState) => state.edition.proclaims);
  const [items, setItems] = React.useState<any>([]);

  React.useEffect(() => {
    const elements: ColumnsType<any> = [];
    elements.push({
      title: "Nom complet",
      align: "left",
      dataIndex: "user",
      render(value, record, index) {
        return userHelper.getUserName(value);
      },
    });
    exams.map((p) => {
      elements.push({
        title: p.title,
        align: "center",
        dataIndex: "results",
        render(value: any[], record, index) {
          let result =
            value.find((x) => x?.exams?.id === p.id)?.leanerNote ?? `----`;
          return result;
        },
      });
    });
    setColumns(elements);
  }, []);

  const onInit = React.useCallback(() => {
    const list: any[] = [];
    learners.map((p) => {
      const item = proclaims.find((x) => p.id === x?.learner?.id);
      const data = { ...p };
      data.proclaim = item;
      list.push(data);
    });
    //const elements:any = groupBy(results,p=>p.leaner?.id);
    setItems(list);
  }, [proclaims, exams, learners]);

  React.useEffect(() => {
    onInit();
  }, [proclaims, exams, learners]);

  return (
    <React.Fragment>
      <Table
        className="table--1"
        dataSource={items}
        columns={[
          {
            title: "Nom complet",
            align: "left",
            dataIndex: "user",
            render(value, record, index) {
              return userHelper.getUserName(value);
            },
          },
          {
            title: "Pourcentage obtenu",
            dataIndex: "proclaim",
            render(value, record, index) {
              const percent = value?.percent ?? 0;
              if (percent > 0) {
                return <Tag color="blue">{`${percent} %`}</Tag>;
              } else {
                return <Tag color="red">{`Non classé`}</Tag>;
              }
            },
          },
          {
            render(value, record, index) {
              const percent = value?.proclaim?.percent ?? 0;
              return (
                <Button disabled={percent < 50} type="primary">
                  {`Certificat`}
                </Button>
              );
            },
          },
        ]}
      />
    </React.Fragment>
  );
}
