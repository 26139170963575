import { Col, Divider, Image, Layout, Menu, Modal, Row } from "antd";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Typography } from "antd";
import logo from "../assets/images/min.png";
import { isEmpty, upperFirst } from "lodash";
import { useDispatch } from "react-redux";
import { routes } from "../routes";
import userHelper from "../utils/helpers/userHelper";
import authService from "../services/authService";

const getMenu = (current = "") => {
  return (
    <Menu
      mode="inline"
      defaultSelectedKeys={["1"]}
      style={{ background: "#fff" }}
      className="card"
      activeKey={current}
    >
      <Menu.Item
        key={0}
        style={{
          height: 80,
          width: 100,
          borderRadius: 5,
          background: "#f7f7f7",
        }}
      >
        <Image src={logo} preview={false} />
      </Menu.Item>
      {routes
        .filter((p) => p.isMenu && p.layout === "/admin")
        .map((p, index) => {
          return (
            <Menu.Item
              className="card"
              key={index + 1}
              style={{ height: 80, width: 100, borderRadius: 5 }}
            >
              <Link to={p.layout + p.path}>
                <Row align="middle" justify="center">
                  <Col span={24} style={{ textAlign: "center" }}>
                    {isEmpty(p.icon) ? (
                      <p.icon className="icon" />
                    ) : !isEmpty(p.icon) ? (
                      <i className={`fa-solid ${p.icon}`}></i>
                    ) : null}
                  </Col>
                  <Col span={24} style={{ textAlign: "center" }}>
                    <Typography.Text style={{ fontSize: 12 }}>
                      {p.label}
                    </Typography.Text>
                  </Col>
                </Row>
              </Link>
            </Menu.Item>
          );
        })}
    </Menu>
  );
};

function hasChildren(name?: string) {
  if (isEmpty(name)) {
    return false;
  }
  const list = routes.filter((p) => p.parent === name && p.isMenu);
  return list.length > 0;
}
interface mainMenuInterface {
  current?: string;
}
function MainMenus(props: mainMenuInterface) {
  const { current } = props;
  const [active, setActive] = React.useState("");
  const dispatch = useDispatch();

  const route = useLocation();

  function getIndex(path: string) {
    const index =
      `${path}` === route.pathname || `${path}/` === route.pathname ? 1 : -1;
    return index;
  }

  function logout() {
    authService.logout();
    userHelper.setLogout(dispatch);
    window.location.href = "/";
  }

  function handleLogout() {
    Modal.warning({
      centered: true,
      title: "Déconnexion",
      content: "Voulez-vous vous déconnecter?",
      okText: "Se déconnecter",
      cancelText: "Annuler",
      onCancel: () => window.history.back(),
      okCancel: true,
      onOk: logout,
    });
  }

  function isCurrentUrl(path: string) {
    if (path === current || path === current + "/") {
      return "yc_active";
    } else {
      return "";
    }
  }

  function handleToggleList(id: string) {
    if (active === id) {
      setActive("");
    } else {
      setActive(id);
    }
  }

  return (
    <React.Fragment>
      {routes
        .filter((p) => p.layout === "/admin" && p.isMenu)
        .map((p, index) => (
          <React.Fragment key={`route${index}`}>
            {isEmpty(p.parent) && (
              <li>
                <Link
                  to={`${hasChildren(p.group) ? "#d" : p.layout + p.path}`}
                  onClick={() => handleToggleList(p.name)}
                  className={`yc_menu_item js-link-submenu ${
                    getIndex(p.layout + p.path) != -1 ? "active" : ""
                  }`}
                >
                  {!isEmpty(p.icon) && <i className={`${p.icon} yc_icon`}></i>}
                  <span>{upperFirst(p.label)}</span>
                  {hasChildren(p.group) && (
                    <i
                      className={`fa-solid ${
                        active !== p.name ? "fa-chevron-down" : "fa-chevron-up"
                      } yc_icon_group`}
                    ></i>
                  )}
                </Link>
                {hasChildren(p.group) && (
                  <ul className={`${active !== p.name ? "mm-collapse" : ""}`}>
                    {routes
                      .filter((x) => x.parent === p.group && x.isMenu)
                      .map((x, indexLi) => (
                        <li key={`li_${indexLi}`}>
                          <Link
                            to={x.layout + x.path}
                            className={`${isCurrentUrl(x.layout + x.path)}`}
                          >
                            {!isEmpty(x.icon) && (
                              <i className={`${x.icon} yc_icon`}></i>
                            )}
                            <span>{upperFirst(x.label)}</span>
                          </Link>
                        </li>
                      ))}
                  </ul>
                )}
              </li>
            )}
          </React.Fragment>
        ))}
      <Divider style={{ margin: "0.5rem 0" }} />
      <li>
        <a href="#" onClick={handleLogout}>
          <i className="fa-solid fa-right-from-bracket yc_icon"></i>
          <span>{upperFirst("Se déconnecter")}</span>
        </a>
      </li>
    </React.Fragment>
  );
}

const SiderAdmin: React.FC<{ current: string }> = ({ current }) => {
  const [open, setOpen] = React.useState(false);
  function handleClick() {
    setOpen(!open);
  }
  return (
    <div className="yc_sidebar_wrapper">
      <div className={`yc_sidebar_container ${open ? "open" : ""}`}>
        <div className="yc_sidebar_logo">
          <a className="logo" href="/"></a>
          <span
            className={`menu-logo ${open ? "open" : ""}`}
            onClick={handleClick}
          >
            {!open && <i className="fa-solid fa-bars"></i>}
            {open && <i className="fa-solid fa-xmark"></i>}
          </span>
          <a
            href="#"
            className="hide yc_sidebar_hamburger hamburger--elastic mobile-toggle-nav"
          >
            <i className="fa-thin fa-bars"></i>
          </a>
        </div>
        <ul className="vertical-nav-menu">
          <MainMenus key="ds" current={current} />
        </ul>
      </div>
    </div>
  );
};

export { SiderAdmin };
