import { Avatar, Badge, BadgeProps, Calendar } from "antd";
import { Dayjs } from "dayjs";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import dateHelpers from "../../utils/helpers/dateHelpers";
import { useNavigate } from "react-router-dom";

export function AllCalendar() {
  const examens = useSelector((state: RootState) => state.exam.all);
  const navigation = useNavigate();

  const getListData = (value: Dayjs, mode?: "year" | "month") => {
    const date = value.toDate();
    let listData = [];
    if (mode === "month") {
      listData = examens.filter((p) =>
        dateHelpers.isSameMoment(date, p.date, "month")
      );
    } else {
      listData = examens.filter((p) => dateHelpers.isSameDay(date, p.date));
    }

    return listData || [];
  };

  const getMonthData = (value: Dayjs) => {
    if (value.month() === 8) {
      return 1394;
    }
  };

  function handleClick(value: Dayjs, type: "month" | "day", list: any[]) {
    if (list.length === 0) {
      return;
    }
    let url = `/admin/exams/`;
    switch (type) {
      case "day":
        url += `?month=${value.month()}&year=${value.year()}&day=${value.day()}`;
        break;
      case "month":
        url += `?month=${value.month()}&year=${value.year()}`;
        break;
    }
    navigation(url);
  }

  const dateCellRender = (value: Dayjs) => {
    const listData = getListData(value);
    return (
      <div
        onClick={() => handleClick(value, "day", listData)}
        className="row"
        style={{ justifyContent: "center", alignItems: "center" }}
      >
        {listData.length > 0 && (
          <Avatar size={80} shape="square">
            <div>
              {`${listData.length} examen${listData.length > 1 ? "s" : ""}`}
            </div>
          </Avatar>
        )}
      </div>
    );
  };

  const monthCellRender = (value: Dayjs) => {
    const listData = getListData(value, "month");
    return (
      <div
        onClick={() => handleClick(value, "month", listData)}
        className="row"
        style={{ justifyContent: "center", alignItems: "center" }}
      >
        {listData.length > 0 && (
          <Avatar size={80} shape="square">
            <div>
              {`${listData.length} examen${listData.length > 1 ? "s" : ""}`}
            </div>
          </Avatar>
        )}
      </div>
    );
  };

  return (
    <React.Fragment>
      <Calendar
        locale={{
          lang: {
            locale: "fr_FR",
            placeholder: "Séléctionner la date",
            rangePlaceholder: ["Date de début", "Date de fin"],
            today: "Ajourd'hui",
            now: "Maintenant",
            backToToday: "Retourner au jour",
            ok: "OK",
            clear: "Effacer",
            month: "Mois",
            year: "Année",
            timeSelect: "Select time",
            dateSelect: "Select date",
            monthSelect: "Choose a month",
            yearSelect: "Choose a year",
            decadeSelect: "Choose a decade",
            yearFormat: "YYYY",
            dateFormat: "M/D/YYYY",
            dayFormat: "D",
            dateTimeFormat: "M/D/YYYY HH:mm:ss",
            monthFormat: "MMMM",
            monthBeforeYear: true,
            previousMonth: "Previous month (PageUp)",
            nextMonth: "Next month (PageDown)",
            previousYear: "Last year (Control + left)",
            nextYear: "Next year (Control + right)",
            previousDecade: "Last decade",
            nextDecade: "Next decade",
            previousCentury: "Last century",
            nextCentury: "Next century",
            shortWeekDays: moment.weekdaysShort().map((p) => p?.toUpperCase()),
            shortMonths: moment.monthsShort().map((p) => p?.toUpperCase()),
          },
          timePickerLocale: {
            placeholder: "Select time",
          },
        }}
        dateCellRender={dateCellRender}
        monthCellRender={monthCellRender}
      />
    </React.Fragment>
  );
}
