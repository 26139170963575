import { Button, Divider, Skeleton, Typography } from "antd";
import { isEmpty, upperCase, upperFirst } from "lodash";
import React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import learnerService from "../../services/learnerService";
import dateHelpers from "../../utils/helpers/dateHelpers";
import { getUid } from "../../utils/helpers/title";
import userHelper from "../../utils/helpers/userHelper";
import { ElementCard } from "../../components/element";
import formerService from "../../services/formerService";

export function FormerView() {
  const params = useParams();
  const [loading, setLoading] = React.useState(true);
  const [refresh, setRefresh] = React.useState(false);
  const [former, setFormer] = React.useState<any>({});
  const dispatch = useDispatch();

  React.useEffect(() => {
    setLoading(true);
    init();
  }, [params.id]);

  async function activeLearner() {
    setRefresh(true);
    const id = params.id;
    await learnerService
      .update(`${id}`, { isActive: true })
      .then(async () => {
        userHelper.getLearners(dispatch);
        userHelper.getInactivateLeaners(dispatch);
        await init();
      })
      .catch(() => {});
    setRefresh(false);
  }

  async function disableLearner() {
    setRefresh(true);
    const id = params.id;
    await learnerService
      .update(`${id}`, { isActive: false })
      .then(async () => {
        userHelper.getLearners(dispatch);
        userHelper.getInactivateLeaners(dispatch);
        await init();
      })
      .catch(() => {});
    setRefresh(false);
  }

  async function init() {
    const id = params.id;
    await formerService
      .get(`${id}`)
      .then(async (response) => {
        const data: any = response.data;
        setFormer(data);
      })
      .catch((reason) => {});
    setLoading(false);
  }

  return (
    <React.Fragment>
      <main className="main main yc_container_main">
        {loading && (
          <React.Fragment>
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
          </React.Fragment>
        )}
        {!loading && (
          <section className="container">
            <div className="row">
              <div className="col-md-3 item">
                {/*<ElementCard
                icon="fa-regular fa-vector-circle"
                subtitle="Filière"
                title={former?.sector?.name}
                type="primary"
                //url={`/admin/sector/${getUid(former?.sector["@id"] ?? "")}`}
              />*/}
                <ElementCard
                  icon="fa-solid fa-location-dot"
                  subtitle="Centre"
                  title={former?.center?.name}
                  type="danger"
                  //url={`/admin/center/${getUid(former?.center["@id"] ?? "")}`}
                />
                <ElementCard
                  icon="fa-duotone fa-location-crosshairs"
                  subtitle="Sous-division"
                  title={former?.center?.subDivision?.name}
                  type="gold"
                  /*url={`/admin/sub_division/${getUid(
                  former?.center?.subDivision ?? ["@id"] ?? ""
                )}`}*/
                />
                <ElementCard
                  icon="fa-solid fa-location-crosshairs"
                  subtitle="Division"
                  title={former?.center?.subDivision?.division?.name}
                  type="primary"
                  /*url={`/admin/division/${getUid(
                  former?.center?.subDivision?.division["@id"] ?? ""
                )}`}*/
                />
                <ElementCard
                  icon="fa-solid fa-location-crosshairs"
                  subtitle="Qualification"
                  title={former?.qualification}
                  type="primary"
                  /*url={`/admin/division/${getUid(
                  former?.center?.subDivision?.division["@id"] ?? ""
                )}`}*/
                />
              </div>
              <div className="col-md-9" style={{ marginTop: "1.6rem" }}>
                <Typography.Title>
                  {`${upperFirst(former?.firstName)} ${upperCase(
                    former?.lastName
                  )} ${upperCase(former?.postName)}`}
                </Typography.Title>
                <Divider style={{ margin: "0.5rem 0" }} />
                <div className="row card--stretch">
                  <div className="col-md-3 item">
                    <ElementCard
                      subtitle="date de naissance"
                      title={
                        dateHelpers.getDate(former?.bornDate, "DD MMMM YYYY") ??
                        ""
                      }
                      icon=""
                    />
                  </div>
                  {!isEmpty(dateHelpers.getAge(former?.bornDate) ?? "") && (
                    <div className="col-md-3 item">
                      <ElementCard
                        subtitle="Age"
                        title={dateHelpers.getAge(former?.bornDate) ?? ""}
                        icon=""
                      />
                    </div>
                  )}
                  <div className="col-md-3 item">
                    <ElementCard
                      subtitle="Référence"
                      title={former?.commitmentReference}
                      icon=""
                    />
                  </div>
                  <div className="col-md-3 item">
                    <ElementCard
                      subtitle="Genre"
                      title={former?.sex === "f" ? "Femme" : "Homme"}
                      icon=""
                    />
                  </div>
                </div>
                <Divider />
              </div>
            </div>
          </section>
        )}
      </main>
    </React.Fragment>
  );
}
