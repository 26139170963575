import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface editionData {
  current: any;
  list: any[];
  all: any[];
  proclaims: any[];
}

const initialState: editionData = {
  current: {},
  all: [],
  list: [],
  proclaims: [],
};

export const editionSlice = createSlice({
  name: "editions",
  initialState,
  reducers: {
    setEdition: (state, action: PayloadAction<any>) => {
      state.current = action.payload;
    },
    setEditions: (state, action: PayloadAction<any>) => {
      state.list = action.payload;
    },
    setAllEditions: (state, action: PayloadAction<any[]>) => {
      state.all = action.payload;
    },
    setProclaims: (state, action: PayloadAction<any[]>) => {
      state.proclaims = action.payload;
    },
  },
});
export const { setAllEditions, setEdition, setEditions, setProclaims } =
  editionSlice.actions;
export default editionSlice.reducer;
