import { Divider, Typography } from "antd";
import React from "react";
import { DillInput } from "../../../components/input";
import { EffectifSector } from "../sheet/table";
import { Spreadsheet } from "../../../components/table/items";
import { ColumnsType } from "antd/es/table";

export const MovementCarrer: React.FC<{ formik: any }> = ({ formik }) => {
  const [columns] = React.useState<
    ColumnsType<any> & {
      children?: ColumnsType<any>;
    }
  >([
    { title: `Année de formation 20… 20…`, dataIndex: "year" },
    { title: "Nom et code de l’établissement", dataIndex: "center" },
    { title: "Mention obtenu", dataIndex: "mention" },
  ]);

  return (
    <React.Fragment>
      <Divider style={{ margin: 5 }} />
      <Typography.Title
        level={2}
        style={{ fontSize: 20, margin: 5, textAlign: "center" }}
      >{`III. LE PARCOURS DE FORMATION EFFECTUE PAR L’APPRENANT`}</Typography.Title>
      <DillInput formik={formik} name="reserve" label="RESERVE" inline />
      <Spreadsheet
        formik={formik}
        name="states"
        initItems={formik.values.states}
        colums={columns}
      />
    </React.Fragment>
  );
};
