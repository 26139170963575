import { Button, List, Modal, Popover, Tabs } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { TitleComponent } from "../components/title";
import { RootState } from "../redux/store";
import { getExtension } from "../utils/helpers/title";
import { isEmpty } from "lodash";

const MediaCard: React.FC<{
  type: "pdf" | "video";
  path: string;
  title: string;
  link?: string;
}> = ({ type, path, title, link }) => {
  {
    /*<div className="content">
            <i className="fa-solid fa-video icon"></i>
            <div className="title">{title}</div>
          </div>*/
  }
  function onOpen(link: string) {
    /**@ts-ignore */
    video?.current?.pause();
    window.open(link, "_blank");
  }

  let video = React.createRef();

  return (
    <React.Fragment>
      {/* <a
        className="card--book"
        href={path}
        target="_blank"
        rel="noopener noreferrer"
      >
        {type === "video" && (
          <video
            src={path}
            controls
            className="video"
            onPlay={() => onOpen(path)}
            //**@ts-ignore
            ref={video}
          />
        )}
        {type === "pdf" && (
          <div className="content">
            <i className="fa-solid fa-file-pdf icon"></i>
            <div className="title">{title}</div>
          </div>
        )}
      </a> */}
      <Link className="card--book" to={link ?? "#"}>
        {type === "video" && (
          // <video
          //   src={path}
          //   controls
          //   className="video"
          //   onPlay={() => onOpen(path)}
          //   /**@ts-ignore */
          //   ref={video}
          // />
          <div className="content">
            <i className="fa-solid fa-video icon"></i>
            <div className="title">{title}</div>
          </div>
        )}
        {type === "pdf" && (
          <div className="content">
            <i className="fa-solid fa-file-pdf icon"></i>
            <div className="title">{title}</div>
          </div>
        )}
      </Link>
    </React.Fragment>
  );
};

export function BookstoreScreen() {
  const books = useSelector((state: RootState) => state.book.all);
  const navigate = useNavigate();
  const route = useLocation();
  const [activeKey, setActiveKey] = React.useState<string | undefined>();

  React.useEffect(() => {
    const args = new URLSearchParams(route.search);
    const type = args.get("type") ?? "";
    if (!isEmpty(type)) {
      switch (type) {
        case "book":
          setActiveKey("books");
          break;
        case "course":
          setActiveKey("courses");
          break;
        case "program":
          setActiveKey("programs");
          break;
        case "palmares":
          setActiveKey("palmares");
          break;

        default:
          break;
      }
    }
  }, []);

  function handleOpen(id: string) {
    navigate(`/admin/bookstore/${id}`);
  }
  const renderItem = (item: any, index: number) => {
    const extension = getExtension(item.path);
    return (
      <List.Item>
        <MediaCard
          path={item.path}
          title={item.title}
          type={extension === "pdf" ? "pdf" : "video"}
          link={`/admin/bookstore/${item.id}`}
        />
      </List.Item>
    );
  };

  function handleGoToStudio() {}

  return (
    <React.Fragment>
      <main className="main main yc_container_main">
        <section className="container">
          <TitleComponent title="Bibliothèque">
            <Popover
              popupVisible
              title="Action sur la bibliothèque"
              trigger="click"
              content={
                <div>
                  <div>
                    <Button
                      className="btn--round"
                      type="default"
                      style={{ width: "100%", borderRadius: 0 }}
                    >
                      <Link
                        to={`/admin/bookstore/create?type=book`}
                      >{`Ajouter un livre`}</Link>
                    </Button>
                  </div>
                  <div>
                    <Button
                      className="btn--round"
                      type="default"
                      style={{ width: "100%", borderRadius: 0 }}
                    >
                      <Link
                        to={`/admin/bookstore/create?type=program`}
                      >{`Ajouter un programme scolaire`}</Link>
                    </Button>
                  </div>
                  <div>
                    <Button
                      className="btn--round"
                      type="default"
                      style={{ width: "100%", borderRadius: 0 }}
                    >
                      <Link
                        to={`/admin/bookstore/create?type=course`}
                      >{`Ajouter un cours en ligne`}</Link>
                    </Button>
                  </div>
                </div>
              }
            >
              <Button className="btn--round">
                {`Ajouter les éléments à la bibliothèque`}
              </Button>
            </Popover>
          </TitleComponent>
          <Tabs
            activeKey={activeKey}
            onChange={(v) => setActiveKey(v)}
            type="card"
          >
            <Tabs.TabPane tab="Livres" tabKey="books" key="books">
              <List
                grid={{ gutter: 8, lg: 4, xl: 4, xxl: 4 }}
                dataSource={books.filter((p) => p.category === "book")}
                renderItem={renderItem}
                className="list--stretch"
              />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab="Programmes scolaires"
              tabKey="programs"
              key="programs"
            >
              <List
                grid={{ gutter: 8, lg: 4, xl: 4, xxl: 4 }}
                dataSource={books.filter((p) => p.category === "program")}
                renderItem={renderItem}
                className="list--stretch"
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Cours en ligne" tabKey="courses" key="courses">
              <List
                grid={{ gutter: 8, lg: 4, xl: 4, xxl: 4 }}
                dataSource={books.filter((p) => p.category === "course")}
                renderItem={renderItem}
                className="list--stretch"
              />
            </Tabs.TabPane>
            {/*<Tabs.TabPane
              tab="Palmarès"
              key="palmares"
              tabKey="palmares"
            ></Tabs.TabPane>*/}
          </Tabs>
        </section>
      </main>
    </React.Fragment>
  );
}
