import { isEmpty } from "lodash";

class DraftHelper {
  private key: string = "@yekola-plug";

  private loadData(
    type?:
      | "building"
      | "learner"
      | "center"
      | "establishment"
      | "movement"
      | "register"
      | "administrative"
  ) {
    let result: any = {};
    const value = localStorage.getItem(this.key);
    if (value != null) {
      result = JSON.parse(value);
      if (!isEmpty(type)) {
        if (type !== result.current) {
          result = {};
        }
      }
    }
    return result;
  }

  getData(
    type?:
      | "building"
      | "learner"
      | "center"
      | "establishment"
      | "movement"
      | "register"
      | "administrative"
  ) {
    let result: any = {};
    const value = this.loadData(type);
    result = value?.data ?? {};
    return result;
  }

  getCategory() {
    const data = this.loadData();
    return data.current;
  }

  getCreatedAt() {
    const data = this.loadData();
    return data.createdAt;
  }

  getUpdatedAt() {
    const data = this.loadData();
    return data.updatedAt;
  }

  async setData(
    value: any,
    type:
      | "building"
      | "learner"
      | "center"
      | "establishment"
      | "movement"
      | "register"
      | "administrative"
  ) {
    const data = this.loadData();
    const item: any = {
      updatedAt: new Date(),
      data: value,
      current: type,
    };
    if (data?.current !== type && !isEmpty(data?.current)) {
      return;
    }
    if (isEmpty(data)) {
      item.createdAt = new Date();
      item.current = type;
    } else {
      item.createdAt = data.createdAt;
    }
    localStorage.setItem(this.key, JSON.stringify(item));
  }
  remove() {
    localStorage.removeItem(this.key);
  }
}

export default new DraftHelper();
