import { Col, Modal, Row, Skeleton, Typography } from "antd";
import React from "react";
import { ElementCard } from "../../components/element";
import { useParams } from "react-router-dom";
import bookService from "../../services/bookService";
import dateHelpers from "../../utils/helpers/dateHelpers";
import { getExtension } from "../../utils/helpers/title";

export function BookView() {
  const params = useParams();
  const [book, setBook] = React.useState<any>({});
  const [loading, setLoading] = React.useState(false);
  let video = React.createRef();

  React.useEffect(() => {
    setLoading(true);
    init();
  }, [params]);

  async function init() {
    const id = params.id;
    setLoading(true);
    await bookService
      .get(`${id}`)
      .then((response) => {
        setBook(response.data);
        console.log("BOOK", response.data);
      })
      .catch((reason) => {
        const error = bookService.getError(reason);
        whenError();
      });
    setLoading(false);
  }

  function whenError() {
    Modal.confirm({
      centered: true,
      okText: "Recharger",
      cancelText: "Quitter",
      //onCancel:()=>{window.history?.back();},
      onOk: init,
      content:
        "Le livre demandé ne semble pas exister ou il y a un problème de connexion",
      title: "Recherche non aboutie",
      okButtonProps: { loading: loading },
    });
  }

  function onOpen(link: string) {
    /**@ts-ignore */
    video?.current?.pause();
    window.open(link, "_blank");
  }
  return (
    <React.Fragment>
      <main className="main main yc_container_main">
        <section className="container">
          {!loading && (
            <>
              <Typography.Title className="search--title">
                <span className="item">{`Livre`}</span>
              </Typography.Title>
              <Row gutter={16} style={{ height: "100%", marginTop: 10 }}>
                <Col>
                  <ElementCard
                    type="primary"
                    icon=""
                    subtitle="Titre"
                    title={book?.title}
                  />
                  <ElementCard
                    type="gold"
                    icon=""
                    subtitle="Dernière mise à jour"
                    title={dateHelpers.getDate(
                      book.updatedAt,
                      "dddd DD MMM YYYY HH:mm"
                    )}
                  />
                </Col>
                <Col flex={1}>
                  {getExtension(book.path) === "pdf" ? (
                    <iframe
                      style={{ width: "100%", height: "100vh" }}
                      src={book.path}
                    ></iframe>
                  ) : (
                    <video
                      src={book.path}
                      controls
                      className="video"
                      onPlay={() => onOpen(book.path)}
                      /**@ts-ignore */
                      ref={video}
                    />
                  )}
                </Col>
              </Row>
            </>
          )}
          {loading && (
            <>
              <Skeleton active />
              <Skeleton active />
              <Skeleton active />
              <Skeleton active />
            </>
          )}
        </section>
      </main>
    </React.Fragment>
  );
}
