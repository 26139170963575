import React from "react";
import styled from "styled-components";
import { useTable, usePagination } from "react-table";
import PropTypes from "prop-types";
import { ColumnsType } from "antd/es/table";
import { isEmpty, random, toNumber } from "lodash";
import { v4 as uid } from "uuid";
import { Button, Divider, Typography } from "antd";
import { FormikProps } from "formik";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

const Styles = styled.div`
  padding: 0rem;
  overflow-x: auto;
  table {
    border-spacing: 0;
    border: 1px solid black;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid #0004;
      border-right: 1px solid #0004;
      :last-child {
        border-right: 0;
      }
      input {
        padding: 0;
        margin: 0;
        border: 0;
      }
    }
    th {
      border-bottom: 0px solid #0004;
      border-right: 0px solid #0004;
      border-top: 1px solid #0004;
      border-bottom: 0px solid #0004;
    }
  }
  .pagination {
    padding: 0.5rem;
  }
`;

// Create an editable cell renderer
const EditableCell: React.FC<{
  value: any;
  row: any;
  column: any;
  updateMyData: any;
}> = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateMyData, // This is a custom function that we supplied to our table instance
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue);

  const onChange = (e: any) => {
    setValue(e.target.value);
  };

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateMyData(index, id, value);
  };

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <input
      value={value}
      className="input"
      onChange={onChange}
      onBlur={onBlur}
    />
  );
};

// Set our editable cell renderer as the default Cell renderer
const defaultColumn = {
  Cell: EditableCell,
};

// Be sure to pass our updateMyData and the skipPageReset option
const Table: React.FC<{
  columns: any[];
  data: any[];
  updateMyData: any;
  skipPageReset: boolean;
}> = ({ columns, data, updateMyData, skipPageReset }) => {
  // For this example, we're using pagination to illustrate how to stop
  // the current page from resetting when our data changes
  // Otherwise, nothing is different here.
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    /**@ts-ignore */
    page,
    /**@ts-ignore */
    canPreviousPage,
    /**@ts-ignore */
    canNextPage,
    /**@ts-ignore */
    pageOptions,
    /**@ts-ignore */
    pageCount,
    /**@ts-ignore */
    gotoPage,
    /**@ts-ignore */
    nextPage,
    /**@ts-ignore */
    previousPage,
    /**@ts-ignore */
    setPageSize,
    /**@ts-ignore */
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      /**@ts-ignore */
      defaultColumn,
      // use the skipPageReset option to disable page resetting temporarily
      autoResetPage: !skipPageReset,
      // updateMyData isn't part of the API, but
      // anything we put into these options will
      // automatically be available on the instance.
      // That way we can call this function from our
      // cell renderer!
      updateMyData,
    },
    usePagination
  );

  React.useEffect(() => {
    setPageSize(data.length > 0 ? data.length : 10);
  }, [data]);

  // Render the UI for your table
  return (
    <>
      <table {...getTableProps()} className="tableur">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              key={`${random(1, 9999, false)}_${uid()}_dd`}
            >
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  key={`${random(1, 9999, false)}_${uid()}`}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {/**@ts-ignore */}
          {page?.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={`INDE${i}`}>
                {/**@ts-ignore */}
                {row?.cells?.map((cell, j) => {
                  return (
                    <td key={`FG${j}`} {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export const Spreadsheet: React.FC<{
  formik: FormikProps<any>;
  title?: string;
  name?: string;
  counter?: boolean;
  genericText?: string;
  genericValue?: string;
  colums: ColumnsType<any> & { children?: ColumnsType<any> };
  initItems: any[];
  showAddButton?: boolean;
  tabProps?: any;
}> = ({
  formik,
  colums,
  initItems,
  title,
  name,
  counter,
  genericText,
  genericValue,
  showAddButton = true,
  tabProps,
}) => {
  const [headers, setHeaders] = React.useState<any[]>([]);
  const columnList = React.useMemo(() => headers, [headers]);
  const drafting = useSelector((state: RootState) => state.sheet.drafting);

  const [data, setData] = React.useState<any[]>(() => []);
  const [originalData] = React.useState(data);
  const [skipPageReset, setSkipPageReset] = React.useState(false);
  const [count, setCount] = React.useState(0);

  /**@ts-ignore */
  const updateMyData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
    /**@ts-ignore */
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            /**@ts-ignore */
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };

  // After data chagnes, we turn the flag back off
  // so that if data actually changes when we're not
  // editing it, the page is reset
  React.useEffect(() => {
    setSkipPageReset(false);
    const elements: any[] = [];
    const values = { ...formik.values };
    data.map((p) => {
      elements.push(p);
    });
    const metaName = name ?? "";
    if (!isEmpty(metaName)) {
      values[metaName] = elements;
      formik.setValues(values);
    }
  }, [data]);

  React.useEffect(() => {
    const items: any[] = [];
    colums.map((p) => {
      const item: any = {};
      item.Header = p.title;
      const children: any[] = [];
      /**@ts-ignore */
      if (p?.children?.length > 0) {
        /**@ts-ignore */
        p?.children?.map((x) => {
          children.push({
            accessor: x?.dataIndex,
            Header: x?.title,
          });
        });
        item.columns = children;
      } else {
        /**@ts-ignore */
        item.accessor = p?.dataIndex;
      }
      items.push(item);
    });
    setHeaders(items);
    const elements: any[] = [];
    initItems.map((p) => {
      elements.push(p);
    });
    setData(() => elements);
  }, []);

  React.useEffect(() => {
    if (count < 3) {
      const items = formik.values[name ?? ""] ?? [];
      const elements: any[] = [];
      items.map((p: any) => {
        elements.push(p);
      });
      if (elements.length > 0) {
        setData(() => elements);
      }
      setCount(count + 1);
    }
  }, [formik.values[name ?? ""], formik.values]);

  // Let's add a data resetter/randomizer to help
  // illustrate that flow...
  const resetData = () => setData(originalData);

  const handleAdd = () => {
    const n = data.length;
    const elements: any[] = [];
    data.map((p) => {
      elements.push(p);
    });
    let freeKey = `${n}`;
    elements.forEach((p) => {
      const nK = toNumber(p.key);
      if (nK === toNumber(freeKey)) {
        freeKey = `${toNumber(freeKey) + 1}`;
      }
    });
    let newData: any = {};
    newData.key = freeKey;
    if (!isEmpty(genericValue)) {
      let text = `${genericText ?? ""}`;
      if (counter) {
        text += ` ${n + 1}`;
      }
      newData[genericValue ?? ""] = text;
    }
    elements.push(newData);
    setData(elements);
  };

  return (
    <section>
      <Divider style={{ margin: 5 }} />
      <Typography.Title style={{ margin: 0, fontSize: 20 }} level={2}>
        {title}
      </Typography.Title>
      <Styles>
        <Table
          columns={columnList}
          data={data}
          updateMyData={updateMyData}
          skipPageReset={skipPageReset}
        />
        {data.length === 0 && (
          <div
            style={{
              width: "100%",
              height: 150,
              border: "ridge",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {`VIDE`}
          </div>
        )}
      </Styles>
      {showAddButton && (
        <Button
          onClick={handleAdd}
          type="primary"
          style={{ marginBottom: 16, marginTop: 16 }}
        >
          Ajouter une ligne
        </Button>
      )}
    </section>
  );
};
