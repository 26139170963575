import { publicRoutes } from "./routes/public";
import { connectedRoutes } from "./routes/connected";
import { divisionRoutes } from "./routes/division";
import { subDivisionRoutes } from "./routes/sub-division";
import { centerRoutes } from "./routes/center";
import { learnerRoutes } from "./routes/learner";
import { formerRoutes } from "./routes/former";
import { examRoutes } from "./routes/exam";
import { calendarRoutes } from "./routes/calendar";
import { mailRoutes } from "./routes/mails";
import { sessionRoutes } from "./routes/session";
import { dssRoutes } from "./routes/dss";
declare type layoutType = "/admin" | "/home" | "/" | "/mail";
export declare type userType = "student" | "responsible" | "teacher";

export interface routeInterface {
  name: string;
  label: string;
  path: string;
  layout: layoutType;
  isMenu: boolean;
  component: any;
  icon: any | null;
  iconTransition?: any | null;
  parent?: string;
  visibility?: userType[];
  subscribeable: boolean;
  title?: string;
  priority?: number;
  group?: string;
  description?: string;
}

const routes: routeInterface[] = [];

function addRoutes(...list: routeInterface[][]) {
  for (let items of list) {
    items.map((element) => {
      const index = routes.findIndex((p) => p.name === element.name);
      if (index === -1) {
        routes.push(element);
      }
    });
  }
}

addRoutes(
  divisionRoutes,
  subDivisionRoutes,
  centerRoutes,
  learnerRoutes,
  formerRoutes,
  connectedRoutes,
  publicRoutes,
  examRoutes,
  calendarRoutes,
  mailRoutes,
  sessionRoutes,
  dssRoutes
);

export { routes };
