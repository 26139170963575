import { notification, Steps } from "antd";
import { useFormik } from "formik";
import React from "react";
import { CreateFileBook } from "./file";
import * as yup from "yup";
import { BookSectorChoices } from "./choices";
import { isEmpty } from "lodash";
import bookService from "../../services/bookService";
import { useDispatch } from "react-redux";
import userHelper from "../../utils/helpers/userHelper";
import { useLocation, useNavigate } from "react-router-dom";

export function CreateBookView() {
  const [step, setStep] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const route = useLocation();
  const [type, setType] = React.useState("");
  const navigate = useNavigate();

  React.useEffect(() => {
    const args = new URLSearchParams(route.search);
    setType(args.get("type") ?? "");
  }, [route]);

  const formik1 = useFormik({
    initialValues: {
      video: null,
      pdf: null,
      title: "",
    },
    onSubmit: onSave1,
    validationSchema: yup.object({
      title: yup.string().required("Champ requis"),
    }),
  });

  const formik2 = useFormik({
    initialValues: {
      items: [],
    },
    onSubmit: onSave2,
    validationSchema: yup.object({
      items: yup.array().typeError("").required("Champ requis"),
    }),
  });

  function onSave1() {
    setStep(step + 1);
  }

  async function onSave2() {
    setLoading(true);
    const { pdf, video, title } = formik1.values;
    const filePdf: any = pdf;
    const fileVideo: any = video;
    const { items } = formik2.values;
    const data = new FormData();
    if (!isEmpty(filePdf)) {
      data.append("file", filePdf?.originFileObj ?? "");
    } else {
      data.append("file", fileVideo?.originFileObj ?? "");
    }
    const list: any[] = [];
    items.forEach((p: any) => {
      list.push({ sector: `/api/sectors/${p.id}` });
    });
    data.append("title", title);
    data.append("category", type);
    await bookService
      .store(data)
      .then(async (response) => {
        const rep = response.data;
        await addSectors(rep.id, list);
        await userHelper.getBooks(dispatch);
        formik1.resetForm({ values: { pdf: null, video: null, title: "" } });
        formik2.resetForm({ values: { items: [] } });
        setStep(0);
        notification.info({
          message: "Enregistrement",
          description: "Un nouvel élement est ajouté dans la bibliothèque",
        });
        navigate(`/admin/bookstores?type=${type}`);
      })
      .catch((reason) => {
        const error = bookService.getError(reason);
        notification.error({
          message: "Erreur d'enregistrement",
          description: error,
        });
      });
    setLoading(false);
  }

  async function addSectors(id: string, list: any[]) {
    await bookService
      .update(id, { bookSectors: list })
      .then(() => {})
      .catch(() => {});
  }

  return (
    <React.Fragment>
      <main className="main main yc_container_main">
        <section className="container">
          <div className="row">
            <div className="col-md-3" style={{ margin: "1.6rem 0" }}>
              <Steps
                direction="vertical"
                current={step}
                percent={((step + 1) / 2) * 100}
              >
                <Steps.Step title="Contenu" stepIndex={0} />
                <Steps.Step title="Choix des filières" stepIndex={1} />
              </Steps>
            </div>
            <div className="col-md-9">
              {step === 0 && <CreateFileBook formik={formik1} />}
              {step === 1 && (
                <BookSectorChoices loading={loading} formik={formik2} />
              )}
            </div>
          </div>
        </section>
      </main>
    </React.Fragment>
  );
}
