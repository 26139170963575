import { routeInterface } from "../routes";
import { FormersScreen } from "../screens/formers";
import { CreateFormerScreen } from "../screens/former/create";
import { FormerView } from "../screens/former/view";
export const formerRoutes: routeInterface[] = [
  {
    component: CreateFormerScreen,
    icon: `fa-sharp fa-light fa-user-plus`,
    isMenu: false,
    label: "Ajouter un formateur",
    layout: "/admin",
    name: "former-create",
    path: "/former/create",
    subscribeable: false,
  },
  {
    component: FormersScreen,
    icon: `fa-light fa-person-chalkboard`,
    isMenu: true,
    label: "Liste de formateurs",
    layout: "/admin",
    name: "former-screen",
    path: "/formers",
    subscribeable: false,
  },
  {
    component: FormerView,
    icon: ``,
    isMenu: false,
    label: "",
    layout: "/admin",
    name: "former-view",
    path: "/former/:id",
    subscribeable: false,
  },
];
