import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface mailData {
  current: any;
  list: any[];
  all: any[];
  profil?: any;
  notifs: any[];
}

const initialState: mailData = {
  current: {},
  all: [],
  list: [],
  notifs: [],
};

export const mailSlice = createSlice({
  name: "mails",
  initialState,
  reducers: {
    setMail: (state, action: PayloadAction<any>) => {
      state.current = action.payload;
    },
    setNotifMails: (state, action: PayloadAction<any[]>) => {
      state.notifs = action.payload;
    },
    setMails: (state, action: PayloadAction<any>) => {
      state.list = action.payload;
    },
    setAllMails: (state, action: PayloadAction<any[]>) => {
      state.all = action.payload;
    },
  },
});
export const { setAllMails, setMail, setMails, setNotifMails } =
  mailSlice.actions;
export default mailSlice.reducer;
