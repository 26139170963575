import { Typography } from "antd";
import { isEmpty } from "lodash";
import React from "react";
import { useParams } from "react-router-dom";
import { DivisionMap } from "../../components/map";
import centerService from "../../services/centerService";
import divisionService from "../../services/divisionService";
import { mapMarker } from "../../utils/helpers/title";
import { calculateCenter } from "../../utils/helpers/geoHelper";

export function DivisionShowView() {
  const [centers, setCenters] = React.useState<any[]>([]);
  const [division, setDivision] = React.useState<any>({});
  const [markers, setMarkers] = React.useState<mapMarker[]>([]);
  const params = useParams();
  const [loading, setLoading] = React.useState(true);
  const [center, setCenter] = React.useState<
    google.maps.LatLngLiteral | undefined
  >();

  async function init() {
    const id = params.id;
    setLoading(true);
    const list: mapMarker[] = [];

    divisionService
      .get(`${id}`)
      .then((response) => {
        setDivision(response.data);
      })
      .catch(() => {});
    await centerService
      .getByKey(`subDivision.division=${id}`)
      .then((response) => {
        const data = centerService.getData(response);
        data.forEach((p) => {
          let { latitude, longitude, name } = p;
          if (latitude === 0 || longitude === 0) {
            latitude = null;
            longitude = null;
          }
          if (latitude && longitude && !isEmpty(name)) {
            list.push({
              position: { lat: latitude, lng: longitude },
              title: name,
            });
          }
        });
        if (list.length === 1) {
          setCenter(list[0].position);
        } else {
          const coordinates: google.maps.LatLngLiteral[] = [];
          list.forEach((p) => {
            coordinates.push(p.position);
          });
          const c: google.maps.LatLngLiteral = calculateCenter(coordinates);
          setCenter(c);
        }
        setMarkers(list);
      })
      .catch((reason) => {});
    setLoading(false);
  }

  React.useEffect(() => {
    init();
  }, [params.id]);

  return (
    <React.Fragment>
      <section className="container">
        <div>
          <Typography.Title className="search--title">
            {division?.name}
          </Typography.Title>
        </div>
      </section>
      {!loading && (
        <DivisionMap
          markers={markers}
          zoom={isEmpty(center) ? 6 : 13}
          center={center}
        />
      )}
    </React.Fragment>
  );
}
