import { isEmpty } from "lodash";
import React from "react";
import { ElementCard } from "../../components/element";
import { getUid } from "../../utils/helpers/title";

export const ProfileAccessView: React.FC<{ admin: any }> = ({ admin }) => {
  return (
    <React.Fragment>
      <div
        className="row"
        style={{ justifyContent: "center", alignItems: "center" }}
      >
        <div className="col-md-6 item">
          {!isEmpty(admin?.center?.name) && (
            <ElementCard
              icon="fa-solid fa-location-dot"
              subtitle="Responsable de l'établissement"
              title={admin?.center?.name}
              type="danger"
              url={`/admin/center/${admin?.center?.id ?? ""}`}
            />
          )}
          {!isEmpty(admin?.subDivision?.name) && (
            <ElementCard
              icon="fa-duotone fa-location-crosshairs"
              subtitle="Responsable de sous-division et ses établissements"
              title={admin?.subDivision?.name}
              type="gold"
              url={`/admin/sub_division/${getUid(
                admin?.subDivision?.id ?? ""
              )}`}
            />
          )}
          {!isEmpty(admin?.division?.name) && (
            <ElementCard
              icon="fa-solid fa-location-crosshairs"
              subtitle="Responsable de division, ses sous-provinces éducationnelles et établissements"
              title={admin?.division?.name}
              type="primary"
              url={`/admin/division/${getUid(admin?.division?.id ?? "")}`}
            />
          )}
          {isEmpty(admin?.division) &&
            isEmpty(admin?.subDivision) &&
            isEmpty(admin?.center) && (
              <ElementCard
                icon="fa-solid fa-location-crosshairs"
                subtitle="Responsable de système"
                title={"Responsabilité globale sur tout le système"}
                type="primary"
              />
            )}
        </div>
      </div>
    </React.Fragment>
  );
};
