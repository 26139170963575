import {
  Button,
  Divider,
  FormInstance,
  Modal,
  Skeleton,
  Table,
  Typography,
  notification,
} from "antd";
import React from "react";
import { useParams } from "react-router-dom";
import examService from "../../services/examService";
import { ElementCard } from "../../components/element";
import learnerService from "../../services/learnerService";
import { getExamStatus, getStatus } from "../../utils/helpers/title";
import { isEmpty, toNumber, toString } from "lodash";
import userHelper from "../../utils/helpers/userHelper";
import { useFormik } from "formik";
import * as yup from "yup";
import { DillInput } from "../../components/input";
import examResultService from "../../services/examResultService";
import dateHelpers from "../../utils/helpers/dateHelpers";

export function ViewExam() {
  const params = useParams();
  const [loading, setLoading] = React.useState(true);
  const [saving, setSaving] = React.useState(false);
  const [current, setCurrent] = React.useState<any>({});
  const [learners, setLearners] = React.useState<any[]>([]);
  const [selected, setSelected] = React.useState<any>({});
  const [visible, setVisible] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      leanerNote: 0,
    },
    onSubmit: onSave,
    validationSchema: yup.object({
      leanerNote: yup
        .number()
        .typeError("Ecrivez un nombre")
        .positive("Nombre positif requis")
        .required("Champs requis"),
    }),
  });

  async function onSave() {
    if (isEmpty(current) || isEmpty(selected)) {
      return;
    }
    const { leanerNote } = formik.values;
    const data: any = {
      leanerNote: toNumber(leanerNote),
      leaner: `/api/leaners/${selected.id}`,
      exams: `/api/exams/${current.id}`,
    };
    const id = selected.resultId;
    setSaving(true);
    if (isEmpty(toString(id))) {
      await examResultService
        .store(data)
        .then(async () => {
          await init();
          setVisible(false);
          formik.resetForm();
        })
        .catch((reason) => {
          const error = examResultService.getError(reason);
          notification.error({
            message: "Enregistrement de la côte",
            description: error,
          });
        });
    } else {
      await examResultService
        .update(`${id}`, data)
        .then(async () => {
          await init();
          setVisible(false);
          formik.resetForm();
        })
        .catch((reason) => {
          const error = examResultService.getError(reason);
          notification.error({
            message: "Enregistrement de la côte",
            description: error,
          });
        });
    }
    setSaving(false);
    setSelected({});
  }

  React.useEffect(() => {
    init();
  }, [params.id]);

  function handleSave() {
    formik.submitForm();
  }

  async function init() {
    const id = params.id;
    await examService
      .get(`${id}`)
      .then(async (response) => {
        const data = response.data;
        setCurrent(data);
        await getLearners(data?.training?.id ?? "", data);
      })
      .catch((reason) => {});
    setLoading(false);
  }

  async function getLearners(id: string, item: any) {
    const results = await getAllResult(item.id);
    await learnerService
      .getByKey(`training=${id}`)
      .then(async (response) => {
        let data = learnerService.getData(response);
        data = data.filter((p) => !isEmpty(p.user));
        await Promise.all(
          data.map(async (p) => {
            const result = await getResult(p.id, item, results);
            p.resultId = result.id;
            if (item.maxima > 0) {
              p.result = `${result.leanerNote ?? 0}/${item.maxima}`;
            } else {
              p.result = result.leanerNote ?? 0;
            }
          })
        );
        setLearners(data);
      })
      .catch((reason) => {});
  }

  async function getResult(id: string, item: any, list: any[]) {
    let result: any = {};
    if (isEmpty(toString(item.id)) || isEmpty(toString(id))) {
      return result;
    }
    const elem = list.find((p) => p?.leaner?.id == id);
    result = elem ?? {};
    return result;
  }

  async function getAllResult(id: any) {
    let items: any[] = [];
    await examResultService
      .getByKey(`exams=${id}`)
      .then((response) => {
        items = examResultService.getData(response);
      })
      .catch(() => {});
    return items;
  }

  function handleOpen(data: any) {
    setSelected(data);
    formik.setValues({ leanerNote: data.result ?? 0 });
    setVisible(true);
  }

  return (
    <React.Fragment>
      <main className="main main yc_container_main">
        <section className="container">
          {loading && (
            <>
              <Skeleton active />
              <Skeleton active />
              <Skeleton active />
            </>
          )}
          {!loading && (
            <>
              <Typography.Title className="search--title">
                <span className="item">{current?.title}</span>
              </Typography.Title>
              <Divider />
              <div className="row card--stretch">
                <div className="col-md-3 item">
                  <ElementCard
                    subtitle="Filière"
                    title={current?.training?.sector?.name}
                    icon=""
                  />
                </div>
                <div className="col-md-3 item">
                  <ElementCard
                    subtitle="Etablissement"
                    title={current?.center?.name}
                    icon=""
                  />
                </div>
                <div className="col-md-3 item">
                  <ElementCard
                    subtitle="Durée en mois"
                    title={current?.training?.duration}
                    icon=""
                  />
                </div>
                <div className="col-md-3 item">
                  <ElementCard
                    subtitle="Pondération"
                    title={`${current.maxima} point${
                      current.maxima > 1 ? "s" : ""
                    }`}
                    icon=""
                  />
                </div>
                <div className="col-md-3 item">
                  <ElementCard
                    subtitle="Etat de publication"
                    title={getExamStatus(current.status, current.date)}
                    icon=""
                  />
                </div>
                <div className="col-md-3 item">
                  <ElementCard
                    subtitle="SESSION"
                    title={current?.examsSession?.edition}
                    icon=""
                  />
                </div>
                <div className="col-md-3 item">
                  <ElementCard
                    subtitle="ETAT DE LA SESSION"
                    title={getStatus(current?.examsSession?.status).title}
                    icon=""
                  />
                </div>
                <div className="col-md-3 item">
                  <ElementCard
                    subtitle="Date de l'examen"
                    title={dateHelpers.getDate(current.date, "DD MMM YYYY")}
                    icon=""
                  />
                </div>
              </div>
              <Divider />
              {
                <Table
                  dataSource={learners}
                  columns={[
                    {
                      title: "N°",
                      render(value, record, index) {
                        return index + 1;
                      },
                    },
                    {
                      title: "Nom complet",
                      dataIndex: "user",
                      render(value, record, index) {
                        return userHelper.getUserName(value);
                      },
                      align: "center",
                    },
                    {
                      title: "Côte",
                      dataIndex: "result",
                      render(value, record, index) {
                        return value ?? "---";
                      },
                    },
                    {
                      title: "",
                      width: 100,
                      render(value, record, index) {
                        return (
                          <Button
                            shape="circle"
                            type="primary"
                            disabled={
                              current?.examsSession?.status === "published"
                            }
                            onClick={() => handleOpen(value)}
                          >
                            <i className="fa-solid fa-pen-to-square"></i>
                          </Button>
                        );
                      },
                    },
                  ]}
                  className="table"
                />
              }
            </>
          )}
        </section>
        <Modal
          open={visible}
          okText="Sauvegarder"
          onOk={handleSave}
          confirmLoading={saving}
          cancelText="Fermer"
          onCancel={() => {
            setVisible(false);
          }}
        >
          <div>
            <DillInput
              formik={formik}
              name="leanerNote"
              label={`Côte /${current.maxima}`}
              type="num"
            />
          </div>
        </Modal>
      </main>
    </React.Fragment>
  );
}
