import { Button, Input, List, Radio, Tag, Typography } from "antd";
import React from "react";
import { TitleComponent } from "../../components/title";
import centerService from "../../services/centerService";
import divisionService from "../../services/divisionService";
import subDivisionService from "../../services/subDivisionService";

export const MessageChoices: React.FC<{
  formik: any;
  type?: "division" | "sub-division" | "center" | string;
}> = ({ formik, type }) => {
  const [search, setSearch] = React.useState("");
  const [searching, setSearching] = React.useState(false);
  const [list, setList] = React.useState<any[]>([]);
  function handleChange(v: any) {
    formik.setFieldValue("all", v.target.value);
  }
  React.useEffect(() => {
    onSearch();
  }, [search]);

  async function onSearch() {
    if (search.length < 2) {
      return;
    }
    switch (type) {
      case "center":
        setSearching(true);
        await centerService
          .getByKey(`name=${search}`)
          .then((response) => {
            const data = centerService.getData(response);
            const items = [...formik.values.items];
            const elements = data.filter(
              (p) => items.findIndex((x: any) => x["@id"] === p["@id"]) === -1
            );
            setList(elements);
          })
          .catch(() => {});
        setSearching(false);
        break;
      case "division":
        setSearching(true);
        await divisionService
          .getByKey(`name=${search}`)
          .then((response) => {
            const data = divisionService.getData(response);
            const items = [...formik.values.items];
            const elements = data.filter(
              (p) => items.findIndex((x: any) => x["@id"] === p["@id"]) === -1
            );
            setList(elements);
          })
          .catch(() => {});
        setSearching(false);
        break;
      case "sub-division":
        setSearching(true);
        await subDivisionService
          .getByKey(`name=${search}`)
          .then((response) => {
            const data = subDivisionService.getData(response);
            const items = [...formik.values.items];
            const elements = data.filter(
              (p) => items.findIndex((x: any) => x["@id"] === p["@id"]) === -1
            );
            setList(elements);
          })
          .catch(() => {});
        setSearching(false);
        break;
    }
  }

  function setItems(list: any[]) {
    formik.setValues({ ...formik.values, items: list });
  }

  function onAdd(item: any) {
    const items = [...formik.values.items];
    let list = [...items];
    const index = items.findIndex((x: any) => x["@id"] === item["@id"]);
    if (index === -1) {
      list.push(item);
      setItems(list);
    }
  }

  function onDelete(item: any) {
    const items = [...formik.values.items];
    const list = items.filter((x: any) => x["@id"] !== item["@id"]);
    setItems(list);
  }

  const renderItem = (item: any) => {
    return (
      <List.Item>
        <Button
          className="btn--choice"
          onClick={() => onAdd(item)}
        >{`${item.name}`}</Button>
      </List.Item>
    );
  };

  const renderItemTag = (item: any) => {
    return (
      <List.Item>
        <Tag closable onClose={() => onDelete(item)}>
          {`${item.name}`}
        </Tag>
      </List.Item>
    );
  };
  return (
    <React.Fragment>
      <section className="box fadeIn">
        <div className="card box__search">
          <TitleComponent title={`Choix de ${type}`} type="creation" />
          <div className="card">
            <Typography.Title>{`Choisir les ${type}s`}</Typography.Title>
            <Radio.Group
              value={formik.values.all}
              options={[
                {
                  label: `Tou${type === "center" ? "s" : "tes"} les ${type}s`,
                  value: true,
                },
                { label: `Choisir les ${type}`, value: false },
              ]}
              onChange={handleChange}
            />
            {!formik.values.all && (
              <div className="fadeIn">
                <div style={{ margin: "0.75rem 0" }}>
                  <List
                    dataSource={formik.values.items}
                    renderItem={renderItemTag}
                    grid={{ gutter: 8 }}
                    locale={{ emptyText: "Liste vide" }}
                  />
                </div>
                <Input.Search
                  value={search}
                  loading={searching}
                  onChange={(v) => setSearch(v.target.value)}
                  onSearch={onSearch}
                />
                <div style={{ margin: "0.75rem 0" }}>
                  <List
                    dataSource={list}
                    renderItem={renderItem}
                    grid={{ column: 3, gutter: 8 }}
                    locale={{ emptyText: "Aucun élément" }}
                  />
                </div>
              </div>
            )}
            <div
              className="row"
              style={{
                alignItems: "center",
                justifyContent: "center",
                padding: "10px 0 0",
              }}
            >
              <Button
                onClick={() => formik.handleSubmit()}
                type="primary"
                disabled={
                  formik.values.all === false &&
                  formik.values.items?.length === 0
                }
              >{`Suivant`}</Button>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};
