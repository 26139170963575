import {
  Avatar,
  Button,
  Col,
  List,
  Modal,
  notification,
  Row,
  Table,
  Tag,
} from "antd";
import { ColumnsType } from "antd/es/table";
import { useFormik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { TitleComponent } from "../components/title";
import { RootState } from "../redux/store";
import { getExtension, getUid } from "../utils/helpers/title";
import * as yup from "yup";
import { DillInput, DillSelect } from "../components/input";
import sectorService from "../services/sectorService";
import userHelper from "../utils/helpers/userHelper";
import { capitalize } from "lodash";

export function SectorScreen() {
  const sectors = useSelector((state: RootState) => state.sector.all);
  const navigate = useNavigate();
  const [visible, setVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [current, setCurrent] = React.useState<any>({});
  const [currentType, setCurrentType] = React.useState<string>("");
  const [currentList, setCurrentList] = React.useState<any[]>([]);
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: yup.object({
      name: yup.string().required("Champ requis"),
    }),
    onSubmit: onSave,
  });

  async function onSave() {
    setLoading(true);
    const { name } = formik.values;
    await sectorService
      .store({ name, type: "sector" })
      .then(async (response) => {
        await userHelper.getSectors(dispatch);
        setVisible(false);
        formik.resetForm();
        notification.info({
          message: "Enregistrement de filière",
          description: "L'enregistrement a réussi",
        });
      })
      .catch((reason) => {
        const error = sectorService.getError(reason);
        notification.error({ message: "Erreur", description: error });
      });
    setLoading(false);
  }

  function handleSave() {
    formik.handleSubmit();
  }

  const columns: ColumnsType<any> = [
    {
      title: "N°",
      render(value, record, index) {
        return index + 1;
      },
    },
    { title: "Nom de la filière ou métier", dataIndex: "name" },
    {
      title: capitalize("établissements"),
      render(value, record, index) {
        return <Tag>{value.trainingDurations?.length}</Tag>;
      },
      onCell(data: any, index?: number) {
        return {
          onClick: () => handleClick(data, "center"),
        };
      },
    },
    {
      title: "Apprenants",
      render(value, record, index) {
        return <Tag>{value.leaners?.length}</Tag>;
      },
      onCell(data: any, index?: number) {
        return {
          onClick: () => handleClick(data, "learner"),
        };
      },
    },
    {
      title: "Ouvrages",
      render(value, record, index) {
        return <Tag>{value.bookSectors?.length}</Tag>;
      },
      onCell(data: any, index?: number) {
        return {
          onClick: () => handleClick(data, "book"),
        };
      },
    },
    {
      title: "Catégorie",
      render(value, record, index) {
        return (
          <Tag
            style={{ borderRadius: 8 }}
            color={value.type === "job" ? "blue" : "success"}
          >
            {value.type === "job" ? "Métier" : "Filière"}
          </Tag>
        );
      },
    },
    {
      title: "",
      render(value, record, index) {
        return (
          <div>
            <Button
              disabled={record.type === "job"}
              onClick={() => onRow(record, index)}
            >
              {`Ouvrir`}
            </Button>
          </div>
        );
      },
    },
  ];

  function handleClick(data: any, type: "learner" | "center" | "book") {
    setCurrentType(type);
    switch (type) {
      case "center":
        if (data.center?.length > 0) {
          setCurrent(data);
          setCurrentList(data.center);
          //setOpen(true);
        }
        break;
      case "book":
        if (data.bookSectors?.length > 0) {
          setCurrent(data);
          setCurrentList(data.bookSectors);
          setOpen(true);
        }
        break;
      case "learner":
        if (data.leaners?.length > 0) {
          setCurrent(data);
          setCurrentList(data.leaners);
          setOpen(true);
        }
        break;

      default:
        break;
    }
  }

  function handleOpen() {
    setVisible(true);
  }

  function handleHide() {
    if (loading) {
      return;
    }
    setVisible(false);
  }

  function goToUrl(uid: string) {
    if (currentType === "leaner") {
    }
    switch (currentType) {
      case "learner":
        navigate(`/admin/leaner/${uid}`);
        break;
      case "center":
        navigate(`/admin/center/${uid}`);
        break;
      case "book":
        navigate(`/admin/bookstore/${uid}`);
        break;

      default:
        break;
    }
  }

  const renderItem = (item: any) => {
    return (
      <List.Item style={{ cursor: "pointer" }} onClick={() => goToUrl(item.id)}>
        {currentType === "learner" && (
          <Row align="middle" gutter={8}>
            <Col>
              <Avatar src={`${item.imagePath}`}></Avatar>
            </Col>
            <Col>
              <div>{userHelper.getUserName(item?.user ?? {})}</div>
            </Col>
          </Row>
        )}
        {currentType === "book" && (
          <Row align="middle" gutter={8}>
            <Col>
              <Avatar>
                {getExtension(item?.book?.path) === "pdf" ? (
                  <i className="fa-solid fa-file-pdf icon"></i>
                ) : (
                  <i className="fa-solid fa-video icon"></i>
                )}
              </Avatar>
            </Col>
            <Col>
              <div>{item?.book?.title}</div>
            </Col>
          </Row>
        )}
      </List.Item>
    );
  };

  function onRow(data: any, index?: number | undefined) {
    navigate(`/admin/sectors/${data.id}/view`);
  }

  return (
    <React.Fragment>
      <main className="main main yc_container_main">
        <TitleComponent title="Toutes les filières et métiers">
          <Button
            className="btn--round"
            onClick={handleOpen}
          >{`Ajouter une filière`}</Button>
        </TitleComponent>
        <section className="container">
          <Table className="table" columns={columns} dataSource={sectors} />
        </section>
        <Modal
          title="Enregistrement de filière"
          open={visible}
          onCancel={handleHide}
          onOk={handleSave}
          confirmLoading={loading}
          centered
          okText="Enregistrer"
          cancelText="Annuler"
        >
          <div>
            <DillInput
              label="Le nom de la filière"
              formik={formik}
              name="name"
            />
          </div>
        </Modal>
        <Modal
          open={open}
          onCancel={() => setOpen(false)}
          centered
          footer={<div></div>}
          title={
            currentType === "learner"
              ? "Apprenants"
              : currentType === "book"
              ? "Livre"
              : currentType === "center"
              ? "Etablissements"
              : ""
          }
          cancelText=""
        >
          <div>
            <List dataSource={currentList} renderItem={renderItem} />
          </div>
        </Modal>
      </main>
    </React.Fragment>
  );
}
