import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import {
  PDFViewer,
  Document,
  Page,
  View,
  Text,
  Image,
  Font,
  StyleSheet,
} from "@react-pdf/renderer";
import { TableView } from "./table";
import CenterHelper from "../../utils/helpers/centerHelper";
import { centerMeta } from "../../utils/helpers/meta";
import { ItemTabView } from "./building/item";
import { Identification } from "./building/identification";
import { TablePdfView } from "../tablePDF";
import { isArray, isEmpty, isString } from "lodash";
import dateHelpers from "../../utils/helpers/dateHelpers";
import { ItemView } from "./center/itemView";
import { initAdministrativeSheets } from "../../utils/helpers/title";

Font.register({
  family: "calibri-bold-italic",
  src: require("../../assets/fonts/calibri/Calibri Bold Italic.ttf"),
});
Font.register({
  family: "calibri-bold",
  src: require("../../assets/fonts/calibri/Calibri Bold.TTF"),
});
Font.register({
  family: "calibri-italic",
  src: require("../../assets/fonts/calibri/Calibri Italic.ttf"),
});
Font.register({
  family: "calibri-light-italic",
  src: require("../../assets/fonts/calibri/Calibri Light Italic.ttf"),
});
Font.register({
  family: "calibri-light",
  src: require("../../assets/fonts/calibri/Calibri Light.ttf"),
});
Font.register({
  family: "calibri",
  src: require("../../assets/fonts/calibri/Calibri Regular.ttf"),
});

const bgColor = "#dcdcdc";

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "#FFF",
    padding: "20px",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
    backgroundColor: "red",
    border: "solid 5px yellow",
  },
  title: {
    textTransform: "uppercase",
    fontSize: 11,
    padding: "10px 0",
    fontFamily: "calibri-bold",
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    width: "100%",
    borderBottom: "1px solid #dadada",
  },
  rowItem: {
    display: "flex",
    flex: 1,
    fontSize: "14px",
    padding: "8px 0",
    justifyContent: "center",
    alignItems: "center",
  },
  heading: {
    backgroundColor: "#ececec",
  },
  logoCountry: {
    fontFamily: "calibri-italic",
    fontSize: 11,
    textAlign: "center",
  },
  logoHeader: {
    fontFamily: "calibri-bold",
    fontSize: 16,
    textTransform: "uppercase",
    textAlign: "center",
  },
  logoHeader2: {
    fontFamily: "calibri-bold",
    fontSize: 16,
    textAlign: "center",
  },
  title1: {
    fontSize: 11,
    fontFamily: "calibri",
    borderBottom: "1px solid #000",
    backgroundColor: bgColor,
    textAlign: "center",
    minHeight: "auto",
    color: "#000",
    alignItems: "center",
    justifyContent: "center",
    padding: "1px",
  },
  title2: {
    fontSize: 11,
    fontFamily: "calibri",
    borderBottom: "1px solid #000",
    backgroundColor: bgColor,
    textAlign: "center",
    minHeight: "auto",
    color: "#000",
    //height:"auto",
    padding: "1px",
    flex: 1,
  },
  value1: {
    fontSize: 11,
    fontFamily: "calibri",
    padding: 1,
    minHeight: "15px",
    height: "auto",
    display: "flex",
    justifyContent: "center",
    color: "#000",
  },
  view1: {
    width: "15%",
    borderRight: "1px solid #000",
  },
  view2: {
    borderRight: "1px solid #000",
    height: "100%",
  },
});

export function AdministrativePDF() {
  const plug = useSelector((state: RootState) => state.center.plug);
  const [center, setCenter] = React.useState<CenterHelper>(
    new CenterHelper({})
  );
  const [headers, setHeaders] = React.useState<any[]>([]);
  const [list1, setList1] = React.useState<any[]>([]);
  const [list2, setList2] = React.useState<any[]>([]);
  const [list3, setList3] = React.useState<any[]>([]);
  const [list4, setList4] = React.useState<any[]>([]);
  const [element1, setElement1] = React.useState<any[]>([]);
  const [element2, setElement2] = React.useState<any[]>([]);
  const [date, setDate] = React.useState("");

  React.useEffect(() => {
    const c = new CenterHelper(plug, "learnerPlugMetas");
    setCenter(c);
    let elements: any[] = [];
    console.log("PLUG", plug);
    initAdministrativeSheets.map((p, i) => {
      const name = p.ministry;
      const elem = c.getMetaValue(name, false, plug);
      if (isString(elem)) {
        elements.push({
          ministry: name,
          professional: elem,
        });
      } else {
        elements.push({
          ministry: name,
          professional: "",
        });
      }
    });
    setList1(isEmpty(elements) ? [] : elements);
    setHeaderElements(c);
    setPart1(c);
    setPart2(c);
  }, [plug]);

  function setHeaderElements(c: CenterHelper) {
    let list: any[] = [];
    list.push({ label: "PROVINCE :", value: plug.province });
    list.push({
      label: "PROV FORM  :",
      value: c.getMetaValue("subProvince", false, plug),
    });
    list.push({ label: "MATRICULE :", value: plug.matricule });
    list.push({
      label: "TELEPHONE :",
      value: c.getMetaValue("phoneNumber", false, plug),
    });
    const year1 = c.getMetaValue("year1", false, plug);
    const year2 = c.getMetaValue("year2", false, plug);
    if (!isEmpty(year1) && !isEmpty(year2)) {
      list.push({ label: "ANNEE DE FORMATION :", value: `${year1}-${year2}` });
    } else {
      list.push({ label: "ANNEE DE FORMATION :", value: `` });
    }
    setHeaders(list);
  }

  function setPart1(c: CenterHelper) {
    let list: any[] = [];
    list.push({ label: "DENOMINATION: ", value: plug.name });
    list.push({
      label: "STATUT JURIDIQUE: ",
      value: c.getMetaValue("legalStatus", false, plug),
    });
    list.push({
      label: "TYPE DE GESTION: ",
      value: plug.regime,
    });
    list.push({
      label: "ARRETE D’AGR N°: ",
      value: c.getMetaValue("orderNumber", false, plug),
    });
    list.push({
      label: "NOTIFICATION: ",
      value: c.getMetaValue("notification", false, plug),
    });
    list.push({ label: "MATRICULE: ", value: plug.matricule });
    list.push({
      label: "COMMUNE/TERRITOIRE D’IMPLATATION :",
      value: c.getMetaValue("settlementMunicipality", false, plug),
    });
    list.push({
      label: "SOUS-DIVISION:",
      value: plug.subDivision,
    });
    list.push({
      label: "NOM DU PROMOTEUR:",
      value: c.getMetaValue("leader", false, plug),
    });
    list.push({
      label: "PROFESSION:",
      value: c.getMetaValue("leaderWork", false, plug),
    });
    list.push({
      label: "ANNEE DE CREATION:",
      value: c.getMetaValue("creationYear", false, plug),
    });
    setElement1(list);
  }

  function setPart2(c: CenterHelper) {
    let list: any[] = [];
    list.push({
      label: "AVENUE + NUMERO :",
      value: c.getMetaValue("street", false, plug),
    });
    list.push({
      label: "QUARTIER/VILLAGE :",
      value: c.getMetaValue("quarter", false, plug),
    });
    list.push({
      label: "COMMUNE/LOCALITE :",
      value: c.getMetaValue("locality", false, plug),
    });
    list.push({
      label: "POINT DE REPERE :",
      value: c.getMetaValue("landmark", false, plug),
    });
    list.push({
      label: "STATUT D’OCCUPATION DES LIEUX :",
      value: c.getMetaValue("occupancy", false, plug),
    });
    list.push({
      label: "ETABLISSEMENTS VOISINS (3)  :",
      value: c.getMetaValue("neighboringEstablishments", false, plug),
    });
    list.push({
      label: "QUARTIER :",
      value: c.getMetaValue("quarter", false, plug),
    });
    list.push({
      label: "COMMUNE :",
      value: c.getMetaValue("commune", false, plug),
    });
    list.push({
      label: "SOUS-DIVISION :",
      value: c.getMetaValue("subProvince", false, plug),
    });
    list.push({ label: "PROVINCE  :", value: plug.province });
    setElement2(list);
  }

  return (
    <React.Fragment>
      <section className="container">
        <PDFViewer style={{ width: "100%", height: "100vh" }}>
          <Document author="DANTIC" creator="DEV">
            <Page size="A4" style={styles.page}>
              <View>
                <View>
                  <Header list={headers} />
                  <View
                    style={{
                      justifyContent: "center",
                      alignContent: "center",
                      alignItems: "center",
                      margin: "10px 0",
                    }}
                  >
                    <Text
                      style={{
                        padding: "5px",
                        paddingBottom: "1px",
                        fontSize: 11,
                        width: "auto",
                        borderBottom: "1px solid #000",
                        color: "#000",
                      }}
                    >
                      {`FICHE D’IDENTIFICATION DU PERSONNEL ADMINISTRATIF `}
                    </Text>
                  </View>
                  <View style={{ border: "1px solid #000", borderTop: "none" }}>
                    <TablePdfView
                      columns={[
                        { title: "MINISTERE", dataIndex: "ministry" },
                        {
                          title:
                            "Ministère de la formation professionnelle et métiers",
                          dataIndex: "professional",
                        },
                      ]}
                      list={list1}
                    />
                  </View>
                </View>
                <View style={{ flexDirection: "row", alignItems: "stretch" }}>
                  <View style={{ flex: 1 }}>
                    <Text
                      style={{
                        textAlign: "center",
                        fontSize: 11,
                        padding: "20px 0 10px",
                        color: "red",
                      }}
                    >
                      {`NB : Prévoir des filtres pour : direction général, direction, division, bureau, sexe`}
                    </Text>
                  </View>
                </View>
              </View>
            </Page>
          </Document>
        </PDFViewer>
      </section>
    </React.Fragment>
  );
}

const Header: React.FC<{ list: any[] }> = ({ list }) => {
  return (
    <View
      style={{ display: "flex", flexDirection: "row", alignItems: "stretch" }}
    >
      <View style={{ alignItems: "center", flex: 1 }}>
        <Text style={[styles.logoCountry]}>
          {`République Démocratique du Congo`}
        </Text>
        <Text style={[styles.logoHeader]}>{`Ministère de la Formation`}</Text>
        <Text style={[styles.logoHeader]}>{`Professionnelle et Métiers`}</Text>
        <Image
          src={require("../../assets/images/armoirie.png")}
          style={{ width: "80px", textAlign: "center" }}
        />
        <Text style={[styles.logoHeader, { margin: "10px 0 0" }]}>
          {`SECRETARIAT GENERAL`}
        </Text>
        <Text
          style={[
            styles.logoHeader2,
            { fontSize: 14, textDecoration: "underline", color: "#0044ff" },
          ]}
        >{`Direction Statistiques Scolaires`}</Text>
        <Text
          style={[styles.logoHeader2, { fontSize: 14 }]}
        >{`Division Fichier central des données statistiques`}</Text>
      </View>
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignContent: "center",
          padding: "0 5px",
        }}
      ></View>
    </View>
  );
};

const TitleView: React.FC<{ title: string }> = ({ title }) => {
  return (
    <View>
      <Text style={styles.title}>{title}</Text>
    </View>
  );
};
