import { useFormik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { phoneRegex } from "../../utils/helpers/regex";
import { Button, Modal, Typography, notification } from "antd";
import { isEmpty, toNumber, toString } from "lodash";
import { addMeta } from "../../utils/helpers/title";
import centerService from "../../services/centerService";
import userHelper from "../../utils/helpers/userHelper";
import { Identity } from "./sheet/identity";
import { GeneralInfo } from "./sheet/general";
import {
  tab1,
  tabDocuments,
  tabEquipments,
  tabLearnerManuals,
  tabLocalCounts,
  tabLocalNatureCounts,
  tabNatureAndGeneralStates,
  tabRepartition,
  tabRepartitionAdmin,
} from "./sheet/table2";
import { centerMeta } from "../../utils/helpers/meta";
import { Spreadsheet } from "../../components/table/items";
import { DillInput } from "../../components/input";
import { SheetHeader } from "../../components/sheetHeader";
import draftHelper from "../../utils/helpers/draftHelper";
import { setDraftingSheet } from "../../redux/sheetReducer";
import { setSelectedSheet } from "../../redux/sheetReducer";
import { setOpenSheet } from "../../redux/sheetReducer";
import centerPlugService from "../../services/centerPlugService";

export function CenterSheet() {
  const [loading, setLoading] = React.useState(false);
  const [subDivisionId, setSubdivisionId] = React.useState<string | null>(null);
  const [updateId, setUpdateId] = React.useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      name: "",
      year1: "20",
      year2: "20",
      leader: "",
      email: "",
      registrationNumber: "",
      formerCount: "0",
      phoneNumber: "",
      leaderPhone: "",
      gender: "",
      regime: "",
      mechanization: "",
      subDivision: "",

      latitude: 0,
      longitude: 0,
      environment: "",
      occupancy: "",
      distance: "",
      type: "",
      subType: "",

      cfp: "fcp",
      cap: "",
      modularForms: "",
      domain: "",

      courses: "",
      copa: "",
      coges: "",
      waterPointExist: "",
      waterPoint: "",
      supported: "",
      organism: "",
      electricity: "",
      latrine: "",
      boyLatrineCount: "",
      girlLatrineCount: "",
      formerLatrineCount: "",
      playground: "",
      sportsField: "",
      fence: "",
      fenceNature: "",
      otherEtsInLocal: "",
      otherEtsInLocalName: "",
      languages: [],
      sanctions: [],
      affiliatedWithMinistry: "",
      ministryName: "",
      diplomaFollowUp: "",
      receiveProfessionals: "",

      district: "",
      commune: "",
      division: "",
      address: "",
      postalCode: "",
      legalReference: "",

      dssCenterSheetClassrooms: [
        { key: "0", level: "Niveau 1" },
        { key: "1", level: "Niveau 2" },
        { key: "2", level: "Niveau 3" },
      ],
      dssCenterSheetAdministrativeStaffDistributions: [
        { key: "0", level: "-D4 A" },
        { key: "1", level: "D4 A" },
        { key: "2", level: "D4 N" },
        { key: "3", level: "P6 A" },
        { key: "4", level: "D6 A" },
        { key: "5", level: "D6 N" },
        { key: "6", level: "A1" },
        { key: "7", level: "G3" },
        { key: "8", level: "L2" },
        { key: "9", level: "A0" },
      ],
      dssCenterSheetTrainingStaffDistributions: [],
      dssCenterSheetPremisesStateNumbers: [
        { key: "0", local: "Salle de cours" },
        { key: "1", local: "Bureaux" },
        { key: "2", local: "Magasins" },
        { key: "3", local: "Ateliers" },
        { key: "4", local: "Salles des formateurs" },
        { key: "5", local: "Bibliothèque" },
      ],
      dssCenterSheetPremisesNatureNumbers: [],
      dssCenterSheetPremisesType: [],
      dssCenterSheetEquipmentNumbers: [],
      dssCenterSheetLearnerTrainingManuals: [
        { key: "0", manual: "Français" },
        { key: "1", manual: "Calcul" },
        { key: "2", manual: "Histoire" },
        { key: "3", manual: "Géographie" },
        { key: "4", manual: "Sciences" },
        { key: "5", manual: "Lecture/Ecriture" },
        { key: "6", manual: "Hygiène/Sante" },
        { key: "7", manual: "Culture générale" },
        { key: "8", manual: "Autres" },
      ],
      dssCenterSheetManagementDocuments: [
        { key: "0", document: "Programme de formation" },
        { key: "1", document: "Curricula" },
        { key: "2", document: "Convention de stage" },
        { key: "3", document: "Règlement intérieur" },
        { key: "4", document: "Convocation de formation" },
        { key: "5", document: "Attestation de présence" },
        { key: "6", document: "Evaluation de satisfaction" },
        { key: "7", document: "Fiche d’entretien/formateur" },
        { key: "8", document: "Autres" },
      ],
      dssCenterSheetNatures: [],
      dssCenterSheetNatureAndGeneralStates: [
        { key: "0", local: "Salle de cours" },
        { key: "1", local: "Bureaux" },
        { key: "2", local: "Magasins" },
        { key: "3", local: "Ateliers" },
        { key: "4", local: "Salles des éducateurs" },
        { key: "5", local: "Bibliothèque" },
      ],
    },
    onSubmit: onSave,
    validationSchema: yup.object({
      name: yup.string().required("Champ requis"),
      leader: yup.string().required("Champ requis"),
      email: yup.string().required("Champ requis").email("Email non valide"),
      phoneNumber: yup
        .string()
        .required("Champ requis")
        .matches(phoneRegex, { message: "Le format autorisé:+xxxxxxxxxx" }),
      // subDivision: yup
      //   .number()
      //   .typeError("Chercher une sous-province éducationnelle")
      //   .required("Champ requis"),
      //latitude: yup.number().typeError("Veuillez écrire une valeur numérique"),
      //longitude: yup.number().typeError("Veuillez écrire une valeur numérique"),
      year1: yup
        .number()
        .min(2000, "Ecrivez correctement l'année")
        .required("Champ requis")
        .typeError("Veuillez écrire une valeur numérique"),
      year2: yup
        .number()
        .min(2000, "Ecrivez correctement l'année")
        .required("Champ requis")
        .typeError("Veuillez écrire une valeur numérique"),
    }),
    //isInitialValid: true,
  });

  function handleClose() {
    let message =
      "Vous vous appretez à perdre toutes les modifications apportées jusque là.";
    if (isEmpty(updateId)) {
    } else {
    }
    Modal.warning({
      title: "Abondonner les modifications apportées",
      content: message,
      cancelText: "Annuler",
      okText: "Continuer",
      okCancel: true,
      centered: true,
      onCancel: () => {},
      onOk: () => {
        draftHelper.remove();
        dispatch(setDraftingSheet(false));
        dispatch(setSelectedSheet({}));
        dispatch(setOpenSheet(true));
      },
    });
  }

  async function onSave() {
    const {
      email,
      formerCount,
      gender,
      leader,
      leaderPhone,
      mechanization,
      name,
      phoneNumber,
      regime,
      registrationNumber,
      subDivision,
      distance,
      environment,
      latitude,
      longitude,
      occupancy,
      subType,
      type,
      affiliatedWithMinistry,
      boyLatrineCount,
      coges,
      copa,
      courses,
      diplomaFollowUp,
      electricity,
      fence,
      fenceNature,
      formerLatrineCount,
      girlLatrineCount,
      languages,
      latrine,
      ministryName,
      organism,
      otherEtsInLocal,
      otherEtsInLocalName,
      playground,
      receiveProfessionals,
      sanctions,
      sportsField,
      supported,
      waterPoint,
      waterPointExist,
      address,
      cap,
      cfp,
      commune,
      district,
      division,
      domain,
      dssCenterSheetAdministrativeStaffDistributions,
      dssCenterSheetClassrooms,
      dssCenterSheetEquipmentNumbers,
      dssCenterSheetLearnerTrainingManuals,
      dssCenterSheetManagementDocuments,
      dssCenterSheetNatureAndGeneralStates,
      dssCenterSheetNatures,
      dssCenterSheetPremisesNatureNumbers,
      dssCenterSheetPremisesStateNumbers,
      dssCenterSheetPremisesType,
      dssCenterSheetTrainingStaffDistributions,
      legalReference,
      modularForms,
      postalCode,
      year1,
      year2,
    } = formik.values;
    let metas: any[] = [];
    metas = addMeta(metas, "legalReference", legalReference, "string");
    metas = addMeta(metas, "year1", year1, "number");
    metas = addMeta(metas, "year2", year2, "number");
    metas = addMeta(metas, "modularForms", modularForms, "string");
    metas = addMeta(metas, "postalCode", postalCode, "string");
    metas = addMeta(metas, "domain", domain, "string");
    metas = addMeta(metas, "commune", commune, "string");
    metas = addMeta(metas, "division", division, "string");
    metas = addMeta(metas, "district", district, "string");
    metas = addMeta(metas, "gender", gender, "string");
    metas = addMeta(metas, "address", address, "string");
    metas = addMeta(metas, "cap", cap, "string");
    metas = addMeta(metas, "cfp", cfp, "string");
    metas = addMeta(metas, "leaderPhone", leaderPhone, "phone");
    metas = addMeta(
      metas,
      "affiliatedWithMinistry",
      affiliatedWithMinistry,
      ""
    );
    metas = addMeta(metas, "boyLatrineCount", boyLatrineCount, "number");
    metas = addMeta(metas, "coges", coges, "");
    metas = addMeta(metas, "courses", courses, "");
    metas = addMeta(metas, "diplomaFollowUp", diplomaFollowUp, "");
    metas = addMeta(metas, "electricity", electricity, "");
    metas = addMeta(metas, "fence", fence, "bool");
    metas = addMeta(metas, "fenceNature", fenceNature, "");
    metas = addMeta(metas, "formerLatrineCount", formerLatrineCount, "number");
    metas = addMeta(metas, "girlLatrineCount", girlLatrineCount, "number");
    metas = addMeta(metas, "latrine", latrine, "bool");
    metas = addMeta(metas, "ministryName", ministryName, "string");
    metas = addMeta(metas, "organism", organism, "string");
    metas = addMeta(metas, "otherEtsInLocal", otherEtsInLocal, "bool");
    metas = addMeta(
      metas,
      "otherEtsInLocalName",
      otherEtsInLocalName,
      "string"
    );
    metas = addMeta(metas, "playground", playground, "bool");
    metas = addMeta(
      metas,
      "receiveProfessionals",
      receiveProfessionals,
      "bool"
    );
    metas = addMeta(metas, "sportsField", sportsField, "bool");
    metas = addMeta(metas, "supported", supported, "bool");
    metas = addMeta(metas, "waterPoint", waterPoint, "string");
    metas = addMeta(metas, "waterPointExist", waterPointExist, "bool");
    metas = addMeta(metas, "copa", copa, "string");
    languages.map((p) => {
      metas = addMeta(metas, "languages", p, "string");
    });
    sanctions.map((p) => {
      metas = addMeta(metas, "sanctions", p, "string");
    });
    metas = addMeta(
      metas,
      "dssCenterSheetAdministrativeStaffDistributions",
      JSON.stringify(dssCenterSheetAdministrativeStaffDistributions),
      "json"
    );
    metas = addMeta(
      metas,
      "dssCenterSheetClassrooms",
      JSON.stringify(dssCenterSheetClassrooms),
      "json"
    );
    metas = addMeta(
      metas,
      "dssCenterSheetEquipmentNumbers",
      JSON.stringify(dssCenterSheetEquipmentNumbers),
      "json"
    );
    metas = addMeta(
      metas,
      "dssCenterSheetLearnerTrainingManuals",
      JSON.stringify(dssCenterSheetLearnerTrainingManuals),
      "json"
    );
    metas = addMeta(
      metas,
      "dssCenterSheetManagementDocuments",
      JSON.stringify(dssCenterSheetManagementDocuments),
      "json"
    );
    metas = addMeta(
      metas,
      "dssCenterSheetNatureAndGeneralStates",
      JSON.stringify(dssCenterSheetNatureAndGeneralStates),
      "json"
    );
    metas = addMeta(
      metas,
      "dssCenterSheetNatures",
      JSON.stringify(dssCenterSheetNatures),
      "json"
    );
    metas = addMeta(
      metas,
      "dssCenterSheetPremisesNatureNumbers",
      JSON.stringify(dssCenterSheetPremisesNatureNumbers),
      "json"
    );
    metas = addMeta(
      metas,
      "dssCenterSheetPremisesStateNumbers",
      JSON.stringify(dssCenterSheetPremisesStateNumbers),
      "json"
    );
    metas = addMeta(
      metas,
      "dssCenterSheetPremisesType",
      JSON.stringify(dssCenterSheetPremisesType),
      "json"
    );
    metas = addMeta(
      metas,
      "dssCenterSheetTrainingStaffDistributions",
      JSON.stringify(dssCenterSheetTrainingStaffDistributions),
      "json"
    );
    const sectorElements: any[] = [];
    const data: any = {
      leader,
      latitude: toNumber(latitude),
      longitude: toNumber(longitude),
      name,
      registrationNumber,
      formerCount: toNumber(formerCount),
      email,
      phoneNumber,
      regime,
      mechanization,
      environment,
      occupancy,
      type,
      subType,
      subDivision,
    };
    /*if (!isEmpty(toString(subDivision)) || !isEmpty(toString(subDivisionId))) {
      if (!isEmpty(`${subDivision}`)) {
        data.subDivision = `/api/sub_divisions/${subDivision}`;
      } else {
        data.subDivision = `/api/sub_divisions/${subDivisionId}`;
      }
    }*/
    data.sectors = sectorElements;
    data.centerPlugMetas = metas;
    data.plug = "center";
    setLoading(true);
    if (isEmpty(updateId)) {
      await onCreate(data);
    } else {
      await onUpdate(data);
    }
    setLoading(false);
  }

  async function onCreate(data: any) {
    await centerPlugService
      .store(data)
      .then(async (response) => {
        await userHelper.getPlugs(dispatch);
        dispatch(setDraftingSheet(false));
        draftHelper.remove();
        setUpdateId(`${response.data.id}`);
        notification.info({
          message: "Enregistrement de fiche réussi",
        });
        formik.resetForm();
        navigate(`/admin/plug/${response.data.id ?? ""}`);
      })
      .catch((reason) => {
        const error = centerService.getError(reason);
        notification.error({
          message: "Enregistrement de fiche",
          description: error,
        });
      });
  }

  async function onUpdate(data: any) {
    await centerPlugService
      .update(updateId, data)
      .then(async (response) => {
        await userHelper.getCenters(dispatch);
        //dispatch(setDraftingSheet(false));
        notification.info({
          message: "Modification de l'établissement réussie",
        });
        //formik.resetForm();
        //navigate(`/admin/center/${updateId}`);
      })
      .catch((reason) => {
        const error = centerService.getError(reason);
        notification.error({
          message: "Modification de l'établissement",
          description: error,
        });
      });
  }

  function handleSave() {
    formik.submitForm();
    let hasError = false;
    const errors: any = { ...formik.errors };
    for (const key in errors) {
      const element = errors[key] as any;
      if (!isEmpty(element)) {
        hasError = true;
      }
    }
    if (hasError) {
      notification.warning({
        message: "Création de fiche",
        description: "Quelques champs ne sont pas rempli correctement",
      });
    }
  }

  return (
    <React.Fragment>
      <SheetHeader
        loading={loading}
        formik={formik}
        title=""
        type="center"
        onPublish={handleSave}
      />
      <main className="main main yc_container_main">
        <section className="container">
          <div className="row" style={{ paddingLeft: 0, alignItems: "center" }}>
            <div className="col-md-">
              <Typography.Text>{`Année de formation`}</Typography.Text>
            </div>
            <div className="col">
              <div className="row">
                <div className="col">
                  <DillInput formik={formik} name="year1" label="" />
                </div>
                <div className="col">
                  <DillInput formik={formik} name="year2" label="" />
                </div>
              </div>
            </div>
          </div>
          <Identity formik={formik} />
          <GeneralInfo formik={formik} />
          <Spreadsheet
            colums={tab1}
            initItems={formik.values.dssCenterSheetClassrooms}
            title={`III. ${centerMeta.dssCenterSheetClassrooms.title}`}
            formik={formik}
            name={centerMeta.dssCenterSheetClassrooms.metaKey}
            counter
            genericText="Niveau"
            genericValue="level"
            key="element1"
          />
          <Spreadsheet
            colums={tabRepartition}
            key="bm2"
            initItems={[
              { key: "0", level: "-D4" },
              { key: "1", level: "D4 A" },
              { key: "2", level: "D4 N" },
              { key: "3", level: "D6 A" },
              { key: "4", level: "D6 N" },
              { key: "5", level: "G3" },
              { key: "6", level: "A2" },
              { key: "7", level: "A1" },
              { key: "8", level: "A0" },
              { key: "9", level: "L2" },
            ]}
            title={`IV. ${centerMeta.dssCenterSheetTrainingStaffDistributions.title}`}
            formik={formik}
            showAddButton={false}
            name={centerMeta.dssCenterSheetTrainingStaffDistributions.metaKey}
          />
          <Spreadsheet
            colums={tabRepartitionAdmin}
            key="bm3"
            initItems={
              formik.values.dssCenterSheetAdministrativeStaffDistributions
            }
            title={`V. ${centerMeta.dssCenterSheetAdministrativeStaffDistributions.title}`}
            formik={formik}
            showAddButton={false}
            name={
              centerMeta.dssCenterSheetAdministrativeStaffDistributions.metaKey
            }
          />
          <Spreadsheet
            colums={tabLocalCounts}
            key="bm4"
            initItems={formik.values.dssCenterSheetPremisesStateNumbers}
            title={`VI. ${centerMeta.dssCenterSheetPremisesStateNumbers.title}`}
            showAddButton={false}
            formik={formik}
            name={centerMeta.dssCenterSheetPremisesStateNumbers.metaKey}
          />
          <Spreadsheet
            colums={tab1}
            key="bm5"
            initItems={[formik.values.dssCenterSheetPremisesNatureNumbers]}
            title={`VII. ${centerMeta.dssCenterSheetPremisesNatureNumbers.title}`}
            formik={formik}
            name={centerMeta.dssCenterSheetPremisesNatureNumbers.metaKey}
          />
          <Spreadsheet
            colums={tabNatureAndGeneralStates}
            key="bm7"
            initItems={formik.values.dssCenterSheetNatureAndGeneralStates}
            title={``}
            name={centerMeta.dssCenterSheetNatureAndGeneralStates.metaKey}
            formik={formik}
            showAddButton={false}
          />
          <Spreadsheet
            colums={tabEquipments}
            key="bm8"
            initItems={formik.values.dssCenterSheetEquipmentNumbers}
            title={`VIII. ${centerMeta.dssCenterSheetEquipmentNumbers.title}`}
            formik={formik}
            name={centerMeta.dssCenterSheetEquipmentNumbers.metaKey}
          />

          <Spreadsheet
            colums={tabLearnerManuals}
            key="bm9"
            initItems={formik.values.dssCenterSheetLearnerTrainingManuals}
            title={`IX. ${centerMeta.dssCenterSheetLearnerTrainingManuals.title}`}
            formik={formik}
            name={centerMeta.dssCenterSheetEquipmentNumbers.metaKey}
            showAddButton
          />
          <Spreadsheet
            colums={tabDocuments}
            key="bm10"
            initItems={formik.values.dssCenterSheetManagementDocuments}
            title={`X. ${centerMeta.dssCenterSheetManagementDocuments.title}`}
            formik={formik}
            name={centerMeta.dssCenterSheetManagementDocuments.metaKey}
            showAddButton
          />

          {/*<div
          className="row"
          style={{
            alignItems: "center",
            justifyContent: "center",
            padding: 16,
          }}
        >
          <Button
            onClick={handleSave}
            loading={loading}
            disabled={loading}
            type="primary"
          >
            {`Sauvegarder`}
          </Button>
        </div>*/}
        </section>
      </main>
    </React.Fragment>
  );
}
