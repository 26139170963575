import { routeInterface } from "../routes";
import { FormersScreen } from "../screens/formers";
import { CreateFormerScreen } from "../screens/former/create";
import { FormerView } from "../screens/former/view";
import { SessionView } from "../screens/session/view";
import { SheetScreen } from "../screens/sheet";
import { CenterSheet } from "../screens/center/sheet";
import { TableExample } from "../components/table/example";
import { BuildingSheet } from "../screens/center/buildings";
import { EstablishmentInformationSheet } from "../screens/center/ establishmentInformationSheet";
import { LearnerSheet } from "../screens/learner/sheet";
import { LearnerIdentificationSheet } from "../screens/learner/sheetLearnerIdentification";
import { LearnerMovementSheet } from "../screens/center/learnerMovement";
import { MovementPDF } from "../screens/center/movement/pdf";
import { PDFFileScreen } from "../screens/pdf";
import { PlugListScreen } from "../screens/plugs";
import { LearnerPDFFileScreen } from "../screens/pdfLearner";
export const dssRoutes: routeInterface[] = [
  {
    component: PlugListScreen,
    icon: `fa-solid fa-flag`,
    isMenu: true,
    label: "Fiche DSS",
    layout: "/admin",
    name: "plug-view",
    path: "/plugs",
    subscribeable: false,
    // group: "dss",
    description: "Les Fiches DSS",
  },
  {
    component: SheetScreen,
    icon: `fa-thin fa-flag`,
    isMenu: false,
    label: "Saisi de fiche DSS",
    layout: "/admin",
    name: "sheet-view",
    path: "/sheets",
    subscribeable: false,
    // group: "dss",
    description: "Fiches DSS",
  },
  {
    component: PDFFileScreen,
    icon: `fa-solid fa-flag`,
    isMenu: false,
    label: "",
    layout: "/admin",
    name: "sheet-movement-view",
    path: "/plug/:id",
    subscribeable: false,
    // group: "dss",
    description: "Fiches DSS",
  },
  {
    component: LearnerPDFFileScreen,
    icon: `fa-solid fa-flag`,
    isMenu: false,
    label: "",
    layout: "/admin",
    name: "sheet-pdf-learner-view",
    path: "/plug/:id/learner",
    subscribeable: false,
    // group: "dss",
    description: "Fiches DSS",
  },
  // {
  //   component: CenterSheet,
  //   icon: `fa-solid fa-flag`,
  //   isMenu: true,
  //   label: "Identification de centre",
  //   layout: "/admin",
  //   name: "sheet-view-center",
  //   path: "/sheets/center",
  //   subscribeable: false,
  //   parent: "dss",
  //   description: "Fiches DSS d'identification de centre",
  // },
  // {
  //   component: BuildingSheet,
  //   icon: `fa-solid fa-flag`,
  //   isMenu: true,
  //   label: "Identification de bâtiment",
  //   layout: "/admin",
  //   name: "sheet-create-building",
  //   path: "/sheets/building",
  //   subscribeable: false,
  //   parent: "dss",
  //   description: "Fiches DSS d'identification de bâtiment",
  // },
  // {
  //   component: EstablishmentInformationSheet,
  //   icon: `fa-solid fa-flag`,
  //   isMenu: true,
  //   label: "Renseignement de l'établissement",
  //   layout: "/admin",
  //   name: "sheet-create-recorder",
  //   path: "/sheets/recorders",
  //   subscribeable: false,
  //   parent: "dss",
  //   description: "Fiches DSS renseignement de l'établissement",
  // },
  // {
  //   component: LearnerMovementSheet,
  //   icon: `fa-solid fa-flag`,
  //   isMenu: true,
  //   label: "Mouvement des apprenants",
  //   layout: "/admin",
  //   name: "sheet-view-learner",
  //   path: "/sheets/movements",
  //   subscribeable: false,
  //   parent: "dss",
  //   description: "Fiches DSS sur le mouvement des apprenants",
  // },
  // {
  //   component: LearnerIdentificationSheet,
  //   icon: `fa-solid fa-flag`,
  //   isMenu: true,
  //   label: "Identification de l'apprenant",
  //   layout: "/admin",
  //   name: "sheet-view-center",
  //   path: "/sheets/learners",
  //   subscribeable: false,
  //   parent: "dss",
  //   description: "Fiches DSS d'identification des apprenants",
  // },
  // {
  //   component: LearnerSheet,
  //   icon: `fa-solid fa-flag`,
  //   isMenu: true,
  //   label: "Nouveaux inscrits",
  //   layout: "/admin",
  //   name: "sheet-view-center",
  //   path: "/sheets/center",
  //   subscribeable: false,
  //   parent: "dss",
  //   description: "Enregistrement des nouveaux inscrits",
  // },
];
