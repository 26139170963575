import { useFormik } from "formik";
import React from "react";
import { BuildingEquipment } from "./building/equipment";
import { BuildingStatePlace } from "./building/statePlace";
import { BuildingIdentity } from "./building/identity";
import subDivisionService from "../../services/subDivisionService";
import { isEmpty, toString } from "lodash";
import divisionService from "../../services/divisionService";
import { DillSelect } from "../../components/input";
import { Modal, notification } from "antd";
import userHelper from "../../utils/helpers/userHelper";
import { useNavigate } from "react-router-dom";
import { addMeta } from "../../utils/helpers/title";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { SheetHeader } from "../../components/sheetHeader";
import draftHelper from "../../utils/helpers/draftHelper";
import {
  setDraftingSheet,
  setOpenSheet,
  setSelectedSheet,
} from "../../redux/sheetReducer";
import centerPlugService from "../../services/centerPlugService";

export function BuildingSheet() {
  const [divisions, setDivisions] = React.useState<any[]>([]);
  const [subDivisions, setSubDivisions] = React.useState<any[]>([]);
  const [searchDivision, setSearchDivision] = React.useState("");
  const [searchSubDivision, setSearchSubDivision] = React.useState("");
  const [searchingDivision, setSearchingDivision] = React.useState(false);
  const [searchingSubDivision, setSearchingSubDivision] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      division: "",
      subDivision: "",
      name: "",
      level: "", //new
      SENACEPEF: "", //new
      regime: "",

      streetNumber: "", //new
      street: "", //new
      quarter: "", //new
      commune: "",
      phoneNumber: "",
      approved: "", //new

      leader: "",
      leaderSENACEPEF: "", //new
      leaderPhone: "",
      occupacy: "",
      registrationCertificateNumber: "", //new

      organizedClassCount: "", //new
      allowedClassCount: "", //new
      sectionCount: "", //new
      boyCount: "", //new
      girlCount: "", //new

      hardClassConstruction: "", //new(yes or no)
      schoolLocationState: "", //new(more choices)
      schoolAccecibility: "", //new(yes or no)
      constructionState: "", //new
      constructionEnterprise: "", //new
      constructionYear: "", //new
      constructionPartner: "", //new

      schoolYardArea: "", //new
      playgroundArea: "", //new
      fencedYardArea: "", //new
      affectedSchoolYardArea: "", //new

      buildingTrainingCount: "", //new
      buildingAdministrativeCount: "", //new
      buildingResidentialCount: "", //new

      boyLatrineExist: "", //new
      girlLatrineExist: "", //new
      manAdminLatrineExist: "", //new
      womanAdminLatrineExist: "", //new
      separedLatrine: "", //new(yes or no)

      localOfficeCount: "", //new
      localClassroomCount: "", //new
      localLabCount: "", //new
      schoolFence: "", //new(yes or no)
      localSchoolFenceMaterial: "", //new
      waterPointExist: "",
      waterPoint: "",
      latrineConstructionAuthor: "", //new
      latrineConstructionYear: "", //new
      electricity: "",
      electricitySoucre: "", //new(snel or other)
      washbasin: "", //new(yes or no)
      tank: "", //new(yes or no)
      materialsReceived: [], //new
      equipmentAvailables: [], //new
      materialReceivedDate: "", //new
      materialReceivedSoucre: "", //new(gouvernement, partenaire)
      comment: "", //new
    },
    onSubmit: onSave,
    validationSchema: yup.object({
      division: yup.string().required("Champ requis"),
      subDivision: yup.string().required("Champ requis"),
      name: yup.string().required("Champ requis"),
      streetNumber: yup.string().required("Champ requis"),
      street: yup.string().required("Champ requis"),
      commune: yup.string().required("Champ requis"),

      localOfficeCount: yup.number().typeError("Valeur numérique exigée"), //new
      localClassroomCount: yup.number().typeError("Valeur numérique exigée"), //new
      localLabCount: yup.number().typeError("Valeur numérique exigée"),
      latrineConstructionYear: yup
        .number()
        .typeError("Valeur numérique exigée"),
      buildingTrainingCount: yup.number().typeError("Valeur numérique exigée"), //new
      buildingAdministrativeCount: yup
        .number()
        .typeError("Valeur numérique exigée"), //new
      buildingResidentialCount: yup
        .number()
        .typeError("Valeur numérique exigée"),
      organizedClassCount: yup.number().typeError("Valeur numérique exigée"), //new
      allowedClassCount: yup.number().typeError("Valeur numérique exigée"), //new
      sectionCount: yup.number().typeError("Valeur numérique exigée"), //new
      boyCount: yup.number().typeError("Valeur numérique exigée"), //new
      girlCount: yup.number().typeError("Valeur numérique exigée"), //new
    }),
  });

  function getList(data: any[], emptyLabel: string) {
    const elements: any[] = [];
    data.forEach((p) => {
      elements.push({ value: `${p.name}`, title: `${p.name}` });
    });
    elements.unshift({ value: ``, title: emptyLabel });
    return elements;
  }

  async function onSearchDivision() {
    setSearchingDivision(true);
    let query = `name=${searchDivision}`;
    if (isEmpty(searchDivision)) {
      query = ``;
    }
    await divisionService
      .getByKey(query)
      .then((response) => {
        setDivisions(
          getList(
            divisionService.getData(response),
            "Séléctionner une province éducationnelle"
          )
        );
        formik.setValues({ ...formik.values, subDivision: "" });
        setSubDivisions(
          getList([], "Veuillez choisir une province éducationnelle avant tout")
        );
      })
      .catch(() => {});
    setSearchingDivision(false);
  }

  async function onSearchSubDivision() {
    const name = searchSubDivision;
    const id = formik.values.division;
    if (isEmpty(toString(id))) {
      return;
    }
    let query = `division=${id}`;
    if (!isEmpty(name)) {
      query = `&name=${name}`;
    }
    setSearchingSubDivision(true);
    await subDivisionService
      .getByKey(query)
      .then((response) => {
        setSubDivisions(
          getList(
            subDivisionService.getData(response),
            "Séléctionner une sous-province éducationnelle"
          )
        );
      })
      .catch(() => {});
    setSearchingSubDivision(false);
  }

  /**Search division by name */
  React.useEffect(() => {
    onSearchDivision();
  }, [searchDivision]);

  /**search sub-division by division only or by division and sub-division name */
  React.useEffect(() => {
    onSearchSubDivision();
  }, [searchSubDivision, formik.values.division]);

  async function onCreate(data: any) {
    await centerPlugService
      .store(data)
      .then(async (response) => {
        await userHelper.getPlugs(dispatch);
        dispatch(setDraftingSheet(false));
        draftHelper.remove();
        notification.info({
          message: "Enregistrement de fiche réussi",
        });
        formik.resetForm();
        navigate(`/admin/plug/${response.data.id ?? ""}`);
      })
      .catch((reason) => {
        const error = centerPlugService.getError(reason);
        notification.error({
          message: "Enregistrement de fiche",
          description: error,
        });
      });
  }

  function handleSave() {
    formik.submitForm();
    let hasError = false;
    const errors: any = { ...formik.errors };
    for (const key in errors) {
      const element = errors[key] as any;
      if (!isEmpty(element)) {
        hasError = true;
      }
    }
    if (hasError) {
      notification.warning({
        message: "Création de fiche",
        description: "Quelques champs ne sont pas rempli correctement",
      });
    }
  }

  async function onSave() {
    const {
      SENACEPEF,
      affectedSchoolYardArea,
      allowedClassCount,
      approved,
      boyCount,
      boyLatrineExist,
      buildingAdministrativeCount,
      buildingResidentialCount,
      buildingTrainingCount,
      comment,
      commune,
      constructionEnterprise,
      constructionPartner,
      constructionState,
      constructionYear,
      division,
      electricity,
      electricitySoucre,
      equipmentAvailables,
      fencedYardArea,
      girlCount,
      girlLatrineExist,
      hardClassConstruction,
      latrineConstructionAuthor,
      latrineConstructionYear,
      leader,
      leaderPhone,
      leaderSENACEPEF,
      level,
      localClassroomCount,
      localLabCount,
      localOfficeCount,
      localSchoolFenceMaterial,
      manAdminLatrineExist,
      materialReceivedDate,
      materialReceivedSoucre,
      materialsReceived,
      name,
      occupacy,
      organizedClassCount,
      phoneNumber,
      playgroundArea,
      quarter,
      regime,
      registrationCertificateNumber,
      schoolAccecibility,
      schoolFence,
      schoolLocationState,
      schoolYardArea,
      sectionCount,
      separedLatrine,
      street,
      streetNumber,
      subDivision,
      tank,
      washbasin,
      waterPoint,
      waterPointExist,
      womanAdminLatrineExist,
    } = formik.values;
    let metas: any[] = [];
    metas = addMeta(metas, "SENACEPEF", SENACEPEF, "string");
    metas = addMeta(
      metas,
      "affectedSchoolYardArea",
      affectedSchoolYardArea,
      "number"
    );
    metas = addMeta(metas, "allowedClassCount", allowedClassCount, "number");
    metas = addMeta(metas, "approved", approved, "string");
    metas = addMeta(metas, "boyCount", boyCount, "numeric");
    metas = addMeta(metas, "boyLatrineExist", boyLatrineExist, "bool");
    metas = addMeta(metas, "commune", commune, "string");
    metas = addMeta(metas, "division", division, "string");
    metas = addMeta(
      metas,
      "buildingAdministrativeCount",
      buildingAdministrativeCount,
      "numeric"
    );
    metas = addMeta(
      metas,
      "buildingResidentialCount",
      buildingResidentialCount,
      "numeric"
    );
    metas = addMeta(
      metas,
      "buildingTrainingCount",
      buildingTrainingCount,
      "numeric"
    );
    metas = addMeta(metas, "comment", comment, "string");
    metas = addMeta(
      metas,
      "constructionEnterprise",
      constructionEnterprise,
      "string"
    );
    metas = addMeta(metas, "leaderPhone", leaderPhone, "phone");
    metas = addMeta(
      metas,
      "constructionPartner",
      constructionPartner,
      "string"
    );

    metas = addMeta(metas, "constructionState", constructionState, "string");
    metas = addMeta(metas, "constructionYear", constructionYear, "numeric");
    metas = addMeta(metas, "electricitySoucre", electricitySoucre, "string");
    metas = addMeta(
      metas,
      "equipmentAvailables",
      JSON.stringify(equipmentAvailables),
      "json"
    );
    metas = addMeta(metas, "electricity", electricity, "");
    metas = addMeta(metas, "fencedYardArea", fencedYardArea, "numeric");
    metas = addMeta(metas, "girlCount", girlCount, "numeric");
    metas = addMeta(metas, "girlLatrineExist", girlLatrineExist, "bool");
    metas = addMeta(
      metas,
      "hardClassConstruction",
      hardClassConstruction,
      "string"
    );
    metas = addMeta(
      metas,
      "latrineConstructionAuthor",
      latrineConstructionAuthor,
      "string"
    );
    metas = addMeta(
      metas,
      "latrineConstructionYear",
      latrineConstructionYear,
      "numeric"
    );
    metas = addMeta(metas, "leaderSENACEPEF", leaderSENACEPEF, "string");
    metas = addMeta(metas, "level", level, "string");
    metas = addMeta(
      metas,
      "localClassroomCount",
      localClassroomCount,
      "numeric"
    );
    metas = addMeta(metas, "localLabCount", localLabCount, "numeric");
    metas = addMeta(metas, "localOfficeCount", localOfficeCount, "numeric");
    metas = addMeta(
      metas,
      "localSchoolFenceMaterial",
      localSchoolFenceMaterial,
      "string"
    );
    metas = addMeta(
      metas,
      "manAdminLatrineExist",
      manAdminLatrineExist,
      "bool"
    );
    metas = addMeta(metas, "waterPoint", waterPoint, "string");
    metas = addMeta(metas, "waterPointExist", waterPointExist, "bool");
    metas = addMeta(
      metas,
      "materialReceivedDate",
      materialReceivedDate,
      "date"
    );
    metas = addMeta(
      metas,
      "materialReceivedSoucre",
      materialReceivedSoucre,
      "string"
    );
    metas = addMeta(
      metas,
      "materialsReceived",
      JSON.stringify(materialsReceived),
      "json"
    );
    metas = addMeta(metas, "occupacy", occupacy, "string");
    metas = addMeta(
      metas,
      "organizedClassCount",
      organizedClassCount,
      "numeric"
    );
    metas = addMeta(metas, "playgroundArea", playgroundArea, "numeric");
    metas = addMeta(metas, "quarter", quarter, "string");
    metas = addMeta(
      metas,
      "registrationCertificateNumber",
      registrationCertificateNumber,
      "string"
    );
    metas = addMeta(metas, "schoolAccecibility", schoolAccecibility, "bool");
    metas = addMeta(metas, "schoolFence", schoolFence, "bool");
    metas = addMeta(
      metas,
      "schoolLocationState",
      schoolLocationState,
      "string"
    );
    metas = addMeta(metas, "schoolYardArea", schoolYardArea, "numeric");
    metas = addMeta(metas, "sectionCount", sectionCount, "numeric");
    metas = addMeta(metas, "separedLatrine", separedLatrine, "bool");
    metas = addMeta(metas, "street", street, "string");
    metas = addMeta(metas, "streetNumber", streetNumber, "numeric");
    metas = addMeta(metas, "tank", tank, "bool");
    metas = addMeta(metas, "washbasin", washbasin, "bool");
    metas = addMeta(
      metas,
      "womanAdminLatrineExist",
      womanAdminLatrineExist,
      "bool"
    );
    const data: any = {
      leader,
      latitude: 0,
      longitude: 0,
      name,
      registrationNumber: "dfg",
      formerCount: 0,
      phoneNumber,
      regime,
      email: "admin@yekolamosala.online",
    };
    if (!isEmpty(toString(subDivision))) {
      if (!isEmpty(`${subDivision}`)) {
        data.subDivision = `/api/sub_divisions/${subDivision}`;
      }
    }
    data.centerPlugMetas = metas;
    data.plug = "building";
    data.type = "building";
    setLoading(true);
    await onCreate(data);
    setLoading(false);
  }

  function handleClose() {
    let message =
      "Vous vous appretez à perdre toutes les modifications apportées jusque là.";
    /*if (isEmpty(updateId)) {
    } else {
    }*/
    Modal.warning({
      title: "Abondonner les modifications apportées",
      content: message,
      cancelText: "Annuler",
      okText: "Continuer",
      okCancel: true,
      centered: true,
      onCancel: () => {},
      onOk: () => {
        draftHelper.remove();
        dispatch(setDraftingSheet(false));
        dispatch(setSelectedSheet({}));
        dispatch(setOpenSheet(true));
      },
    });
  }

  return (
    <React.Fragment>
      <SheetHeader
        formik={formik}
        onPublish={handleSave}
        loading={loading}
        onClose={handleClose}
        title=""
        type="building"
      />
      <main className="main main yc_container_main">
        <section className="container">
          <div className="row">
            <div className="col">
              <DillSelect
                loading={searchingDivision}
                search
                setSearch={setSearchDivision}
                formik={formik}
                name="division"
                label="Province Educationnelle"
                list={divisions}
              />
            </div>
            <div className="col">
              <DillSelect
                loading={searchingSubDivision}
                search
                setSearch={setSearchSubDivision}
                formik={formik}
                name="subDivision"
                label="Sous-Province Educationnelle"
                list={subDivisions}
              />
            </div>
          </div>
          <BuildingIdentity formik={formik} />
          <BuildingStatePlace formik={formik} />
          <BuildingEquipment formik={formik} />
        </section>
      </main>
    </React.Fragment>
  );
}
