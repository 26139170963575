import { Button, notification } from "antd";
import { useFormik } from "formik";
import React from "react";
import { DillInput, DillPassword } from "../components/input";
import { Navigate, redirect, useNavigate } from "react-router-dom";
import { TitleComponent } from "../components/title";
import userService from "../services/userService";
import userHelper from "../utils/helpers/userHelper";
import { useDispatch } from "react-redux";
import authService from "../services/authService";
import { isEmpty, toNumber } from "lodash";

export function LoginScreen() {
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    onSubmit: onLogin,
  });

  async function onLogin() {
    const { password, username } = formik.values;
    const data: any = { password, username };
    setLoading(true);
    await userService
      .login(data)
      .then(async (response) => {
        const result = response.data;
        let id = result?.data?.roles?.id;
        const token = result?.token;
        id = `${id}`;
        if (!isEmpty(id) && !isEmpty(token)) {
          const user = await getUser(id ?? "");
          authService.setToken(token);
          await userHelper.setCurrentUser(user, dispatch);
          //window.location.href = "/admin";
          navigate("/admin");
        } else {
          formik.setErrors({
            password: "Veuillez vérfier peut-être le mot de passe",
            username: "Veuillez vérifier peut-être l'adresse E-mail",
          });
          setLoading(false);
        }
      })
      .catch((reason) => {
        const error = userService.getError(reason);
        console.log("Error", reason?.response ?? reason);
        notification.error({
          message: "Authentification",
          description: "Les informations d'identification invalides.",
        });
        setLoading(false);
      });
  }

  async function getUser(id: string) {
    let result = null;
    /**@ts-ignore */
    if (isEmpty(`${id}`) && !(id > 0)) {
      return result;
    }
    await userService
      .get(id)
      .then((response) => {
        result = response.data;
      })
      .catch((reason) => {
        const error = userService.getError(reason);
      });
    return result;
  }

  function handleLogin() {
    formik.handleSubmit();
  }
  return (
    <React.Fragment>
      <section className="box">
        <div className="card box__login">
          <TitleComponent title="Connexion" type="creation"></TitleComponent>
          <div className="card">
            <DillInput
              formik={formik}
              name="username"
              label={"Nom d'utilisateur"}
              placeholder={"Nom d'utilisateur"}
              icon="fa-solid fa-user"
            />
            <DillPassword
              formik={formik}
              label="Mot de passe"
              placeholder="Mot de passe"
              name="password"
              icon="fa-solid fa-key"
            />
            <div
              className="row"
              style={{
                alignItems: "center",
                justifyContent: "center",
                padding: "10px 0 0",
              }}
            >
              <Button
                loading={loading}
                onClick={handleLogin}
                disabled={loading}
                type="primary"
              >{`Se connecter`}</Button>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
