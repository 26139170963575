import React from "react";
import {
  PDFViewer,
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";

export const ItemView: React.FC<{
  label: string;
  value: string;
  sub?: boolean;
  strong?: boolean;
}> = ({ label, value, sub, strong }) => {
  return (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        paddingLeft: sub ? 50 : 0,
        paddingBottom: 10,
      }}
    >
      <Text
        style={{
          fontSize: 11,
          fontFamily: strong ? "calibri-bold" : "calibri",
          marginRight: 5,
        }}
      >
        {label ?? ""}
      </Text>
      <Text
        style={{
          fontSize: 12,
          fontFamily: "calibri",
          flex: 1,
          paddingBottom: 0,
          borderBottom: "0.7px dotted #000",
        }}
      >
        {value ?? ""}
      </Text>
    </View>
  );
};
