import { Card, Divider, Typography } from "antd";
import React from "react";
import { DillInput, DillRadio, DillSelect } from "../../../components/input";
import { fermedResponses } from "../../../utils/helpers/title";

export const BuildingStatePlace: React.FC<{ formik: any }> = ({ formik }) => {
  return (
    <React.Fragment>
      <Divider />
      <Typography.Title
        level={2}
        style={{ fontSize: 20 }}
      >{`II. ETAT DES LIEUX `}</Typography.Title>
      <Card hoverable className="card">
        <DillRadio
          formik={formik}
          name="constructionState"
          label="Centre"
          list={[
            { value: "Non réhabilitée", title: "Non réhabilitée" },
            { value: "Réhabilité", title: "Réhabilité" },
            { value: "Construction", title: "Construction" },
          ]}
          inline
        />
        <div className="row">
          <div className="col">
            <DillInput
              formik={formik}
              name="constructionPartner"
              label="Partenaire"
            />
          </div>
          <div className="col">
            <DillInput
              formik={formik}
              name="constructionEnterprise"
              label="Entreprise"
            />
          </div>
          <div className="col">
            <DillInput formik={formik} name="constructionYear" label="Année" />
          </div>
        </div>
        <Typography.Text
          type="success"
          strong
        >{`Superficie de la cour`}</Typography.Text>
        <div className="row">
          <div className="col">
            <DillInput
              formik={formik}
              name="schoolYardArea"
              label="Scolaire (m²)"
              type="num"
            />
          </div>
          <div className="col">
            <DillInput
              formik={formik}
              name="playgroundArea"
              label="Jeux (m²)"
              type="num"
            />
          </div>
          <div className="col">
            <DillRadio
              formik={formik}
              name="fencedYardArea"
              label="Clôturé"
              type="num"
              list={fermedResponses}
            />
          </div>
          <div className="col">
            <DillRadio
              formik={formik}
              name="affectedSchoolYardArea"
              type="num"
              label="Ecole sinistrée"
              list={fermedResponses}
            />
          </div>
        </div>
        <Typography.Text
          type="success"
          strong
        >{`Nombre de bâtiment`}</Typography.Text>
        <div className="row">
          <div className="col">
            <DillInput
              formik={formik}
              name="buildingTrainingCount"
              label="Formation"
              type="num"
            />
          </div>
          <div className="col">
            <DillInput
              formik={formik}
              name="buildingAdministrativeCount"
              label="Administratifs"
              type="num"
            />
          </div>
          <div className="col">
            <DillInput
              formik={formik}
              name="buildingResidentialCount"
              label="Résidentiels"
              type="num"
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Typography.Text
              type="success"
              strong
            >{`Blocs sanitaires pour élèves`}</Typography.Text>
            <DillRadio
              list={fermedResponses}
              inline
              name="girlLatrineExist"
              label="Filles"
              formik={formik}
            />
            <DillRadio
              list={fermedResponses}
              inline
              name="boyLatrineExist"
              label="Garçons"
              formik={formik}
            />
          </div>
          <div className="col">
            <Typography.Text
              type="success"
              strong
            >{`Blocs sanitaires pour administratifs`}</Typography.Text>
            <DillRadio
              list={fermedResponses}
              inline
              name="womanLatrineExist"
              label="Femmes"
              formik={formik}
            />
            <DillRadio
              list={fermedResponses}
              inline
              name="manLatrineExist"
              label="Hommes"
              formik={formik}
            />
          </div>
        </div>
        <DillRadio
          list={fermedResponses}
          inline
          name="separedLatrine"
          label="Installations hygiéniques séparées"
          formik={formik}
        />
        <Typography.Text
          type="success"
          strong
        >{`Nombre de locaux pour`}</Typography.Text>
        <div className="row">
          <div className="col">
            <DillInput
              formik={formik}
              name="localOfficeCount"
              label="Bureaux"
              type="num"
            />
          </div>
          <div className="col">
            <DillInput
              formik={formik}
              name="localClassroomCount"
              label="Salles de classes"
              type="num"
            />
          </div>
          <div className="col">
            <DillInput
              formik={formik}
              name="localLabCount"
              label="Atélier ou labo"
              type="num"
            />
          </div>
          <div className="col">
            <DillRadio
              list={fermedResponses}
              formik={formik}
              name="schoolFence"
              label="Clôture de l'école"
            />
          </div>
        </div>
        <DillInput
          inline
          formik={formik}
          name="localSchoolFenceMaterial"
          label="Matériaux"
        />
        <Typography.Text
          type="success"
          strong
        >{`Point d'eaux`}</Typography.Text>
        <div className="row">
          <div className="col">
            <DillRadio
              list={fermedResponses}
              formik={formik}
              name="waterPointExist"
              label="Présence"
            />
          </div>
          <div className="col">
            <DillSelect
              formik={formik}
              name="waterPoint"
              label="Source"
              list={[
                { value: "", title: "Séléctionner une source" },
                { value: "Puits", title: "Puits" },
                { value: "Robinet", title: "Robinet" },
                { value: "Autre", title: "Autre" },
              ]}
            />
          </div>
        </div>
        <Typography.Text type="success" strong>{`Electricité`}</Typography.Text>
        <div className="row">
          <div className="col">
            <DillRadio
              list={fermedResponses}
              formik={formik}
              name="electricity"
              label="Présence"
            />
          </div>
          <div className="col">
            <DillSelect
              formik={formik}
              name="electricitySoucre"
              label="Source"
              list={[
                { value: "", title: "Séléctionner une source" },
                { value: "SNEL", title: "SNEL" },
                { value: "Autre", title: "Autre" },
              ]}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <DillInput
              name="latrineConstructionAuthor"
              label="Latrines construites par"
              formik={formik}
            />
          </div>
          <div className="col">
            <DillInput
              name="latrineConstructionYear"
              label="Année de construction"
              formik={formik}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <DillRadio
              inline
              list={fermedResponses}
              formik={formik}
              name="washbasin"
              label="Lave-mains"
            />
          </div>
          <div className="col">
            <DillRadio
              inline
              list={fermedResponses}
              formik={formik}
              name="tank"
              label="Citerne"
            />
          </div>
        </div>
      </Card>
    </React.Fragment>
  );
};
