import { Button, notification, Steps, Typography } from "antd";
import { useFormik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { DillInput, DillPassword, DillSelect } from "../../components/input";
import { RootState } from "../../redux/store";
import centerService from "../../services/centerService";
import userHelper from "../../utils/helpers/userHelper";
import { isEmpty, random, toNumber, toString } from "lodash";
import * as yup from "yup";
import subDivisionService from "../../services/subDivisionService";
import { TitleComponent } from "../../components/title";
import { useLocation, useNavigate } from "react-router-dom";
import jsonCenters from "../../centers.json";
import jsonSectors from "../../sectors.json";
import sectorService from "../../services/sectorService";
import { phoneRegex } from "../../utils/helpers/regex";
import { CreateIdentityCenter } from "./createIdentity";
import { CreateLocationScreen } from "./createLocation";
import { CreateSectorCenterScreen } from "./createSector";
import { CreateOtherCenterScreen } from "./createOther";
import { v4 as uuid } from "uuid";
import { addMeta } from "../../utils/helpers/title";

export function CreateCenterScreen() {
  const [loading, setLoading] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const [searching, setSearching] = React.useState(false);
  const [subDivisionId, setSubdivisionId] = React.useState<string | null>(null);
  const [subDivisionTitle, setSubdivisionTitle] = React.useState<string>("");
  const [refresh, setRefresh] = React.useState(false);
  const [updateId, setUpdateId] = React.useState("");
  const route = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [step, setStep] = React.useState(0);
  const formik1 = useFormik({
    initialValues: {
      name: "",
      leader: "",
      email: "",
      registrationNumber: "",
      formerCount: "0",
      phoneNumber: "",
      leaderPhone: "",
      gender: "",
      regime: "",
      mechanization: "",
      subDivision: "",
    },
    onSubmit: onSave1,
    validationSchema: yup.object({
      name: yup.string().required("Champ requis"),
      leader: yup.string().required("Champ requis"),
      email: yup.string().required("Champ requis").email("Email non valide"),
      phoneNumber: yup
        .string()
        .required("Champ requis")
        .matches(phoneRegex, { message: "Le format autorisé:+xxxxxxxxxx" }),
      subDivision: yup
        .number()
        .typeError("Chercher une sous-province éducationnelle")
        .required("Champ requis"),
    }),
  });

  const formik2 = useFormik({
    initialValues: {
      latitude: 0,
      longitude: 0,
      environment: "",
      occupancy: "",
      distance: "",
      type: "",
      subType: "",
    },
    onSubmit: onSave2,
    validationSchema: yup.object({
      latitude: yup.number().typeError("Veuillez écrire une valeur numérique"),
      longitude: yup.number().typeError("Veuillez écrire une valeur numérique"),
    }),
  });

  const formik3 = useFormik({
    initialValues: {
      sectors: [],
    },
    onSubmit: onSave3,
    validationSchema: yup.object({
      sectors: yup
        .array()
        .required("Champ requis")
        .of(
          yup.object({
            sector: yup.number().typeError("").required("Champ requis"),
            duration: yup.number().typeError("").required("Champ requis"),
          })
        ),
    }),
  });

  const formik4 = useFormik({
    initialValues: {
      courses: "",
      copa: "",
      coges: "",
      waterPointExist: "",
      waterPoint: "",
      supported: "",
      organism: "",
      electricity: "",
      latrine: "",
      boyLatrineCount: "",
      girlLatrineCount: "",
      formerLatrineCount: "",
      playground: "",
      sportsField: "",
      fence: "",
      fenceNature: "",
      otherEtsInLocal: "",
      otherEtsInLocalName: "",
      languages: [],
      sanctions: [],
      affiliatedWithMinistry: "",
      ministryName: "",
      diplomaFollowUp: "",
      receiveProfessionals: "",
    },
    onSubmit: onSave,
    validationSchema: yup.object({
      //sectors: yup.array().required("Champ requis"),
    }),
  });

  React.useEffect(() => {
    const args = new URLSearchParams(route.search);
    const id = args.get("sub_division");
    const update = args.get("update");
    if (!isEmpty(`${id}`)) {
      getSubDivision(`${id}`);
      setSubdivisionId(id);
    }
    if (!isEmpty(update)) {
      setUpdateId(update ?? "");
    }
  }, [route]);

  async function getSubDivision(id: string) {
    await subDivisionService
      .get(id)
      .then((response) => {
        setSubdivisionTitle(response.data.name);
      })
      .catch(() => {});
  }

  async function onSave() {
    const {
      email,
      formerCount,
      gender,
      leader,
      leaderPhone,
      mechanization,
      name,
      phoneNumber,
      regime,
      registrationNumber,
      subDivision,
    } = formik1.values;
    const {
      distance,
      environment,
      latitude,
      longitude,
      occupancy,
      subType,
      type,
    } = formik2.values;
    const { sectors } = formik3.values;
    const {
      affiliatedWithMinistry,
      boyLatrineCount,
      coges,
      copa,
      courses,
      diplomaFollowUp,
      electricity,
      fence,
      fenceNature,
      formerLatrineCount,
      girlLatrineCount,
      languages,
      latrine,
      ministryName,
      organism,
      otherEtsInLocal,
      otherEtsInLocalName,
      playground,
      receiveProfessionals,
      sanctions,
      sportsField,
      supported,
      waterPoint,
      waterPointExist,
    } = formik4.values;
    let metas: any[] = [];
    metas = addMeta(metas, "gender", gender, "string");
    metas = addMeta(metas, "leaderPhone", leaderPhone, "phone");
    metas = addMeta(
      metas,
      "affiliatedWithMinistry",
      affiliatedWithMinistry,
      ""
    );
    metas = addMeta(metas, "boyLatrineCount", boyLatrineCount, "number");
    metas = addMeta(metas, "coges", coges, "");
    metas = addMeta(metas, "courses", courses, "");
    metas = addMeta(metas, "diplomaFollowUp", diplomaFollowUp, "");
    metas = addMeta(metas, "electricity", electricity, "");
    metas = addMeta(metas, "fence", fence, "bool");
    metas = addMeta(metas, "fenceNature", fenceNature, "");
    metas = addMeta(metas, "formerLatrineCount", formerLatrineCount, "number");
    metas = addMeta(metas, "girlLatrineCount", girlLatrineCount, "number");
    metas = addMeta(metas, "latrine", latrine, "bool");
    metas = addMeta(metas, "ministryName", ministryName, "string");
    metas = addMeta(metas, "organism", organism, "string");
    metas = addMeta(metas, "otherEtsInLocal", otherEtsInLocal, "bool");
    metas = addMeta(
      metas,
      "otherEtsInLocalName",
      otherEtsInLocalName,
      "string"
    );
    metas = addMeta(metas, "playground", playground, "bool");
    metas = addMeta(
      metas,
      "receiveProfessionals",
      receiveProfessionals,
      "bool"
    );
    metas = addMeta(metas, "sportsField", sportsField, "bool");
    metas = addMeta(metas, "supported", supported, "bool");
    metas = addMeta(metas, "waterPoint", waterPoint, "string");
    metas = addMeta(metas, "waterPointExist", waterPointExist, "bool");
    metas = addMeta(metas, "copa", copa, "string");
    languages.map((p) => {
      metas = addMeta(metas, "languages", p, "string");
    });
    sanctions.map((p) => {
      metas = addMeta(metas, "sanctions", p, "string");
    });
    const sectorElements: any[] = [];
    sectors.map((p) => {
      sectorElements.push({
        //@ts-ignore
        duration: p.duration, //@ts-ignore
        sector: p.sector,
      });
    });
    const data: any = {
      leader,
      latitude: toNumber(latitude),
      longitude: toNumber(longitude),
      name,
      registrationNumber,
      formerCount: toNumber(formerCount),
      email,
      phoneNumber,
      regime,
      mechanization,
      environment,
      occupancy,
      type,
      subType,
    };
    if (!isEmpty(toString(subDivision)) || !isEmpty(toString(subDivisionId))) {
      if (!isEmpty(`${subDivision}`)) {
        data.subDivision = `/api/sub_divisions/${subDivision}`;
      } else {
        data.subDivision = `/api/sub_divisions/${subDivisionId}`;
      }
    }
    data.sectors = sectorElements;
    data.metas = metas;
    setLoading(true);
    if (isEmpty(updateId)) {
      await onCreate(data);
    } else {
      await onUpdate(data);
    }
    setLoading(false);
  }

  async function onCreate(data: any) {
    await centerService
      .store(data)
      .then(async (response) => {
        await userHelper.getCenters(dispatch);
        notification.info({
          message: "Enregistrement de l'établissement réussi",
        });
        formik1.resetForm();
        formik2.resetForm();
        formik3.resetForm();
        formik4.resetForm();
        setStep(0);
      })
      .catch((reason) => {
        const error = centerService.getError(reason);
        notification.error({
          message: "Enregistrement de l'établissement",
          description: error,
        });
      });
  }

  async function onUpdate(data: any) {
    await centerService
      .update(updateId, data)
      .then(async (response) => {
        await userHelper.getCenters(dispatch);
        notification.info({
          message: "Modification de l'établissement réussie",
        });
        formik1.resetForm();
        formik2.resetForm();
        formik3.resetForm();
        formik4.resetForm();
        setStep(0);
        navigate(`/admin/center/${updateId}`);
      })
      .catch((reason) => {
        const error = centerService.getError(reason);
        notification.error({
          message: "Modification de l'établissement",
          description: error,
        });
      });
  }

  async function saveAllCenters() {
    setLoading(true);
    await Promise.all(
      jsonCenters.map(async (p, i) => {
        const data: any = {
          leader: "Not set",
          latitude: toNumber(p.Latitude),
          longitude: toNumber(p.Longitude),
          name: p.comment,
          registrationNumber: `${i}${random(100000000, 999999999, false)}`,
          email: `${p.comment
            ?.replaceAll(" ", "")
            ?.trim()
            ?.toLowerCase()}@yekolamosala.com`,
          phoneNumber: `0${random(80, 99, false)}${random(
            1000000,
            9999999,
            false
          )}`,
          subDivision: `/api/sub_divisions/${subDivisionId}`,
        };
        await saveCenter(data);
      })
    );
    await Promise.all(
      jsonSectors.map(async (p, i) => {
        const data: any = {
          name: p.nom.trimStart(),
        };
        await saveSector(data);
      })
    );
    setLoading(false);
  }

  async function saveCenter(data: any) {
    await centerService
      .store(data)
      .then(() => {})
      .catch(() => {});
  }

  async function saveSector(data: any) {
    await sectorService
      .store(data)
      .then(() => {})
      .catch(() => {});
  }

  function onSave1() {
    setStep(1);
  }
  function onSave2() {
    setStep(2);
  }
  function onSave3() {
    setStep(3);
  }

  function handleForward() {
    switch (step) {
      case 0:
        formik1.handleSubmit();
        break;
      case 1:
        formik2.handleSubmit();
        break;
      case 2:
        formik4.handleSubmit();
        break;

      default:
        break;
    }
  }

  function handleReturn() {
    if (step > 0) {
      setStep(step - 1);
    }
  }

  function getForwardTitle() {
    let title = "";
    if (step === 3) {
      title = isEmpty(updateId) ? `Enregistrer` : `Modifier`;
    } else {
      title = "Suivant";
    }
    return title;
  }

  function getReturnTitle() {
    let title = "";
    if (step === 0) {
      title = "Annuler";
    } else {
      title = "Retourner";
    }
    return title;
  }

  return (
    <React.Fragment>
      <main className="main main yc_container_main">
        {!isEmpty(subDivisionTitle) && (
          <section className="container">
            <Typography.Title
              className="search--title"
              style={{ textTransform: "uppercase" }}
            >
              {subDivisionTitle}
            </Typography.Title>
          </section>
        )}
        {!refresh && (
          <section className="box">
            <div className="card box__file">
              <TitleComponent
                title={
                  isEmpty(updateId)
                    ? "Enregistrement de l'établissement de formation"
                    : "Modification de l'établissement de formation"
                }
                type={isEmpty(updateId) ? "creation" : "update"}
              />
              <div className="card">
                <Steps
                  current={step}
                  items={[
                    {
                      icon: <i className="fa-solid fa-circle-info"></i>,
                      title: "IDENTIFICATION DE L'ETABLISSEMENT ",
                    },
                    {
                      icon: (
                        <i className="fa-solid fa-circle-location-arrow"></i>
                      ),
                      title: "ETAT DES LIEUX",
                    },
                    {
                      icon: <i className="fa-solid fa-circle-ellipsis"></i>,
                      title: "Autres info",
                    },
                  ]}
                />
                <div className="container">
                  {step === 0 && (
                    <CreateIdentityCenter
                      formik={formik1}
                      formikLocation={formik2}
                      formikMeta={formik4}
                      key="info"
                    />
                  )}
                  {step === 1 && (
                    <CreateLocationScreen formik={formik2} key="location" />
                  )}
                  {step === 2 && (
                    <CreateOtherCenterScreen formik={formik4} key="other" />
                  )}
                </div>
                <div
                  className="row"
                  style={{
                    alignItems: "center",
                    justifyContent: "flex-end",
                    padding: "10px 0 0",
                  }}
                >
                  <div style={{ marginRight: 10 }}>
                    <Button
                      onClick={handleReturn}
                      disabled={loading || step === 0}
                      type="default"
                    >
                      {getReturnTitle()}
                    </Button>
                  </div>
                  <div>
                    <Button
                      onClick={handleForward}
                      loading={loading}
                      disabled={loading}
                      type="primary"
                    >
                      {getForwardTitle()}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </main>
    </React.Fragment>
  );
}
