import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface messageData {
  current: any;
  list: any[];
  all: any[];
  profil?: any;
  notifs: any[];
}

const initialState: messageData = {
  current: {},
  all: [],
  list: [],
  notifs: [],
};

export const messageSlice = createSlice({
  name: "messages",
  initialState,
  reducers: {
    setMessage: (state, action: PayloadAction<any>) => {
      state.current = action.payload;
    },
    setNotifMessages: (state, action: PayloadAction<any[]>) => {
      state.notifs = action.payload;
    },
    setMessages: (state, action: PayloadAction<any>) => {
      state.list = action.payload;
    },
    setAllMessages: (state, action: PayloadAction<any[]>) => {
      state.all = action.payload;
    },
  },
});
export const { setAllMessages, setMessage, setMessages, setNotifMessages } =
  messageSlice.actions;
export default messageSlice.reducer;
