import { routeInterface } from "../routes";
import { LoginScreen } from "../screens/login";
export const publicRoutes: routeInterface[] = [
  {
    component: LoginScreen,
    icon: "",
    isMenu: true,
    label: "Connexion",
    layout: "/home",
    name: "login",
    path: "/",
    subscribeable: false,
    title: "Apprenants",
  },
];
