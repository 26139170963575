import { Button, Table, Tag } from "antd";
import { useFormik } from "formik";
import { isEmpty, toUpper, upperCase, upperFirst } from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { DillSelect, selectItem } from "../components/input";
import { TitleComponent } from "../components/title";
import { RootState } from "../redux/store";
import adminService from "../services/adminService";
import centerService from "../services/centerService";
import divisionService from "../services/divisionService";
import subDivisionService from "../services/subDivisionService";
import { getUid } from "../utils/helpers/title";

export function AdminScreen() {
  const responsibles = useSelector((state: RootState) => state.admin.all);
  const [items, setItems] = React.useState<any[]>([]);
  const inactivates = useSelector(
    (state: RootState) => state.learner.inactivates
  );
  const [showInactive, setShowInactive] = React.useState(false);
  const route = useLocation();
  const [divisions, setDivisions] = React.useState<
    { title: string; value: string }[]
  >([]);
  const [subDivisions, setSubDivisions] = React.useState<
    { title: string; value: string }[]
  >([]);
  const [centers, setCenters] = React.useState<selectItem[]>([]);
  const [sectors, setSectors] = React.useState<selectItem[]>([]);

  const [loadDivision, setLoadDivision] = React.useState(false);
  const [loadSubDivision, setSubLoadDivision] = React.useState(false);
  const [loadCenter, setLoadCenter] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      center: "",
      division: "",
      sub_division: "",
      sector: "",
    },
    onSubmit: () => {},
  });

  React.useEffect(() => {
    const args = new URLSearchParams(route.search);
    const status = args.get("status");
    if ("inactive" === status) {
      setShowInactive(true);
    } else {
      setShowInactive(false);
    }
  }, [route]);

  React.useEffect(() => {
    setItems(responsibles);
  }, [responsibles]);

  async function getAllDivisions() {
    const list: selectItem[] = [];
    setLoadDivision(true);
    await divisionService
      .getByKey(``)
      .then((response) => {
        const data = divisionService.getData(response);
        data.forEach((p) => {
          list.push({
            title: p.name,
            value: p.id,
          });
        });
        if (list.length > 0) {
          list.unshift({
            title: "Séléctionner une province éducationnelle",
            value: "",
          });
        } else {
          list.unshift({
            title: "Aucune division",
            value: "",
          });
        }
        setDivisions(list);
      })
      .catch(() => {});
    setLoadDivision(false);
  }

  async function getSubDivisions() {
    const { division } = formik.values;
    if (isEmpty("" + division)) {
      setSubDivisions([{ title: "Aucune sous-division", value: "" }]);
      return;
    }
    const list: selectItem[] = [];
    setSubLoadDivision(true);
    await subDivisionService
      .getByKey(`division=${division}`)
      .then((response) => {
        const data = subDivisionService.getData(response);
        data.forEach((p) => {
          list.push({
            title: p.name,
            value: p.id,
          });
        });
        if (list.length > 0) {
          list.unshift({
            title: "Séléctionner une sous-province éducationnelle",
            value: "",
          });
        } else {
          list.unshift({
            title: "Aucune sous-division",
            value: "",
          });
        }
        setSubDivisions(list);
      })
      .catch(() => {});
    setSubLoadDivision(false);
  }

  async function getCenters() {
    const { sub_division } = formik.values;
    if (isEmpty("" + sub_division)) {
      setCenters([{ title: "Aucun établissement", value: "" }]);
      return;
    }
    const list: selectItem[] = [];
    setLoadCenter(true);
    await centerService
      .getByKey(`subDivision=${sub_division}`)
      .then((response) => {
        const data = centerService.getData(response);
        data.forEach((p) => {
          list.push({
            title: p.name,
            value: p.id,
          });
        });
        if (list.length > 0) {
          list.unshift({
            title: "Séléctionner un établissement",
            value: "",
          });
        } else {
          list.unshift({
            title: "Aucun établissement",
            value: "",
          });
        }
        setCenters(list);
      })
      .catch(() => {});
    setLoadCenter(false);
  }

  React.useEffect(() => {
    getAllDivisions();
  }, []);

  React.useEffect(() => {
    getSubDivisions();
  }, [formik.values.division]);

  React.useEffect(() => {
    getCenters();
  }, [formik.values.sub_division]);

  React.useEffect(() => {}, [formik.values.center]);

  React.useEffect(() => {}, [formik.values.sector]);

  React.useEffect(() => {
    onFilter();
  }, [formik.values]);

  async function onFilter() {
    let query = ``;
    const { center, division, sector, sub_division } = formik.values;
    if (!isEmpty(`${sector}`)) {
      query = `sector=${sector}`;
    } else if (!isEmpty(`${center}`)) {
      query = `center=${center}`;
    } else if (!isEmpty(`${sub_division}`)) {
      query = `subDivision=${sub_division}`;
    } else if (!isEmpty(`${division}`)) {
      query = `division=${division}`;
    }
    setLoading(true);
    await adminService
      .getByKey(query)
      .then((response) => {
        const data = adminService.getData(response);
        setItems(data);
      })
      .catch(() => {});
    setLoading(false);
  }

  function handleNavigate(data: any) {
    navigate(`/admin/responsible/${getUid(data["@id"])}`);
  }

  return (
    <React.Fragment>
      <main className="main main yc_container_main">
        <TitleComponent title="Les administrateurs">
          <Button className="btn--round">
            <Link
              to={`/admin/responsible/create`}
            >{`Ajouter un responsable`}</Link>
          </Button>
        </TitleComponent>
        {!showInactive && (
          <section className="container fadeIn">
            <div className="row">
              <div className="col">
                <DillSelect
                  formik={formik}
                  name="division"
                  list={divisions}
                  loading={loadDivision}
                  key="division"
                />
              </div>
              <div className="col">
                <DillSelect
                  formik={formik}
                  name="sub_division"
                  list={subDivisions}
                  loading={loadSubDivision}
                  key="subdivision"
                />
              </div>
              <div className="col">
                <DillSelect
                  formik={formik}
                  name="center"
                  list={centers}
                  loading={loadCenter}
                  key="center"
                />
              </div>
            </div>
          </section>
        )}
        <section className="container">
          <Table
            className="table red"
            loading={loading}
            columns={[
              {
                title: "N°",
                render(value, record, index) {
                  return index + 1;
                },
              },
              {
                title: "Nom de responsable",
                dataIndex: "user",
                render(value, record, index) {
                  return `${upperFirst(value.name)} ${toUpper(
                    value.lastname
                  )} ${toUpper(value.postname)}`;
                },
              },
              {
                title: "Centre",
                render(value, record, index) {
                  if (!isEmpty(value?.center?.name)) {
                    return (
                      <Tag color="blue-inverse">
                        {upperCase(value?.center?.name)}
                      </Tag>
                    );
                  } else {
                    return <Tag color="red">{`Aucune responsabilité`}</Tag>;
                  }
                },
              },
              {
                title: "Sous-division",
                render(value, record, index) {
                  if (!isEmpty(value?.subDivision?.name)) {
                    return (
                      <Tag color="blue-inverse">
                        {upperCase(value?.subDivision?.name)}
                      </Tag>
                    );
                  } else {
                    return <Tag color="red">{`Aucune responsabilité`}</Tag>;
                  }
                },
              },
              {
                title: "Division",
                render(value, record, index) {
                  if (!isEmpty(value?.division?.name)) {
                    return (
                      <Tag color="blue-inverse">
                        {upperCase(value?.division?.name)}
                      </Tag>
                    );
                  } else {
                    return <Tag color="red">{`Aucune responsabilité`}</Tag>;
                  }
                },
              },
              {
                title: "Adresse E-mail",
                dataIndex: "user",
                render(value, record, index) {
                  return <Tag>{value.email}</Tag>;
                },
              },
            ]}
            dataSource={showInactive ? inactivates : items}
            onRow={(data: any, index?: number) => {
              return {
                onClick: () => handleNavigate(data),
              };
            }}
          />
        </section>
      </main>
    </React.Fragment>
  );
}
