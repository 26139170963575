import { ColumnsType } from "antd/es/table";

const currentYear = new Date().getFullYear();
export declare type tabType = ColumnsType<any> & {
  children?: ColumnsType<any>;
  editable?: boolean;
  can?: "none" | "editable";
};

export const tab1: tabType = [
  { title: "Niveau de formation", dataIndex: "level" },
  {
    title: "Nombre de salles de cours ouvertes",
    dataIndex: "openClassroomCount",
  },
  {
    title: `Apprenants Inscrits en ${currentYear - 1}-${currentYear}`,
    children: [
      { title: "GF", dataIndex: "current_year_gf" },
      { title: "F", dataIndex: "current_year_f" },
    ],
  },
  {
    title: `Redoublants apprenants de ${currentYear - 2}-${currentYear - 1}`,
    children: [
      { title: "GF", dataIndex: "last_year_gf" },
      { title: "F", dataIndex: "last_year_f" },
    ],
  },
];

/**
 * Repartitions des personnels formateurs
 */
export const tabRepartition: tabType = [
  { title: "", dataIndex: "level", className: "none-editable" },
  { title: "HF", dataIndex: "hf" },
  { title: "F", dataIndex: "f" },
];

/**
 * Repartitions des personnels administratifs
 */
export const tabRepartitionAdmin: tabType = [
  {
    title: "Fonction",
    dataIndex: "fonction",
    align: "center",
    children: [
      { dataIndex: "level", title: "Qualif.", className: "none-editable" },
    ],
  },
  {
    title: "Chef de Centre",
    children: [
      { title: "HF", dataIndex: "center_hf" },
      { title: "F", dataIndex: "center_f" },
    ],
  },
  {
    title: "Chef d’atelier",
    children: [
      { title: "HF", dataIndex: "atelier_hf" },
      { title: "F", dataIndex: "atelier_f" },
    ],
  },
  {
    title: "Autre personnel administratif",
    children: [
      { title: "HF", dataIndex: "personnel_hf" },
      { title: "F", dataIndex: "personnel_f" },
    ],
  },
  {
    title: "Personnel ouvrier",
    children: [
      { title: "HF", dataIndex: "ouvrier_hf" },
      { title: "F", dataIndex: "ouvrier_f" },
    ],
  },
];

/**
 * Nombre de locaux
 */
export const tabLocalCounts: tabType = [
  { title: "Types de locaux", dataIndex: "local", className: "none-editable" },
  { title: "Nombre", dataIndex: "count" },
  { title: "En bon état", dataIndex: "bad" },
  { title: "En mauvais état", dataIndex: "good" },
];

/**
 * Nombre de locaux par nature
 */
export const tabLocalNatureCounts: tabType = [
  { title: "Types de locaux", dataIndex: "local", className: "none-editable" },
  { title: "Nombre", dataIndex: "count" },
  { title: "En dur", dataIndex: "bad" },
  { title: "En semi dur", dataIndex: "half_hard" },
  { title: "En terre battue", dataIndex: "hard_ground" },
  { title: "En paille/feuillage", dataIndex: "paille" },
];

/**
 * Nature et état général
 */
export const tabNatureAndGeneralStates: tabType = [
  { title: "Types de locaux", dataIndex: "local", className: "none-editable" },
  {
    title: "NATURE  ET  ETAT GENERAL",
    children: [
      {
        title: "En dur",
        dataIndex: "hard",
        children: [
          { title: "Bon état", dataIndex: "hard_good" },
          { title: "Mauvais état", dataIndex: "hard_bad" },
        ],
      },
      {
        title: "En semi dur",
        dataIndex: "half_hard",
        children: [
          { title: "Bon état", dataIndex: "half_hard_good" },
          { title: "Mauvais état", dataIndex: "half_hard_bad" },
        ],
      },
      {
        title: "En terre battue",
        dataIndex: "terre",
        children: [
          { title: "Bon état", dataIndex: "ground_good" },
          { title: "Mauvais état", dataIndex: "ground_bad" },
        ],
      },
      {
        title: "En paille/feuillage",
        dataIndex: "paille",
        children: [
          { title: "Bon état", dataIndex: "paille_good" },
          { title: "Mauvais état", dataIndex: "paille_bad" },
        ],
      },
    ],
  },
];

/**
 * Nombre d'équipements
 */
export const tabEquipments: tabType = [
  { title: "Type d’équipements", dataIndex: "equipment" },
  {
    title: "ETAT",
    children: [
      { title: "Bon", dataIndex: "good" },
      { title: "Mauvais", dataIndex: "bad" },
    ],
  },
];

/**
 * Nombre d'équipements
 */
export const tabLearnerManuals: tabType = [
  { title: "Types manuels", dataIndex: "manual", className: "none-editable" },
  { title: "14 jours", dataIndex: "14_day" },
  { title: "1 mois", dataIndex: "1_month" },
  { title: "3 mois", dataIndex: "3_month" },
  { title: "6 mois", dataIndex: "6_month" },
  { title: "12 mois", dataIndex: "12_month" },
  { title: "24 mois", dataIndex: "24_month" },
];

/**
 * Nombre d'équipements
 */
export const tabDocuments: tabType = [
  {
    title: "Types documents",
    dataIndex: "document",
    className: "none-editable",
  },
  { title: "14 jours", dataIndex: "14_day" },
  { title: "1 mois", dataIndex: "1_month" },
  { title: "3 mois", dataIndex: "3_month" },
  { title: "6 mois", dataIndex: "6_month" },
  { title: "12 mois", dataIndex: "12_month" },
  { title: "24 mois", dataIndex: "24_month" },
];

export const adminStaffIdentityColums: tabType = [
  { title: "MINISTERE", dataIndex: "ministry", className: "none-editable" },
  {
    title: "Ministère de la formation professionnelle et métiers",
    dataIndex: "professional",
  },
];
