import { Divider, Typography } from "antd";
import React from "react";
import { EffectifSector } from "../sheet/table";
import { DillInput, DillRadio } from "../../../components/input";
import { Spreadsheet } from "../../../components/table/items";

export const BuildingEquipment: React.FC<{ formik: any }> = ({ formik }) => {
  return (
    <React.Fragment>
      <Divider />
      <Typography.Title
        level={2}
        style={{ fontSize: 20 }}
      >{`III. DISPONIBILITE DES EQUIPEMENTS`}</Typography.Title>
      <Spreadsheet
        formik={formik}
        initItems={[{ key: "0", range: "1" }]}
        colums={[
          { title: "N°", dataIndex: "range", className: "none-editable" },
          { title: "Désignation", dataIndex: "designation" },
          { title: "Nbre", dataIndex: "count" },
          { title: "Total", dataIndex: "total" },
          { title: "Suffisant", dataIndex: "suffisant" },
          { title: "Insuffisant", dataIndex: "insuffisant" },
          { title: "Observation", dataIndex: "observation" },
        ]}
        counter
        genericValue="range"
        name="materialsReceived"
      />
      <Divider />
      <Typography.Title
        level={2}
        style={{ fontSize: 20 }}
      >{`IV. MATERIELS RECUS `}</Typography.Title>
      <div className="row" style={{ alignItems: "center" }}>
        <div className="col">
          <DillInput
            type="date"
            inline
            formik={formik}
            label="DATE"
            name="materialReceivedDate"
          />
        </div>
        <div className="col">
          <DillRadio
            formik={formik}
            label=""
            list={[
              { value: "GOUVERNEMENT", title: "GOUVERNEMENT" },
              { value: "PARTENAIRE", title: "PARTENAIRE" },
            ]}
            name="materialReceivedSoucre"
          />
        </div>
      </div>
      <Spreadsheet
        formik={formik}
        initItems={[{ key: "0", range: "1" }]}
        colums={[
          { title: "N°", dataIndex: "range", className: "none-editable" },
          { title: "Désignation", dataIndex: "designation" },
          { title: "Nombre", dataIndex: "count" },
          { title: "Motif", dataIndex: "motif" },
          { title: "Observation", dataIndex: "observation" },
        ]}
        counter
        genericValue="range"
        name="equipmentAvailables"
      />
    </React.Fragment>
  );
};
