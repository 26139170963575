import { useFormik } from "formik";
import React from "react";
import { RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, toString } from "lodash";
import divisionService from "../../services/divisionService";
import subDivisionService from "../../services/subDivisionService";
import centerService from "../../services/centerService";
import { Card, notification } from "antd";
import {
  DillCheckbox,
  DillInput,
  DillRadio,
  DillSelect,
  DillTextarea,
} from "../../components/input";
import { EffectifSector } from "../center/sheet/table";
import { addMeta, centerLanguages } from "../../utils/helpers/title";
import { SheetHeader } from "../../components/sheetHeader";
import { getProvinces } from "../../utils/helpers/geoHelper";
import learnerPlugService from "../../services/learnerPlugService";
import { useNavigate } from "react-router-dom";
import { setDraftingSheet } from "../../redux/sheetReducer";
import draftHelper from "../../utils/helpers/draftHelper";

export function LearnerIdentificationSheet() {
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      sex: "Femme",
      center: "",
      lastName: "",
      firstName: "",
      postName: "",
      option: "",
      level: "",
      birthDate: "",
      bornPlace: "",
      nationality: "",

      fatherName: "",
      fatherWork: "",
      montherName: "",
      montherWork: "",

      tutorName: "",
      tutorWork: "",
      tutorPhone: "",
      tutorAdress: "",

      province: "",
      territory: "",
      originSector: "",
      village: "",
      languages: [],

      phoneNumber: "",
      address: "",
      health: "",
      intellectualAptitude: "",
      interest: "",
      local: "",
    },
    onSubmit: onSave,
  });

  async function onSave() {
    const {
      address,
      birthDate,
      bornPlace,
      center,
      fatherName,
      fatherWork,
      firstName,
      health,
      intellectualAptitude,
      interest,
      languages,
      lastName,
      level,
      local,
      montherName,
      montherWork,
      nationality,
      option,
      originSector,
      phoneNumber,
      postName,
      province,
      sex,
      territory,
      tutorAdress,
      tutorName,
      tutorPhone,
      tutorWork,
      village,
    } = formik.values;

    const data: any = {
      firstName,
      postName,
      lastName,
      birthDate,
      birthPlace: bornPlace,
      address,
      phone: phoneNumber,
      plug: "learner",
    };
    let metas: any[] = [];
    metas = addMeta(metas, "center", center, "string");
    metas = addMeta(metas, "fatherName", fatherName, "string");
    metas = addMeta(metas, "fatherWork", fatherWork, "string");
    metas = addMeta(metas, "health", health, "health");
    metas = addMeta(metas, "montherName", montherName, "string");
    metas = addMeta(metas, "montherWork", montherWork, "string");
    metas = addMeta(metas, "originSector", originSector, "string");
    metas = addMeta(metas, "province", province, "string");
    metas = addMeta(metas, "sex", sex, "string");
    metas = addMeta(metas, "territory", territory, "string");
    metas = addMeta(metas, "tutorAdress", tutorAdress, "string");
    metas = addMeta(metas, "nationality", nationality, "string");
    metas = addMeta(metas, "option", option, "string");
    metas = addMeta(metas, "level", level, "string");
    metas = addMeta(metas, "tutorName", tutorName, "string");
    metas = addMeta(metas, "tutorPhone", tutorPhone, "string");
    metas = addMeta(metas, "tutorWork", tutorWork, "string");
    metas = addMeta(metas, "village", village, "string");
    metas = addMeta(metas, "local", local, "string");
    metas = addMeta(metas, "interest", interest, "string");
    metas = addMeta(
      metas,
      "intellectualAptitude",
      intellectualAptitude,
      "string"
    );
    metas = addMeta(metas, "languages", JSON.stringify(languages), "json");
    data.learnerPlugMetas = metas;
    setLoading(true);
    await learnerPlugService
      .store(data)
      .then((response) => {
        dispatch(setDraftingSheet(false));
        draftHelper.remove();
        notification.info({
          message: "Enregistrement de fiche réussi",
        });
        formik.resetForm();
        navigate(`/admin/plug/${response.data.id ?? ""}/learner`);
      })
      .catch((reason) => {
        const error = learnerPlugService.getError(reason);
        notification.error({
          message: "Enregistrement de fiche",
          description: error,
        });
      });
    setLoading(false);
  }

  function handleSave() {
    formik.submitForm();
    let hasError = false;
    const errors: any = { ...formik.errors };
    for (const key in errors) {
      const element = errors[key] as any;
      if (!isEmpty(element)) {
        hasError = true;
      }
    }
    if (hasError) {
      notification.warning({
        message: "Création de fiche",
        description: "Quelques champs ne sont pas rempli correctement",
      });
    }
  }

  return (
    <React.Fragment>
      <SheetHeader
        onPublish={handleSave}
        formik={formik}
        title=""
        type="learner"
        loading={loading}
      />
      <main className="main main yc_container_main">
        <section className="container">
          <Card hoverable className="card">
            <div className="row">
              <div className="col">
                <DillInput
                  //loading={searchingCenter}
                  //search
                  //setSearch={setSearchCenter}
                  formik={formik}
                  name="center"
                  label="Centre"
                  //list={centers}
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <DillInput inline formik={formik} name="level" label="Niveau" />
              </div>
              <div className="col">
                <DillInput
                  inline
                  formik={formik}
                  name="option"
                  label="Option"
                />
              </div>
            </div>
          </Card>
          <Card className="card" hoverable style={{ margin: "20px 0 0" }}>
            <div className="row">
              <div className="col">
                <DillInput formik={formik} name="lastName" label="Nom" />
              </div>
              <div className="col">
                <DillInput formik={formik} name="postName" label="Post-nom" />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <DillInput formik={formik} name="firstName" label="Prénom" />
              </div>
              <div className="col">
                <DillRadio
                  button
                  list={[
                    { value: "Femme", title: "Femme" },
                    { value: "Homme", title: "Homme" },
                  ]}
                  formik={formik}
                  name="sex"
                  label="Sexe"
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <DillInput
                  formik={formik}
                  name="bornPlace"
                  label="Lieu de naissance"
                />
              </div>
              <div className="col">
                <DillInput
                  formik={formik}
                  type="date"
                  name="birthDate"
                  label="Date de naissance"
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <DillInput
                  formik={formik}
                  name="nationality"
                  label="Nationalité"
                />
              </div>
            </div>
          </Card>
          <Card className="card" hoverable style={{ margin: "20px 0 0" }}>
            <div className="row">
              <div className="col">
                <DillInput
                  formik={formik}
                  name="fatherName"
                  label="Nom du père"
                />
              </div>
              <div className="col">
                <DillInput
                  formik={formik}
                  name="fatherWork"
                  label="Profession du père"
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <DillInput
                  formik={formik}
                  name="montherName"
                  label="Nom de la mère"
                />
              </div>
              <div className="col">
                <DillInput
                  formik={formik}
                  name="montherWork"
                  label="Profession de la mère"
                />
              </div>
            </div>
          </Card>
          <Card className="card" hoverable style={{ margin: "20px 0 0" }}>
            <div className="row">
              <div className="col">
                <DillInput
                  formik={formik}
                  name="tutorName"
                  label="Nom du tuteur"
                />
              </div>
              <div className="col">
                <DillInput
                  formik={formik}
                  name="tutorWork"
                  label="Profession du tuteur"
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <DillInput
                  formik={formik}
                  name="tutorPhone"
                  type="tel"
                  label="N° Téléphone du tuteur"
                />
              </div>
              <div className="col">
                <DillInput
                  formik={formik}
                  name="tutorAddress"
                  label="Adresse du tuteur"
                />
              </div>
            </div>
          </Card>
          <Card className="card" hoverable style={{ margin: "20px 0" }}>
            <div className="row">
              <div className="col">
                <DillSelect
                  formik={formik}
                  name="province"
                  label="Province d'origine"
                  list={[
                    { value: "", title: "Séléctionner la province" },
                    ...getProvinces(),
                  ]}
                />
              </div>
              <div className="col">
                <DillInput
                  formik={formik}
                  name="territory"
                  label="Territoire d'origine"
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <DillInput
                  formik={formik}
                  name="originSector"
                  label="Secteur d'origine"
                />
              </div>
              <div className="col">
                <DillInput
                  formik={formik}
                  name="village"
                  label="Village d'origine"
                />
              </div>
            </div>
            <DillCheckbox
              enabled
              formik={formik}
              list={centerLanguages}
              name="languages"
              label="Langues parlées "
            />
            <DillInput
              formik={formik}
              name="phoneNumber"
              type="tel"
              label="N° Téléphone de l'apprenant"
            />
            <DillInput
              formik={formik}
              name="address"
              label="Adresse de l'apprenant"
            />
            <DillInput
              formik={formik}
              name="intellectualAptitude"
              label="Aptitudes intellectuelles de l'apprenant"
            />
            <DillInput
              formik={formik}
              name="health"
              label="Etat de santé de l'apprenant"
            />
            <DillInput
              formik={formik}
              name="interest:"
              label="Intérêts de l'apprenant"
            />
            <DillInput
              formik={formik}
              name="local"
              label="Local de cours de l'apprenant"
            />
          </Card>
        </section>
      </main>
    </React.Fragment>
  );
}
