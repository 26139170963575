import React from "react";
import {
  DillInput,
  DillRadio,
  DillSelect,
  DillTextarea,
} from "../../../components/input";
import { Divider, Typography } from "antd";
import subDivisionService from "../../../services/subDivisionService";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { fermedResponses } from "../../../utils/helpers/title";
import { getProvinces } from "../../../utils/helpers/geoHelper";

export const Identity: React.FC<{ formik: any }> = ({ formik }) => {
  const [divisions, setDivisions] = React.useState<any[]>([]);
  const [subDivisions, setSubDivisions] = React.useState<any[]>([]);
  const allDivisions = useSelector((state: RootState) => state.division.list);

  React.useEffect(() => {
    const elements: any[] = getProvinces();
    /*allDivisions.map((p) => {
      elements.push({ value: p.id, title: p.name });
    });
    elements.unshift({ value: "", title: "Séléctionner une province éducationnelle" });*/
    elements.unshift({ value: "", title: "Séléctionner une province" });
    setDivisions(elements);
  }, [allDivisions]);

  React.useEffect(() => {
    getDistricts();
  }, [formik.values.division]);

  async function getDistricts() {
    formik.setValues({ ...formik.values, subDivision: "" });
    const id = formik.values.division;
    const elements: any[] = [];
    const query = `order[name]=asc`;
    await subDivisionService
      //.getByKey(`division=${id}&order[name]=asc`)
      .getByKey(query)
      .then((response) => {
        const data = subDivisionService.getData(response);
        data.map((p) => {
          elements.push({ value: p.id, title: p.name });
        });
      })
      .catch(() => {});
    elements.unshift({
      value: "",
      title: "Séléctionner une sous-province éducationnelle",
    });
    setSubDivisions(elements);
  }

  return (
    <React.Fragment>
      <div>
        <Divider style={{ margin: 5 }} />
        <Typography.Title level={2}>
          {`I. IDENTIFICATION DU CENTRE`}
        </Typography.Title>
        <DillInput inline formik={formik} name="name" label="Nom du centre" />
        <DillRadio
          formik={formik}
          list={[
            { value: "CFP", title: "CFP" },
            { value: "CAP", title: "CAP" },
          ]}
          button
          name="cfp"
        />
        <DillInput
          formik={formik}
          name="address"
          label="Adresse du centre"
          inline
        />
        <div className="row">
          <div className="col">
            <DillInput
              formik={formik}
              name="phoneNumber"
              label="Téléphone du centre"
              type="tel"
            />
          </div>
          <div className="col">
            <DillInput
              formik={formik}
              name="email"
              label="Courriel du centre"
              type="email"
            />
          </div>
        </div>
        <DillInput
          formik={formik}
          name="postalCode"
          inline
          label="Boite postal du centre"
        />
        <Typography.Title level={3} style={{ margin: 1 }}>
          {`Localisation administrative `}
        </Typography.Title>
        <div className="row">
          <div className="col">
            <DillSelect
              name="division"
              formik={formik}
              list={divisions}
              label="Province"
            />
          </div>
          <div className="col">
            <DillInput name="district" formik={formik} label="District" />
          </div>
          <div className="col">
            <DillInput
              //list={subDivisions}
              formik={formik}
              name="subDivision"
              label="Territoire/Commune"
            />
          </div>
        </div>
        <DillRadio
          label="Type de centre"
          formik={formik}
          list={[
            {
              value: "Féminin, Filles uniquement",
              title: "Féminin, Filles uniquement",
            },
            { value: "Masculin", title: "Masculin" },
            {
              value: "Mixte (filles et garçons)",
              title: "Mixte (filles et garçons)",
            },
          ]}
          name="gender"
          inline
        />
        <DillRadio
          label="Milieu"
          formik={formik}
          list={[
            { value: "Urbain", title: "Urbain" },
            { value: "Rural", title: "Rural" },
          ]}
          name="environment"
          inline
        />
        <DillRadio
          label="Régime de gestion"
          formik={formik}
          list={[
            { value: "Public", title: "Public" },
            { value: "Privé non agréé", title: "Privé non agréé" },
            { value: "Privé agréé", title: "Privé agréé" },
            { value: "Confessionnel", title: "Confessionnel" },
          ]}
          name="regime"
          inline
        />
        <DillInput
          name="legalReference"
          label="N° de Référence juridique (Arrêté d’agrément /d’autorisation)"
          formik={formik}
          inline
        />
        <div className="row">
          <div className="col">
            <DillInput
              name="leader"
              label="Nom et post nom du Chef de centre "
              formik={formik}
            />
          </div>
          <div className="col">
            <DillInput
              name="leaderPhone"
              label="Téléphone du chef de Centre"
              formik={formik}
              type="tel"
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <DillRadio
              label="Le centre organise-t-il  des formations modulaires "
              formik={formik}
              list={fermedResponses}
              name="modularForms"
              button
              inline
            />
          </div>
          {formik?.values.modularForms === "yes" && (
            <div className="col">
              <DillInput
                formik={formik}
                name="domain"
                label="Dans quel domaine"
              />
            </div>
          )}
        </div>
        <DillInput
          formik={formik}
          name="duration"
          label="La durée de la formation"
          inline
        />
      </div>
    </React.Fragment>
  );
};
