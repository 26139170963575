import { Button, Divider, Skeleton, Tabs, Typography } from "antd";
import { isEmpty, upperCase, upperFirst } from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import learnerService from "../../services/learnerService";
import dateHelpers from "../../utils/helpers/dateHelpers";
import { getUid } from "../../utils/helpers/title";
import userHelper from "../../utils/helpers/userHelper";
import { ElementCard } from "../../components/element";
import { LearnerCareer } from "./career";
import { RootState } from "../../redux/store";
import { setFloatUrl } from "../../redux/userReducer";

export function LearnerView() {
  const params = useParams();
  const [loading, setLoading] = React.useState(true);
  const [refresh, setRefresh] = React.useState(false);
  const [leaner, setLeaner] = React.useState<any>({});
  const dispatch = useDispatch();

  const url = useSelector((state: RootState) => state.user.floatUrl);

  React.useEffect(() => {
    dispatch(setFloatUrl(`/admin/learner/create?update=${params.id}`));
  }, [params.id, url]);

  React.useEffect(() => {
    setLoading(true);
    init();
  }, [params.id]);

  async function activeLearner() {
    setRefresh(true);
    const id = params.id;
    await learnerService
      .update(`${id}`, { isActive: true })
      .then(async () => {
        userHelper.getLearners(dispatch);
        userHelper.getInactivateLeaners(dispatch);
        await init();
      })
      .catch(() => {});
    setRefresh(false);
  }

  async function disableLearner() {
    setRefresh(true);
    const id = params.id;
    await learnerService
      .update(`${id}`, { isActive: false })
      .then(async () => {
        userHelper.getLearners(dispatch);
        userHelper.getInactivateLeaners(dispatch);
        await init();
      })
      .catch(() => {});
    setRefresh(false);
  }

  async function init() {
    const id = params.id;
    await learnerService
      .get(`${id}`)
      .then(async (response) => {
        const data: any = response.data;
        setLeaner(data);
      })
      .catch((reason) => {});
    setLoading(false);
  }

  return (
    <React.Fragment>
      <main className="main main yc_container_main">
        {loading && (
          <React.Fragment>
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
          </React.Fragment>
        )}
        {!loading && (
          <section className="container" style={{ paddingTop: 25 }}>
            <Tabs type="card">
              <Tabs.TabPane tabKey="show" tab="Présentation">
                <section>
                  <div className="row">
                    <div className="col-md-3 item">
                      <ElementCard
                        icon="fa-regular fa-vector-circle"
                        subtitle="Filière"
                        title={`${leaner?.training?.sector?.name ?? "Aucune"}`}
                        type="primary"
                        url={`/admin/sector/${getUid(
                          leaner?.training?.sector?.id ?? ""
                        )}`}
                      />
                      <ElementCard
                        icon="fa-solid fa-location-dot"
                        subtitle="Centre"
                        title={leaner?.center?.name}
                        type="danger"
                        url={`/admin/center/${getUid(
                          leaner?.center["@id"] ?? ""
                        )}`}
                      />
                      <ElementCard
                        icon="fa-duotone fa-location-crosshairs"
                        subtitle="Sous-division"
                        title={leaner?.center?.subDivision?.name}
                        type="gold"
                        url={`/admin/sub_division/${getUid(
                          leaner?.center?.subDivision ?? ["@id"] ?? ""
                        )}`}
                      />
                      <ElementCard
                        icon="fa-solid fa-location-crosshairs"
                        subtitle="Division"
                        title={leaner?.center?.subDivision?.division?.name}
                        type="primary"
                        url={`/admin/division/${getUid(
                          leaner?.center?.subDivision?.division["@id"] ?? ""
                        )}`}
                      />
                    </div>
                    <div className="col-md-9" style={{ marginTop: "1.6rem" }}>
                      {!leaner.isActive && (
                        <Button
                          onClick={activeLearner}
                          loading={refresh}
                          disabled={refresh}
                          type="primary"
                        >
                          {`Activer l'apprenant`}
                        </Button>
                      )}
                      {leaner.isActive && (
                        <Button
                          onClick={disableLearner}
                          loading={refresh}
                          disabled={refresh}
                          type="primary"
                          danger
                        >
                          {`Désactiver l'apprenant`}
                        </Button>
                      )}
                      <Typography.Title>
                        {`${upperFirst(leaner?.user?.name)} ${upperCase(
                          leaner?.user?.lastname
                        )} ${upperCase(leaner?.user?.postname)}`}
                      </Typography.Title>
                      <Divider style={{ margin: "0.5rem 0" }} />
                      <div className="row card--stretch">
                        <div className="col-md-3 item">
                          <ElementCard
                            subtitle="date de naissance"
                            title={
                              dateHelpers.getDate(
                                leaner?.user?.bornDate,
                                "DD MMMM YYYY"
                              ) ?? ""
                            }
                            icon=""
                          />
                        </div>
                        {!isEmpty(
                          dateHelpers.getAge(leaner?.user?.bornDate) ?? ""
                        ) && (
                          <div className="col-md-3 item">
                            <ElementCard
                              subtitle="Age"
                              title={
                                dateHelpers.getAge(leaner?.user?.bornDate) ?? ""
                              }
                              icon=""
                            />
                          </div>
                        )}
                        <div className="col-md-3 item">
                          <ElementCard
                            subtitle="Lieu de naissance"
                            title={leaner?.user?.bornPlace}
                            icon=""
                          />
                        </div>
                        <div className="col-md-3 item">
                          <ElementCard
                            subtitle="Genre"
                            title={
                              leaner?.user?.sex === "f" ? "Femme" : "Homme"
                            }
                            icon=""
                          />
                        </div>
                        <div className="col-md-3 item">
                          <ElementCard
                            subtitle="Téléphone"
                            title={leaner?.user?.phoneNumber}
                            icon=""
                          />
                        </div>
                        <div className="col-md-3 item">
                          <ElementCard
                            subtitle="E-mail"
                            title={leaner?.user?.email}
                            icon=""
                          />
                        </div>
                        <div className="col-md-3 item">
                          <ElementCard
                            subtitle="Durée de formation"
                            title={
                              leaner?.training?.duration
                                ? `${leaner?.training?.duration} mois`
                                : "Non définie"
                            }
                            icon=""
                          />
                        </div>
                        <div className="col-md-3 item">
                          <ElementCard
                            subtitle="Année de formation"
                            title={leaner?.trainingYear}
                            icon=""
                          />
                        </div>
                        <div className="col-md-3 item">
                          <ElementCard
                            subtitle="Classe"
                            title={leaner?.level?.title}
                            icon=""
                          />
                        </div>
                      </div>
                      <Divider />
                    </div>
                  </div>
                </section>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Parcours" tabKey="career" key="career">
                <LearnerCareer />
              </Tabs.TabPane>
            </Tabs>
          </section>
        )}
      </main>
    </React.Fragment>
  );
}
