import { FloatButton, Tabs } from "antd";
import React from "react";
import { CenterShowView } from "./show";
import { CenterSectorView } from "./sector";
import { CenterFormersView } from "./formers";
import { useDispatch, useSelector } from "react-redux";
import { setFloatUrl } from "../../redux/userReducer";
import { useParams } from "react-router-dom";
import { RootState } from "../../redux/store";
import { CenterMetaView } from "./meta";
import { CenterLevelView } from "./levels";

export function CenterView() {
  const dispatch = useDispatch();
  const params = useParams();
  const url = useSelector((state: RootState) => state.user.floatUrl);

  React.useEffect(() => {
    dispatch(setFloatUrl(`/admin/center/create?update=${params.id}`));
  }, [params.id, url]);
  return (
    <React.Fragment>
      <main className="main main yc_container_main">
        <section className="container" style={{ marginBottom: "1.6rem" }}>
          <Tabs
            animated
            items={[
              {
                label: "Présentation",
                key: "show",
                children: <CenterShowView />,
              },
              {
                label: "Les filières et métiers",
                key: "sector",
                children: <CenterSectorView />,
              },
              {
                label: "Les formateurs",
                key: "former",
                children: <CenterFormersView />,
              },
              {
                label: "Les classes",
                key: "level",
                children: <CenterLevelView />,
              },
              // {
              //   label: "Autres informations de l'établissement",
              //   key: "centerMeta",
              //   children: <CenterMetaView />,
              // },
            ]}
          />
        </section>
      </main>
    </React.Fragment>
  );
}
