import { Button, notification, Typography } from "antd";
import { useFormik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { DillInput, DillPassword, DillSelect } from "../../components/input";
import { RootState } from "../../redux/store";
import * as yup from "yup";
import subDivisionService from "../../services/subDivisionService";
import userHelper from "../../utils/helpers/userHelper";
import { isEmpty, toNumber } from "lodash";
import divisionService from "../../services/divisionService";
import { TitleComponent } from "../../components/title";
import { useLocation, useNavigate } from "react-router-dom";

export function CreateSubDivisionScreen() {
  const divList = useSelector((state: RootState) => state.division.list);
  const [divisions, setDivisions] = React.useState<any[]>([]);
  const [elements, setElements] = React.useState<any[]>(divList);
  const [loading, setLoading] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const [searching, setSearching] = React.useState(false);
  const dispatch = useDispatch();
  const [divisionId, setDivisionId] = React.useState<string | null>(null);
  const [divisionTitle, setDivisionTitle] = React.useState<string>("");
  const route = useLocation();
  const navigate = useNavigate();
  const [refresh, setRefresh] = React.useState(false);
  const [updateId, setUpdateId] = React.useState("");
  const formik = useFormik({
    initialValues: {
      name: "",
      leader: "",
      email: "",
      division: "",
      registrationNumber: "",
      agentsNumber: 0,
      centerNumber: 0,
      phoneNumber: "",
    },
    validationSchema: yup.object({
      name: yup.string().required("Champ requis"),
      leader: yup.string().required("Champ requis"),
      email: yup.string().required("Champ requis").email("Email non valide"),
      phoneNumber: yup.string().required("Champ requis"),
      division: yup.string().required("Champ requis"),
    }),
    onSubmit: onSave,
  });

  React.useEffect(() => {
    const list: any[] = [];
    elements.forEach((p) => {
      list.push({ value: `${p.id}`, title: p.name });
    });
    list.unshift({
      value: "",
      title: "Séléctionner la province éducationnelle",
    });
    setDivisions(list);
  }, [elements]);

  async function onSave() {
    const {
      agentsNumber,
      centerNumber,
      division,
      leader,
      email,
      name,
      phoneNumber,
      registrationNumber,
    } = formik.values;
    const data: any = {
      leader,
      email,
      name,
      phoneNumber,
      agentsNumber,
      centerNumber,
      registrationNumber,
    };
    if (!isEmpty(division)) {
      data.division = `/api/divisions/${division}`;
    }
    setLoading(true);
    if (isEmpty(updateId)) {
      await onCreate(data);
    } else {
      await onUpdate(data);
    }
    setLoading(false);
  }

  async function onCreate(data: any) {
    await subDivisionService
      .store(data)
      .then(async (response) => {
        await userHelper.getSubDivisions(dispatch);
        notification.info({
          message: "Enregistrement de la sous-division",
        });
        formik.resetForm({
          values: {
            agentsNumber: 0,
            centerNumber: 0,
            division: "",
            leader: "",
            email: "",
            name: "",
            phoneNumber: "",
            registrationNumber: "",
          },
        });
        if (!isEmpty(divisionId)) {
          navigate(`/admin/division/${divisionId}`);
        }
      })
      .catch((reason) => {
        const error = subDivisionService.getError(reason);
        notification.error({
          message: "Enregistrement de sous-division",
          description: error,
        });
      });
  }

  async function onUpdate(data: any) {
    await subDivisionService
      .update(updateId, data)
      .then(async (response) => {
        await userHelper.getSubDivisions(dispatch);
        notification.info({
          message: "Modification de la sous-division réussie",
        });
        formik.resetForm({
          values: {
            agentsNumber: 0,
            centerNumber: 0,
            division: "",
            leader: "",
            email: "",
            name: "",
            phoneNumber: "",
            registrationNumber: "",
          },
        });
        navigate(`/admin/sub_division/${updateId}`);
      })
      .catch((reason) => {
        const error = subDivisionService.getError(reason);
        notification.error({
          message: "Modification de sous-division",
          description: error,
        });
      });
  }

  React.useEffect(() => {
    onSearchDivision();
  }, [search]);

  async function onSearchDivision() {
    setSearching(true);
    let query = `name=${search}`;
    if (isEmpty(search)) {
      query = ``;
    }
    await divisionService
      .getByKey(query)
      .then((response) => {
        setElements(divisionService.getData(response));
      })
      .catch(() => {});
    setSearching(false);
  }

  React.useEffect(() => {
    const args = new URLSearchParams(route.search);
    const id = args.get("division") ?? "";
    const update = args.get("update");
    if (!isEmpty(`${id}`)) {
      getDivision(`${id}`);
      setDivisionId(`${id}`);
      formik.setValues({ ...formik.values, division: `${id}` });
    } else if (!isEmpty(update)) {
      setUpdateId(update ?? "");
    }
  }, [route]);

  React.useEffect(() => {
    initUpdate();
  }, [updateId, divisions]);

  async function initUpdate() {
    if (isEmpty(updateId)) {
      return;
    }
    setRefresh(true);
    await subDivisionService
      .get(updateId)
      .then((response) => {
        const data = response.data;
        const {
          email,
          leader,
          name,
          phoneNumber,
          registrationNumber,
          division,
        } = data;
        formik.setValues({
          ...formik.values,
          email,
          leader,
          name,
          phoneNumber,
          registrationNumber,
          division: `${division?.id ?? ""}`,
        });
      })
      .catch(() => {});
    setRefresh(false);
  }

  async function getDivision(id: string) {
    await divisionService
      .get(id)
      .then((response) => {
        setDivisionTitle(response.data.name);
      })
      .catch(() => {});
  }

  return (
    <React.Fragment>
      <main className="main main yc_container_main">
        {!isEmpty(divisionTitle) && (
          <section className="container">
            <Typography.Title
              className="search--title"
              style={{ textTransform: "uppercase" }}
            >
              {divisionTitle}
            </Typography.Title>
          </section>
        )}
        <section className="box">
          <div className="card box__login">
            <TitleComponent
              title={
                isEmpty(updateId)
                  ? "Enregistrement de la sous-division"
                  : "Modification de la sous-division"
              }
              type={isEmpty(updateId) ? "creation" : "update"}
            />
            <div className="card">
              <DillInput
                formik={formik}
                name="name"
                label={"Nom de la sous-division"}
                placeholder={""}
              />
              <DillInput
                formik={formik}
                name="leader"
                label={"Nom du responsable"}
                placeholder={""}
              />
              {/*<DillInput
              formik={formik}
              name="formerCount"
              label={"Nombre de formateurs"}
              placeholder={""}
  />*/}
              <DillInput
                formik={formik}
                name="registrationNumber"
                label={"Numéro d'enregistrement"}
                placeholder={""}
                type="num"
              />
              <DillInput
                formik={formik}
                name="phoneNumber"
                label={"Numéro de téléphone"}
                placeholder={""}
                type="tel"
              />
              <DillInput
                formik={formik}
                name="email"
                label={"Adresse E-mail"}
                placeholder={""}
                type="email"
              />
              {isEmpty(divisionId) && (
                <DillSelect
                  formik={formik}
                  name="division"
                  list={divisions}
                  label="Division"
                  search
                  setSearch={setSearch}
                  loading={searching}
                />
              )}
              <div
                className="row"
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "10px 0 0",
                }}
              >
                <Button
                  onClick={() => formik.handleSubmit()}
                  disabled={loading}
                  loading={loading}
                  type="primary"
                >
                  {isEmpty(updateId) ? `Enregistrer` : `Modifier`}
                </Button>
              </div>
            </div>
          </div>
        </section>
      </main>
    </React.Fragment>
  );
}
