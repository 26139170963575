import React, { PropsWithChildren } from "react";
import { Popover, Button, Avatar } from "antd";
import { routes } from "../routes";
import { Link, useLocation } from "react-router-dom";
import { ProfilNavbar } from "./profil";
import { colorSecondary } from "../utils/themes/colors";
import { isEmpty } from "lodash";

export const Navbar: React.FC<
  PropsWithChildren<{ showMenu?: boolean; isMail?: boolean }>
> = ({ children, showMenu, isMail }) => {
  const [visible, setVisible] = React.useState(false);
  return (
    <header className="header">
      <div className="row" style={{ alignItems: "center" }}>
        <div className="logo">
          <img src={require("../assets/images/min.png")} alt="logo" />
        </div>
        <div className="space"></div>
        {showMenu && (
          <nav className="nav">
            {/*<Popover
              content={<NavComponent setVisible={setVisible} />}
              onOpenChange={(v) => setVisible(v)}
              trigger="click"
              overlayInnerStyle={{ width: 220 }}
              open={visible}
            >
              <Button shape="circle" size="large">
                <i className="icon fa-solid fa-gear"></i>
              </Button>
        </Popover>*/}
          </nav>
        )}
        {showMenu && <ProfilNavbar isMail={isMail} />}
      </div>
      <div className="decorate">
        <span className="item"></span>
        <span className="item"></span>
        <span className="item"></span>
      </div>
    </header>
  );
};

const NavComponent: React.FC<{ setVisible: any }> = ({ setVisible }) => {
  function handleClose() {
    if (typeof setVisible === "function") {
      setVisible(false);
    }
  }
  const route = useLocation();

  function getIndex(path: string) {
    const index =
      `${path}` === route.pathname || `${path}/` === route.pathname ? 1 : -1;
    return index;
  }
  return (
    <ul className="nav--list">
      {routes
        .filter((p) => p.isMenu && p.layout === "/admin")
        .map((p, i) => {
          return (
            <li key={`link__${i}`} className="nav--link">
              <Link
                className={`${
                  getIndex(p.layout + p.path) != -1 ? "active" : ""
                }`}
                to={`${p.layout}${p.path}`}
                onClick={handleClose}
              >
                <div className="row" style={{ alignItems: "center" }}>
                  {!isEmpty(p.icon) && (
                    <i className={`${p.icon} nav--icon`}></i>
                  )}
                  <span style={{ flex: 1, paddingLeft: "0.5rem" }}>
                    {p.label}
                  </span>
                </div>
              </Link>
            </li>
          );
        })}
    </ul>
  );
};
