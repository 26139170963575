import { isEmpty, random, toNumber, toUpper } from "lodash";
import dateHelpers from "./dateHelpers";

const appName = "DANTIC";
const refKey = "yekla-ref";
//const mapKey = "AIzaSyC-LGOLFYF_XiHhQv_EQnMk_iPfcJVEzro";
const mapKey = "AIzaSyAMcKyE6ZHxd2PHA8DqTUVxmWNbwXMkNy0";

export declare type sheetType =
  | "building"
  | "learner"
  | "center"
  | "establishment"
  | "movement"
  | "register"
  | "administrative";
export declare type mapMarker = {
  title: string;
  position: google.maps.LatLngLiteral;
};

const setAppTitle = (
  value: string,
  description?: string,
  canonical?: string
) => {
  document.title = `${appName}${!isEmpty(value) ? " | " : ""}${value}`;
  if (!isEmpty(description)) {
    document.getElementsByName("description").forEach((p) => {
      if (toUpper(p.nodeName) === "META") {
        p.setAttribute("content", description ?? "Web app created by DILL");
      }
    });
  }
};

const getUid = (value: string): string => {
  if (typeof value !== "string") {
    return "";
  }
  if (isEmpty(value)) {
    return "";
  }
  let response = "";
  const items = value.split("/");
  if (items.length > 0) {
    response = items[items.length - 1];
  }
  return response;
};

export function cleanHTML(str: string) {
  let response = "";
  if (!isEmpty(str)) {
    str = str.toString();
    response = str.replace(/<[^>]*>/g, "");
  }
  return response;
}
export function getLessonPoster(files: any[]) {
  const index = random(false);
  const images = files.filter((p) => p.typeMime.search("image") != -1);
  let response = `https://picsum.photos/800/600?random=${index}`;
  if (images.length > 0) {
    response = images[0].file;
  }
  return response;
}

export function getExtension(filename: string) {
  let result = "";
  const elem = /[.]/.exec(filename) ? /[^.]+$/.exec(filename) : undefined;
  if (!isEmpty(elem)) {
    result = elem?.[0] ?? "";
  }
  return result;
}

const CenterCategories: any[] = [
  { value: "", title: "Séléctionner le type de l'établissement" },
  {
    value: "cam",
    title: "l'établissement d'Apprentissage des Métiers (CAM)",
  },
  {
    value: "cfp",
    title: "l'établissement de Formation Professionnelle (CFP)",
  },
  { value: "am", title: "L'Académie de Métiers (AM)" },
  {
    value: "ifp",
    title: "L'Institut de Formation Professionnelle (IFP)",
  },
];

const plugCategories: any[] = [
  { value: "", title: "Séléctionner le type fiche" },
  {
    value: "building",
    title: "Identification des bâtiments",
    icon: `fa-solid fa-building`,
    type: "center",
  },
  {
    value: "center",
    title: "Identification des centres",
    icon: `fa-regular fa-house`,
    type: "center",
  },
  {
    value: "establishment",
    title: "Enregistrement des établissements",
    icon: `fa-sharp fa-regular fa-school`,
    type: "center",
  },
  {
    value: "learner",
    title: "Identification des apprenants",
    icon: `fa-solid fa-graduation-cap`,
    type: "learner",
  },
  {
    value: "movement",
    title: "Mouvement des apprenants",
    icon: `fa-solid fa-location-dot`,
    type: "learner",
  },
  {
    value: "register",
    title: "Renseignement des nouveaux inscrits",
    icon: `fa-sharp fa-solid fa-list-dropdown`,
    type: "center",
  },
  {
    value: "administrative",
    title: "Fiche d'identification du personnel administratif",
    icon: `fa-sharp fa-solid fa-file-user`,
    type: "learner",
  },
];

const centerLanguages: any[] = [
  {
    value: "Lingala",
    title: "Lingala",
  },
  {
    value: "Tshiluba",
    title: "Tshiluba",
  },
  { value: "Kikongo", title: "Kikongo" },
  {
    value: "Swahili",
    title: "Swahili",
  },
  { value: "Français", title: "Français" },
  { value: "Autres", title: "Autres" },
];

function getStatus(value?: any) {
  let result: { title: string; color: string } = {
    color: "blue",
    title: "Session ouverte",
  };
  switch (value) {
    case "closed":
      result = {
        color: "brown",
        title: "Session fermée",
      };
      break;
    case "opened":
      result = {
        color: "blue",
        title: "Session ouverte",
      };
      break;
    case "drafted":
      result = {
        color: "gold",
        title: "Session en brouillon",
      };
      break;
    case "published":
      result = {
        color: "gold",
        title: "Session publiée",
      };
      break;
  }
  return result;
}

function addMeta(
  items: any[],
  metaKey: string,
  value: string,
  type: string,
  form2?: boolean,
  nullable?: boolean
) {
  if (nullable) {
    if (form2) {
      items.push({
        metaKey: metaKey,
        metaType: type,
        metaValue: value,
      });
    } else {
      /*items.push({
        metaKey: metaKey,
        type,
        value,
      });*/
      items.push({
        metaKey: metaKey,
        metaType: type,
        metaValue: value,
      });
    }
    return items;
  }
  if (!isEmpty(metaKey) && !isEmpty(type) && !isEmpty(value)) {
    if (form2) {
      items.push({
        metaKey: metaKey,
        metaType: type,
        metaValue: value,
      });
    } else {
      /*items.push({
        metaKey: metaKey,
        type,
        value,
      });*/
      items.push({
        metaKey: metaKey,
        metaType: type,
        metaValue: value,
      });
    }
  }
  return items;
}

const fermedResponses: any[] = [
  { value: "yes", title: "Oui" },
  { value: "no", title: "Non" },
];

const centerRegimes: any[] = [
  { value: "", title: "Séléctionner le regime de gestion" },
  { value: "No conventionné", title: "Non  conventionné" },
  { value: "Catholique", title: "Catholique" },
  { value: "Protestant", title: "Protestant" },
  { value: "Kimbaguiste", title: "Kimbanguiste" },
  { value: "Salutiste", title: "Salutiste" },
  { value: "Fraternité", title: "Fraternité" },
  { value: "Privé", title: "Privé" },
  { value: "Islamique", title: "Islamique" },
  { value: "Publique", title: "Publique" },
  { value: "Autres", title: "Autres" },
];

export function getNumber(value: any) {
  return !isNaN(toNumber(value)) ? toNumber(value) : 0;
}

export function getExamStatus(status: string, date: string) {
  let result = ``;
  const after = dateHelpers.isAfter(date, new Date());
  switch (status) {
    case "pending":
      if (after) {
        result = `En attente`;
      } else {
        result = `Effectué`;
      }
      break;
    case "published":
      if (after) {
        result = `Publié`;
      } else {
        result = `Publié`;
      }
      break;
    case "canceled":
      if (after) {
        result = `Annulé`;
      } else {
        result = `Annulé`;
      }
      break;

    default:
      break;
  }
  return result;
}

const initAdministrativeSheets: any[] = [
  { key: "0", ministry: "Direction Générale", professional: "" },
  { key: "1", ministry: "Direction", professional: "" },
  { key: "2", ministry: "Division", professional: "" },
  { key: "3", ministry: "Bureau", professional: "" },
  { key: "4", ministry: "Nom", professional: "" },
  { key: "5", ministry: "Post nom", professional: "" },
  { key: "6", ministry: "Prénom", professional: "" },
  { key: "7", ministry: "Sexe", professional: "" },
  { key: "8", ministry: "Etat Civile", professional: "" },
  { key: "9", ministry: "Nombre D’enfant", professional: "" },
  { key: "10", ministry: "Date De Naissance", professional: "" },
  { key: "11", ministry: "Matricule", professional: "" },
  { key: "12", ministry: "Grade Statuaire", professional: "" },
  { key: "13", ministry: "Grade De Fonction", professional: "" },
  { key: "14", ministry: "Date D’engagement", professional: "" },
  { key: "15", ministry: "Référence de l’acte d’engagement", professional: "" },
  {
    key: "16",
    ministry: "Référence de l’acte de nomination",
    professional: "",
  },
  {
    key: "17",
    ministry: "Référence de l’acte de commissionnement",
    professional: "",
  },
  { key: "18", ministry: "Niveau d’étude", professional: "" },
  { key: "19", ministry: "Filière", professional: "" },
  { key: "20", ministry: "Position administrative", professional: "" },
  { key: "21", ministry: "Niveau d’affectation", professional: "" },
  { key: "22", ministry: "Province de travail", professional: "" },
  { key: "23", ministry: "Lieu d’affectation de travail", professional: "" },
  { key: "24", ministry: "Salaire perçu", professional: "" },
  { key: "25", ministry: "Prime perçu", professional: "" },
  { key: "26", ministry: "Statut de recensement", professional: "" },
  { key: "27", ministry: "Numéro de téléphone", professional: "" },
  { key: "28", ministry: "Adresse e-mail", professional: "" },
  { key: "29", ministry: "Adresse du domicile", professional: "" },
  { key: "30", ministry: "Personne vivant avec handicap", professional: "" },
  { key: "31", ministry: "Nature de l’handicap", professional: "" },
  { key: "32", ministry: "Observations", professional: "" },
  { key: "33", ministry: "Référence autres documents", professional: "" },
];

export {
  appName,
  getUid,
  refKey,
  setAppTitle,
  mapKey,
  CenterCategories,
  getStatus,
  addMeta,
  fermedResponses,
  centerLanguages,
  centerRegimes,
  plugCategories,
  initAdministrativeSheets,
};
