import { isEmpty } from "lodash";
import React, { Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { routeInterface, routes } from "../routes";
import { SplashScreen } from "./splash";
import { FallbackScreen } from "./fallback";
import { BlockScreen } from "./block";
import { NotFoundScreen } from "./404";
import { Navbar } from "../components/navbar";
import userHelper from "../utils/helpers/userHelper";
import { FooterLayout } from "./footer";
import { setAppTitle } from "../utils/helpers/title";
import { RootState } from "../redux/store";
import { FloatButton } from "antd";
import { setFloatUrl } from "../redux/userReducer";
import { ProSidebarProvider } from "react-pro-sidebar";
import { SiderbarMail } from "../components/sidebarMail";

export function MailLayout() {
  const [items, setItems] = React.useState<routeInterface[]>(routes);
  const dispatch = useDispatch();
  const route = useLocation();
  const [displayLocation, setDisplayLocation] = React.useState(route);
  const [transitionStage, setTransistionStage] = React.useState("fadeIn");
  const user = useSelector((state: RootState) => state.user.profil);
  const url = useSelector((state: RootState) => state.user.floatUrl);
  const navigation = useNavigate();

  React.useEffect(() => {
    userHelper.initData(dispatch);
  }, []);

  React.useEffect(() => {
    init();
  }, [user]);

  async function init() {
    if (isEmpty(user)) {
      const element = await userHelper.getUserProfil();
      if (!isEmpty(element)) {
        await userHelper.setCurrentUser(element, dispatch);
      }
      if (isEmpty(element)) {
        window.location.href = "/";
      }
    }
  }

  React.useEffect(() => {
    if (route !== displayLocation) setTransistionStage("fadeOut");
    const index = routes.findIndex(
      (p) =>
        `${p.layout}${p.path}` === route.pathname ||
        `${p.layout}${p.path}/` === route.pathname
    );
    if (index != -1) {
      setAppTitle(routes[index]?.label);
    }
    dispatch(setFloatUrl(""));
  }, [route, displayLocation]);

  function onAnimation() {
    if (transitionStage === "fadeOut") {
      setTransistionStage("fadeIn");
      setDisplayLocation(route);
    }
  }

  function handleClick() {
    navigation("/mail/create");
  }

  return (
    <React.Fragment>
      <ProSidebarProvider>
        <BlockScreen />
        <Navbar key="nav-admin" showMenu isMail />
        <SiderbarMail current="" />
        <Suspense fallback={<FallbackScreen />}>
          <main
            className={`main yc_container_main ${transitionStage}`}
            onAnimationEnd={onAnimation}
          >
            <div className="flex--1">
              <Routes location={displayLocation}>
                {items
                  .filter((p) => p.layout === "/mail")
                  .map((p, index) => {
                    return (
                      <Route
                        path={`${p.path}`}
                        element={<p.component />}
                        key={`${p.name}${index}`}
                      />
                    );
                  })}
                <Route
                  path={`*`}
                  element={<NotFoundScreen />}
                  key={`notfound`}
                />
              </Routes>
            </div>
          </main>
          <FloatButton
            type="primary"
            icon={<i className="fa-solid fa-pen-to-square"></i>}
            style={{ position: "fixed", bottom: 20 }}
            onClick={handleClick}
            tooltip="Nouveau message"
          />
        </Suspense>
        <FooterLayout />
        {/*</Navbar>*/}
      </ProSidebarProvider>
    </React.Fragment>
  );
}
