import { Tabs } from "antd";
import React from "react";
import { ImminentCalendar } from "./calendar/imminent";
import { AllCalendar } from "./calendar/all";
import { SessionScreen } from "./calendar/session";
import { useNavigate } from "react-router-dom";

export function CalendarScreen() {
  const navigate = useNavigate();

  function handleChange(v: string) {
    //navigate(`#${v}`,{})
  }
  return (
    <React.Fragment>
      <main className="main main yc_container_main">
        <section className="container">
          <Tabs
            onChange={handleChange}
            items={[
              /*{
              label: "Examen imminent",
              key: "imminent",
              children: <ImminentCalendar />,
            },*/
              {
                label: "Tout le calendrier",
                key: "all",
                children: <AllCalendar />,
              },
              {
                label: "Les sessions d'examen",
                key: "session",
                children: <SessionScreen />,
              },
            ]}
          />
        </section>
      </main>
    </React.Fragment>
  );
}
