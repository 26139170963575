import { Table, Tag } from "antd";
import { ColumnsType } from "antd/es/table";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import userHelper from "../../utils/helpers/userHelper";
import { groupBy } from "lodash";

export function LearnerSession() {
  const [columns, setColumns] = React.useState<ColumnsType<any>>([]);
  const exams = useSelector((state: RootState) => state.exam.list);
  const learners = useSelector((state: RootState) => state.learner.list);
  const results = useSelector((state: RootState) => state.result.list);
  const proclaims = useSelector((state: RootState) => state.edition.proclaims);
  const [items, setItems] = React.useState<any>([]);

  React.useEffect(() => {
    onInit();
  }, [results, items]);

  const onInit = React.useCallback(() => {
    const elements: ColumnsType<any> = [];
    elements.push({
      title: "Nom complet",
      align: "left",
      dataIndex: "user",
      render(value, record, index) {
        return userHelper.getUserName(value);
      },
    });
    exams.map((p) => {
      elements.push({
        title: p.title,
        align: "center",
        //dataIndex: "results",
        render(value: any, record, index) {
          let result = results.find(
            (x) => x?.exams?.id == p.id && x?.leaner?.id === value?.id
          );
          const point = result?.leanerNote ?? 0;
          const maxima = result?.exams?.maxima;
          if (point > 0 && maxima > 0) {
            return <Tag>{`${point}/${maxima}`}</Tag>;
          } else {
            return <Tag color="red">{point}</Tag>;
          }
        },
      });
    });
    setColumns(elements);
  }, [results, items]);

  React.useEffect(() => {
    onLearners();
  }, [learners]);

  const onLearners = React.useCallback(() => {
    const list: any[] = [];
    learners.map((p) => {
      const item = results.filter((x) => x.id === p?.leaner?.id);
      const data = { ...p };
      data.results = item;
      list.push(data);
    });
    //const elements:any = groupBy(results,p=>p.leaner?.id);
    setItems(list);
    console.log("ITEMS", list);
  }, [learners]);

  return (
    <React.Fragment>
      <Table dataSource={items} columns={columns} />
    </React.Fragment>
  );
}
