import { ColumnsType } from "antd/es/table";
import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { TitleComponent } from "../../components/title";
import {
  Button,
  message,
  Modal,
  notification,
  Skeleton,
  Table,
  Tag,
} from "antd";
import centerService from "../../services/centerService";
import sectorService from "../../services/sectorService";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import { DillInput, DillSelect } from "../../components/input";
import { RootState } from "../../redux/store";
import { getUid } from "../../utils/helpers/title";
import userHelper from "../../utils/helpers/userHelper";
import { isEmpty, toNumber } from "lodash";
import trainingDurationService from "../../services/trainingDurationService";
import { setCenterSectors } from "../../redux/centerReducer";

export function CenterSectorView() {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(true);
  const [center, setCenter] = React.useState<any>({});
  const [search, setSearch] = React.useState("");
  const [searching, setSearching] = React.useState(false);
  const params = useParams();
  const [visible, setVisible] = React.useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [list, setList] = React.useState<any[]>([]);
  const sectors = useSelector((state: RootState) => state.sector.all);
  const centerSectors = useSelector((state: RootState) => state.center.sectors);
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      sector: "",
      duration: 0,
    },
    validationSchema: yup.object({
      sector: yup.string().required("Champ requis"),
      duration: yup
        .number()
        .required("Champ requis")
        .typeError("Ecrire june valeur numérique")
        .min(1, "Au moins un mois de formaton"),
    }),
    onSubmit: onSave,
  });

  React.useEffect(() => {
    const elements: any[] = [];
    sectors.forEach((p) => {
      elements.push({ value: getUid(p["@id"]), title: p.name });
    });
    elements.unshift({ value: "", title: "Séléctionner une filière" });
    setList(elements);
  }, [sectors]);

  React.useEffect(() => {
    onSearcSector();
  }, [search]);

  async function onSearcSector() {
    if (search?.length > 2) {
      setSearching(true);
      await sectorService
        .getByKey(`name=${search}`)
        .then((response) => {
          const data = sectorService.getData(response);
          const elements: any[] = [];
          data.forEach((p) => {
            elements.push({ value: getUid(p["@id"]), title: p.name });
          });
          elements.unshift({ value: "", title: "Séléctionner une filière" });
          setList(elements);
        })
        .catch(() => {});
      setSearching(false);
    } else {
      const elements: any[] = [];
      sectors.forEach((p) => {
        elements.push({ value: getUid(p["@id"]), title: p.name });
      });
      elements.unshift({ value: "", title: "Séléctionner une filière" });
      setList(elements);
    }
  }

  async function onSave() {
    setConfirmLoading(true);
    const { sector, duration } = formik.values;
    const data: any = {
      sector: `/api/sectors/${sector}`,
      center: `/api/centers/${params.id}`,
      duration: toNumber(duration),
    };
    await trainingDurationService
      .store(data)
      .then(async () => {
        await init();
        setVisible(false);
        formik.resetForm();
        notification.info({
          message: "Enregistrement de filière",
          description: "L'enregistrement a réussi",
        });
      })
      .catch((reason) => {
        const error = trainingDurationService.getError(reason);
        notification.error({ message: "Erreur", description: error });
      });
    setConfirmLoading(false);
  }

  // async function onUpdate(elements: any[], id: string) {
  //   const index = elements.findIndex(
  //     (p) => getUid(p["@id"]) === `${params.id}`
  //   );
  //   if (index === -1) {
  //     elements.push(`/api/centers/${params.id}`);
  //     await sectorService
  //       .update(id, { center: elements })
  //       .then(async () => {
  //         userHelper.getSectors(dispatch);
  //         await init();
  //         setVisible(false);
  //         formik.resetForm({ values: { sector: "" } });
  //         notification.info({
  //           message: "Enregistrement de filière",
  //           description: "L'enregistrement a réussi",
  //         });
  //       })
  //       .catch((reason) => {
  //         const error = sectorService.getError(reason);
  //         notification.error({ message: "Erreur", description: error });
  //       });
  //   } else {
  //     notification.warning({
  //       message: "La filière ciblée est déjà enregistrée dans ce centre",
  //     });
  //   }
  // }

  function handleSave() {
    formik.handleSubmit();
  }

  function handleOpen() {
    setVisible(true);
  }

  function handleHide() {
    if (loading) {
      return;
    }
    setVisible(false);
  }

  const columns: ColumnsType<any> = [
    {
      title: "Nom de la filière",
      dataIndex: "sector",
      render(value, record, index) {
        return value?.name;
      },
    },
    {
      title: "Durée",
      dataIndex: "duration",
      align: "center",
      render(value, record, index) {
        return `${value} mois`;
      },
    },
    {
      title: center?.subtype === "" ? "Apprentis" : "Apprenants",
      render(value, record, index) {
        //return <Tag>{value?.leaners?.length}</Tag>;
        return <Tag>{``}</Tag>;
      },
    },
    {
      title: "Ouvrages",
      render(value, record, index) {
        return <Tag>{value?.books?.length}</Tag>;
      },
    },
  ];

  function handleClick(data: any) {
    //navigate(`/admin/center/${data.id}`);
  }

  async function init() {
    const id = params.id;
    setLoading(true);
    await centerService
      .get(`${id}`)
      .then((response) => {
        setCenter(response.data);
      })
      .catch((reason) => {
        message.error({ content: "Ce centre n'existe pas" });
        //window.history.back();
      });
    await getSectors();
    setLoading(false);
  }

  async function getSectors() {
    const id = params.id;
    await trainingDurationService
      .getByKey(`center=${id}&order[center.name]=asc&order[duration]=asc`)
      .then((response) => {
        const data = trainingDurationService.getData(response);
        dispatch(setCenterSectors(data));
      })
      .catch(() => {});
  }

  React.useEffect(() => {
    init();
  }, [params.id]);

  return (
    <React.Fragment>
      {!loading && (
        <React.Fragment>
          <TitleComponent title={center.name}>
            <Button className="btn--round" onClick={handleOpen}>
              {`Ajouter une filière`}
            </Button>
          </TitleComponent>
          <section className="container">
            <Table
              className="table"
              columns={columns}
              dataSource={centerSectors}
              onRow={(data: any, index?: number) => {
                return {
                  onClick: () => handleClick(data),
                };
              }}
            />
          </section>
        </React.Fragment>
      )}
      {loading && (
        <React.Fragment>
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
        </React.Fragment>
      )}
      <Modal
        title="Enregistrement de filière"
        open={visible}
        onCancel={handleHide}
        onOk={handleSave}
        confirmLoading={confirmLoading}
        centered
        okText="Enregistrer"
        cancelText="Annuler"
        okButtonProps={{ disabled: isEmpty(formik.values.sector) }}
      >
        <div>
          <DillSelect
            list={list}
            label="Le nom de la filière"
            formik={formik}
            name="sector"
            search
            setSearch={setSearch}
            loading={searching}
          />
          <DillInput
            label="Durée de formation en nombre de mois"
            formik={formik}
            name="duration"
            type="num"
          />
        </div>
      </Modal>
    </React.Fragment>
  );
}
