import { Button, Tabs } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { DivisionShowView } from "./show";
import { DivisionSubDivisionView } from "./subdivision";
import { RootState } from "../../redux/store";
import { setFloatUrl } from "../../redux/userReducer";
import { TitleComponent } from "../../components/title";

export function DivisionView() {
  const params = useParams();
  const dispatch = useDispatch();
  const url = useSelector((state: RootState) => state.user.floatUrl);

  React.useEffect(() => {
    dispatch(setFloatUrl(`/admin/division/create?update=${params.id}`));
  }, [params.id, url]);
  return (
    <React.Fragment>
      <main className="main main yc_container_main">
        <TitleComponent title="Division">
          <Button className="btn--round">
            <Link
              to={`/admin/sub_division/create?division=${params?.id ?? ""}`}
            >{`Ajouter une sous-province éducationnelle`}</Link>
          </Button>
        </TitleComponent>
        <section className="container" style={{ marginBottom: "1.6rem" }}>
          <Tabs
            animated
            items={[
              {
                label: "Présentation de la province éducationnelle",
                key: "show",
                children: <DivisionShowView />,
              },
              {
                label:
                  "Les sous-provinces éducationnelles de la province éducationnelle",
                key: "subDivision",
                children: <DivisionSubDivisionView />,
              },
            ]}
          />
        </section>
      </main>
    </React.Fragment>
  );
}
