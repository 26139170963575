import { routeInterface } from "../routes";
import { SubDivisionView } from "../screens/subDivision/view";
import { CreateSubDivisionScreen } from "../screens/subDivision/create";
export const subDivisionRoutes: routeInterface[] = [
  {
    component: SubDivisionView,
    icon: "",
    isMenu: false,
    label: "Sous-division",
    layout: "/admin",
    name: "sub-division",
    path: "/sub_division/:id",
    subscribeable: false,
  },
  {
    component: CreateSubDivisionScreen,
    icon: "fa-duotone fa-location-plus",
    isMenu: false,
    label: "Ajouter une sous-division",
    layout: "/admin",
    name: "create_sub_division",
    path: "/sub_division/create",
    subscribeable: false,
  },
];
