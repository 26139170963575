import { routeInterface } from "../routes";
import { SectorScreen } from "../screens/sectors";
import { SearchScreen } from "../screens/search";
import { MessageScreen } from "../screens/messages";
import { MessageCreationScreen } from "../screens/message/create";
import { BookstoreScreen } from "../screens/bookstore";
import { CreateBookView } from "../screens/book/create";
import { AdminScreen } from "../screens/admins";
import { CreateAdminScreen } from "../screens/admin/create";
import { ProfilScreen } from "../screens/profil";
import { BookView } from "../screens/book/view";
export const connectedRoutes: routeInterface[] = [
  {
    component: SectorScreen,
    icon: "fa-light fa-pipe-section",
    isMenu: true,
    label: "Les filières et métiers",
    layout: "/admin",
    name: "sector_list",
    path: "/sectors",
    subscribeable: false,
  },
  {
    component: SearchScreen,
    icon: `fa-sharp fa-regular fa-magnifying-glass`,
    isMenu: false,
    label: "Moteur de recherche",
    layout: "/admin",
    name: "search",
    path: "/search",
    subscribeable: false,
  },
  {
    component: MessageScreen,
    icon: `fa-solid fa-messages`,
    isMenu: true,
    label: "Messagerie",
    layout: "/admin",
    name: "message",
    path: "/messages",
    subscribeable: false,
  },
  {
    component: MessageCreationScreen,
    icon: `fa-solid fa-message-plus`,
    isMenu: false,
    label: "Ajouter un message",
    layout: "/admin",
    name: "message-create",
    path: "/message/create",
    subscribeable: false,
  },
  {
    component: BookstoreScreen,
    icon: `fa-solid fa-books`,
    isMenu: true,
    label: "Bibliothèques",
    layout: "/admin",
    name: "bookstore",
    path: "/bookstores",
    subscribeable: false,
    priority: 5,
  },
  {
    component: BookView,
    icon: `fa-solid fa-books`,
    isMenu: false,
    label: "Bibliothèques",
    layout: "/admin",
    name: "bookstore-view",
    path: "/bookstore/:id",
    subscribeable: false,
    priority: 5,
  },
  {
    component: CreateBookView,
    icon: `fa-thin fa-books-medical`,
    isMenu: false,
    label: "Ajouter les éléments à la bibliothèque",
    layout: "/admin",
    name: "bookstore-create",
    path: "/bookstore/create",
    subscribeable: false,
  },
  {
    component: AdminScreen,
    icon: `fa-solid fa-user-tie`,
    isMenu: true,
    label: "Tous les responsables",
    layout: "/admin",
    name: "responsible-screen",
    path: "/responsibles",
    subscribeable: false,
  },
  {
    component: CreateAdminScreen,
    icon: `fa-solid fa-user-tie`,
    isMenu: false,
    label: "Ajouter un responsable",
    layout: "/admin",
    name: "responsible-create",
    path: "/responsible/create",
    subscribeable: false,
  },
  {
    component: ProfilScreen,
    icon: `fa-solid fa-user-tie`,
    isMenu: false,
    label: "Profil",
    layout: "/admin",
    name: "profil",
    path: "/profil",
    subscribeable: false,
  },
  {
    component: ProfilScreen,
    icon: `fa-solid fa-user-tie`,
    isMenu: false,
    label: "Profil",
    layout: "/admin",
    name: "profile",
    path: "/profile",
    subscribeable: false,
  },
  {
    component: ProfilScreen,
    icon: `fa-solid fa-user-tie`,
    isMenu: false,
    label: "Responsable",
    layout: "/admin",
    name: "responsible-view",
    path: "/responsible/:id/view",
    subscribeable: false,
  },
];
