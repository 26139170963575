import { Button, Input, List, Tag } from "antd";
import React from "react";
import { TitleComponent } from "../../components/title";
import sectorService from "../../services/sectorService";

export const BookSectorChoices: React.FC<{
  formik: any;
  loading: boolean;
  type?: "division" | "sub-division" | "center" | string;
}> = ({ formik, type, loading }) => {
  const [search, setSearch] = React.useState("");
  const [searching, setSearching] = React.useState(false);
  const [list, setList] = React.useState<any[]>([]);

  React.useEffect(() => {
    onSearch();
  }, [search]);

  async function onSearch() {
    if (search.length < 2) {
      return;
    }
    setSearching(true);
    await sectorService
      .getByKey(`name=${search}`)
      .then((response) => {
        const data = sectorService.getData(response);
        const items = [...formik.values.items];
        const elements = data.filter(
          (p) => items.findIndex((x: any) => x["@id"] === p["@id"]) === -1
        );
        setList(elements);
      })
      .catch(() => {});
    setSearching(false);
  }

  function setItems(list: any[]) {
    formik.setValues({ ...formik.values, items: list });
  }

  function onAdd(item: any) {
    const items = [...formik.values.items];
    let list = [...items];
    const index = items.findIndex((x: any) => x["@id"] === item["@id"]);
    if (index === -1) {
      list.push(item);
      setItems(list);
    }
  }

  function onDelete(item: any) {
    const items = [...formik.values.items];
    const list = items.filter((x: any) => x["@id"] !== item["@id"]);
    setItems(list);
  }

  const renderItem = (item: any) => {
    return (
      <List.Item>
        <Button
          className="btn--choice"
          onClick={() => onAdd(item)}
        >{`${item.name}`}</Button>
      </List.Item>
    );
  };

  const renderItemTag = (item: any) => {
    return (
      <List.Item>
        <Tag color="blue" closable onClose={() => onDelete(item)}>
          {`${item.name}`}
        </Tag>
      </List.Item>
    );
  };
  return (
    <React.Fragment>
      <section className="box fadeIn">
        <div className="card box__search">
          <TitleComponent title={`Choix des filières`} type="creation" />
          <div className="card">
            <div className="fadeIn">
              <div style={{ margin: "0.75rem 0" }}>
                <List
                  dataSource={formik.values.items}
                  renderItem={renderItemTag}
                  grid={{ gutter: 8 }}
                  locale={{ emptyText: "Liste vide" }}
                />
              </div>
              <Input.Search
                value={search}
                loading={searching}
                onChange={(v) => setSearch(v.target.value)}
                onSearch={onSearch}
              />
              <div style={{ margin: "0.75rem 0" }}>
                <List
                  dataSource={list}
                  renderItem={renderItem}
                  grid={{ column: 3, gutter: 8 }}
                  locale={{ emptyText: "Aucun élément" }}
                />
              </div>
            </div>
            <div
              className="row"
              style={{
                alignItems: "center",
                justifyContent: "center",
                padding: "10px 0 0",
              }}
            >
              <Button
                onClick={() => formik.handleSubmit()}
                type="primary"
                disabled={!(formik.values?.items?.length > 0) || loading}
                loading={loading}
              >{`Sauvgarder`}</Button>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};
