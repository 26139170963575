import React from "react";
import {
  DillCheckbox,
  DillInput,
  DillRadio,
  DillSelect,
} from "../../../components/input";
import { centerLanguages, fermedResponses } from "../../../utils/helpers/title";
import { Divider, Typography } from "antd";

export const GeneralInfo: React.FC<{ formik: any }> = ({ formik }) => {
  return (
    <React.Fragment>
      <div>
        <Divider style={{ margin: 5 }} />
        <Typography.Title level={2}>
          {`II. INFORMATIONS GENERALES SUR LE CENTRE`}
        </Typography.Title>
        <DillRadio
          formik={formik}
          name="waterPointExist"
          label="Le centre dispose-t-il d'un  point  d'eau : "
          list={fermedResponses}
          className="radio"
          inline
        />
        {formik?.values.waterPointExist === "yes" && (
          <DillSelect
            formik={formik}
            name="waterPoint"
            label="Le type de point d'eau"
            list={[
              { value: "", title: "Choisir" },
              { value: "Robinet", title: "Robinet" },
              { value: "Forages/Puits", title: "Forages/Puits" },
              { value: "Sources", title: "Sources" },
              { value: "Citerne", title: "Citerne" },
              { value: "Gouttière", title: "Gouttière" },
            ]}
          />
        )}
        <DillRadio
          formik={formik}
          name="electricity"
          label="Le centre dispose-t-il de l'électricité: "
          list={fermedResponses}
          className="radio"
          inline
        />

        <DillRadio
          formik={formik}
          name="latrine"
          label="Le centre dispose-t-il des  latrines: "
          list={fermedResponses}
          className="radio"
          inline
        />

        <DillRadio
          formik={formik}
          name="playground"
          inline
          label="Le centre dispose-t-il d'une cour de récréation: "
          list={fermedResponses}
          className="radio"
        />

        <DillRadio
          formik={formik}
          name="sportsField"
          inline
          label="Le centre dispose-t-il d'un terrain de sport: "
          list={fermedResponses}
          className="radio"
        />

        <div className="row">
          <div className="col">
            <DillRadio
              formik={formik}
              name="fence"
              inline
              label="Le centre dispose-t-il d'une  clôture': "
              list={fermedResponses}
              className="radio"
            />
          </div>
          {formik?.values.fence === "yes" && (
            <div className="col">
              <DillSelect
                formik={formik}
                name="fenceNature"
                label="Nature de clôture"
                list={[
                  { value: "", title: "Choisir" },
                  { value: "En dur", title: "En  dur" },
                  { value: "En semi-dur", title: "En semi-dur" },
                  { value: "En haie", title: "En haie" },
                  { value: "Autres", title: "Autres" },
                ]}
              />
            </div>
          )}
        </div>

        <div className="row">
          <div className="col">
            <DillRadio
              formik={formik}
              inline
              name="otherEtsInLocal"
              label="les locaux sont-ils utilisés par un deuxième centre: "
              list={fermedResponses}
              className="radio"
            />
          </div>

          {formik?.values?.otherEtsInLocal === "yes" && (
            <div className="col">
              <DillInput
                formik={formik}
                name="otherEtsInLocalName"
                label="Précisez le nom du deuxième centre"
              />
            </div>
          )}
        </div>

        <DillCheckbox
          formik={formik}
          list={centerLanguages}
          name="languages"
          enabled
          label="Langues utilisées "
        />
      </div>
    </React.Fragment>
  );
};
