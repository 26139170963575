import { Tabs, notification } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import editionService from "../../services/editionService";
import { setEdition } from "../../redux/editionReducer";
import examService from "../../services/examService";
import { setExams } from "../../redux/examReducer";
import learnerService from "../../services/learnerService";
import { ShowSession } from "./show";
import { ExamSession } from "./exam";
import { LearnerSession } from "./learner";
import { setLeaners } from "../../redux/learnerReducer";
import { getUid } from "../../utils/helpers/title";
import { isEmpty } from "lodash";
import examResultService from "../../services/examResultService";
import { setResults } from "../../redux/resultsReducer";
import userHelper from "../../utils/helpers/userHelper";
import { RootState } from "../../redux/store";
import { ProclaimSession } from "./proclaim";

export function SessionView() {
  const params = useParams();
  const dispatch = useDispatch();
  const edition = useSelector((state: RootState) => state.edition.current);
  const [tab, setTab] = React.useState("show");

  async function init() {
    const id = params.id;
    dispatch(setLeaners([]));
    dispatch(setEdition({}));
    dispatch(setExams([]));
    let learners: any[] = [];
    await editionService
      .get(`${id}`)
      .then(async (response) => {
        dispatch(setEdition(response.data));
        const training = response.data?.training ?? {};
        const tr = getUid(training["@id"] ?? "");
        if (!isEmpty(tr)) {
          await learnerService
            .getByKey(`training=${tr}`)
            .then((response) => {
              learners = learnerService.getData(response);
              dispatch(setLeaners(learners));
            })
            .catch(() => {});
        }
      })
      .catch((reason) => {
        const error = editionService.getError(reason);
        notification.error({ message: "Erreur", description: error });
        console.log("Error session", reason, error);
      });
    await examService
      .getByKey(`examsSession=${id}&order[date]=asc`)
      .then(async (response) => {
        const data = examService.getData(response);
        dispatch(setExams(data));
      })
      .catch(() => {});
    await examResultService
      .getByKey(`exams.examsSession=${id}&exams.order[date]=asc`)
      .then(async (response) => {
        const data = examResultService.getData(response);
        dispatch(setResults(data));
      })
      .catch(() => {});
    await userHelper.getEditionProclaim(`${id}`, dispatch);
  }

  React.useEffect(() => {
    init();
  }, [params.id]);

  function openResult() {
    setTab("proclaim");
  }

  return (
    <React.Fragment>
      <main className="main main yc_container_main">
        <section className="container">
          <Tabs
            activeKey={tab}
            onChange={(v) => setTab(v)}
            items={[
              {
                label: "Présentation",
                key: "show",
                children: <ShowSession onOpenResults={openResult} />,
              },
              {
                label: "Les examens",
                key: "exam",
                children: <ExamSession />,
              },
              {
                label: "Résultat",
                key: "learner",
                children: <LearnerSession />,
              },
              {
                label: "Proclamation",
                key: "proclaim",
                children: <ProclaimSession />,
                disabled: edition?.status !== "published",
              },
            ]}
          />
        </section>
      </main>
    </React.Fragment>
  );
}
