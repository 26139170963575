import { Button, Divider, Modal, Transfer, notification } from "antd";
import { useFormik } from "formik";
import React from "react";
import * as yup from "yup";
import { DillInput, DillSelect, DillTextarea } from "../../components/input";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { TransferDirection } from "antd/es/transfer";
import centerService from "../../services/centerService";
import trainingDurationService from "../../services/trainingDurationService";
import { isEmpty, toNumber, toString } from "lodash";
import editionService from "../../services/editionService";
import examService from "../../services/examService";
import userHelper from "../../utils/helpers/userHelper";

export const CreateExam: React.FC<{}> = ({}) => {
  const [visible, setVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const [centers, setCenters] = React.useState<any[]>([]);
  const [trainings, setTrainings] = React.useState<any[]>([]);
  const [editions, setEditions] = React.useState<any[]>([]);
  const [targetKeys, setTargetKeys] = React.useState<any[]>([]);
  const [selectedKeys, setSelectedKeys] = React.useState<any[]>([]);
  const [searchEdition, setSearchEdition] = React.useState("");
  const [searchSector, setSearchSector] = React.useState("");
  const [searchCenter, setSearchCenter] = React.useState("");
  const [searchingEdition, setSearchingEdition] = React.useState(false);
  const [searchingSector, setSearchingSector] = React.useState(false);
  const [searchingCenter, setSearchingCenter] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      title: "",
      sector: "",
      date: "",
      time: "",
      maxima: 0,
      description: "",
      examsSession: "",
      center: "",
      training: "",
    },
    onSubmit: onSave,
    validationSchema: yup.object({
      maxima: yup
        .number()
        .typeError("Ecrivez un nombre valide")
        .required("Champ requis")
        .positive("Ecrivez un nombre positif"),
      title: yup.string().required("Champ requis"),
      description: yup.string().required("Champ requis"),
      training: yup
        .number()
        .typeError("La filière est requise")
        .required("Champ requis"),
      date: yup.string().required("Champ requis"),
      examsSession: yup
        .number()
        .typeError("La session est requise")
        .required("Champ requis"),
    }),
  });

  async function onSave() {
    const {
      center,
      date,
      description,
      examsSession,
      maxima,
      training,
      title,
      sector,
    } = formik.values;
    const data: any = {
      center: `/api/centers/${center}`,
      date,
      maxima,
      title,
      examsSession: `/api/eams_sessions/${examsSession}`,
      status: "pending",
      training: `/api/training_durations/${training}`,
      Sector: `/api/sectors/${sector}`,
      description,
    };
    setLoading(true);
    await examService
      .store(data)
      .then(async () => {
        userHelper.getSessions(dispatch);
        await userHelper.getExams(dispatch);
        notification.success({ message: "Ajout d'un examen", description: "" });
        setVisible(false);
        formik.resetForm();
      })
      .catch((reason) => {
        const error = examService.getError(reason);
        notification.error({
          message: "Ajout d'un examen",
          description: error,
        });
      });
    setLoading(false);
  }

  function handleOpen() {
    setVisible(true);
  }

  function handleHide() {
    if (loading) {
      return;
    }
    setVisible(false);
  }

  const onChange = (
    nextTargetKeys: string[],
    direction: TransferDirection,
    moveKeys: string[]
  ) => {
    setTargetKeys(nextTargetKeys);
  };

  const onSelectChange = (
    sourceSelectedKeys: string[],
    targetSelectedKeys: string[]
  ) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const onScroll = (
    direction: TransferDirection,
    e: React.SyntheticEvent<HTMLUListElement>
  ) => {};

  async function onSearchCenter() {
    let query = ``;
    if (searchCenter.length > 0) {
      query = `name=${searchCenter}&`;
    }
    query += `order[name]=asc`;
    setSearchingCenter(true);
    await centerService
      .getByKey(query)
      .then((response) => {
        const data = centerService.getData(response);
        const elements: any[] = [];
        data.forEach((p) => {
          elements.push({
            value: `${p.id}`,
            title: `${p.name}`,
          });
        });
        elements.unshift({ value: "", title: "Séléctionner un établissement" });
        setCenters(elements);
      })
      .catch(() => {});
    setSearchingCenter(false);
  }

  async function onSearchEdition() {
    let query = ``;
    if (!formik.values.center || !formik.values.training) {
      setEditions([{ value: "", title: "Séléctionner une édition" }]);
      return;
    }
    if (searchEdition.length > 0) {
      query = `edition=${searchEdition}&`;
    }
    //query += `center=${formik.values.center}&`;
    query += `training=${formik.values.training}&`;
    query += `order[edition]=asc`;
    setSearchingEdition(true);
    await editionService
      .getByKey(query)
      .then((response) => {
        const data = editionService.getData(response);
        const elements: any[] = [];
        data.forEach((p) => {
          elements.push({
            value: `${p.id}`,
            title: `${p.edition}`,
          });
        });
        elements.unshift({ value: "", title: "Séléctionner une édition" });
        setEditions(elements);
      })
      .catch(() => {});
    setSearchingEdition(false);
  }

  async function onSearchSector() {
    const { center } = formik.values;
    if (isEmpty(center) || !(toNumber(`${center}`) > 0)) {
      setTrainings([
        { value: "", title: "Veuillez choisir un établissement au préalable" },
      ]);
      return;
    }
    let query = `center=${center}&`;
    if (searchSector.length > 0) {
      query += `name=${searchCenter}&`;
    }
    query += `order[sector.name]=asc`;
    setSearchingSector(true);
    await trainingDurationService
      .getByKey(query)
      .then((response) => {
        const data = trainingDurationService.getData(response);
        const elements: any[] = [];
        data.forEach((p) => {
          elements.push({
            value: `${p.id}`,
            title: `${p.sector?.name} (${p.duration} mois)`,
            sector: p.sector,
          });
        });
        elements.unshift({ value: "", title: "Séléctionner une filière" });
        setTrainings(elements);
      })
      .catch(() => {});
    setSearchingSector(false);
  }

  React.useEffect(() => {
    onSearchCenter();
  }, [searchCenter]);

  React.useEffect(() => {
    onSearchEdition();
  }, [searchEdition, formik.values.center, formik.values.training]);

  React.useEffect(() => {
    onSearchSector();
  }, [searchSector, formik.values.center]);

  React.useEffect(() => {
    const id = formik.values.training;
    const item = trainings.find((p) => p.value == `${id}`);
    if (!isEmpty(item)) {
      formik.setValues({ ...formik.values, sector: item?.sector?.id });
    }
  }, [formik.values.training]);

  function handleSave() {
    formik.handleSubmit();
  }

  async function handleAddEdition(edition: string) {
    setSearchingEdition(true);
    const { center, training } = formik.values;
    if (isEmpty(toString(center)) || isEmpty(toString(training))) {
      notification.error({
        message: "Ajout de session",
        description: "Vous devez Séléctionner l'établissement et la filière",
      });
      return;
    }
    await editionService
      .store({
        edition,
        center: `/api/centers/${center}`,
        training: `/api/training_durations/${training}`,
      })
      .then(async (response) => {
        const id = response.data.id;
        await onSearchEdition();
        formik.setValues({ ...formik.values, examsSession: `${id}` });
      })
      .catch((reason) => {
        const error = editionService.getError(reason);
        notification.error({ message: "Ajout de session", description: error });
      });
    setSearchingEdition(false);
  }

  return (
    <React.Fragment>
      <Button type="primary" onClick={handleOpen} className="btn--round">
        {`Ajouter un examen`}
      </Button>
      <Modal
        open={visible}
        title={`Ajouter un examen`}
        confirmLoading={loading}
        cancelText="Annuler"
        okText="Enregistrer"
        centered
        onCancel={handleHide}
        style={{ margin: "1.6rem 0" }}
        onOk={handleSave}
      >
        <section>
          <DillInput formik={formik} name="title" label="Titre de l'examen" />
          <div className="row">
            <div className="flex--1">
              <DillInput
                formik={formik}
                name="date"
                label="Date et heure de tenu d'examen"
                type="date"
              />
            </div>
            {/* <div className="col">
              <DillInput
                formik={formik}
                name="time"
                label="Heure de tenu d'examen"
                type="time"
              />
            </div> */}
          </div>
          <Divider style={{ margin: 10 }} />
          <DillSelect
            formik={formik}
            label="L'établissement"
            name="center"
            placeholder=""
            list={centers}
            search
            setSearch={setSearchCenter}
            loading={searchingCenter}
          />
          <DillSelect
            formik={formik}
            label="La filière"
            name="training"
            placeholder=""
            list={trainings}
            search
            setSearch={setSearchSector}
            loading={searchingSector}
          />
          <Divider style={{ margin: 10 }} />
          <div className="row" style={{ alignItems: "center" }}>
            <div className="flex--1">
              <DillInput
                formik={formik}
                name="maxima"
                label="Maximum de points"
                type="number"
              />
            </div>
            <div className="col">
              <DillSelect
                formik={formik}
                name="examsSession"
                label="Edition"
                list={editions}
                showInput
                titleBtn="Ajouter une nouvelle Session"
                search
                setSearch={setSearchEdition}
                loading={searchingEdition}
                onClick={handleAddEdition}
              />
            </div>
          </div>
          {/* <Transfer
            titles={["Filières", "Séléctionnés"]}
            dataSource={items}
            targetKeys={targetKeys}
            selectedKeys={selectedKeys}
            onChange={onChange}
            onSelectChange={onSelectChange}
            render={(item) => item.title}
            listStyle={{ width: 250 }}
            style={{ width: "100%" }}
            className="transfer"
            showSearch
            status="warning"
          /> */}
          <Divider style={{ margin: 10 }} />
          <DillTextarea
            formik={formik}
            name="description"
            label="Description"
          />
        </section>
      </Modal>
    </React.Fragment>
  );
};
