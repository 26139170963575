import { ColumnsType } from "antd/es/table";
import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { TitleComponent } from "../../components/title";
import { Button, message, Modal, notification, Skeleton, Table } from "antd";
import userHelper from "../../utils/helpers/userHelper";
import levelService from "../../services/levelService";
import { useFormik } from "formik";
import * as yup from "yup";
import { DillInput } from "../../components/input";
import { toNumber } from "lodash";

export function CenterLevelView() {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(true);
  const [refresh, setRefresh] = React.useState(false);
  const [items, setItems] = React.useState<any[]>([]);
  const [center, setCenter] = React.useState<any>({});
  const params = useParams();
  const [open, setOpen] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      title: "",
      promotion: "1",
    },
    onSubmit,
    validationSchema: yup.object({
      title: yup.string().required("Champ requis"),
      promotion: yup.number().typeError("Une valeur numérique"),
    }),
  });

  async function onSubmit() {
    const { promotion, title } = formik.values;

    const data: any = {
      promotion: toNumber(promotion),
      title,
      center: `/api/centers/${params.id}`,
    };
    setRefresh(true);
    await levelService
      .store(data)
      .then(async () => {
        await init();
        handleClose();
        formik.resetForm();
      })
      .catch((reason) => {
        const error = levelService.getError(reason);
        notification.error({
          message: "Erreur d'enregistrement",
          description: error,
        });
      });
    setRefresh(false);
  }

  const columns: ColumnsType<any> = [
    {
      title: "Titre de la classe",
      dataIndex: "title",
      render(value, record, index) {
        return value;
      },
    },
    { title: "Promotion", dataIndex: "promotion" },
  ];

  function handleClick(data: any) {
    //navigate(`/admin/center/${data.id}`);
  }

  async function init() {
    const id = params.id;
    await levelService
      .getByKey(`center=${id}`)
      .then((response) => {
        const data = levelService.getData(response);
        setItems(data);
      })
      .catch(() => {});
    setLoading(false);
  }

  React.useEffect(() => {
    onInit();
  }, [params.id]);

  const onInit = React.useCallback(() => {
    setLoading(true);
    init();
  }, [params.id]);

  function handleOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  function handleSubmit() {
    formik.handleSubmit();
  }

  return (
    <React.Fragment>
      {!loading && (
        <React.Fragment>
          <TitleComponent title={"Les classes de l'établissement"}>
            <Button className="btn--round" onClick={handleOpen}>
              {`Ajouter une classe`}
            </Button>
          </TitleComponent>
          <section className="container">
            <Table
              className="table"
              columns={columns}
              dataSource={items}
              onRow={(data: any, index?: number) => {
                return {
                  onClick: () => handleClick(data),
                };
              }}
            />
          </section>
          <Modal
            confirmLoading={refresh}
            onOk={handleSubmit}
            okText="Enregistrer"
            cancelText="Annuler"
            centered
            open={open}
            onCancel={handleClose}
            title="Enregistrement de la classe"
          >
            <div>
              <DillInput
                formik={formik}
                name="title"
                label="Titre de la classe"
              />
              <DillInput
                formik={formik}
                name="promotion"
                label="Niveau"
                type="num"
              />
            </div>
          </Modal>
        </React.Fragment>
      )}
      {loading && (
        <React.Fragment>
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
