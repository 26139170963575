import { Divider, Typography } from "antd";
import React from "react";
import { DillTextarea } from "../../../components/input";

export const MovementAutorisation: React.FC<{ formik: any }> = ({ formik }) => {
  return (
    <React.Fragment>
      <Divider style={{ margin: 5 }} />
      <Typography.Title
        level={2}
        style={{ fontSize: 20, margin: 5 }}
      >{`II. AUTORISATION DE MOUVEMENT`}</Typography.Title>
      <Typography.Text>
        {`La Direction de l’école, le parent ou le tuteur entendu, l’apprenant mieux identifiée ci-dessus est
autorisé(e) à changer d’établissement.`}
      </Typography.Text>
      <DillTextarea name="motif" label="Motif" formik={formik} />
    </React.Fragment>
  );
};
