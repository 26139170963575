import React from "react";
import { TitleComponent } from "../../components/title";
import {
  Button,
  Modal,
  Skeleton,
  Table,
  Tag,
  Typography,
  notification,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { capitalize, toUpper } from "lodash";
import sectorService from "../../services/sectorService";
import { useParams } from "react-router-dom";
import { setSector } from "../../redux/sectorReducer";
import { useFormik } from "formik";
import * as yup from "yup";
import userHelper from "../../utils/helpers/userHelper";
import { DillInput } from "../../components/input";
import { ColumnsType } from "antd/es/table";

export function SectorViewScreen() {
  const sector = useSelector((state: RootState) => state.sector.current);
  const params = useParams();
  const [visible, setVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [refresh, setRefresh] = React.useState(false);
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: yup.object({
      name: yup.string().required("Champ requis"),
    }),
    onSubmit: onSave,
  });

  function handleSave() {
    formik.handleSubmit();
  }

  function handleOpen() {
    setVisible(true);
  }

  function handleHide() {
    if (loading) {
      return;
    }
    setVisible(false);
  }

  async function onSave() {
    setRefresh(true);
    const { name } = formik.values;
    const id = params.id;
    await sectorService
      .store({ name, type: "job", parent: `/api/sectors/${id}` })
      .then(async (response) => {
        userHelper.getSectors(dispatch);
        await init();
        setVisible(false);
        formik.resetForm();
        notification.info({
          message: "Enregistrement de métier",
          description: "L'enregistrement a réussi",
        });
      })
      .catch((reason) => {
        const error = sectorService.getError(reason);
        notification.error({ message: "Erreur", description: error });
      });
    setRefresh(false);
  }

  const oninit = React.useCallback(async () => {
    setLoading(true);
    await init();
  }, [params.id]);

  async function init() {
    const id = params.id;
    await sectorService
      .get(`${id}`)
      .then(async (response) => {
        const data: any = response.data;
        console.log("Data", data);
        dispatch(setSector(data));
      })
      .catch((reason) => {});
    setLoading(false);
  }

  React.useEffect(() => {
    oninit();
  }, [params.id]);

  const columns: ColumnsType<any> = [
    {
      title: "N°",
      render(value, record, index) {
        return index + 1;
      },
    },
    { title: "Nom du métier", dataIndex: "name" },
    {
      title: capitalize("établissements"),
      render(value, record, index) {
        return <Tag>{value.trainingDurations?.length}</Tag>;
      },
      /*onCell(data: any, index?: number) {
            return {
              onClick: () => handleClick(data, "center"),
            };
          },*/
    },
    {
      title: "Apprenants",
      render(value, record, index) {
        return <Tag>{value.leaners?.length}</Tag>;
      },
      /*onCell(data: any, index?: number) {
            return {
              onClick: () => handleClick(data, "learner"),
            };
          },*/
    },
    {
      title: "Ouvrages",
      render(value, record, index) {
        return <Tag>{value.bookSectors?.length}</Tag>;
      },
      /*onCell(data: any, index?: number) {
            return {
              onClick: () => handleClick(data, "book"),
            };
          },*/
    },
  ];

  return (
    <React.Fragment>
      <main className="main main yc_container_main">
        {loading && (
          <React.Fragment>
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
          </React.Fragment>
        )}
        {!loading && (
          <React.Fragment>
            <TitleComponent title={toUpper(sector.name)}>
              {sector.type !== "job" && (
                <Button
                  className="btn--round"
                  onClick={handleOpen}
                >{`Ajouter un métier`}</Button>
              )}
            </TitleComponent>
            <section className="container">
              <Typography.Title className="search--title">
                {"Les métiers"}
              </Typography.Title>
              <Table
                className="table"
                columns={columns}
                dataSource={sector?.children}
              />
            </section>
            <Modal
              title="Enregistrement du métier"
              open={visible}
              onCancel={handleHide}
              onOk={handleSave}
              confirmLoading={refresh}
              centered
              okText="Enregistrer"
              cancelText="Annuler"
            >
              <div>
                <DillInput
                  label="Le nom du métier"
                  formik={formik}
                  name="name"
                />
              </div>
            </Modal>
          </React.Fragment>
        )}
      </main>
    </React.Fragment>
  );
}
