import React from "react";
import {
  PDFViewer,
  Document,
  Page,
  View,
  Text,
  Image,
  Font,
  StyleSheet,
} from "@react-pdf/renderer";

export const ItemTabView: React.FC<{
  label: string;
  clearBg?: boolean;
  value: string;
  border?: boolean;
  styles: any;
}> = ({ label, value, border, clearBg, styles }) => {
  return (
    <>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          borderBottom: border ? "1px solid #000" : "none",
        }}
      >
        <View
          style={[
            styles.title1,
            { flex: 1, borderRight: "1px solid #000", borderBottom: "none" },
            clearBg ? { backgroundColor: "#fff" } : {},
          ]}
        >
          <Text style={{ fontSize: 11 }}>{label}</Text>
        </View>
        <View style={{ width: 30 }}>
          <Text style={styles.value1}>{value}</Text>
        </View>
      </View>
    </>
  );
};
