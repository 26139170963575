//import { getWeek, startOfWeek } from "date-fns";
import moment from "moment";
import "moment/locale/fr";
import { isEmpty } from "lodash";

class DateHelper {
  getDate(date: any, format: string) {
    moment.locale("fr");
    if (date === null) return "";
    return moment(date).format(format);
  }

  getAge(date: any) {
    moment.locale("fr");
    const diff = moment().diff(date, "years");
    if (diff > 0) {
      return `${diff} ans`;
    }
    return "";
  }

  getDateOnly(date: any) {
    if (date === null) return null;
    return moment(date);
  }

  isSameDay(date1: any, date2: any) {
    if (
      date1 === null ||
      date1 === undefined ||
      date2 === null ||
      date2 === undefined
    ) {
      return false;
    }
    return moment(date1).isSame(date2, "day");
  }

  isSameMoment(date1: any, date2: any, granularity: moment.unitOfTime.StartOf) {
    if (
      date1 === null ||
      date1 === undefined ||
      date2 === null ||
      date2 === undefined
    ) {
      return false;
    }
    return moment(date1).isSame(date2, granularity);
  }

  /*getWeekDays(date: Date): Date[] {
    const response = [];
    const startDate = startOfWeek(date, { weekStartsOn: 1 });
    for (let index = 0; index < 7; index++) {
      const value = moment(startDate).add("d", index);
      response.push(value.toDate());
    }
    return response;
  }

  getCurrentWeek() {
    return getWeek(new Date());
  }*/

  getDifference(date1: any, date2: any, unitOfTime: moment.unitOfTime.Diff) {
    if (
      date1 === undefined ||
      date2 === undefined ||
      date1 === null ||
      date2 === null
    ) {
      return -1;
    }
    return moment(date1).diff(moment(date2), unitOfTime);
  }

  getDateStatus(date: any, mode: "date" | "time" | "datetime") {
    let result = "";
    if (this.isSameDay(date, new Date())) {
      result = "Aujourd'hui";
      if (mode === "datetime") {
        result += ` ${this.getDate(date, "HH:mm")}`;
      }
    } else {
      const diffHours = this.getDifference(new Date(), date, "hours");
      const diffDay = this.getDifference(new Date(), date, "days");
      if (diffDay === 0 && diffHours > 0) {
        if (diffHours < 24) {
          result = `Il y a ${diffHours} heure${diffHours > 1 ? "s" : ""}`;
        } else {
          result = "Hier";
        }
      } else if (diffDay < 7) {
        result = `Il y a ${diffDay} jour${diffDay > 1 ? "s" : ""}`;
      } else {
        const diffWeek = this.getDifference(new Date(), date, "weeks");
        const diffMonth = this.getDifference(new Date(), date, "months");
        const diffYear = this.getDifference(new Date(), date, "years");
        if (diffYear > 1) {
          result = `Il y a ${diffYear} année${diffYear > 1 ? "s" : ""}`;
        } else if (diffMonth > 6) {
          result = `Il y a ${diffMonth} mois`;
        } else {
          result = `Il y a ${diffWeek} semaine${diffWeek > 1 ? "s" : ""}`;
        }
      }
    }
    return result;
  }

  getMonths(short = false) {
    if (short) {
      return moment.monthsShort();
    }
    return moment.months();
  }

  isAfter(date1: any, date2: any) {
    return moment(date1).isAfter(date2);
  }

  getDays(short = false) {
    if (short) {
      return moment.weekdaysShort();
    }
    return moment.weekdays();
  }

  getDayInMonth(year: number, month: number) {
    return moment(`${year}-${month}`).daysInMonth();
  }

  /*isDayOfWeek(value){
    if(value>7){
        return false;
    }
    moment().day('')
}*/

  /*sortFolderTypeDatetime(a, b) {
    const dateA = new Date(a.typeDatetime).getTime();
    const dateB = new Date(b.typeDatetime).getTime();
    return dateA - dateB;
}*/
}

export default new DateHelper();
