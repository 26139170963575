import { routeInterface } from "../routes";
import { CreateLearnerScreen } from "../screens/learner/create";
import { LearnerScreen } from "../screens/learner";
import { LearnerView } from "../screens/learner/view";
export const learnerRoutes: routeInterface[] = [
  {
    component: LearnerScreen,
    icon: "fa-duotone fa-screen-users",
    isMenu: true,
    label: "Liste des apprenants",
    layout: "/admin",
    name: "learner",
    path: "",
    subscribeable: false,
    title: "Apprenants",
    priority: 1,
  },
  {
    component: CreateLearnerScreen,
    icon: "fa-solid fa-user-plus",
    isMenu: false,
    label: "Ajouter un apprenant",
    layout: "/admin",
    name: "create_learner",
    path: "/learner/create",
    subscribeable: false,
    priority: 2,
  },
  {
    component: LearnerView,
    icon: `fa-solid fa-books`,
    isMenu: false,
    label: "Voir",
    layout: "/admin",
    name: "learner-view",
    path: "/leaner/:id",
    subscribeable: false,
  },
];
