const centerMeta = {
  courses: {
    title: "",
    description: "",
    metaKey: "courses",
  },
  copa: {
    title: "",
    description: "",
    metaKey: "copa",
  },
  coges: {
    title: "",
    description: "",
    metaKey: "coges",
  },
  waterPointExist: {
    title: "",
    description: "",
    metaKey: "waterPointExist",
  },
  waterPoint: {
    title: "",
    description: "",
    metaKey: "waterPoint",
  },
  supported: {
    title: "",
    description: "",
    metaKey: "supported",
  },
  organism: {
    title: "",
    description: "",
    metaKey: "organism",
  },
  electricity: {
    title: "",
    description: "",
    metaKey: "electricity",
  },
  latrine: {
    title: "",
    description: "",
    metaKey: "latrine",
  },
  boyLatrineCount: {
    title: "",
    description: "",
    metaKey: "boyLatrineCount",
  },
  girlLatrineCount: {
    title: "",
    description: "",
    metaKey: "girlLatrineCount",
  },
  formerLatrineCount: {
    title: "",
    description: "",
    metaKey: "formerLatrineCount",
  },
  playground: {
    title: "",
    description: "",
    metaKey: "playground",
  },
  sportsField: {
    title: "",
    description: "",
    metaKey: "sportsField",
  },
  fence: {
    title: "",
    description: "",
    metaKey: "fence",
  },
  fenceNature: {
    title: "",
    description: "",
    metaKey: "fenceNature",
  },
  otherEtsInLocal: {
    title: "",
    description: "",
    metaKey: "otherEtsInLocal",
  },
  otherEtsInLocalName: {
    title: "",
    description: "",
    metaKey: "otherEtsInLocalName",
  },
  languages: {
    title: "",
    description: "",
    metaKey: "languages",
  },
  sanctions: {
    title: "",
    description: "",
    metaKey: "sanctions",
  },
  affiliatedWithMinistry: {
    title: "",
    description: "",
    metaKey: "affiliatedWithMinistry",
  },
  ministryName: {
    title: "",
    description: "",
    metaKey: "ministryName",
  },
  diplomaFollowUp: {
    title: "",
    description: "",
    metaKey: "diplomaFollowUp",
  },
  receiveProfessionals: {
    title: "",
    description: "",
    metaKey: "receiveProfessionals",
  },
  cfp: {
    title: "CFP",
    description: "",
    metaKey: "cfp",
  },
  cap: {
    title: "CAP",
    description: "",
    metaKey: "cap",
  },
  modularForms: {
    title: "Le centre organise des formations modulaires",
    description: "",
    metaKey: "modularForms",
  },
  domain: {
    title: "Le centre organise des formations modulaire dans le domaine",
    description: "",
    metaKey: "domain",
  },
  duration: {
    title: "La durée de la formation",
    description: "",
    metaKey: "duration",
  },
  dssCenterSheetClassrooms: {
    title:
      "NOMBRE DE SALLES, EFFECTIFS DES APPRENANTS ET REDOUBLANTS PAR NIVEAU D’ETUDE ET PAR SEXE",
    description: "",
    metaKey: "dssCenterSheetClassrooms",
  },
  dssCenterSheetAdministrativeStaffDistributions: {
    title:
      "REPARTITION DU PERSONNEL ADMINISTRATIF ET OUVRIER PAR QUALIFICATION ET PAR SEXE",
    description: "",
    metaKey: "dssCenterSheetAdministrativeStaffDistributions",
  },
  dssCenterSheetNatureAndGeneralStates: {
    title: "",
    description: "",
    metaKey: "dssCenterSheetNatureAndGeneralStates",
  },
  dssCenterSheetTrainingStaffDistributions: {
    title: "REPARTITION DU PERSONNEL FORMATEUR  PAR QUALIFICATION ET PAR SEXE",
    description: "",
    metaKey: "dssCenterSheetTrainingStaffDistributions",
  },
  dssCenterSheetPremisesStateNumbers: {
    title: "NOMBRE DE LOCAUX   REPARTIS  SELON   LEURS ETATS",
    description: "",
    metaKey: "dssCenterSheetPremisesStateNumbers",
  },
  dssCenterSheetPremisesType: {
    title:
      "NOMBRE DE SALLES, EFFECTIFS DES APPRENANTS ET REDOUBLANTS PAR NIVEAU D’ETUDE ET PAR SEXE",
    description: "",
    metaKey: "dssCenterSheetPremisesType",
  },
  dssCenterSheetEquipmentNumbers: {
    title: "NOMBRE D’EQUIPEMENTS (Matériels) SELON LEUR  ETAT ET LEURS  TYPES",
    description: "",
    metaKey: "dssCenterSheetEquipmentNumbers",
  },
  dssCenterSheetLearnerTrainingManuals: {
    title: "NOMBRE DE MANUELS DE FORMATION MIS A LA DISPOSITION DES APPRENANTS",
    description: "",
    metaKey: "dssCenterSheetLearnerTrainingManuals",
  },
  dssCenterSheetManagementDocuments: {
    title: "NOMBRE DE DOCUMENTS DE GESTION ET GUIDE DE FORMATION",
    description: "",
    metaKey: "dssCenterSheetManagementDocuments",
  },
  dssCenterSheetNatures: {
    title: "",
    description: "",
    metaKey: "dssCenterSheetNatures",
  },
  dssCenterSheetPremisesNatureNumbers: {
    title: "NOMBRE DE LOCAUX  REPARTIS  SELON  LEURS  NATURES ",
    description: "",
    metaKey: "dssCenterSheetPremisesNatureNumbers",
  },
};

export { centerMeta };
