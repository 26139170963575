import { Avatar, Button, Divider, Tooltip, Upload, notification } from "antd";
import { useFormik } from "formik";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { DillInput, DillSelect } from "../../components/input";
import ImgCrop from "antd-img-crop";
import { RootState } from "../../redux/store";
import * as yup from "yup";
import { isEmpty, toNumber, toString } from "lodash";
import learnerService from "../../services/learnerService";
import userHelper from "../../utils/helpers/userHelper";
import dateHelpers from "../../utils/helpers/dateHelpers";
import { TitleComponent } from "../../components/title";
import { getUid } from "../../utils/helpers/title";
import centerService from "../../services/centerService";
import sectorService from "../../services/sectorService";
import userService from "../../services/userService";
import trainingDurationService from "../../services/trainingDurationService";
import { useLocation, useNavigate, useNavigation } from "react-router-dom";
import { colorPrimary } from "../../utils/themes/colors";
import { ItemView } from "../../components/pdf/center/itemView";
import { ElementCard } from "../../components/element";
import levelService from "../../services/levelService";

export function CreateLearnerScreen() {
  const centerList = useSelector((state: RootState) => state.center.list);
  const [centers, setCenters] = React.useState<any[]>([]);
  const [division, setDivision] = React.useState<any>({});
  const [subDivision, setSubDivision] = React.useState<any>({});
  const [loadScreen, setLoadScreen] = React.useState(true);
  const [updateId, setUpdateId] = React.useState("");
  const route = useLocation();
  const [file, setFile] = React.useState<UploadFile>();
  const [fileList, setFileList] = React.useState<UploadFile[]>([]);
  const [sectors, setSectors] = React.useState<any[]>([
    { title: "Séléctionner une filière", value: "" },
  ]);
  const [levels, setLevels] = React.useState<any[]>([
    { title: "Séléctionner une classe", value: "" },
  ]);
  const [loading, setLoading] = React.useState(false);
  const [searching, setSearching] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      postName: "",
      center: "",
      phoneNumber: "",
      email: "",
      bornDate: "",
      bornPlace: "",
      sex: "",
      sector: "",
      training: "",
      level: "",
    },
    onSubmit: onSave,
    validationSchema: yup.object({
      firstName: yup.string().required("Champ requis"),
      lastName: yup.string().required("Champ requis"),
      center: yup.string().required("Champ requis"),
      training: yup.string().required("Champ requis"),
      bornDate: yup.string().required("Champ requis"),
      email: yup.string().required("Champ requis").email("E-mail invalide"),
      phoneNumber: yup.string(),
    }),
  });

  React.useEffect(() => {
    const list: any[] = [];
    centerList.forEach((p) => {
      list.push({ value: `${p.id ?? getUid(p["@id"])}`, title: p.name });
    });
    list.unshift({
      value: "",
      title: "Séléctionner l'établissement",
    });
    setCenters(list);
  }, [centerList]);

  React.useEffect(() => {
    const args = new URLSearchParams(route.search);
    const update = args.get("update");
    if (!isEmpty(update)) {
      setUpdateId(`${update}`);
    }
  }, [route]);

  React.useEffect(() => {
    getCurrentLearner();
  }, [updateId]);

  React.useEffect(() => {
    const uid = formik.values.center;
    setDivision({});
    setSubDivision({});
    if (isEmpty(uid)) {
      return;
    }
    centerService
      .get(uid)
      .then((response) => {
        const data = response.data;
        setSubDivision(data?.subDivision ?? {});
        setDivision(data?.subDivision?.division ?? {});
      })
      .catch(() => {});
  }, [formik.values.center]);

  async function getCurrentLearner() {
    if (isEmpty(updateId)) {
      setLoadScreen(false);
      return;
    }
    setLoadScreen(true);
    await learnerService
      .get(updateId)
      .then((response) => {
        const data = response.data;
        const {
          name,
          lastname,
          postname,
          bornDate,
          bornPlace,
          sex,
          email,
          phoneNumber,
        }: any = data?.user as any;
        formik.setValues({
          ...formik.values,
          bornDate: bornDate ? dateHelpers.getDate(bornDate, "YYYY-MM-DD") : "",
          bornPlace,
          sex,
          firstName: name,
          lastName: lastname,
          email,
          postName: postname,
          phoneNumber,
          training: `${data.training?.id}`,
          sector: `${getUid(data.training?.sector["@id"] ?? "")}`,
          center: `${data.training?.center?.id}`,
          level: `${
            !isEmpty(data.level) ? getUid(data?.level["@id"] ?? "") : ""
          }`,
        });
      })
      .catch(() => {});
    setLoadScreen(false);
  }

  async function onSave() {
    const {
      center,
      firstName,
      lastName,
      postName,
      phoneNumber,
      email,
      bornDate,
      bornPlace,
      sex,
      training,
      level,
    } = formik.values;
    let sector = null;
    await trainingDurationService
      .get(training)
      .then((response) => {
        sector = response.data.sector?.id;
      })
      .catch(() => {});
    if (sector == null) {
      //return;
    }
    const formData = new FormData();
    formData.append("name", firstName);
    formData.append("lastname", lastName);
    formData.append("postname", postName);
    formData.append("bornDate", bornDate);
    formData.append("bornPlace", bornPlace);
    formData.append("sex", sex);
    const learner: any = {
      trainingYear: new Date().getFullYear() + "",
      duration: 1,
      startDate: dateHelpers.getDate(new Date(), "YYYY-MM-DD"),
      endDate: dateHelpers.getDate(new Date(), "YYYY-MM-DD"),
      isActive: true,
    };
    if (!isEmpty(center)) {
      learner.center = `/api/centers/${center}`;
    }
    if (!isEmpty(toString(sector))) {
      learner.sector = `/api/sectors/${sector}`;
    }
    if (!isEmpty(training)) {
      learner.training = `/api/training_durations/${training}`;
    }
    if (toNumber(level) > 0 || !isEmpty(level)) {
      learner.level = `/api/levels/${level}`;
    }
    formData.append("leaner", JSON.stringify(learner));
    const data: any = {
      name: firstName,
      lastname: lastName,
      postname: postName,
      bornDate,
      bornPlace,
      sex,
      leaner: learner,
    };
    if (isEmpty(updateId)) {
      data.password = "12345678";
      data.email = email;
      data.phoneNumber = phoneNumber;
      formData.append("password", "12345678");
      formData.append("email", email);
      formData.append("phoneNumber", phoneNumber);
    }
    if (!isEmpty(file) && isEmpty(updateId)) {
      /**@ts-ignore */
      formData.append("photo", file.originFileObj);
    }
    setLoading(true);
    if (isEmpty(updateId)) {
      onCreate(formData);
    } else {
      if (!isEmpty(file)) {
        const formDataUpdate = new FormData();
        /**@ts-ignore */
        formDataUpdate.append("photo", file.originFileObj);
        formDataUpdate.append("user", updateId);
        await userService
          .updatePhoto(formDataUpdate)
          .then(() => {})
          .catch(() => {});
      }
      onUpdate(formData);
    }
  }

  React.useEffect(() => {
    formik.setValues({ ...formik.values, sector: "" });
    setSectors([{ value: "", title: "Séléctionner une filière" }]);
    getSectors();
    getLevels();
  }, [formik.values.center]);

  async function getSectors() {
    const { center } = formik.values;
    const elemsEmpty: any[] = [];
    elemsEmpty.push({ value: "", title: "Séléctionner une filière" });
    setSectors(elemsEmpty);
    await trainingDurationService
      .getByKey(`center=${center}&order[sector.name]=asc&order[duration]=asc`)
      .then((response) => {
        const data = trainingDurationService.getData(response);
        const elems: any[] = [];
        data.forEach((p) => {
          elems.push({
            value: getUid(p["@id"]),
            title: `${p.sector?.name} - ${p.duration} mois`,
          });
        });
        elems.unshift({ value: "", title: "Séléctionner une filière" });
        setSectors(elems);
      });
  }

  async function getLevels() {
    const { center } = formik.values;
    const elemsEmpty: any[] = [];
    elemsEmpty.push({ value: "", title: "Séléctionner une classe" });
    setLevels(elemsEmpty);
    if (!(!isEmpty(center) || toNumber(center) > 0)) {
      return;
    }
    await levelService
      .getByKey(`center=${center}&order[promotion]=asc`)
      .then((response) => {
        const data = levelService.getData(response);
        const elems: any[] = [];
        data.forEach((p) => {
          elems.push({
            value: getUid(p["@id"]),
            title: `${p?.title}`,
          });
        });
        elems.unshift({ value: "", title: "Séléctionner une classe" });
        setLevels(elems);
      });
  }

  async function getSectorsByQuery(query: string) {
    await sectorService
      .getByKey(query)
      .then((response) => {
        const data = sectorService.getData(response);
        const elems: any[] = [];
        data.forEach((p) => {
          elems.push({ value: getUid(p["@id"]), title: p.name });
        });
        elems.unshift({ value: "", title: "Séléctionner une filière" });
        setSectors(elems);
      })
      .catch(() => {});
  }

  async function onSearchCenter() {
    if (search.length < 3) {
      return;
    }
    setSearching(true);
    await centerService
      .getByKey(`name=${search}`)
      .then((response) => {
        const data = centerService.getData(response);
        const elems: any[] = [];
        data.forEach((p) => {
          elems.push({ value: `${getUid(p["@id"])}`, title: p.name });
        });
        setCenters(elems);
      })
      .catch(() => {});
    setSearching(false);
  }

  React.useEffect(() => {
    onSearchCenter();
  }, [search]);

  async function onCreate(data: any) {
    await userService
      .store(data)
      .then(async (response) => {
        await userHelper.getLearners(dispatch);
        notification.info({
          message: "Enregistrement de l'apprenant réussi",
        });
        formik.resetForm();
      })
      .catch((reason) => {
        const error = userService.getError(reason);
        notification.error({
          message: "Enregistrement de l'apprenant",
          description: error,
        });
      });
    setLoading(false);
  }

  async function onUpdate(data: any) {
    await userService
      .update(updateId, data)
      .then(async (response) => {
        await userHelper.getLearners(dispatch);
        notification.info({
          message: "Modification de l'apprenant réussi",
        });
        navigate(`/admin/leaner/${updateId}`);
      })
      .catch((reason) => {
        const error = userService.getError(reason);
        notification.error({
          message: "Modification de l'apprenant",
          description: error,
        });
      });
    setLoading(false);
  }

  const onChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    if (newFileList.length > 0) {
      setFile(newFileList[0]);
    }
  };

  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as RcFile);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  return (
    <React.Fragment>
      <main className="main main yc_container_main">
        <section className="box">
          <div className="card box__file">
            <TitleComponent
              title="Inscription de l'apprenant"
              type="creation"
            />
            <div className="card">
              <div className="row">
                <div className="col">
                  <DillInput
                    formik={formik}
                    name="lastName"
                    label={"Nom"}
                    placeholder={""}
                  />
                  <DillInput
                    formik={formik}
                    name="PostName"
                    label={"Post-nom"}
                    placeholder={""}
                  />
                  <DillInput
                    formik={formik}
                    name="firstName"
                    label={"Prénom"}
                    placeholder={""}
                  />
                  <DillSelect
                    formik={formik}
                    name="sex"
                    list={[
                      { value: "", title: "Choir le genre" },
                      { value: "f", title: "Femme" },
                      { value: "m", title: "Homme" },
                    ]}
                    label="Genre"
                  />
                  <Divider style={{ margin: "0.8rem 0" }} />
                  <DillInput
                    formik={formik}
                    name="phoneNumber"
                    label={"Téléphone"}
                    placeholder={""}
                    type="tel"
                    enabled={isEmpty(updateId)}
                  />
                  <DillInput
                    formik={formik}
                    name="email"
                    label={"E-mail"}
                    placeholder={""}
                    type="email"
                    enabled={isEmpty(updateId)}
                  />
                  <Divider style={{ margin: "0.8rem 0" }} />
                  <DillInput
                    formik={formik}
                    name="bornDate"
                    label={"Date de naissance"}
                    placeholder={""}
                    type="date"
                  />
                  <DillInput
                    formik={formik}
                    name="bornPlace"
                    label={"Lieu de naissance"}
                    placeholder={""}
                    type="text"
                  />
                  <Divider style={{ margin: "0.8rem 0" }} />
                  <DillSelect
                    formik={formik}
                    name="center"
                    list={centers}
                    label="Centre de formation"
                    search
                    setSearch={setSearch}
                    loading={searching}
                  />
                  <DillSelect
                    formik={formik}
                    name="training"
                    list={sectors}
                    label="Filière"
                  />
                  <DillSelect
                    formik={formik}
                    name="level"
                    list={levels}
                    label="Classe"
                  />
                </div>
                <div
                  className="col"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    paddingTop: 60,
                  }}
                >
                  <Tooltip title="Cliquez pour charger une photo de profil">
                    <ImgCrop
                      rotationSlider
                      modalTitle="Dénifir les dimensions"
                      maxZoom={100}
                      minZoom={0}
                      zoomSlider
                      modalCancel="Annuler"
                      modalOk="Valider"
                      aspectSlider
                      aspect={1}
                      showGrid
                      cropperProps={{
                        cropSize: { height: 300, width: 300 },
                        mediaProps: {},
                        restrictPosition: false,
                        style: {},
                        zoomSpeed: 1,
                      }}
                      showReset
                      resetText="Rétablir"
                      cropShape="rect"
                    >
                      <Upload
                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                        listType="picture-card"
                        fileList={fileList}
                        onChange={onChange}
                        onPreview={onPreview}
                        className="avatar--upload"
                      >
                        {fileList.length < 1 && (
                          <i className="fa-solid fa-user"></i>
                        )}
                      </Upload>
                    </ImgCrop>
                  </Tooltip>
                  <div>
                    <ElementCard
                      type="primary"
                      subtitle="Division"
                      title={
                        division.name ?? "------------------------------------"
                      }
                      icon=""
                    />
                    <ElementCard
                      type="gold"
                      subtitle="Sous-division"
                      title={
                        subDivision.name ??
                        "------------------------------------"
                      }
                      icon=""
                    />
                  </div>
                </div>
              </div>
              <div
                className="row"
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "10px 0 0",
                }}
              >
                <Button
                  disabled={loading}
                  loading={loading}
                  onClick={() => {
                    formik.handleSubmit();
                  }}
                  type="primary"
                >
                  {isEmpty(updateId) ? `Enregistrer` : `Modifier`}
                </Button>
              </div>
            </div>
          </div>
        </section>
      </main>
    </React.Fragment>
  );
}
