import axios from "axios";
import config from "./config";

export default axios.create({
  baseURL: config.URL_API,
  headers: {
    "Content-Type": "application/json",
  },
});

export const axiosWeb = axios.create({
  baseURL: config.URL_WEB,
  headers: {
    "Content-Type": "application/json",
  },
});
