import {
  Button,
  Checkbox,
  Collapse,
  notification,
  Radio,
  Typography,
} from "antd";
import { useFormik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DillInput,
  DillPassword,
  DillSelect,
  DillTextarea,
} from "../../components/input";
import { RootState } from "../../redux/store";
import * as yup from "yup";
import { isEmpty } from "lodash";
import learnerService from "../../services/learnerService";
import userHelper from "../../utils/helpers/userHelper";
import dateHelpers from "../../utils/helpers/dateHelpers";
import { TitleComponent } from "../../components/title";

export const MessageCreateDestinator: React.FC<{ formik: any }> = ({
  formik,
}) => {
  function handleChange(v: any) {
    formik.setFieldValue("type", v.target.value);
    formik.setFieldValue("all", "");
  }

  function handleChangeAll(v: any) {
    formik.setFieldValue("all", v);
  }

  function handleChangePerson(v: any) {
    formik.setFieldValue("person", v);
  }
  return (
    <React.Fragment>
      <section className="box fadeIn">
        <div className="card box__login">
          <TitleComponent title="Destination" type="creation" />
          <div className="card">
            <Collapse
              activeKey={[
                "zone",
                "person",
                "division",
                "sub-division",
                "center",
              ]}
            >
              <Collapse.Panel
                showArrow={false}
                header="Zone à deservir"
                key={"zone"}
              >
                <Radio.Group
                  value={formik.values.type}
                  options={[
                    {
                      label: "les provinces éducationnelles",
                      value: "division",
                    },
                    {
                      label: "Les sous-provinces éducationnelles",
                      value: "sub-division",
                    },
                    { label: "Les établissements", value: "center" },
                  ]}
                  onChange={handleChange}
                />
              </Collapse.Panel>
              <Collapse.Panel
                showArrow={false}
                header="Type de destinataire"
                key={"person"}
              >
                <Checkbox.Group
                  value={formik.values.person}
                  options={[
                    { label: "Apprenants", value: "learner" },
                    { label: "Formateurs", value: "former" },
                    { label: "Administrateurs", value: "admin" },
                  ]}
                  onChange={handleChangePerson}
                />
              </Collapse.Panel>
            </Collapse>
            <div
              className="row"
              style={{
                alignItems: "center",
                justifyContent: "center",
                padding: "10px 0 0",
              }}
            >
              <Button
                onClick={() => formik.handleSubmit()}
                type="primary"
                disabled={
                  formik.values.person.length === 0 ||
                  isEmpty(formik.values.type)
                }
              >{`Suivant`}</Button>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};
