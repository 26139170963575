import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface examData {
  current: any;
  list: any[];
  all: any[];
  calendars: any[];
}

const initialState: examData = {
  current: {},
  all: [],
  list: [],
  calendars: [],
};

export const examSlice = createSlice({
  name: "exam",
  initialState,
  reducers: {
    setExam: (state, action: PayloadAction<any>) => {
      state.current = action.payload;
    },
    setCalendars: (state, action: PayloadAction<any[]>) => {
      state.calendars = action.payload;
    },
    setExams: (state, action: PayloadAction<any>) => {
      state.list = action.payload;
    },
    setAllExams: (state, action: PayloadAction<any[]>) => {
      state.all = action.payload;
    },
  },
});
export const { setAllExams, setCalendars, setExam, setExams } =
  examSlice.actions;
export default examSlice.reducer;
