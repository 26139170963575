import { List, Typography } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import dateHelpers from "../utils/helpers/dateHelpers";

export function MailScreen() {
  const mails = useSelector((state: RootState) => state.mail.all);

  function getDate(value: any) {
    let result = "";
    if (dateHelpers.isSameDay(value, new Date())) {
      result = dateHelpers.getDate(value, "HH:mm") ?? "";
    } else if (dateHelpers.isSameMoment(value, new Date(), "year")) {
      result = dateHelpers.getDate(value, "DD MMMM") ?? "";
    } else {
      result = dateHelpers.getDate(value, "DD MMMM YYYY") ?? "";
    }
    return result;
  }

  const renderItem = (item: any, index: number) => {
    return (
      <List.Item className="mail">
        <div className="row mail--card">
          <div className="col-md-3 name ">
            <Typography.Paragraph ellipsis={{ rows: 1 }}>
              {`Nom de recepteur`}
            </Typography.Paragraph>
          </div>
          <div className="col-md-6 content">
            <Typography.Paragraph ellipsis={{ rows: 1 }}>
              <span className="title">{`Tilte of mail`}</span>
              <span className="text">
                {`Lorem ipsum dolor sit amet, consectetur adipisicing elit. Expedita numquam asperiores temporibus obcaecati dolor modi autem voluptatem eum velit vel ut, tenetur, magnam consectetur quo earum placeat repellat aspernatur nobis?`}
              </span>
            </Typography.Paragraph>
          </div>
          <div className="col-md-3 col-lg-2 date">
            {getDate(item?.createdAt) ?? "13:45'"}
          </div>
        </div>
      </List.Item>
    );
  };
  return (
    <React.Fragment>
      <List
        renderItem={renderItem}
        dataSource={["", "", "", "", "", "", "", "", "", "", ""]}
      />
    </React.Fragment>
  );
}
