import { isEmpty, toNumber } from "lodash";
import proclamationService from "../../services/proclamationService";
import { setProclaims } from "../../redux/editionReducer";

class CenterHelper {
  private center: any = {};
  private metas: any[] = [];
  private name?: string = "";

  constructor(item: any, name?: string) {
    this.center = item;
    if (!isEmpty(name)) {
      this.metas = item[name ?? ""] ?? [];
    } else {
      this.metas = item?.centerMetas ?? [];
    }
    if (!isEmpty(name)) {
      this.name = name;
    }
  }

  getCenter() {
    return this.center;
  }

  getMetaValue(dataIndex: string, multiple?: boolean, center?: any) {
    let result: any = null;
    if (!multiple) {
      let element: any = center?.centerMetas;
      if (!isEmpty(this.name)) {
        element = center[this.name ?? ""] ?? {};
      }
      if (isEmpty(element)) {
        return result;
      }
      let item = (element ?? []).find((p: any) => p.metaKey === dataIndex);
      if (!isEmpty(center)) {
        item = this.metas.find((p) => p.metaKey === dataIndex);
      }
      if (!isEmpty(item)) {
        const type = item.type ?? item.metaType ?? "string";
        switch (type) {
          case "json":
            try {
              result = JSON.parse(item?.value ?? item?.metaValue ?? "");
            } catch (error) {
              //console.log("LL ERROR",error,item?.value ?? item?.metaValue)
            }
            break;
          case "numeric":
            result = toNumber(item?.value ?? item?.metaValue ?? "");
            break;

          default:
            result = item?.value ?? item?.metaValue ?? "";
            break;
        }
      }
    } else {
      result = ``;
      let element: any = center?.centerMetas;
      if (!isEmpty(this.name)) {
        element = center[this.name ?? ""] ?? {};
      }
      if (isEmpty(element)) {
        return result;
      }
      //const items = this.metas.filter((p) => p.metaKey === dataIndex);
      const items = element.filter((p: any) => p.metaKey === dataIndex);
      items.forEach((p: any) => {
        result += `${p?.value ?? p?.metaValue}, `;
      });
    }
    return result;
  }
}

export default CenterHelper;
