import React from "react";
import { EffectifSector } from "../sheet/table";
import { Typography } from "antd";
import { Spreadsheet } from "../../../components/table/items";

export const SectorEtsSheet: React.FC<{ formik: any }> = ({ formik }) => {
  return (
    <React.Fragment>
      <Spreadsheet
        colums={[
          { title: "FILIERES", dataIndex: "sector", width: 200 },
          {
            title: "3 MOIS",
            children: [
              { title: "N/Local", dataIndex: "3_month_local" },
              { title: "GF", dataIndex: "3_month_gf" },
              { title: "F", dataIndex: "3_month_f" },
            ],
          },
          {
            title: "6 MOIS",
            children: [
              { title: "N/Local", dataIndex: "6_month_local" },
              { title: "GF", dataIndex: "6_month_gf" },
              { title: "F", dataIndex: "6_month_f" },
            ],
          },
          {
            title: "12 MOIS",
            children: [
              { title: "N/Local", dataIndex: "12_month_local" },
              { title: "GF", dataIndex: "12_month_gf" },
              { title: "F", dataIndex: "12_month_f" },
            ],
          },
        ]}
        formik={formik}
        name="sectors1"
        initItems={[{ key: "0" }]}
      />
      <Spreadsheet
        colums={[
          { title: "FILIERES", dataIndex: "sector", width: 200 },
          {
            title: "24 MOIS",
            children: [
              { title: "N/Local", dataIndex: "24_month_local" },
              { title: "GF", dataIndex: "24_month_gf" },
              { title: "F", dataIndex: "24_month_f" },
            ],
          },
          {
            title: "1 MOIS",
            children: [
              { title: "N/Local", dataIndex: "1_month_local" },
              { title: "GF", dataIndex: "1_month_gf" },
              { title: "F", dataIndex: "1_month_f" },
            ],
          },
          {
            title: "14 JOURS",
            children: [
              { title: "N/Local", dataIndex: "14_day_local" },
              { title: "GF", dataIndex: "14_day_gf" },
              { title: "F", dataIndex: "14_day_f" },
            ],
          },
        ]}
        formik={formik}
        name="sectors2"
        initItems={[{ key: "0" }]}
      />
      <Typography.Title
        level={2}
        style={{ fontSize: 20, margin: 5, textAlign: "center" }}
      >{`STATISTIQUE DE NOUVEAUX INSCRITS`}</Typography.Title>
      <Spreadsheet
        colums={[
          {
            title: "14 JOURS",
            children: [
              { title: "G", dataIndex: "14_day_g" },
              { title: "F", dataIndex: "14_day_f" },
            ],
          },
          {
            title: "1 MOIS",
            children: [
              { title: "G", dataIndex: "1_month_g" },
              { title: "F", dataIndex: "1_month_f" },
            ],
          },
          {
            title: "3 MOIS",
            children: [
              { title: "G", dataIndex: "3_month_g" },
              { title: "F", dataIndex: "3_month_f" },
            ],
          },
          {
            title: "6 MOIS",
            children: [
              { title: "G", dataIndex: "6_month_g" },
              { title: "F", dataIndex: "6_month_f" },
            ],
          },
          {
            title: "STAGE",
            children: [
              { title: "G", dataIndex: "stage_g" },
              { title: "F", dataIndex: "stage_f" },
            ],
          },
        ]}
        formik={formik}
        name="sectorStats1"
        initItems={[{ key: "0" }]}
        showAddButton={false}
      />
      <Spreadsheet
        colums={[
          {
            title: "12 MOIS",
            children: [
              { title: "G", dataIndex: "12_month_g" },
              { title: "F", dataIndex: "12_month_f" },
            ],
          },
          {
            title: "24 MOIS",
            children: [
              { title: "G", dataIndex: "24_month_g" },
              { title: "F", dataIndex: "24_month_f" },
            ],
          },
          {
            title: "EMPLOYEURS",
            children: [
              { title: "G", dataIndex: "employeurs_g" },
              { title: "F", dataIndex: "employeurs_f" },
            ],
          },
          {
            title: "EMPLOYES",
            children: [
              { title: "G", dataIndex: "employes_g" },
              { title: "F", dataIndex: "employes_f" },
            ],
          },
          {
            title: "STAGE",
            children: [
              { title: "G", dataIndex: "stage_g" },
              { title: "F", dataIndex: "stage_f" },
            ],
          },
        ]}
        formik={formik}
        name="sectorStats2"
        initItems={[{ key: "0" }]}
        showAddButton={false}
      />
    </React.Fragment>
  );
};
