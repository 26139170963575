import { isEmpty } from "lodash";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { routes } from "../routes";
import { Navbar } from "../components/navbar";
import { FooterLayout } from "./footer";
import userHelper from "../utils/helpers/userHelper";

export function HomeLayout() {
  const [loading, setLoading] = React.useState(true);

  async function init() {
    const user = await userHelper.getUserProfil();
    if (!isEmpty(user)) {
      window.location.href = "/admin";
    } else {
      setLoading(false);
    }
  }

  React.useEffect(() => {
    init();
  }, []);
  return <React.Fragment>{!loading && <RenderComponent />}</React.Fragment>;
}

function RenderComponent() {
  return (
    <React.Fragment>
      <Navbar key="nav-home" />
      <main className="main">
        <Routes>
          {routes
            .filter((p) => p.layout === "/home")
            .map((p, index) => {
              return (
                <Route
                  path={`${p.path}`}
                  element={<p.component />}
                  key={`${p.name}${index}`}
                />
              );
            })}
        </Routes>
      </main>
      <FooterLayout />
    </React.Fragment>
  );
}
