import { Button, Upload, UploadProps } from "antd";
import { isEmpty } from "lodash";
import React from "react";
import { DillInput } from "../../components/input";
import { TitleComponent } from "../../components/title";
import { useLocation } from "react-router-dom";

export const CreateFileBook: React.FC<{ formik: any }> = ({ formik }) => {
  const { Dragger } = Upload;
  const route = useLocation();
  const [type, setType] = React.useState("");

  React.useEffect(() => {
    const args = new URLSearchParams(route.search);
    setType(args.get("type") ?? "");
  }, [route]);

  const videoProps: UploadProps = {
    name: "video-file",
    multiple: false,
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    accept: "video/*",
    onRemove(file) {
      formik.setValues({ ...formik.values, video: null });
    },
    onChange(info) {
      const { status, type } = info.file;
      if (type?.includes("video") && status !== "removed") {
        formik.setValues({ ...formik.values, video: info.file });
      }
    },
    onDrop(e) {},
  };

  const pdfProps: UploadProps = {
    name: "pdf-file",
    multiple: false,
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    accept: "application/pdf",
    onRemove(file) {
      formik.setValues({ ...formik.values, pdf: null });
    },
    onChange(info) {
      const { status, type } = info.file;
      if (type === "application/pdf" && status !== "removed") {
        formik.setValues({ ...formik.values, pdf: info.file });
      }
    },
    onDrop(e) {},
  };

  function handleClick() {
    formik.handleSubmit();
  }

  function getTitle() {
    let result = ``;
    switch (type) {
      case "course":
        result = `Titre du cours *`;
        break;
      case "program":
        result = `Titre de programme scolaire *`;
        break;
      case "book":
        result = `Titre du livre *`;
        break;

      default:
        break;
    }
    return result;
  }

  return (
    <React.Fragment>
      <section className="box fadeIn">
        <div className="card box__file">
          <TitleComponent
            title="Téléchargement des fichiers"
            type={
              type === "course"
                ? "creation"
                : type === "program"
                ? "list"
                : "update"
            }
          />
          <div className="card">
            <DillInput name="title" formik={formik} label={getTitle()} />
            {type === "course" && (
              <Dragger {...videoProps}>
                <p className="ant-upload-drag-icon">
                  <i
                    className="fa-solid fa-video"
                    style={{ fontSize: "4.8rem" }}
                  ></i>
                </p>
                <p className="ant-upload-text">
                  Cliquez ou faites glisser la vidéo dans cette zone pour le
                  télécharger
                </p>
                <p className="ant-upload-hint">
                  {`Prise en charge d'un téléchargement unique.`}
                </p>
              </Dragger>
            )}
            {(type === "book" || type === "program") && (
              <div style={{ margin: "1.6rem 0" }}>
                <Dragger {...pdfProps}>
                  <p className="ant-upload-drag-icon">
                    <i
                      className="fa-solid fa-file-pdf"
                      style={{ fontSize: "4.8rem" }}
                    ></i>
                  </p>
                  <p className="ant-upload-text">
                    Cliquez ou faites glisser le document PDF dans cette zone
                    pour le télécharger
                  </p>
                  <p className="ant-upload-hint">
                    {`Prise en charge d'un téléchargement unique.`}
                  </p>
                </Dragger>
              </div>
            )}

            <div
              className="row"
              style={{
                alignItems: "center",
                justifyContent: "center",
                padding: "10px 0 0",
              }}
            >
              <Button
                onClick={handleClick}
                type="primary"
                //loading={loading}
                disabled={
                  isEmpty(formik.values.pdf) && isEmpty(formik.values.video)
                }
              >{`Suivant`}</Button>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};
