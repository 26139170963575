import { Button, Divider, Modal, notification } from "antd";
import { useFormik } from "formik";
import React from "react";
import { DillInput, DillPassword } from "../../components/input";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import userService from "../../services/userService";
import userHelper from "../../utils/helpers/userHelper";
import * as yup from "yup";

export const ProfileUpdatePassword: React.FC<{}> = () => {
  const [visible, setVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const user = useSelector((state: RootState) => state.user.profil);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      password: "",
      confirm: "",
      old: "",
    },
    onSubmit: onSave,
    validationSchema: yup.object({
      password: yup.string().required("Champ requis"),
      old: yup.string().required("Champ requis"),
      confirm: yup
        .string()
        .required("Champ requis")
        .oneOf([yup.ref("password")], "Incohérence de mot de passe."),
    }),
  });

  async function onSave() {
    const { password } = formik.values;
    const data: any = { password };
    setLoading(true);
    await userService
      .update(user.id, data)
      .then((response) => {
        setVisible(false);
        notification.info({ message: "La modification a réussi" });
        userHelper.setLogout(dispatch);
        window.location.href = "/";
      })
      .catch((reason) => {
        const error = userService.getError(reason);
        notification.error({
          message: "Erreur de modification",
          description: error,
        });
      });
    setLoading(false);
  }

  function handleUpdate() {
    formik.handleSubmit();
  }

  function handleShow() {
    setVisible(true);
  }

  function handleClose() {
    if (loading) {
      return;
    }
    setVisible(false);
  }

  return (
    <React.Fragment>
      <Button shape="round" danger onClick={handleShow} type="primary">
        <i className="fa-solid fa-pen-to-square"></i>
        <span>{`Modifier le mot de passe`}</span>
      </Button>
      <Modal
        open={visible}
        centered
        confirmLoading={loading}
        okText="Modifier"
        cancelText="Annuler"
        onOk={handleUpdate}
        onCancel={handleClose}
        title="Modifier la date de naissance"
      >
        <div>
          <DillPassword
            formik={formik}
            name="old"
            label="Ancien mot de passe"
          />
          <Divider />
          <DillPassword formik={formik} name="password" label="Mot de passe" />
          <DillPassword
            formik={formik}
            name="confirm"
            label="Confirmation de mot de passe"
          />
        </div>
      </Modal>
    </React.Fragment>
  );
};
