import { Button, Table } from "antd";
import { ColumnsType, ColumnType } from "antd/es/table";
import React from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { TitleComponent } from "../components/title";
import { RootState } from "../redux/store";

export function DivisionScreen() {
  const divisions = useSelector((state: RootState) => state.division.list);
  const nvigate = useNavigate();

  const columns: ColumnsType<any> = [
    {
      title: "N°",
      render(value, record, index) {
        return index + 1;
      },
    },
    { title: "Nom de la province éducationnelle", dataIndex: "name" },
    { title: "Responsable", dataIndex: "leader" },
    {
      title: "Les sous-provinces éducationnelles",
      dataIndex: "subDivisionCount",
    },
    { title: "Les établissements", dataIndex: "subCenterCount" },
  ];

  function handleClick(data: any) {
    nvigate(`/admin/division/${data.id}`);
  }

  return (
    <React.Fragment>
      <main className="main main yc_container_main">
        <TitleComponent title="Toutes les provinces éducationnelles">
          <Button className="btn--round">
            <Link
              to={`/admin/division/create`}
            >{`Ajouter une province éducationnelle`}</Link>
          </Button>
        </TitleComponent>
        <section className="container">
          <Table
            className="table"
            columns={columns}
            dataSource={divisions}
            onRow={(data: any, index?: number) => {
              return {
                onClick: () => handleClick(data),
              };
            }}
          />
        </section>
      </main>
    </React.Fragment>
  );
}
