import { ColumnsType } from "antd/es/table";
import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { TitleComponent } from "../../components/title";
import { Button, message, Skeleton, Table } from "antd";
import centerService from "../../services/centerService";
import formerService from "../../services/formerService";
import userHelper from "../../utils/helpers/userHelper";

export function CenterFormersView() {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(true);
  const [items, setItems] = React.useState<any[]>([]);
  const [center, setCenter] = React.useState<any>({});
  const params = useParams();

  const columns: ColumnsType<any> = [
    {
      title: "Nom de formateur",
      render(value, record, index) {
        return userHelper.getUserName(value);
      },
    },
    { title: "Qualification", dataIndex: "qualification" },
  ];

  function handleClick(data: any) {
    //navigate(`/admin/center/${data.id}`);
  }

  async function init() {
    const id = params.id;
    setLoading(true);
    await formerService
      .getByKey(`center=${id}`)
      .then((response) => {
        const data = centerService.getData(response);
        setItems(data);
      })
      .catch(() => {});
    setLoading(false);
  }

  React.useEffect(() => {
    init();
  }, [params.id]);

  return (
    <React.Fragment>
      {!loading && (
        <React.Fragment>
          <TitleComponent title={center.name}>
            <Button className="btn--round">
              <Link to={`/admin/former/create?center=${params.id}`}>
                {`Ajouter un formateur`}
              </Link>
            </Button>
          </TitleComponent>
          <section className="container">
            <Table
              className="table"
              columns={columns}
              dataSource={items}
              onRow={(data: any, index?: number) => {
                return {
                  onClick: () => handleClick(data),
                };
              }}
            />
          </section>
        </React.Fragment>
      )}
      {loading && (
        <React.Fragment>
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
