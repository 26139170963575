// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCOQ9GVPvdsfaBzxvZFMpzWdYDDzmNPcH4",
  authDomain: "yekola-mosala.firebaseapp.com",
  databaseURL: "https://yekola-mosala-default-rtdb.firebaseio.com",
  projectId: "yekola-mosala",
  storageBucket: "yekola-mosala.appspot.com",
  messagingSenderId: "383749800574",
  appId: "1:383749800574:web:e2524c633cc94315998e2f",
  measurementId: "G-8BH575H050",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
