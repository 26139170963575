import { Divider, Typography } from "antd";
import React from "react";
import { DillInput, DillSelect, DillTextarea } from "../../../components/input";
import { getProvinces } from "../../../utils/helpers/geoHelper";

export const MovementStuation: React.FC<{ formik: any }> = ({ formik }) => {
  return (
    <React.Fragment>
      <Divider style={{ margin: 5 }} />
      <Typography.Title
        level={2}
        style={{ fontSize: 20, margin: 5, textAlign: "center" }}
      >{`NOUVELLE SITUATION DE L’ELEVE`}</Typography.Title>
      <div className="row">
        <div className="col-md-6">
          <DillSelect
            name="division"
            list={[
              { value: "", title: "Séléctionner la province" },
              ...getProvinces(),
            ]}
            formik={formik}
            label="PROVINCE"
          />
          <DillInput name="network" formik={formik} label="RESEAU" />
          <DillInput name="option" formik={formik} label="OPTION" />
          <DillInput name="level" formik={formik} label="NIVEAU" />
        </div>
        <div className="col-md-6">
          <DillInput name="subDivision" formik={formik} label="S/DIV" />
          <DillInput name="center" formik={formik} label="ETABLISSEMENT" />
          <DillInput name="classe" formik={formik} label="CLASSE" />
        </div>
        <div className="row">
          <div className="col">
            <DillInput name="tutorName" formik={formik} label="TUTEUR" />
          </div>
          <div className="col">
            <DillInput name="tutorName" formik={formik} label="N° TEL." />
          </div>
          <div className="col">
            <DillInput name="tutorWork" formik={formik} label="PROFESSION" />
          </div>
        </div>
        <DillTextarea name="tutorAddress" label="ADRESSE" formik={formik} />
        <DillTextarea
          name="address"
          label="ADRESSE DE L’ELEVE"
          formik={formik}
        />
      </div>
    </React.Fragment>
  );
};
