import { routeInterface } from "../routes";
import { LoginScreen } from "../screens/login";
import { CreateMailScreen } from "../screens/mail/create";
import { MailScreen } from "../screens/mails";
export const mailRoutes: routeInterface[] = [
  {
    component: MailScreen,
    icon: "",
    isMenu: true,
    label: "Boîte de réception",
    layout: "/mail",
    name: "inbox",
    path: "",
    subscribeable: false,
    title: "Boîte de réception",
  },
  {
    component: LoginScreen,
    icon: "",
    isMenu: true,
    label: "Messages envoyés",
    layout: "/mail",
    name: "sender",
    path: "/sender",
    subscribeable: false,
    title: "Messages envoyés",
  },
  {
    component: CreateMailScreen,
    icon: "",
    isMenu: false,
    label: "",
    layout: "/mail",
    name: "write",
    path: "/create",
    subscribeable: false,
    title: "",
  },
  {
    component: CreateMailScreen,
    icon: "",
    isMenu: false,
    label: "",
    layout: "/mail",
    name: "read",
    path: "/read/:id",
    subscribeable: false,
    title: "",
  },
];
