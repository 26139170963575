import { Result, Timeline, TimelineItemProps } from "antd";
import React from "react";
import { ElementCard } from "../../components/element";
import archiveService from "../../services/archiveService ";
import dateHelpers from "../../utils/helpers/dateHelpers";
import { useParams } from "react-router-dom";

export function LearnerCareer() {
  const [times, setTimes] = React.useState<TimelineItemProps[]>([]);
  const params = useParams();
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    init();
  }, []);

  async function init() {
    let archives: any[] = [];
    setLoading(true);
    await archiveService
      .getByKey(`leaner=${params.id}`)
      .then((response) => {
        archives = archiveService.getData(response);
        console.log("Archives", archives);
      })
      .catch(() => {});
    const items: TimelineItemProps[] = [];
    await Promise.all(
      archives.map((p: any) => {
        items.push({
          label: dateHelpers.getDate(p.date, "dddd DD MMM YYYY"),
          children: <ElementCard icon="" subtitle="" small title={p.action} />,
        });
      })
    );
    /*items.push({
      label: "Formation en Coupe et couture",
      children: <ElementCard icon="" subtitle="Don" small title="Coupe et couture" />,
    });
    items.push({
      label: "Formation en Coupe et couture",
      children: (
        <ElementCard
          type="primary"
          stretch
          icon=""
          subtitle="Don"
          title={`lorem ipsum dhjdsjh jsdhj hjsdhj jhdshj aza sjhds hjsd hjsdhjsd hjsdhj hjsd sjjksd jjds jjkds jkkjsd`}
          small
        />
      ),
    });
    items.push({
      label: "Formation en Coupe et couture",
      position: "left",

      children: (
        <ElementCard
          type="primary"
          stretch
          icon=""
          subtitle="Don"
          title="Coupe et couture"
          small
        />
      ),
    });*/
    setTimes(items);
    setLoading(false);
  }
  return (
    <React.Fragment>
      <section>
        {times.length > 0 && !loading && (
          <Timeline mode="alternate" items={times} />
        )}
        {times.length === 0 && !loading && <Result title="Aucun parcours" />}
        {loading && <Timeline mode="alternate" items={times} />}
      </section>
    </React.Fragment>
  );
}
