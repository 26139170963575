import { Button, Divider, notification, Steps, Tag, Typography } from "antd";
import { useFormik } from "formik";
import React from "react";
import * as yup from "yup";
import { MessageCreateContent } from "./createContent";
import { MessageCreateDestinator } from "./createDestinator";
import { MessageChoices } from "./choices";
import { TitleComponent } from "../../components/title";
import messageService from "../../services/messageService";
import { getUid } from "../../utils/helpers/title";

export function MessageCreationScreen() {
  const [step, setStep] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const formik1 = useFormik({
    initialValues: {
      title: "",
      content: "",
      files: [],
    },
    validationSchema: yup.object({
      content: yup.string().required("Champ requis"),
    }),
    onSubmit: onSave1,
    isInitialValid: false,
  });

  const formik2 = useFormik({
    initialValues: {
      type: "",
      person: [],
    },
    validationSchema: yup.object({
      type: yup.string().required("Champ requis"),
      person: yup.array().required("Champ requis"),
    }),
    onSubmit: onSave2,
    isInitialValid: false,
  });

  const formik3 = useFormik({
    initialValues: {
      all: true,
      items: [],
    },
    validationSchema: yup.object({
      all: yup.boolean().required("Champ requis"),
      /**@ts-ignore */
      items: yup.array().when("all", (all: boolean, field: any) => {
        all == false ? field.required("Champ requis") : field;
      }),
    }),
    onSubmit: onSave3,
    isInitialValid: true,
  });

  function onSave1() {
    setStep(step + 1);
  }

  function onSave2() {
    formik3.setValues({ all: true, items: [] });
    setStep(step + 1);
  }

  function onSave3() {
    setStep(step + 1);
  }

  function handleClick(index: number) {
    if (index === 0) {
      setStep(index);
    } else if (index === 1) {
      if (formik2.isValid) {
        setStep(1);
      }
    } else if (index === 2) {
      if (formik1.isValid && formik2.isValid) {
        setStep(2);
      }
    }
  }

  function getTitlePersons() {
    return (
      <React.Fragment>
        {formik2.values.person.map((p, i) => {
          switch (p) {
            case "learner":
              return <Tag color="blue">{` Les apprenants`}</Tag>;
              break;
            case "former":
              return <Tag color="gold-inverse">{` Les formateurs`}</Tag>;
              break;
            case "admin":
              return <Tag color="lime">{` Les administrateurs`}</Tag>;
              break;
          }
        })}
      </React.Fragment>
    );
  }

  function getTitleZone() {
    let result = ``;
    const p = formik2.values.type;
    const isAll = formik3.values.all;
    switch (p) {
      case "division":
        if (isAll) {
          result = "Toutes les provinces éducationnelles";
        } else {
          result = "les provinces éducationnelles";
        }
        break;
      case "center":
        if (isAll) {
          result = "Tous les établissements";
        } else {
          result = "Les établissements";
        }
        break;
      case "sub-division":
        if (isAll) {
          result = "Toutes les sous-provinces éducationnelles";
        } else {
          result = "Les sous-provinces éducationnelles";
        }
        break;
    }
    return result;
  }

  async function sendMessage() {
    setLoading(true);
    const { content, title } = formik1.values; //titre et contenu du message
    const { person, type } = formik2.values; //type de user et type de zone(centre, division, sous-division)
    const { all, items } = formik3.values;
    const list: any[] = []; //liste de zone avec leur id
    const data: any = {
      title,
      content,
      sendTime: "2023-03-28T07:54:21.371Z",
      receiveTime: "2023-03-28T07:54:21.371Z",
      persons: person,
    };
    switch (type) {
      case "center":
        data.sendToCenter = true;
        break;
      case "sub-division":
        data.sendToSubDivision = true;
        break;
      case "division":
        data.sendToDivision = true;
        break;
    }
    if (!all) {
      items.map((p) => {
        list.push(getUid(p["@id"]));
      });
      data.entities = list;
    } else {
      data.entities = [];
    }
    await messageService
      .store(data)
      .then((response) => {
        //init all messages
        formik1.resetForm();
        formik2.resetForm();
        formik3.resetForm();
        setStep(0);
        notification.info({
          message: "Envoi de message...",
          description: "Votre message est envoyé avec succès",
        });
      })
      .catch((reason) => {
        const error = messageService.getError(reason);
        notification.error({ message: "Erreur d'envoie", description: error });
      });
    setLoading(false);
  }

  return (
    <React.Fragment>
      <main className="main main yc_container_main">
        <section className="container">
          <div className="row">
            <div className="col-md-12 col-xs-12 col-lg-3">
              <Steps
                percent={((step + 1) / 4) * 100}
                current={step}
                direction="vertical"
              >
                <Steps.Step
                  title="Contenu de message"
                  stepIndex={0}
                  onClick={() => handleClick(0)}
                />
                <Steps.Step
                  title="Zone"
                  stepIndex={1}
                  onClick={() => handleClick(1)}
                />
                <Steps.Step
                  title="Selection des destinataires"
                  stepIndex={2}
                  onClick={() => handleClick(2)}
                />
                <Steps.Step
                  title="Envoi"
                  stepIndex={3}
                  onClick={() => handleClick(3)}
                />
              </Steps>
            </div>
            <div className="col-md-12 col-xs-12 col-lg-9">
              {step === 0 && <MessageCreateContent formik={formik1} />}
              {step === 1 && <MessageCreateDestinator formik={formik2} />}
              {step === 2 && (
                <MessageChoices formik={formik3} type={formik2.values.type} />
              )}
              {step === 3 && (
                <section className="box fadeIn">
                  <div className="card box__login">
                    <TitleComponent title="Envoi" type="creation" />
                    <div className="card">
                      <div>
                        <Typography.Title style={{ fontSize: 16 }}>
                          {`Cibles du message`}
                        </Typography.Title>
                        <div>{getTitlePersons()}</div>
                      </div>
                      <div>
                        <Typography.Title style={{ fontSize: 16 }}>
                          {`Les zones de destination`}
                        </Typography.Title>
                        <div>
                          <Tag color="teal">{getTitleZone()}</Tag>
                        </div>
                        <Divider style={{ margin: "0.8rem 0" }} />
                        <div
                          style={{
                            margin: "0.8rem 0",
                            height: "10rem",
                            overflow: "auto",
                          }}
                        >
                          {formik3.values.items.map((p: any, i) => (
                            <Tag
                              color="blue"
                              key={`elem_${i}`}
                              style={{ margin: "0.1rem" }}
                            >
                              {`${p.name}`}
                            </Tag>
                          ))}
                        </div>
                        <Divider style={{ margin: "0.8rem 0" }} />
                        <Typography.Text strong>
                          {formik1.values.title}
                        </Typography.Text>
                        <Typography.Paragraph ellipsis={{ rows: 3 }}>
                          {formik1.values.content}
                        </Typography.Paragraph>
                      </div>
                      <div
                        className="row"
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                          padding: "10px 0 0",
                        }}
                      >
                        <Button
                          onClick={sendMessage}
                          type="primary"
                          loading={loading}
                          disabled={loading}
                        >{`Confirmer et envoyer`}</Button>
                      </div>
                    </div>
                  </div>
                </section>
              )}
            </div>
          </div>
        </section>
      </main>
    </React.Fragment>
  );
}
