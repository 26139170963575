import { Button, Modal, Spin, message } from "antd";
import { isEmpty } from "lodash";
import React from "react";
import { useNavigate } from "react-router-dom";
//@ts-ignore
import html2pdf from "../../../node_modules/html2pdf.js/dist/html2pdf.bundle.min.js";

export function CertificateView() {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(true);
  const print = React.useRef(null);

  function init() {
    const value = localStorage.getItem("certificate");
    if (isEmpty(value)) {
      message.error("Vous n'êtes pas autorisé à utiliser cet espace");
      setTimeout(() => {
        window.history.back();
      }, 3000);
      return;
    }
    const item = JSON.parse(value ?? "") ?? null;
    if (isEmpty(item)) {
      message.error("Vous n'êtes pas autorisé à utiliser cet espace 2");
      setTimeout(() => {
        window.history.back();
      }, 3000);
      return;
    }
    setLoading(false);
  }

  const onInit = React.useCallback(() => {
    init();
  }, []);

  React.useEffect(() => {
    onInit();
  }, []);

  function handleDownload() {
    const element = print.current;
    const opt = {
      filename: `min-formation`,
      jsPDF: {
        format: "a5",
      },
    };
    //@ts-ignore
    html2pdf(element).set(opt).save();
    localStorage.removeItem("certificate");
    window.history.back();
  }

  return (
    <main
      className="main main yc_container_main"
      style={{ minHeight: "100vh" }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          padding: "10px",
          alignItems: "end",
          width: "100%",
        }}
      >
        <Button onClick={handleDownload} type="primary">
          <i className="fa-solid fa-download"></i>
          {`Télécharger`}
        </Button>
      </div>
      <div ref={print}>
        <img
          src={require("../../assets/images/certificate.jpg")}
          alt="certificate"
        />
      </div>
      <Modal
        closable={false}
        centered
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
        open={loading}
      >
        <div style={{ textAlign: "center" }}>
          <Spin size="large" />
        </div>
      </Modal>
    </main>
  );
}
