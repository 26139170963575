import { upperFirst, upperCase, isEmpty, toUpper, toLower } from "lodash";
import { setAllAdmins } from "../../redux/adminReducer";
import { setAllBooks } from "../../redux/bookReducer";
import { setCenters, setPlugs } from "../../redux/centerReducer";
import { setDivisions } from "../../redux/divisionReducer";
import { setAllFormers } from "../../redux/formerReducer";
import { setInactivates, setLeaners } from "../../redux/learnerReducer";
import { setAllMessages } from "../../redux/messageReducer";
import { setAllSectors } from "../../redux/sectorReducer";
import { setSubDivisions } from "../../redux/subDivisionReducer";
import { setLoading, setUserProfil } from "../../redux/userReducer";
import adminService from "../../services/adminService";
import bookService from "../../services/bookService";
import centerService from "../../services/centerService";
import divisionService from "../../services/divisionService";
import formerService from "../../services/formerService";
import learnerService from "../../services/learnerService";
import messageService from "../../services/messageService";
import orgService from "../../services/orgService";
import receptionService from "../../services/receptionService";
import sectorService from "../../services/sectorService";
import subDivisionService from "../../services/subDivisionService";
import userService from "../../services/userService";
import { getUid } from "./title";
import examService from "../../services/examService";
import { setAllExams } from "../../redux/examReducer";
import editionService from "../../services/editionService";
import { setAllEditions, setProclaims } from "../../redux/editionReducer";
import centerPlugService from "../../services/centerPlugService";
import learnerPlugService from "../../services/learnerPlugService";
import authService from "../../services/authService";
import proclamationService from "../../services/proclamationService";

class UserHelper {
  private key: string = "@yekola_user";
  private setting_allow_sync_contacts: string =
    "@yekola_setting@allow_sync_contact";
  private setting_contact_sync_date: string =
    "@yekola_setting@contact_sync_date";

  async setCurrentUser(user: any, dispatch?: any) {
    let result = false;
    if (typeof dispatch === "function") {
      let item: any = user;
      await userService
        .get(user?.id)
        .then((response) => {
          item = response.data;
          result = true;
        })
        .catch(() => {});
      dispatch(setUserProfil(item));
      result = true;
    }
    await localStorage.setItem(this.key, JSON.stringify(user));
    return result;
  }

  getUserName(user: any) {
    return `${upperFirst(user?.firstName ?? user?.name)} ${upperCase(
      user?.lastName ?? user?.lastname
    )} ${upperCase(user?.postName ?? user?.postname ?? "")}`;
  }

  setLogout(dispatch?: any) {
    localStorage.removeItem(this.key);
    authService.logout();
    if (typeof dispatch === "function") {
      dispatch(setUserProfil({}));
    }
  }

  async getUserProfil() {
    let item: any = await localStorage.getItem(this.key);
    if (item != null) {
      const data = JSON.parse(item);
      return data;
    }
    return null;
  }

  async setUserSetting(
    type: "setting-contact-allow-sync" | "setting-contact-sync-date",
    value: any
  ) {
    let elementKey = "";
    if (type === "setting-contact-allow-sync") {
      elementKey = this.setting_allow_sync_contacts;
    } else if (type === "setting-contact-sync-date") {
      elementKey = this.setting_contact_sync_date;
    }
    await localStorage.setItem(elementKey, JSON.stringify(value));
  }

  async getUserSetting(
    type: "setting-contact-allow-sync" | "setting-contact-sync-date"
  ) {
    let elementKey = "";
    if (type === "setting-contact-allow-sync") {
      elementKey = this.setting_allow_sync_contacts;
    } else if (type === "setting-contact-sync-date") {
      elementKey = this.setting_contact_sync_date;
    }
    let item: any = await localStorage.getItem(elementKey);
    if (item != null) {
      const data = JSON.parse(item);
      return data;
    }
    return null;
  }

  async getCenters(dispatch?: any) {
    const user = await this.getUserProfil();
    let items: any[] = [];

    //if (!isEmpty(user)) {
    await centerService
      .getByKey(``)
      .then((response) => {
        items = centerService.getData(response);
        if (typeof dispatch === "function") {
          dispatch(setCenters(items));
        }
      })
      .catch(() => {});
    //}
    return items;
  }

  async getPlugs(dispatch?: any) {
    const user = await this.getUserProfil();
    let items: any[] = [];
    let learners: any[] = [];
    let centers: any[] = [];
    await centerPlugService
      .getByKey(``)
      .then((response) => {
        centers = centerPlugService.getData(response);
      })
      .catch(() => {});
    await learnerPlugService
      .getByKey(``)
      .then((response) => {
        learners = learnerPlugService.getData(response);
      })
      .catch(() => {});
    items = [...centers, ...learners];
    items = items.sort((a, b) => {
      if (a?.createdAtAt > b?.createdAtAt) {
        return 1;
      } else if (a?.createdAtAt < b?.createdAtAt) {
        return -1;
      } else {
        return 0;
      }
    });
    if (typeof dispatch === "function") {
      dispatch(setPlugs(items));
    }
    return items;
  }

  async getSectors(dispatch?: any) {
    const user = await this.getUserProfil();
    let items: any[] = [];

    //if (!isEmpty(user)) {
    await sectorService
      .getByKey(`order[name]=asc&pagination=false`)
      .then((response) => {
        items = sectorService.getData(response);
        items = items.sort((a: any, b: any) =>
          toLower(a.name).localeCompare(toLower(b.name))
        );
        if (typeof dispatch === "function") {
          dispatch(setAllSectors(items));
        }
      })
      .catch(() => {});
    //}
    return items;
  }

  async getExams(dispatch?: any) {
    const user = await this.getUserProfil();
    let items: any[] = [];

    //if (!isEmpty(user)) {
    await examService
      .getByKey(``)
      .then((response) => {
        items = examService.getData(response);
        if (typeof dispatch === "function") {
          dispatch(setAllExams(items));
        }
      })
      .catch(() => {});
    //}
    return items;
  }

  async getDivisions(dispatch?: any) {
    const user = await this.getUserProfil();
    let items: any[] = [];
    await divisionService
      .getByKey(``)
      .then((response) => {
        items = divisionService.getData(response);
        if (typeof dispatch === "function") {
          dispatch(setDivisions(items));
        }
      })
      .catch(() => {});
    return items;
  }

  async getBooks(dispatch?: any) {
    const user = await this.getUserProfil();
    let items: any[] = [];
    await bookService
      .getByKey(``)
      .then((response) => {
        items = bookService.getData(response);
        if (typeof dispatch === "function") {
          dispatch(setAllBooks(items));
        }
      })
      .catch(() => {});
    return items;
  }

  async getSubDivisions(dispatch?: any) {
    const user = await this.getUserProfil();
    let items: any[] = [];
    await subDivisionService
      .getByKey(``)
      .then((response) => {
        items = subDivisionService.getData(response);
        if (typeof dispatch === "function") {
          dispatch(setSubDivisions(items));
        }
      })
      .catch(() => {});
    return items;
  }

  async getLearners(dispatch?: any) {
    const user = await this.getUserProfil();
    let items: any[] = [];
    await learnerService
      .getByKey(``)
      .then(async (response) => {
        items = learnerService.getData(response);
        if (typeof dispatch === "function") {
          dispatch(setLeaners(items));
        }
      })
      .catch(() => {});
    return items;
  }

  async getAdmins(dispatch?: any) {
    const user = await this.getUserProfil();
    let items: any[] = [];
    await adminService
      .getByKey(``)
      .then(async (response) => {
        items = adminService.getData(response);
        if (typeof dispatch === "function") {
          dispatch(setAllAdmins(items));
        }
      })
      .catch(() => {});
    return items;
  }

  async getFormers(dispatch?: any) {
    const user = await this.getUserProfil();
    let items: any[] = [];
    await formerService
      .getByKey(``)
      .then(async (response) => {
        items = formerService.getData(response);
        if (typeof dispatch === "function") {
          dispatch(setAllFormers(items));
        }
      })
      .catch(() => {});
    return items;
  }

  async getMessages(dispatch?: any) {
    const user = await this.getUserProfil();
    let items: any[] = [];
    await receptionService
      .getByKey(`user=${user.id}`)
      .then(async (response) => {
        items = receptionService.getData(response);
        if (typeof dispatch === "function") {
          dispatch(setAllMessages(items));
        }
      })
      .catch(() => {});
    return items;
  }

  async getInactivateLeaners(dispatch?: any) {
    const user = await this.getUserProfil();
    let items: any[] = [];
    await learnerService
      .getByKey(`isActive=false`)
      .then(async (response) => {
        items = learnerService.getData(response);
        if (typeof dispatch === "function") {
          dispatch(setInactivates(items));
        }
      })
      .catch(() => {});
    return items;
  }

  async getSessions(dispatch?: any) {
    const user = await this.getUserProfil();
    let items: any[] = [];
    await editionService
      .getByKey(`order[edition]=asc`)
      .then(async (response) => {
        items = editionService.getData(response);
        if (typeof dispatch === "function") {
          dispatch(setAllEditions(items));
        }
      })
      .catch(() => {});
    return items;
  }

  async initData(dispatch?: any) {
    await this.getCenters(dispatch);
    this.getPlugs(dispatch);
    this.getSectors(dispatch);
    this.getMessages(dispatch);
    this.getFormers(dispatch);
    this.getExams(dispatch);
    this.getSessions(dispatch);
    await this.getDivisions(dispatch);
    await this.getSubDivisions(dispatch);
    await this.getLearners(dispatch);
    await this.getAdmins(dispatch);
    await this.getBooks(dispatch);
    this.getInactivateLeaners(dispatch);
    if (typeof dispatch === "function") {
      dispatch(setLoading(false));
    }
  }

  getTitle(user: any) {
    const { firstName, lastName } = user;
    let result = "";
    if ((firstName ?? "").length > 0) {
      result = firstName.substring(0, 1);
    }
    if ((lastName ?? "").length > 0) {
      result += lastName.substring(0, 1);
    }
    return toUpper(result);
  }

  addMeta(
    value: string,
    name: string,
    type: "string" | "phone" | "text" | "email" | "web"
  ) {
    let meta = null;
    if (!isEmpty(value)) {
      meta = {
        metaKey: name,
        metaValue: value,
        metaType: type,
      };
    }
    return meta;
  }

  getSumPayment(items: any[]) {
    let result = "";
    const elements: any[] = [];
    items.forEach((p) => {
      const index = elements.findIndex((x) => x.currency === p.currency);
      if (index === -1) {
        elements.push({ currency: p.currency, amount: p.amount });
      } else {
        let element: any = elements[index];
        element.amount = p.amount + element.amount;
        elements[index] = element;
      }
    });
    let i = 0;
    elements.forEach((p) => {
      const amount = new Intl.NumberFormat("us-US", {
        maximumSignificantDigits: 2,
      }).format(p.amount);
      result += `${amount} ${toUpper(p.currency)} `;
      if (i < elements.length - 1) {
        result += " + ";
      }
      i++;
    });
    return result;
  }

  async getEditionProclaim(editionUid: string, dispatch?: any) {
    let result: any[] = [];
    await proclamationService
      .getByKey(`edition=${editionUid}`)
      .then((response) => {
        result = proclamationService.getData(response);
        if (typeof dispatch === "function") {
          dispatch(setProclaims(result));
        }
      })
      .catch(() => {});
    return result;
  }
}

export default new UserHelper();
