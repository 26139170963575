import { Card, Divider, Typography } from "antd";
import React from "react";
import {
  DillInput,
  DillRadio,
  DillSelect,
  DillTextarea,
} from "../../../components/input";
import { getProvinces } from "../../../utils/helpers/geoHelper";

export const LocalisationEtsSheet: React.FC<{ formik: any }> = ({ formik }) => {
  return (
    <React.Fragment>
      <Divider />
      <Typography.Title
        level={2}
        style={{ fontSize: 20, margin: 0 }}
      >{`II. LOCALISATION DE L’ETABLISSEMENT (ECOLE-CENTRE-ATELIER-GARAGE) `}</Typography.Title>
      <Card hoverable className="card">
        <div className="row">
          <div className="col">
            <DillInput formik={formik} name="street" label="AVENUE + NUMERO" />
          </div>
          <div className="col">
            <DillInput
              formik={formik}
              name="village"
              label="QUARTIER/VILLAGE"
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <DillInput
              formik={formik}
              name="locality"
              label="COMMUNE/LOCALITE"
            />
          </div>
          <div className="col">
            <DillInput
              formik={formik}
              name="landmark"
              label="POINT DE REPERE"
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <DillRadio
              formik={formik}
              label="STATUT D’OCCUPATION DES LIEUX"
              list={[
                { value: "LOCATAIRE", title: "LOCATAIRE" },
                { value: "PROPRIETAIRE", title: "PROPRIETAIRE" },
              ]}
              name="occupancy"
            />
          </div>
          <div className="col">
            <DillTextarea
              formik={formik}
              label="ETABLISSEMENTS VOISINS (3)"
              name="neighboringEstablishments"
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <DillInput formik={formik} name="quarter" label="QUARTIER" />
          </div>
          <div className="col">
            <DillInput formik={formik} name="commune" label="COMMUNE" />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <DillInput
              formik={formik}
              name="subProvince"
              label="SOUS-DIVISION"
            />
          </div>
          <div className="col">
            <DillSelect
              list={[
                { value: "", title: "Séléctionner la province" },
                ...getProvinces(),
              ]}
              formik={formik}
              name="province"
              label="PROVINCE"
            />
          </div>
        </div>
      </Card>
    </React.Fragment>
  );
};
