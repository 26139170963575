import { Table } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { isEmpty } from "lodash";
import dateHelpers from "../../utils/helpers/dateHelpers";
import { useNavigate } from "react-router-dom";

export function ExamSession() {
  const exams = useSelector((state: RootState) => state.exam.list);
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <Table
        dataSource={exams}
        columns={[
          { title: "Titre de l'examen", dataIndex: "title", align: "center" },
          {
            title: "Pondération",
            dataIndex: "maxima",
            align: "center",
            render(value, record, index) {
              return value;
            },
          },
          {
            title: "Filière",
            dataIndex: "training",
            align: "center",
            render(value, record, index) {
              let result = `${value?.sector?.name ?? "ds"} `;
              if (!isEmpty(value?.sector)) {
                result += `(${value.duration} mois)`;
              }
              return result;
            },
          },
          {
            title: "Etablissement",
            dataIndex: "center",
            align: "center",
            render(value, record, index) {
              return value?.name;
            },
          },
          {
            title: "Date de l'examen",
            dataIndex: "date",
            align: "center",
            render(value, record, index) {
              let result = ``;
              if (dateHelpers.isSameDay(value, new Date())) {
                result = `Aujourd'hui`;
              } else if (dateHelpers.isSameMoment(value, new Date(), "week")) {
                result = dateHelpers.getDate(value, "[ce] dddd");
              } else if (dateHelpers.isSameMoment(value, new Date(), "year")) {
                result = dateHelpers.getDate(value, "DD MMMM");
              } else {
                result = dateHelpers.getDate(value, "DD MMM YYYY");
              }
              return result;
            },
          },
        ]}
        className="table"
        onRow={(data: any, index?: number) => {
          return {
            onClick: () => {
              navigate(`/admin/exam/${data.id}`);
            },
          };
        }}
      />
    </React.Fragment>
  );
}
