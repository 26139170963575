import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { Button, Divider, Spin, Typography, notification } from "antd";
import { ElementCard } from "../../components/element";
import { sumBy } from "lodash";
import { getStatus } from "../../utils/helpers/title";
import editionService from "../../services/editionService";
import examPublishingService from "../../services/examPublishingService";
import { setEdition } from "../../redux/editionReducer";
import userHelper from "../../utils/helpers/userHelper";

export function ShowSession(props: { onOpenResults: () => void }) {
  const current = useSelector((state: RootState) => state.edition.current);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [maxima, setMaxima] = React.useState(0);
  const dispatch = useDispatch();

  async function handlePublish() {
    setLoading(true);
    await examPublishingService
      .store({ examSession: `/api/eams_sessions/${current.id}` })
      .then(async () => {
        await getEdition();
        await userHelper.getEditionProclaim(`${current.id}`, dispatch);
        notification.info({
          message: "success",
          description: "La session est publiée",
        });
      })
      .catch((reason) => {
        const error = editionService.getError(reason);
        notification.error({ message: "Error", description: error });
      });
    setLoading(false);
  }

  async function getEdition() {
    await editionService
      .get(current?.id)
      .then((response) => {
        dispatch(setEdition(response.data));
      })
      .catch(() => {});
  }

  const onEdition = React.useCallback(() => {
    const exams: any[] = current?.exams ?? [];
    const som = sumBy(exams, (p) => p.maxima);
    setMaxima(som);
  }, [current]);

  React.useEffect(() => {
    onEdition();
  }, [current]);
  return (
    <React.Fragment>
      <Typography.Title className="search--title">
        <span style={{ color: "#222" }}>Edition:</span>{" "}
        <span className="item">{current.edition}</span>
      </Typography.Title>
      <div
        style={{
          display: "flex",
          alignContent: "center",
          justifyContent: "center",
          flexDirection: "column",
          width: "100%",
        }}
      >
        {current?.status !== "published" && (
          <div style={{ textAlign: "center" }}>
            <Button
              disabled={loading}
              loading={loading}
              type="primary"
              shape="round"
              onClick={handlePublish}
            >
              {`Publier le résultat`}
            </Button>
          </div>
        )}
        {current?.status === "published" && (
          <div style={{ textAlign: "center" }}>
            <Button type="default" onClick={props.onOpenResults}>
              {`Voir le résultat`}
            </Button>
          </div>
        )}
      </div>
      <Divider />
      <div className="row card--stretch">
        <div className="col-md-3 item">
          <ElementCard
            subtitle="Filière"
            title={current?.training?.sector?.name}
            icon=""
          />
        </div>
        <div className="col-md-3 item">
          <ElementCard
            subtitle="Etablissement"
            title={current?.center?.name}
            icon=""
          />
        </div>
        <div className="col-md-3 item">
          <ElementCard
            subtitle="Durée en mois"
            title={current?.training?.duration}
            icon=""
          />
        </div>
        <div className="col-md-3 item">
          <ElementCard
            subtitle="Nombres d'examens"
            title={current?.exams?.length ?? "Aucun"}
            icon=""
          />
        </div>
        <div className="col-md-3 item">
          <ElementCard
            subtitle="Cumulés des points"
            title={`${maxima} point${maxima > 1 ? "s" : ""}`}
            icon=""
          />
        </div>
        <div className="col-md-3 item">
          <ElementCard
            subtitle="Etat de publication"
            title={current.status === "pulished" ? "Publiée" : "Non publiée"}
            icon=""
          />
        </div>
        <div className="col-md-3 item">
          <ElementCard
            subtitle="Etat de session"
            title={getStatus(current.status).title}
            icon=""
          />
        </div>
      </div>
    </React.Fragment>
  );
}
