import React, { PropsWithChildren } from "react";

export const TitleComponent: React.FC<
  PropsWithChildren<{ title: string; type?: "creation" | "list" | "update" }>
> = ({ title, type, children }) => {
  return (
    <section
      className={`${
        type !== "creation" && type !== "update" ? "container" : ""
      }`}
      style={{ marginTop: 16 }}
    >
      <div className="row step--container border">
        <div className="step--title">
          <div
            className={`title ${
              type === "creation" ? "create" : type === "update" ? "update" : ""
            }`}
          >
            {title}
          </div>
        </div>
        {children && <div>{children}</div>}
      </div>
    </section>
  );
};
