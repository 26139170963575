import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import subDivisionService from "../../services/subDivisionService";
import { useLocation } from "react-router-dom";
import { DillInput, DillSelect } from "../../components/input";
import { isEmpty } from "lodash";
import centerService from "../../services/centerService";
import { Divider } from "antd";
import { centerRegimes } from "../../utils/helpers/title";

export const CreateIdentityCenter: React.FC<{
  formik: any;
  formikLocation?: any;
  formikMeta?: any;
}> = ({ formik, formikLocation, formikMeta }) => {
  const divList = useSelector((state: RootState) => state.subDivision.list);
  const [divisions, setDivisions] = React.useState<any[]>([]);
  const [elements, setElements] = React.useState<any[]>(divList);
  const [search, setSearch] = React.useState("");
  const [searching, setSearching] = React.useState(false);
  const [subDivisionId, setSubdivisionId] = React.useState<string | null>(null);
  const [subDivisionTitle, setSubdivisionTitle] = React.useState<string>("");
  const [refresh, setRefresh] = React.useState(false);
  const [updateId, setUpdateId] = React.useState("");
  const route = useLocation();

  React.useEffect(() => {
    const list: any[] = [];
    divList.forEach((p) => {
      list.push({ value: `${p.id}`, title: p.name });
    });
    list.unshift({
      value: "",
      title: "Séléctionner la sous-division",
    });
    setDivisions(list);
  }, [divList]);

  React.useEffect(() => {
    const args = new URLSearchParams(route.search);
    const id = args.get("sub_division");
    const update = args.get("update");
    if (!isEmpty(`${id}`)) {
      getSubDivision(`${id}`);
      setSubdivisionId(id);
    }
    if (!isEmpty(update)) {
      setUpdateId(update ?? "");
    }
  }, [route]);

  React.useEffect(() => {
    initUpdate();
  }, [updateId, divisions]);

  async function initUpdate() {
    if (isEmpty(updateId)) {
      return;
    }
    setRefresh(true);
    await centerService
      .get(updateId)
      .then((response) => {
        const data = response.data;
        const {
          email,
          latitude,
          longitude,
          leader,
          name,
          phoneNumber,
          registrationNumber,
          subDivision,
          occupancy,
          type,
          environment,
          subType,
          regime,
          mechanization,
          gender,
        } = data;
        formik.setValues({
          ...formik.values,
          email,
          longitude,
          latitude,
          leader,
          name,
          phoneNumber,
          registrationNumber,
          subDivision: `${subDivision?.id ?? ""}`,
          gender: gender ?? "",
          regime,
          mechanization,
        });
        formikLocation?.setValues({
          ...formikLocation.values,
          latitude,
          longitude,
          environment,
          occupancy,
          type,
          subType,
        });
      })
      .catch(() => {});
    setRefresh(false);
  }

  async function getSubDivision(id: string) {
    await subDivisionService
      .get(id)
      .then((response) => {
        setSubdivisionTitle(response.data.name);
      })
      .catch(() => {});
  }

  async function onSearchDivision() {
    setSearching(true);
    let query = `name=${search}`;
    if (isEmpty(search)) {
      query = ``;
    }
    await subDivisionService
      .getByKey(query)
      .then((response) => {
        setElements(subDivisionService.getData(response));
      })
      .catch(() => {});
    setSearching(false);
  }

  React.useEffect(() => {
    onSearchDivision();
  }, [search]);

  const types = [
    { value: "", title: "Séléctionner le type d'école" },
    { value: "female", title: "Féminin" },
    { value: "male", title: "Masculin" },
    { value: "mixte", title: "Mixte" },
  ];

  const regimes = centerRegimes;

  const mechanizations = [
    { value: "", title: "Séléctionner la mécanisation" },
    { value: "mechanized_paied", title: "Mécanisée  et  payée" },
    { value: "mechanized_not_paied", title: "Mécanisé  et  non  payée" },
    { value: "not_mechanized", title: "Non  mécanisée" },
  ];

  return (
    <React.Fragment>
      <DillInput
        formik={formik}
        name="name"
        label={"Nom de l'établissement"}
        placeholder={""}
      />
      <DillInput
        formik={formik}
        name="registrationNumber"
        label={"Numéro matricule"}
        placeholder={""}
        type="num"
      />
      <DillInput
        formik={formik}
        name="leader"
        label={"Nom du responsable"}
        placeholder={""}
      />
      <DillInput
        formik={formik}
        name="phoneNumber"
        label={"Numéro de téléphone"}
        placeholder={""}
        type="tel"
      />
      <Divider style={{ margin: 5 }} />
      <DillInput
        formik={formik}
        name="email"
        label={"Adresse E-mail"}
        placeholder={""}
        type="email"
      />
      <Divider style={{ margin: 5 }} />
      <DillSelect
        formik={formik}
        name="gender"
        list={types}
        label="Type d'école"
      />
      <DillSelect
        formik={formik}
        name="regime"
        list={regimes}
        label="Regime de gestion"
      />
      <DillSelect
        formik={formik}
        name="mechanization"
        list={mechanizations}
        label="Mécanisation"
      />
      <Divider style={{ margin: 5 }} />
      {isEmpty(subDivisionId) && (
        <DillSelect
          formik={formik}
          name="subDivision"
          list={divisions}
          label="Sous-division"
          search
          setSearch={setSearch}
          loading={searching}
        />
      )}
    </React.Fragment>
  );
};
