import { Avatar, Button, List, Typography } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { TitleComponent } from "../components/title";
import dateHelpers from "../utils/helpers/dateHelpers";
import { RootState } from "../redux/store";
import { isEmpty } from "lodash";

export function MessageScreen() {
  const messages = useSelector((state: RootState) => state.message.all);
  const renderExpeditor = (item: any, index: number) => {
    return (
      <List.Item className="message--expeditors-item">
        <div
          className="row"
          style={{ flexWrap: "nowrap", alignItems: "center" }}
        >
          <div style={{ paddingRight: "0.4rem" }}>
            <Avatar size={"large"} />
          </div>
          <div className="flex--1 content">
            <Typography.Text strong>{`expeditor ${index + 1}`}</Typography.Text>
            <Typography.Paragraph ellipsis={{ rows: 1 }} className="message">
              <div>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Est at
                ipsa quos corporis laudantium, repellendus aliquam non?
                Voluptatem porro, eos repudiandae magni et assumenda id,
                mollitia amet fugiat a error.
              </div>
            </Typography.Paragraph>
          </div>
        </div>
      </List.Item>
    );
  };

  const renderMessage = (item: any, index: number) => {
    return (
      <List.Item id={`message-${index}`}>
        <div className="message--content-item exp">
          <div className="content">
            {!isEmpty(item.title) && (
              <Typography.Title
                style={{ fontSize: 16, marginBottom: 0, paddingBottom: 0 }}
              >
                {item.message?.title}
              </Typography.Title>
            )}
            <Typography.Paragraph>{item.message?.content}</Typography.Paragraph>
          </div>
          <div className="report">
            <span className="date">
              {dateHelpers.getDate(
                item.message?.sendTime,
                "DD MMMM YYYY à HH:mm"
              )}
            </span>
          </div>
        </div>
      </List.Item>
    );
  };

  return (
    <React.Fragment>
      <main className="main main yc_container_main">
        <TitleComponent title="Tous les messages reçus">
          <Button className="btn--round">
            <Link to={`/admin/message/create`}>
              {`Envoyer un nouveau message`}
            </Link>
          </Button>
        </TitleComponent>
        <div
          className="container"
          style={{ marginTop: "1.6rem", marginBottom: "1.6rem" }}
        >
          <div className="row">
            <div className="col-xs-3 col-md-3 col-lg-3 hide">
              <section className="message--expeditors">
                <List
                  dataSource={["", "", "", "", "", "", "", "", "", "", "", ""]}
                  renderItem={renderExpeditor}
                  bordered
                />
              </section>
            </div>
            <div className="col-xs-9 col-md-9 col-lg-9">
              <section className="container message--list">
                <List dataSource={messages} renderItem={renderMessage} />
              </section>
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
}
