import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface sheetData {
  current: any;
  list: any[];
  all: any[];
  selected: any;
  open: boolean;
  drafting: boolean;
}

const initialState: sheetData = {
  current: {},
  all: [],
  list: [],
  selected: {},
  open: false,
  drafting: false,
};

export const sheetSlice = createSlice({
  name: "sheet",
  initialState,
  reducers: {
    setSheet: (state, action: PayloadAction<any>) => {
      state.current = action.payload;
    },
    setOpenSheet: (state, action: PayloadAction<boolean>) => {
      state.open = action.payload;
    },
    setDraftingSheet: (state, action: PayloadAction<boolean>) => {
      state.drafting = action.payload;
    },
    setSelectedSheet: (state, action: PayloadAction<any>) => {
      state.selected = action.payload;
    },
    setSheets: (state, action: PayloadAction<any>) => {
      state.list = action.payload;
    },
    setAllSheets: (state, action: PayloadAction<any[]>) => {
      state.all = action.payload;
    },
  },
});
export const {
  setAllSheets,
  setSelectedSheet,
  setSheet,
  setSheets,
  setOpenSheet,
  setDraftingSheet,
} = sheetSlice.actions;
export default sheetSlice.reducer;
