import { Button, Modal, notification } from "antd";
import { useFormik } from "formik";
import React from "react";
import { DillInput } from "../../components/input";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import userService from "../../services/userService";
import userHelper from "../../utils/helpers/userHelper";

export const ProfileUpdateBirthPlace: React.FC<{}> = () => {
  const [visible, setVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const user = useSelector((state: RootState) => state.user.profil);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      bornPlace: user.bornPlace,
    },
    onSubmit: onSave,
  });

  async function onSave() {
    const { bornPlace } = formik.values;
    const data: any = { bornPlace };
    setLoading(true);
    await userService
      .update(user.id, data)
      .then((response) => {
        setVisible(false);
        notification.info({ message: "La modification a réussi" });
        userHelper.setCurrentUser(response.data, dispatch);
      })
      .catch((reason) => {
        const error = userService.getError(reason);
        notification.error({
          message: "Erreur de modification",
          description: error,
        });
      });
    setLoading(false);
  }

  function handleUpdate() {
    formik.handleSubmit();
  }

  function handleShow() {
    setVisible(true);
  }

  function handleClose() {
    if (loading) {
      return;
    }
    setVisible(false);
  }

  React.useEffect(() => {
    const { bornPlace } = user;
    formik.setValues({
      ...formik.values,
      bornPlace,
    });
  }, [user]);

  return (
    <React.Fragment>
      <Button shape="circle" onClick={handleShow} type="primary">
        <i className="fa-solid fa-pen-to-square"></i>
      </Button>
      <Modal
        open={visible}
        centered
        confirmLoading={loading}
        okText="Modifier"
        cancelText="Annuler"
        onOk={handleUpdate}
        onCancel={handleClose}
        title="Modifier le lieu de naissance"
      >
        <div>
          <DillInput
            formik={formik}
            name="bornPlace"
            label="Lieu de naissance"
          />
        </div>
      </Modal>
    </React.Fragment>
  );
};
