import { Button, Divider, Skeleton, Tabs, Typography } from "antd";
import { isEmpty, upperCase, upperFirst } from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import learnerService from "../services/learnerService";
import dateHelpers from "../utils/helpers/dateHelpers";
import { getUid } from "../utils/helpers/title";
import userHelper from "../utils/helpers/userHelper";
import { ElementCard } from "../components/element";
import { RootState } from "../redux/store";
import adminService from "../services/adminService";
import { ProfileIndentityView } from "./profile/identity";
import { ProfileAccessView } from "./profile/accessibility";

export function ProfilScreen() {
  const params = useParams();
  const [loading, setLoading] = React.useState(true);
  const [refresh, setRefresh] = React.useState(false);
  const user = useSelector((state: RootState) => state.user.profil);
  const [admin, setAdmin] = React.useState<any>({});
  const dispatch = useDispatch();

  React.useEffect(() => {
    setLoading(true);
    init();
  }, [user, params]);

  async function init() {
    const id = params.id;
    if (id) {
      await adminService
        .get(`${id ?? ""}`)
        .then(async (response) => {
          const data: any = response.data;
          setAdmin(data);
        })
        .catch((reason) => {});
    } else {
      await adminService
        .get(`${user?.admin?.id ?? ""}`)
        .then(async (response) => {
          const data: any = response.data;
          setAdmin(data);
        })
        .catch((reason) => {});
    }
    setLoading(false);
  }

  return (
    <React.Fragment>
      <main className="main main yc_container_main">
        {loading && (
          <React.Fragment>
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
          </React.Fragment>
        )}
        {!loading && (
          <section className="container" style={{ marginTop: 13 }}>
            {/* <Tabs
            items={[
              {
                key: "identity",
                label: "Identité",
                children: <ProfileIndentityView admin={admin} />,
              },
              {
                key: "confid",
                label: "Confidentialité",
              },
              {
                key: "Accessibility",
                label: "Accessibilité",
                children: <ProfileAccessView admin={admin} />,
              },
            ]}
            tabPosition="left"
            className="tab--profile"
          /> */}
            <ProfileIndentityView admin={admin} />
          </section>
        )}
      </main>
    </React.Fragment>
  );
}
