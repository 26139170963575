import { ColumnsType } from "antd/es/table";
import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { TitleComponent } from "../components/title";
import { Button, message, Skeleton, Table } from "antd";
import divisionService from "../services/divisionService";
import subDivisionService from "../services/subDivisionService";
import centerService from "../services/centerService";
import { CenterCategories, getUid } from "../utils/helpers/title";
import { DillInput, DillSelect } from "../components/input";
import { useFormik } from "formik";
import { isEmpty, toLower } from "lodash";

export function CenterScreen() {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(true);
  const [searching, setSearching] = React.useState(false);
  const [items, setItems] = React.useState<any[]>([]);

  const formik = useFormik({
    initialValues: {
      name: "",
      type: "",
    },
    onSubmit: () => {},
  });

  const columns: ColumnsType<any> = [
    {
      title: "N°",
      render(value, record, index) {
        return index + 1;
      },
    },
    { title: "Nom de l'établissement", dataIndex: "name" },
    { title: "Responsable", dataIndex: "leader" },
    {
      title: "Filières et métiers",
      dataIndex: "trainings",
      align: "center",
      render(value, record, index) {
        return value?.length ?? "Aucune";
      },
    },
    {
      title: "Aprrenants",
      dataIndex: "leaners",
      align: "center",
      render(value, record, index) {
        return value?.length ?? "Aucun";
      },
    },
    {
      title: "Classes",
      dataIndex: "levels",
      align: "center",
      render(value, record, index) {
        return value?.length ?? "Aucun";
      },
    },
  ];

  function handleClick(data: any) {
    const id = getUid(data["@id"]);
    navigate(`/admin/center/${id}`);
  }

  async function init() {
    setLoading(true);
    await centerService
      .getByKey(``)
      .then((response) => {
        const data = centerService.getData(response);
        setItems(data);
      })
      .catch(() => {});
    setLoading(false);
  }

  React.useEffect(() => {
    init();
  }, []);

  async function onFilter() {
    let query = ``;
    const { name, type } = formik.values;
    if (!isEmpty(`${name}`) && name?.length >= 3) {
      query = `name=${name}`;
    } else if (!isEmpty(`${type}`)) {
      query = `type=${type}`;
    } /*else if (!isEmpty(`${sub_division}`)) {
      query = `center.subDivision=${sub_division}`;
    } else if (!isEmpty(`${division}`)) {
      query = `center.subDivision.division=${division}`;
    }*/
    /*if (year?.length === 4) {
      if (query.length !== 0) {
        query += `&`;
      }
      query += `trainingYear=${year}`;
    }
    if (!isEmpty(sex)) {
      if (query.length !== 0) {
        query += `&`;
      }
      query += `user.sex=${sex}`;
    }*/
    if (isEmpty(query)) {
      return;
    }
    setSearching(true);
    await centerService
      .getByKey(query)
      .then((response) => {
        const data = centerService.getData(response);
        setItems(data);
      })
      .catch(() => {});
    setSearching(false);
  }

  React.useEffect(() => {
    onFilter();
  }, [formik.values]);

  return (
    <React.Fragment>
      <main className="main main yc_container_main">
        {!loading && (
          <React.Fragment>
            <TitleComponent title={`Tous les établissements`}>
              <Button className="btn--round">
                <Link
                  to={`/admin/center/create`}
                >{`Ajouter un établissement`}</Link>
              </Button>
            </TitleComponent>
            <section className="container fadeIn">
              <div className="row">
                <div className="col">
                  <DillSelect
                    formik={formik}
                    name="type"
                    list={[...CenterCategories]}
                    key="type"
                  />
                </div>
                <div className="col">
                  <DillInput
                    formik={formik}
                    name="name"
                    key="name"
                    type="search"
                    placeholder={
                      "Nom de l'établissement (3 caractères au minimum)"
                    }
                  />
                </div>

                {/*<div className="col">
              <DillSelect
                formik={formik}
                name="sector"
                list={sectors}
                key="sector"
              />
      </div>*/}
              </div>
            </section>
            <section className="container">
              <Table
                className="table"
                columns={columns}
                dataSource={items}
                onRow={(data: any, index?: number) => {
                  return {
                    onClick: () => handleClick(data),
                  };
                }}
                loading={searching}
                pagination={{
                  simple: false,
                }}
              />
            </section>
          </React.Fragment>
        )}
        {loading && (
          <React.Fragment>
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
          </React.Fragment>
        )}
      </main>
    </React.Fragment>
  );
}
