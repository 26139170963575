import { Button, notification } from "antd";
import { useFormik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import { DillInput, DillPassword, DillSelect } from "../../components/input";
import * as yup from "yup";
import divisionService from "../../services/divisionService";
import { isEmpty, min, toNumber } from "lodash";
import userHelper from "../../utils/helpers/userHelper";
import { TitleComponent } from "../../components/title";
import { useLocation, useNavigate } from "react-router-dom";

export function CreateDivisionScreen() {
  const formik = useFormik({
    initialValues: {
      name: "",
      matricule: "",
      leader: "",
      filieres: "",
      registarationNumber: "",
      agentsNumber: "",
      email: "",
      subdivisionNumber: "",
      phoneNumber: "",
    },
    onSubmit: onSave,
    validationSchema: yup.object({
      name: yup.string().required("Champ requis"),
      email: yup.string().required("Champ requis"),
      phoneNumber: yup.string().required("Champ requis"),
      leader: yup.string().required("Champ requis"),
      /*agentsNumber: yup
        .number()
        .typeError("Un nombre entier positif")
        .required("Champ requis"),
      subdivisionNumber: yup
        .number()
        .typeError("Un nombre entier positif")
        .required("Champ requis"),*/
    }),
  });
  const [loading, setLoading] = React.useState(false);
  const [refresh, setRefresh] = React.useState(false);
  const [updateId, setUpdateId] = React.useState("");
  const dispatch = useDispatch();
  const route = useLocation();
  const navigate = useNavigate();

  React.useEffect(() => {
    const args = new URLSearchParams(route.search);
    const update = args.get("update");
    if (!isEmpty(update)) {
      setUpdateId(update ?? "");
    }
  }, [route]);

  React.useEffect(() => {
    initUpdate();
  }, [updateId]);

  async function initUpdate() {
    if (isEmpty(updateId)) {
      return;
    }
    setRefresh(true);
    await divisionService
      .get(updateId)
      .then((response) => {
        const data = response.data;
        const {
          email,
          leader,
          name,
          phoneNumber,
          registarationNumber,
          matricule,
        } = data;
        formik.setValues({
          ...formik.values,
          email,
          leader,
          name,
          phoneNumber,
          registarationNumber,
          matricule,
        });
      })
      .catch(() => {});
    setRefresh(false);
  }

  async function onSave() {
    const {
      agentsNumber,
      email,
      filieres,
      leader,
      matricule,
      name,
      phoneNumber,
      registarationNumber,
      subdivisionNumber,
    } = formik.values;
    const data: any = {
      name,
      leader,
      registarationNumber: "1",
      agentsNumber: toNumber(agentsNumber),
      subdivisionNumber: toNumber(subdivisionNumber),
      email,
      phoneNumber,
    };
    setLoading(true);
    if (isEmpty(updateId)) {
      await onCreate(data);
    } else {
      await onUpdate(data);
    }
    setLoading(false);
  }

  async function onCreate(data: any) {
    await divisionService
      .store(data)
      .then(async (reponse) => {
        await userHelper.getDivisions(dispatch);
        notification.info({
          message: "Enregistrement de la province éducationnelle réussi",
        });
        formik.resetForm({
          values: {
            agentsNumber: "",
            email: "",
            filieres: "",
            leader: "",
            matricule: "",
            name: "",
            phoneNumber: "",
            registarationNumber: "",
            subdivisionNumber: "",
          },
        });
      })
      .catch((reason) => {
        const error = divisionService.getError(reason);
        notification.error({
          message: "Enregistrement de la province éducationnelle",
          description: error,
        });
      });
  }

  async function onUpdate(data: any) {
    await divisionService
      .update(updateId, data)
      .then(async (reponse) => {
        await userHelper.getDivisions(dispatch);
        notification.info({
          message: "Modification de la province éducationnelle réussie",
        });
        formik.resetForm({
          values: {
            agentsNumber: "",
            email: "",
            filieres: "",
            leader: "",
            matricule: "",
            name: "",
            phoneNumber: "",
            registarationNumber: "",
            subdivisionNumber: "",
          },
        });
        navigate(`/admin/division/${updateId}`);
      })
      .catch((reason) => {
        const error = divisionService.getError(reason);
        notification.error({
          message: "Modification de la province éducationnelle",
          description: error,
        });
      });
  }

  return (
    <React.Fragment>
      <main className="main main yc_container_main">
        <section className="box">
          <div className="card box__file">
            <TitleComponent
              title={
                isEmpty(updateId)
                  ? "Enregistrement de division"
                  : "Modification de division"
              }
              type={isEmpty(updateId) ? "creation" : "update"}
            />
            <div className="card">
              <DillInput
                formik={formik}
                name="name"
                label={"Nom de la province éducationnelle"}
                placeholder={""}
              />
              <DillInput
                formik={formik}
                name="leader"
                label={"Nom de responsable"}
                placeholder={""}
              />
              <DillInput
                formik={formik}
                name="email"
                label={"Adresse E-mail"}
                placeholder={""}
                type="email"
              />
              {/*<DillInput
              formik={formik}
              name="agentsNumber"
              label={"Nombre d'agents"}
              placeholder={""}
            />*/}
              {/*<DillInput
              formik={formik}
              name="registarationNumber"
              label="Nombre d'enregistrement"
  />*/}
              {/*<DillInput
              formik={formik}
              name="subdivisionNumber"
              label={"Nombre de sous-provinces éducationnelles"}
              placeholder={""}
/>*/}
              <DillInput
                formik={formik}
                name="phoneNumber"
                label={"Téléphone"}
                type="tel"
              />
              <div
                className="row"
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "10px 0 0",
                }}
              >
                <Button
                  type="primary"
                  loading={loading}
                  disabled={loading}
                  onClick={() => formik.handleSubmit()}
                >
                  {" "}
                  {isEmpty(updateId) ? `Enregistrer` : `Modifier`}
                </Button>
              </div>
            </div>
          </div>
        </section>
      </main>
    </React.Fragment>
  );
}
