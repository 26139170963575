import { Tabs } from "antd";
import React from "react";
import { DivisionShowView } from "./show";
import { SubDivisionCenterView } from "./center";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { setFloatUrl } from "../../redux/userReducer";

export function SubDivisionView() {
  const params = useParams();
  const dispatch = useDispatch();
  const url = useSelector((state: RootState) => state.user.floatUrl);

  React.useEffect(() => {
    dispatch(setFloatUrl(`/admin/sub_division/create?update=${params.id}`));
  }, [params.id, url]);
  return (
    <React.Fragment>
      <main className="main main yc_container_main">
        <section className="container" style={{ marginBottom: "1.6rem" }}>
          <Tabs
            animated
            items={[
              {
                label: "Présentation de la sous-division",
                key: "show",
                children: <DivisionShowView />,
              },
              {
                label: "Les établissements de la sous-division",
                key: "center",
                children: <SubDivisionCenterView />,
              },
            ]}
          />
        </section>
      </main>
    </React.Fragment>
  );
}
