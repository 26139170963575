import React from "react";
import { TitleComponent } from "../components/title";
import { Button, List, Table } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { useFormik } from "formik";
import { DillInput, DillSelect, selectItem } from "../components/input";
import divisionService from "../services/divisionService";
import { isEmpty, toNumber, toUpper, upperFirst } from "lodash";
import subDivisionService from "../services/subDivisionService";
import centerService from "../services/centerService";
import { CreateExam } from "./exam/create";
import editionService from "../services/editionService";
import { useLocation, useNavigate } from "react-router-dom";
import examService from "../services/examService";
import dateHelpers from "../utils/helpers/dateHelpers";
import proclamationService from "../services/proclamationService";
import { GenerateCertificate } from "./diploma/generate";

export function DiplomaScreen() {
  const [data, setData] = React.useState<any[]>([]);

  const exams = useSelector((state: RootState) => state.exam.all);
  const [loading, setLoading] = React.useState(false);
  const [allOptions, setAllOptions] = React.useState(false);
  const [loadScreen, setLoadScreen] = React.useState(false);
  const [items, setItems] = React.useState<any[]>([]);
  const route = useLocation();
  const navigate = useNavigate();
  const inactivates = useSelector(
    (state: RootState) => state.learner.inactivates
  );
  const [divisions, setDivisions] = React.useState<
    { title: string; value: string }[]
  >([]);
  const [subDivisions, setSubDivisions] = React.useState<
    { title: string; value: string }[]
  >([]);
  const [centers, setCenters] = React.useState<selectItem[]>([]);
  const [sectors, setSectors] = React.useState<selectItem[]>([]);
  const [editions, setEditions] = React.useState<selectItem[]>([]);

  const [loadDivision, setLoadDivision] = React.useState(false);
  const [loadSubDivision, setSubLoadDivision] = React.useState(false);
  const [loadCenter, setLoadCenter] = React.useState(false);
  const [loadEdition, setLoadEdition] = React.useState(false);
  const [searchEdition, setSearchEdition] = React.useState("");
  const formik = useFormik({
    initialValues: {
      name: "",
      sector: "",
      center: "",
      division: "",
      subDivision: "",
      edition: "",
    },
    onSubmit: () => {},
  });

  const navigation = useNavigate();

  async function getAllDivisions() {
    const list: selectItem[] = [];
    setLoadDivision(true);
    await divisionService
      .getByKey(``)
      .then((response) => {
        const data = divisionService.getData(response);
        data.forEach((p) => {
          list.push({
            title: p.name,
            value: p.id,
          });
        });
        if (list.length > 0) {
          list.unshift({
            title: "Séléctionner une province éducationnelle",
            value: "",
          });
        } else {
          list.unshift({
            title: "Aucune division",
            value: "",
          });
        }
        setDivisions(list);
      })
      .catch(() => {});
    setLoadDivision(false);
  }

  async function getSubDivisions() {
    const { division } = formik.values;
    if (isEmpty("" + division)) {
      setSubDivisions([{ title: "Aucune sous-division", value: "" }]);
      return;
    }
    const list: selectItem[] = [];
    setSubLoadDivision(true);
    await subDivisionService
      .getByKey(`division=${division}`)
      .then((response) => {
        const data = subDivisionService.getData(response);
        data.forEach((p) => {
          list.push({
            title: p.name,
            value: p.id,
          });
        });
        if (list.length > 0) {
          list.unshift({
            title: "Séléctionner une sous-province éducationnelle",
            value: "",
          });
        } else {
          list.unshift({
            title: "Aucune sous-division",
            value: "",
          });
        }
        setSubDivisions(list);
      })
      .catch(() => {});
    setSubLoadDivision(false);
  }

  async function getCenters() {
    const { subDivision } = formik.values;
    if (isEmpty("" + subDivision)) {
      setCenters([{ title: "Aucun établissement", value: "" }]);
      return;
    }
    const list: selectItem[] = [];
    setLoadCenter(true);
    await centerService
      .getByKey(`subDivision=${subDivision}`)
      .then((response) => {
        const data = centerService.getData(response);
        data.forEach((p) => {
          list.push({
            title: p.name,
            value: p.id,
          });
        });
        if (list.length > 0) {
          list.unshift({
            title: "Séléctionner un établissement",
            value: "",
          });
        } else {
          list.unshift({
            title: "Aucun établissement",
            value: "",
          });
        }
        setCenters(list);
      })
      .catch(() => {});
    setLoadCenter(false);
  }

  async function onSearchEdition() {
    let query = `edition=${searchEdition}`;
    setLoadEdition(true);
    await editionService.getByKey(query).then((response) => {
      const data = editionService.getData(response);
      const elements: selectItem[] = [];
      data.forEach((p) => {
        elements.push({
          title: `${p.edition}`,
          value: `${p.id}`,
        });
      });
      elements.unshift({ value: "", title: "Séléctionner la session" });
      setEditions(elements);
    });
    setLoadEdition(false);
  }

  React.useEffect(() => {
    onSearchEdition();
  }, [searchEdition]);

  React.useEffect(() => {
    getAllDivisions();
  }, []);

  React.useEffect(() => {
    getSubDivisions();
  }, [formik.values.division]);

  React.useEffect(() => {
    getCenters();
  }, [formik.values.subDivision]);

  React.useEffect(() => {
    onInit();
  }, [route]);

  const onInit = React.useCallback(() => {
    init();
  }, [route]);

  const onSearch = React.useCallback(() => {
    init();
  }, [formik.values]);

  React.useEffect(() => {
    onSearch();
  }, [formik.values]);

  async function init() {
    let query: string = ``;
    const { center, division, edition, name, sector, subDivision } =
      formik.values;

    if (!isEmpty(center.toString())) {
      query += `learner.center=${center}&`;
    } else if (!isEmpty(subDivision.toString())) {
      query += `learner.center.subDivision=${subDivision}&`;
    } else if (!isEmpty(division.toString())) {
      query += `learner.center.subDivision.division=${division}&`;
    }
    if (!isEmpty(edition.toString())) {
      query += `edition=${edition}&`;
    }
    if (!isEmpty(name)) {
      query += `learner.user.name=${name}&`;
    }

    /*const args = new URLSearchParams(route.search);
    const year = toNumber(args.get("year"));
    const month = toNumber(args.get("month"));
    const day = toNumber(args.get("day"));
    let query = ``;
    if (year > 0 && month > 0 && day > 0) {
      setAllOptions(false);
      setLoadScreen(true);
      const date = new Date(year, month - 1, day);
      const date1 = dateHelpers.getDate(date, "YYYY-MM-DD 00:00:00");
      const date2 = dateHelpers.getDate(date, "YYYY-MM-DD 23:59:59");
      query += `date[after]=${date1}&date[before]=${date2}`;
      setLoading(true);
    } else if (year > 0 && month > 0) {
      setAllOptions(false);
      setLoadScreen(true);
      const date1 = dateHelpers.getDate(
        new Date(year, month - 1, 1),
        "YYYY-MM-DD 00:00:00"
      );
      const limit = dateHelpers.getDayInMonth(year, month);
      const date2 = dateHelpers.getDate(
        new Date(year, month - 1, limit),
        "YYYY-MM-DD 23:59:59"
      );
      query += `date[after]=${date1}&date[before]=${date2}`;
      setLoading(true);
    } else {
      setAllOptions(true);
      setItems(exams);
    }*/
    /*if (!isEmpty(query)) {
      await examService
        .getByKey(query)
        .then((response) => {
          const data = examService.getData(response);
          setItems(data);
        })
        .catch((reason) => {
          const error = examService.getError(reason);
        });
    }*/
    //setLoadScreen(false);
    setLoading(true);
    await proclamationService
      .getByKey(query)
      .then((response) => {
        const elements = proclamationService.getData(response);
        setData(elements);
        setData(elements);
      })
      .catch(() => {});
    setLoading(false);
  }

  React.useEffect(() => {}, [formik.values.center]);

  const renderItem = (item: any, index: number) => {
    return <List.Item></List.Item>;
  };
  return (
    <React.Fragment>
      <main className="main main yc_container_main">
        <TitleComponent title="Resultat des examens"></TitleComponent>
        <section className="container fadeIn">
          <div className="row">
            <div className="col">
              <DillSelect
                formik={formik}
                name="division"
                list={divisions}
                loading={loadDivision}
                key="division"
              />
            </div>
            <div className="col">
              <DillSelect
                formik={formik}
                name="subDivision"
                list={subDivisions}
                loading={loadSubDivision}
                key="subdivision"
              />
            </div>
            <div className="col">
              <DillSelect
                formik={formik}
                name="center"
                list={centers}
                loading={loadCenter}
                key="center"
              />
            </div>
            <div className="col">
              <DillInput
                formik={formik}
                name="name"
                key="name"
                placeholder={"Prénom de l'apprenant"}
              />
            </div>
            <div className="col">
              <DillSelect
                formik={formik}
                name="edition"
                list={editions}
                loading={loadEdition}
                key="edition"
                search
                setSearch={setSearchEdition}
              />
            </div>
          </div>
        </section>
        <section className="container">
          <Table
            dataSource={data}
            loading={loading}
            columns={[
              {
                title: "N°",
                render(value, record, index) {
                  return index + 1;
                },
              },
              {
                title: "Apprenant",
                dataIndex: "learner",
                render(value, record, index) {
                  return `${upperFirst(value?.user?.name)} ${toUpper(
                    value?.user?.lastname
                  )} ${toUpper(value?.user?.postname)}`;
                },
              },
              {
                title: "Pourcentage",
                dataIndex: "percent",
                render(value, record, index) {
                  return `${value} %`;
                },
              },
              {
                title: "Etablissement",
                dataIndex: "learner",
                render(value, record, index) {
                  return value?.center?.name;
                },
              },
              {
                title: "Nombre d'examens",
                dataIndex: "results",
                render(value, record, index) {
                  return value?.length;
                },
              },
              {
                title: "Edition",
                dataIndex: "edition",
                align: "center",
                render(value, record, index) {
                  return value?.edition;
                },
                onCell: (data: any, index?: number) => {
                  return {
                    onClick: () => {
                      navigation(`/admin/session/${data.id}`);
                    },
                  };
                },
              },
              {
                title: "Filière",
                dataIndex: "training",
                align: "center",
                render(value, record, index) {
                  let result = `${value?.sector?.name ?? ""} `;
                  if (!isEmpty(value?.sector)) {
                    result += `(${value.duration} mois)`;
                  }
                  return result;
                },
              },
              {
                render(value, record, index) {
                  const percent = value?.percent ?? 0;
                  return <GenerateCertificate item={value} percent={percent} />;
                },
              },
            ]}
            className="table"
            onRow={(data: any, index?: number) => {
              return {
                onClick: () => {
                  //navigate(`/admin/exam/${data.id}`);
                },
              };
            }}
          />
        </section>
      </main>
    </React.Fragment>
  );
}
