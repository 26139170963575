import { ColumnsType } from "antd/es/table";
import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { TitleComponent } from "../components/title";
import { Button, message, Skeleton, Table } from "antd";
import {
  CenterCategories,
  getUid,
  plugCategories,
} from "../utils/helpers/title";
import { DillInput, DillSelect } from "../components/input";
import { useFormik } from "formik";
import { isEmpty } from "lodash";
import userHelper from "../utils/helpers/userHelper";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import dateHelpers from "../utils/helpers/dateHelpers";
import centerPlugService from "../services/centerPlugService";
import learnerPlugService from "../services/learnerPlugService";

export function PlugListScreen() {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [searching, setSearching] = React.useState(false);
  const [items, setItems] = React.useState<any[]>([]);
  const dispatch = useDispatch();
  const plugs = useSelector((state: RootState) => state.center.plugs);

  const formik = useFormik({
    initialValues: {
      name: "",
      type: "",
    },
    onSubmit: () => {},
  });

  React.useEffect(() => {
    setItems(plugs);
  }, []);

  const columns: ColumnsType<any> = [
    {
      title: "N°",
      render(value, record, index) {
        return index + 1;
      },
    },
    {
      title: "Type de fiche",
      dataIndex: "plug",
      render(value, record, index) {
        let result = "";
        const item = plugCategories.find((p) => p.value === value);
        result = item?.title ?? value;
        return result;
      },
    },
    { title: "Identifiant", dataIndex: "name" },
    {
      title: "Apprenant",
      render(value, record, index) {
        let result = userHelper.getUserName(value ?? {});
        return result ?? "---";
      },
    },
    {
      title: "Date de création",
      dataIndex: "createdAt",
      render(value, record, index) {
        const result = dateHelpers.getDateStatus(value, "date");
        return result ?? "---";
      },
    },
  ];

  function handleClick(data: any, center?: boolean) {
    const id = getUid(data["@id"]);
    if (center) {
      navigate(`/admin/plug/${id}`);
    } else {
      navigate(`/admin/plug/${id}/learner`);
    }
  }

  async function init() {
    if (plugs.length === 0) {
      setLoading(true);
    }
    await userHelper.getPlugs(dispatch);
    setLoading(false);
  }

  React.useEffect(() => {
    init();
  }, []);

  async function onFilter() {
    let query = ``;
    const { name, type } = formik.values;
    if (!isEmpty(`${name}`) && name?.length >= 3) {
      query = `name=${name}`;
    } else if (!isEmpty(`${type}`)) {
      query = `plug=${type}`;
    } /*else if (!isEmpty(`${sub_division}`)) {
      query = `center.subDivision=${sub_division}`;
    } else if (!isEmpty(`${division}`)) {
      query = `center.subDivision.division=${division}`;
    }*/
    /*if (year?.length === 4) {
      if (query.length !== 0) {
        query += `&`;
      }
      query += `trainingYear=${year}`;
    }
    if (!isEmpty(sex)) {
      if (query.length !== 0) {
        query += `&`;
      }
      query += `user.sex=${sex}`;
    }*/
    if (isEmpty(query)) {
      return;
    }
    const elements = plugCategories;
    const cat = plugCategories.find((p) => p.value === type);
    setSearching(true);
    if (cat?.type === "center") {
      await centerPlugService
        .getByKey(query)
        .then((response) => {
          const data = centerPlugService.getData(response);
          setItems(data);
        })
        .catch(() => {});
    } else {
      await learnerPlugService
        .getByKey(query)
        .then((response) => {
          const data = learnerPlugService.getData(response);
          setItems(data);
        })
        .catch(() => {});
    }
    setSearching(false);
  }

  React.useEffect(() => {
    onFilter();
  }, [formik.values]);

  return (
    <React.Fragment>
      <main className="main main yc_container_main">
        {!loading && (
          <React.Fragment>
            <TitleComponent title={`Toutes les fiches`}>
              <Button className="btn--round">
                <Link
                  style={{ width: "100%" }}
                  to={`/admin/sheets`}
                >{`Ajouter une fiche`}</Link>
              </Button>
            </TitleComponent>
            <section className="container fadeIn">
              <div className="row">
                <div className="col">
                  <DillSelect
                    formik={formik}
                    name="type"
                    list={[...plugCategories]}
                    key="type"
                  />
                </div>
                <div className="col">
                  <DillInput
                    formik={formik}
                    name="name"
                    key="name"
                    placeholder={"Nom de centre"}
                  />
                </div>

                {/*<div className="col">
              <DillSelect
                formik={formik}
                name="sector"
                list={sectors}
                key="sector"
              />
      </div>*/}
              </div>
            </section>
            <section className="container">
              <Table
                className="table"
                columns={columns}
                dataSource={items}
                onRow={(data: any, index?: number) => {
                  const item = plugCategories.find(
                    (p) => p.value === data.plug
                  );
                  return {
                    onClick: () => handleClick(data, item?.type === "center"),
                  };
                }}
                loading={searching}
                pagination={{
                  simple: false,
                }}
              />
            </section>
          </React.Fragment>
        )}
        {loading && (
          <React.Fragment>
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
          </React.Fragment>
        )}
      </main>
    </React.Fragment>
  );
}
