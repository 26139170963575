import {
  Avatar,
  Badge,
  Button,
  Divider,
  List,
  Popover,
  Space,
  Tooltip,
  Typography,
} from "antd";
import React from "react";
import "../firebase";
import { getDatabase, onValue, push, ref, set } from "firebase/database";
import {
  colorAccent,
  colorPrimary,
  colorSecondary,
} from "../utils/themes/colors";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { setNewUsers } from "../redux/userReducer";
import { isEmpty, toUpper, upperFirst } from "lodash";
import userHelper from "../utils/helpers/userHelper";
import { Link, useNavigate } from "react-router-dom";
import { getUid } from "../utils/helpers/title";
import { setNotifMails } from "../redux/mailReducer";

export const ProfilNavbar: React.FC<{ isMail?: boolean }> = ({ isMail }) => {
  const [visible, setVisible] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const [items, setItems] = React.useState<any[]>([]);
  const user = useSelector((state: RootState) => state.user.profil);
  const navigate = useNavigate();
  const inactivates = useSelector(
    (state: RootState) => state.learner.inactivates
  );
  const mails = useSelector((state: RootState) => state.mail.notifs);
  const dispatch = useDispatch();

  function handleClick(v: boolean) {
    setVisible(v);
  }
  function handleNotifs(v: boolean) {
    if (items.length === 0) {
      setShow(false);
      return;
    }
    setShow(v);
  }

  function handleLogout() {
    userHelper.setLogout(dispatch);
    window.location.href = "/";
  }

  function handleProfil() {
    navigate("/admin/profile");
  }

  React.useEffect(() => {
    init();
  }, []);

  function init() {
    const db = getDatabase();
    const dbRef = ref(db, `users/${user.id ?? getUid(user["@id"])}/posts`);
    const dbMailRef = ref(db, `users/${user.id ?? getUid(user["@id"])}/mails`);
    const dbNewLearnerRef = ref(db, `admin/activation`);
    onValue(dbNewLearnerRef, () => {
      userHelper.getInactivateLeaners(dispatch);
      userHelper.getLearners(dispatch);
    });
    onValue(dbRef, (snapshot) => {
      const elements: any[] = [];
      snapshot.forEach((elem) => {
        const data = elem.val();
        if (data.readed === false) {
          elements.push({ ...data, key: elem.key });
        }
      });
      setItems(elements);
    });
    onValue(dbMailRef, (snapshot) => {
      const elements: any[] = [];
      snapshot.forEach((elem) => {
        const data = elem.val();
        if (data.readed === false) {
          elements.push({ ...data, key: elem.key });
        }
      });
      dispatch(setNotifMails(elements));
    });
  }

  const renderItem = (item: any, index: number) => {
    if (index < 3) {
      return (
        <List.Item className="article--item">
          <div className="row">
            <div className="col-md-3">
              <Avatar />
            </div>
            <div className="col-md-9">
              {!isEmpty(item.title) && (
                <Typography.Text strong>{item.title}</Typography.Text>
              )}
              <Typography.Paragraph ellipsis={{ rows: 2 }}>
                {item.content}
              </Typography.Paragraph>
            </div>
          </div>
          <div className="date">{`Il y a 3 heures`}</div>
        </List.Item>
      );
    } else if (index === 3) {
      return (
        <List.Item className="article--item">
          <Button type="link">{`Voir tous les messages`}</Button>
        </List.Item>
      );
    } else {
      return null;
    }
  };

  const ComponentProfil = () => {
    return (
      <React.Fragment>
        <div>
          <Typography.Paragraph
            style={{ marginBottom: 0, maxWidth: 125 }}
            ellipsis={{ rows: 1 }}
          >
            {`${upperFirst(user.name)} ${toUpper(user.lastname)} ${toUpper(
              user.postname
            )}`}
          </Typography.Paragraph>
          <Divider style={{ margin: 0 }} />
          <Button onClick={handleProfil} type="link">
            {`Voir le profil`}
          </Button>
        </div>
      </React.Fragment>
    );
  };

  const ComponentNotif = () => {
    return (
      <React.Fragment>
        <div style={{ minWidth: "100%" }}>
          {items.length > 0 && (
            <List dataSource={items} renderItem={renderItem} bordered={false} />
          )}
          {items?.length === 0 && <div>{`Aucune notification`}</div>}
          <Divider style={{ marginBottom: 0, marginTop: 5 }} />
        </div>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      {!isMail && (
        <div style={{ padding: "0 10px" }}>
          {/* <Badge count={mails.length}>
            <Tooltip title="Messagerie d'apprenants">
              <Button shape="circle" type="primary">
                <Link to={`/mail/`} target="_blank">
                  <i className="fa-solid fa-envelope"></i>
                </Link>
              </Button>
            </Tooltip>
          </Badge> */}
        </div>
      )}
      {inactivates.length > 0 && !isMail && (
        <div>
          <Badge count={inactivates.length}>
            <Tooltip title="Le nombre d'apprenants non activés">
              <Button shape="circle" danger type="default">
                <Link to={`/admin/?status=inactive`}>
                  <i className="fa-solid fa-light-emergency"></i>
                </Link>
              </Button>
            </Tooltip>
          </Badge>
        </div>
      )}
      {!isMail && (
        <Popover
          trigger="click"
          popupVisible={show}
          onOpenChange={handleNotifs}
          content={<ComponentNotif />}
          overlayInnerStyle={{ minWidth: "200px", maxWidth: "220px" }}
        >
          <div className="login" style={{ cursor: "pointer" }}>
            <Badge count={items?.length ?? 0}>
              <Avatar size="large" style={{ background: colorPrimary }}>
                <i className="icon fa-solid fa-bell"></i>
              </Avatar>
            </Badge>
            <i
              className="fa-solid fa-chevron-down"
              style={{ fontSize: "1.0rem", paddingLeft: "0.2rem" }}
            ></i>
          </div>
        </Popover>
      )}
      <Popover
        trigger="click"
        popupVisible={visible}
        onOpenChange={handleClick}
        content={<ComponentProfil />}
      >
        <div className="login" style={{ cursor: "pointer" }}>
          <Avatar size="large" style={{ background: colorSecondary }}>
            <i className="icon fa-solid fa-user"></i>
          </Avatar>
          <i
            className="fa-solid fa-chevron-down"
            style={{ fontSize: "1.0rem", paddingLeft: "0.2rem" }}
          ></i>
        </div>
      </Popover>
    </React.Fragment>
  );
};
