import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface formerData {
  current: any;
  list: any[];
  all: any[];
}

const initialState: formerData = {
  current: {},
  all: [],
  list: [],
};

export const formerSlice = createSlice({
  name: "formers",
  initialState,
  reducers: {
    setFormer: (state, action: PayloadAction<any>) => {
      state.current = action.payload;
    },
    setFormers: (state, action: PayloadAction<any>) => {
      state.list = action.payload;
    },
    setAllFormers: (state, action: PayloadAction<any[]>) => {
      state.all = action.payload;
    },
  },
});
export const { setAllFormers, setFormer, setFormers } = formerSlice.actions;
export default formerSlice.reducer;
