import { Button, Typography } from "antd";
import { useFormik } from "formik";
import React from "react";
import { DillInput } from "../components/input";

export function SearchScreen() {
  const formik = useFormik({
    initialValues: {
      search: "",
    },
    onSubmit: () => {},
  });

  return (
    <React.Fragment>
      <main className="main main yc_container_main">
        <section className="container">
          <Typography.Title className="search--title">
            <span className="item">{`YEKOLA `}</span>
            <span className="item">{`MOSALA`}</span>
          </Typography.Title>
          <div className="search--input-container">
            <DillInput
              formik={formik}
              type="search"
              name="search"
              className="search--input"
              icon="fa-sharp fa-regular fa-magnifying-glass"
              placeholder={"Recherche tout"}
            />
          </div>
          <div style={{ textAlign: "center", marginTop: 16 }}>
            <Button>{`Rechercher`}</Button>
          </div>
        </section>
      </main>
    </React.Fragment>
  );
}
