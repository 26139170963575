import React from "react";
import { DillInput, DillRadio, DillSelect } from "../../components/input";

export const CreateOtherCenterScreen: React.FC<{
  formik: any;
}> = ({ formik }) => {
  const fermedResponses: any[] = [
    { value: "yes", title: "Oui" },
    { value: "no", title: "Non" },
  ];

  return (
    <React.Fragment>
      <DillRadio
        className="radio"
        formik={formik}
        name="courses"
        label="L'établissement dispose-t-il des programmes officiels des cours: "
        list={fermedResponses}
      />
      <DillRadio
        className="radio"
        formik={formik}
        name="copa"
        label="L'établissement dispose-t-il d'un COPA: "
        list={fermedResponses}
      />
      <DillRadio
        formik={formik}
        name="coges"
        label="L'établissement dispose-t-il d'un COGES: "
        list={fermedResponses}
        className="radio"
      />
      <DillRadio
        formik={formik}
        name="waterPointExist"
        label="L'établissement dispose-t-il d'un  point  d'eau : "
        list={fermedResponses}
        className="radio"
      />
      {formik?.values.waterPointExist === "yes" && (
        <DillSelect
          formik={formik}
          name="waterPoint"
          label="Le type de point d'eau"
          list={[
            { value: "", title: "Choisir" },
            { value: "Robinet", title: "Robinet" },
            { value: "Forages/Puits", title: "Forages/Puits" },
            { value: "Sources", title: "Sources" },
            { value: "Citerne", title: "Citerne" },
            { value: "Gouttière", title: "Gouttière" },
          ]}
        />
      )}
      <DillRadio
        formik={formik}
        name="supported"
        label="L'établissement est-il pris en charge par le programme de réfugiés: "
        list={fermedResponses}
        className="radio"
      />
      {formik?.values.supported === "yes" && (
        <DillInput
          formik={formik}
          name="organism"
          label="Nom de l'organisme ayant pris en charge le programme des réfugiés"
        />
      )}
      <DillRadio
        formik={formik}
        name="electricity"
        label="L'établissement dispose-t-il de l'électricité: "
        list={fermedResponses}
        className="radio"
      />
      <DillRadio
        formik={formik}
        name="latrine"
        label="L'établissement dispose-t-il des  latrines: "
        list={fermedResponses}
        className="radio"
      />
      {formik?.values?.latrine === "yes" && (
        <div className="row">
          <div className="col">
            <DillInput
              formik={formik}
              name="boyLatrineCount"
              type="num"
              placeholder="Nombre de compartiments"
              label="Pour des garçons"
            />
          </div>
          <div className="col">
            <DillInput
              formik={formik}
              name="girlLatrineCount"
              type="num"
              placeholder="Nombre de compartiments"
              label="Pour des filles"
            />
          </div>
          <div className="col">
            <DillInput
              formik={formik}
              placeholder="Nombre de compartiments"
              name="formerLatrineCount"
              type="num"
              label="Pour des formateurs"
            />
          </div>
        </div>
      )}
      <DillRadio
        formik={formik}
        name="playground"
        label="L'établissement dispose-t-il d'une cour de récréation: "
        list={fermedResponses}
        className="radio"
      />
      <DillRadio
        formik={formik}
        name="sportsField"
        label="L'établissement dispose-t-il un terrain de sport: "
        list={fermedResponses}
        className="radio"
      />
      <DillRadio
        formik={formik}
        name="fence"
        label="L'établissement dispose-t-il d'une  clôture': "
        list={fermedResponses}
        className="radio"
      />
      {formik?.values.fence === "yes" && (
        <DillSelect
          formik={formik}
          name="fenceNature"
          label="Nature de clôture"
          list={[
            { value: "", title: "Choisir" },
            { value: "dur", title: "En  dur" },
            { value: "semi-dur", title: "En semi-dur" },
            { value: "haie", title: "En haie" },
            { value: "other", title: "Autres" },
          ]}
        />
      )}
      <DillRadio
        formik={formik}
        name="otherEtsInLocal"
        label="les locaux sont-ils utilisés par un deuxième établissement: "
        list={fermedResponses}
        className="radio"
      />
      {formik?.values?.otherEtsInLocal === "yes" && (
        <DillInput
          formik={formik}
          name="otherEtsInLocalName"
          label="Précisez le nom du deuxième établissement"
        />
      )}
      <DillRadio
        formik={formik}
        name="affiliatedWithMinistry"
        label="L'établissement est-il affilié à un ministère de tutelle: "
        list={fermedResponses}
        className="radio"
      />
      {formik?.values?.affiliatedWithMinistry === "yes" && (
        <div className="row" style={{ alignItems: "center" }}>
          <div className="col">
            <DillInput
              formik={formik}
              name="ministryName"
              label="Ministère de tutelle affilié"
            />
          </div>
          <div className="col">
            <DillInput
              formik={formik}
              name="ministryYear"
              type="num"
              label="Année de début d'éffiliation"
            />
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
