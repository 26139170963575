import { routeInterface } from "../routes";
import { DivisionScreen } from "../screens/divisions";
import { DivisionView } from "../screens/division/view";
import { CreateDivisionScreen } from "../screens/division/create";
import { CalendarScreen } from "../screens/calendar";
export const calendarRoutes: routeInterface[] = [
  {
    component: CalendarScreen,
    icon: "fa-solid fa-calendar-days",
    isMenu: true,
    label: "Calendrier",
    layout: "/admin",
    name: "calendar",
    path: "/calendar",
    subscribeable: false,
  },
  /*{
    component: DivisionView,
    icon: "",
    isMenu: false,
    label: "Division",
    layout: "/admin",
    name: "division",
    path: "/division/:id",
    subscribeable: false,
  },
  {
    component: CreateDivisionScreen,
    icon: "fa-solid fa-location-plus",
    isMenu: true,
    label: "Ajouter une division",
    layout: "/admin",
    name: "create_division",
    path: "/division/create",
    subscribeable: false,
  },*/
];
