import { routeInterface } from "../routes";
import { FormersScreen } from "../screens/formers";
import { CreateFormerScreen } from "../screens/former/create";
import { FormerView } from "../screens/former/view";
import { SessionView } from "../screens/session/view";
import { SheetScreen } from "../screens/sheet";
export const sessionRoutes: routeInterface[] = [
  {
    component: SessionView,
    icon: ``,
    isMenu: false,
    label: "",
    layout: "/admin",
    name: "session-view",
    path: "/session/:id",
    subscribeable: false,
  },
];
