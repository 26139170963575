import { Button, notification } from "antd";
import { useFormik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DillInput,
  DillPassword,
  DillSelect,
  DillTextarea,
} from "../../components/input";
import { RootState } from "../../redux/store";
import * as yup from "yup";
import { isEmpty } from "lodash";
import learnerService from "../../services/learnerService";
import userHelper from "../../utils/helpers/userHelper";
import dateHelpers from "../../utils/helpers/dateHelpers";
import { TitleComponent } from "../../components/title";
import { MessageFile } from "./fileUpload";

export const MessageCreateContent: React.FC<{ formik: any }> = ({ formik }) => {
  function onChangeFiles(v: any) {
    formik.setFieldValue("files", v);
  }
  return (
    <React.Fragment>
      <section className="box fadeIn">
        <div className="card box__file">
          <TitleComponent title="Contenu de message" type="creation" />
          <div className="card">
            <DillInput
              formik={formik}
              name="title"
              label={"Titre du message"}
              placeholder={""}
            />
            <DillTextarea
              formik={formik}
              name="content"
              label={"Contenu du message *"}
              placeholder={""}
            />
            <MessageFile
              files={formik.values?.files ?? []}
              setFiles={onChangeFiles}
            />
            <div
              className="row"
              style={{
                alignItems: "center",
                justifyContent: "center",
                padding: "10px 0 0",
              }}
            >
              <Button
                onClick={() => formik.handleSubmit()}
                type="primary"
              >{`Suivant`}</Button>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};
