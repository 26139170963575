import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface leanerData {
  current: any;
  list: any[];
  all: any[];
  inactivates: any[];
  profil: any;
  stories: any[];
}

const initialState: leanerData = {
  current: {},
  all: [],
  list: [],
  profil: {},
  inactivates: [],
  stories: [],
};

export const leanerSlice = createSlice({
  name: "learners",
  initialState,
  reducers: {
    setLeaner: (state, action: PayloadAction<any>) => {
      state.current = action.payload;
    },
    setLeanerProfil: (state, action: PayloadAction<any>) => {
      state.profil = action.payload;
    },
    setLeaners: (state, action: PayloadAction<any[]>) => {
      state.list = action.payload;
    },
    setAllLeaners: (state, action: PayloadAction<any[]>) => {
      state.all = action.payload;
    },
    setLeanerStories: (state, action: PayloadAction<any[]>) => {
      state.stories = action.payload;
    },
    setInactivates: (state, action: PayloadAction<any[]>) => {
      state.inactivates = action.payload;
    },
  },
});
export const {
  setAllLeaners,
  setLeaner,
  setLeanerProfil,
  setLeaners,
  setInactivates,
  setLeanerStories,
} = leanerSlice.actions;
export default leanerSlice.reducer;
