import { Card, Divider, Typography } from "antd";
import React from "react";
import { DillInput } from "../../../components/input";

export const IdentificationEtsSheet: React.FC<{ formik: any }> = ({
  formik,
}) => {
  return (
    <React.Fragment>
      <Divider />
      <Typography.Title
        level={2}
        style={{ fontSize: 20, margin: 0 }}
      >{`I. IDENTIFICATION DE L’ETABLISSEMENT (ECOLE – CENTRE – ATELIER - GARAGE) `}</Typography.Title>
      <Card hoverable className="card">
        <DillInput
          formik={formik}
          inline
          name="identificationType"
          label="TYPE"
        />
        <div className="row">
          <div className="col">
            <DillInput formik={formik} name="name" label="DENOMINATION" />
          </div>
          <div className="col">
            <DillInput
              formik={formik}
              name="legalStatus"
              label="STATUT JURIDIQUE"
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <DillInput
              formik={formik}
              name="managementType"
              label="TYPE DE GESTION"
            />
          </div>
          <div className="col">
            <DillInput
              formik={formik}
              name="orderNumber"
              label="ARRETE D’AGR N°"
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <DillInput
              formik={formik}
              name="notification"
              label="NOTIFICATION"
            />
          </div>
          <div className="col">
            <DillInput formik={formik} name="matricule" label="MATRICULE" />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <DillInput
              formik={formik}
              name="settlementMunicipality"
              label="COMMUNE/TERRITOIRE D’IMPLATATION"
            />
          </div>
          <div className="col">
            <DillInput
              formik={formik}
              name="subDivision"
              label="SOUS-DIVISION"
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <DillInput formik={formik} name="leader" label="NOM DU PROMOTEUR" />
          </div>
          <div className="col">
            <DillInput formik={formik} name="leaderWork" label="PROFESSION" />
          </div>
        </div>
        <DillInput
          formik={formik}
          inline
          name="creationYear"
          label="ANNEE DE CREATION"
        />
      </Card>
    </React.Fragment>
  );
};
