import { Divider, Typography } from "antd";
import { isEmpty, toUpper } from "lodash";
import React from "react";
import { useParams } from "react-router-dom";
import { DivisionMap } from "../../components/map";
import centerService from "../../services/centerService";
import { mapMarker } from "../../utils/helpers/title";
import { CenterStatView } from "./stat";
import { ElementCard } from "../../components/element";

export function CenterShowView() {
  const [division, setDivision] = React.useState<any>({});
  const [markers, setMarkers] = React.useState<mapMarker[]>([]);
  const [items, setItems] = React.useState<any[]>([]);
  const params = useParams();
  const [loading, setLoading] = React.useState(true);
  const [center, setCenter] = React.useState<
    google.maps.LatLngLiteral | undefined
  >();

  async function init() {
    const id = params.id;
    setLoading(true);
    const list: mapMarker[] = [];

    await centerService
      .get(`${id}`)
      .then((response) => {
        const data = response.data;
        getInfo(data);
        setDivision(data);
        let { latitude, longitude, name } = data;
        if (latitude === 0 || longitude === 0) {
          latitude = null;
          longitude = null;
        }
        if (latitude && longitude && !isEmpty(name)) {
          list.push({
            position: { lat: latitude, lng: longitude },
            title: name,
          });
        }
        if (list.length === 1) {
          setCenter(list[0].position);
        }
        setMarkers(list);
      })
      .catch((reason) => {});
    setLoading(false);
  }

  React.useEffect(() => {
    init();
  }, [params.id]);

  function getInfo(data: any) {
    const elements: any[] = [];
    elements.push({
      title: data?.name,
      subtitle: "Nom de l'établissement",
      icon: "",
    });
    elements.push({
      title: data?.registrationNumber,
      subtitle: "Numéro matricule",
      icon: "",
    });
    elements.push({
      title: data?.leader,
      subtitle: "Responsable de l'établissement",
      icon: "",
    });
    elements.push({ title: data.email, subtitle: "E-mail", icon: "" });
    elements.push({ title: data.phoneNumber, subtitle: "Téléphone", icon: "" });
    elements.push({
      title: isEmpty(data.regime) ? "Non défini" : data.regime,
      subtitle: "Régime",
      icon: "",
    });
    elements.push({
      title: isEmpty(data.environment)
        ? "Non défini"
        : data.environment === "urban"
        ? "Urbain"
        : "Rural",
      subtitle: "Type d'environnement",
      icon: "",
    });
    elements.push({
      title: isEmpty(data.occupancy)
        ? "Non défini"
        : data.occupancy === "owner"
        ? "Propiétaire de l'espace"
        : "Locataire",
      subtitle: "Occupation",
      icon: "",
    });
    elements.push({
      title: isEmpty(data.type) ? "Non défini" : toUpper(data.type),
      subtitle: "Catégorie",
      icon: "",
    });
    if (data.type === "cam") {
      elements.push({
        title: isEmpty(data.subType)
          ? "Non défini"
          : data.subType === "formel"
          ? "Formel"
          : "Informel",
        subtitle: "Sous-catégorie",
        icon: "",
      });
    }

    setItems(elements);
  }

  return (
    <React.Fragment>
      <section className="container">
        <div>
          <Typography.Title
            className="search--title"
            style={{ textTransform: "uppercase" }}
          >
            {division?.name}
          </Typography.Title>
        </div>
      </section>
      <section className="container">
        <div className="row card--stretch">
          {items.map((p: any, index: number) => (
            <div className="col-md-3 col-xs-12 item" key={`element${index}`}>
              <ElementCard
                title={p.title}
                subtitle={p.subtitle}
                icon={p.icon}
              />
            </div>
          ))}
        </div>
      </section>
      <Divider />
      <CenterStatView id={params.id ?? ""} />
      <Divider />
      <div style={{ margin: "1.6rem 0" }}>
        {!loading && (
          <DivisionMap markers={markers} center={center} zoom={18} />
        )}
      </div>
    </React.Fragment>
  );
}
