import { Button } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";

export function GenerateCertificate(props: { percent: number; item: any }) {
  const { percent, item } = props;

  const navigate = useNavigate();

  function handleCertificate() {
    const uid = uuid();
    const value = { ...item, privateKet: uid };
    localStorage.setItem("certificate", JSON.stringify(value));
    navigate(`/admin/certificate`);
  }

  return (
    <>
      <Button
        onClick={handleCertificate}
        disabled={percent < 50}
        type="primary"
      >
        {`Certificat`}
      </Button>
    </>
  );
}
