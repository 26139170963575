import React from "react";
import "antd/dist/reset.css";
import "./assets/styles/main.scss";
import store from "./redux/store";
import { Provider } from "react-redux";
import { ConfigProvider } from "antd";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AdminLayout } from "./layouts/admin";
import { HomeLayout } from "./layouts/home";
import { NotFoundScreen } from "./layouts/404";
import { colorPrimary } from "./utils/themes/colors";
import { MailLayout } from "./layouts/mail";

function App() {
  return (
    <Provider store={store}>
      <ConfigProvider
        //renderEmpty={customizeRenderEmpty}
        theme={{
          token: {
            colorPrimary: colorPrimary,
          },
          components: {
            Input: {
              controlHeight: 40,
            },
            Button: {
              controlHeight: 40,
            },
            Select: {
              controlHeight: 40,
            },
            Radio: {
              controlHeight: 40,
            },
          },
        }}
      >
        <React.Fragment>
          <BrowserRouter>
            <Routes>
              <Route element={<HomeLayout />} path="/*" />
              <Route element={<AdminLayout />} path="admin/*" />
              <Route element={<MailLayout />} path="mail/*" />
              <Route path="*" element={<NotFoundScreen />} />
            </Routes>
          </BrowserRouter>
        </React.Fragment>
      </ConfigProvider>
    </Provider>
  );
}

export default App;
