import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useFormik } from "formik";
import { DillSelect } from "../../components/input";
import {
  colorAccent,
  colorPrimary,
  colorSecondary,
} from "../../utils/themes/colors";
import centerService from "../../services/centerService";
import { isEmpty, random, upperCase } from "lodash";
import { getUid } from "../../utils/helpers/title";
import sectorService from "../../services/sectorService";
import learnerService from "../../services/learnerService";
import trainingDurationService from "../../services/trainingDurationService";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const CenterStatView: React.FC<{ id: string }> = ({ id }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(true);
  const user = useSelector((state: RootState) => state.user.current);
  const [labels, setLabels] = React.useState<string[]>([]);
  const [setors, setSectors] = React.useState<string[]>([]);
  const [data, setData] = React.useState<number[]>([]);
  const [chartTitle, setChartTitle] = React.useState("");
  const [dataTitle, setDataTitle] = React.useState("");
  const [color, setColor] = React.useState(colorPrimary);

  const formik = useFormik({
    initialValues: {
      type: "learner",
    },
    onSubmit: () => {},
  });

  React.useEffect(() => {
    init();
  }, [id]);

  async function calculateSectorByLearners() {
    await centerService
      .get(id)
      .then(async (response) => {
        const data = response.data;
        // const elems = data.sectors;
        const elems = data.trainings ?? [];
        let query = ``;
        elems.forEach((p: any, i: number) => {
          // const uid = p["@id"] ?? "";
          const uid = (p?.id ?? "") + "";
          if (i > 0) {
            query += `&`;
          }
          if (isEmpty(uid)) {
            query += `id[]=${getUid(p)}`;
          } else {
            query += `id[]=${getUid(uid)}`;
          }
        });
        query += `&center=${id}`;
        if (!isEmpty(query)) {
          const list = await getSectorsByQuery(query);
          setSectors(list);
          const listLabels: string[] = [];
          const listData: number[] = [];
          list.map((p) => {
            listLabels.push(upperCase(p?.sector?.name));
            listData.push(p.count);
          });
          setLabels(listLabels);
          setData(listData);
        }
      })
      .catch(() => {});
  }

  async function init() {
    await calculateSectorByLearners();
  }

  async function getSectorsByQuery(query: string) {
    let data: any[] = [];
    await trainingDurationService
      .getByKey(query)
      .then(async (response) => {
        data = trainingDurationService.getData(response);
        await Promise.all(
          data.map(async (p) => {
            p.count = await getLearnerCount(`${p.id}`, `${id}`);
          })
        );
      })
      .catch(() => {});

    return data;
  }

  async function getLearnerCount(sector: string, center: string) {
    let result = 0;
    await learnerService
      .getByKey(`center=${center}&sector=${sector}`)
      .then((response) => {
        result = learnerService.getTotal(response);
      })
      .catch(() => {});
    return result;
  }

  React.useEffect(() => {
    const { type } = formik.values;
    switch (type) {
      case "learner":
        setChartTitle("Etat des apprenants par rapport aux filières");
        setDataTitle("Nombre d'apprenants");
        setColor(colorPrimary);
        break;
      case "former":
        setChartTitle("Etat des formateurs");
        setDataTitle("Nombre de formateurs");
        setColor(colorSecondary);
        break;
      case "books":
        setChartTitle("Etats des ouvrages");
        setDataTitle("Nombre d'ouvrages");
        setColor(colorAccent);
        break;
    }
  }, [formik.values.type]);

  return (
    <React.Fragment>
      {data && (
        <section className="container">
          <DillSelect
            formik={formik}
            name="type"
            list={[
              { value: "learner", title: "Apprenants" },
              //{ value: "books", title: "Bibliothèque" },
              //{ value: "former", title: "Formateurs" },
            ]}
          />
          <Bar
            options={{
              responsive: true,
              plugins: {
                legend: {
                  position: "top" as const,
                },
                title: {
                  display: true,
                  text: chartTitle,
                },
              },
            }}
            data={{
              labels: labels,
              datasets: [
                {
                  data: data,
                  backgroundColor: color,
                  label: dataTitle,
                },
              ],
            }}
            style={{ maxHeight: 350 }}
          />
        </section>
      )}
    </React.Fragment>
  );
};
