import { Button, Divider, notification, Radio } from "antd";
import { useFormik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { DillInput, DillPassword, DillSelect } from "../../components/input";
import { RootState } from "../../redux/store";
import * as yup from "yup";
import { isEmpty } from "lodash";
import userHelper from "../../utils/helpers/userHelper";
import { TitleComponent } from "../../components/title";
import { getUid } from "../../utils/helpers/title";
import centerService from "../../services/centerService";
import userService from "../../services/userService";
import subDivisionService from "../../services/subDivisionService";
import divisionService from "../../services/divisionService";
import { ElementCard } from "../../components/element";

export function CreateAdminScreen() {
  const centerList = useSelector((state: RootState) => state.center.list);
  const subDivisionList = useSelector((state: RootState) => state.division.all);
  const divisionList = useSelector((state: RootState) => state.subDivision.all);

  const [type, setType] = React.useState<"center" | "subDivision" | "division">(
    "center"
  );
  const [centers, setCenters] = React.useState<any[]>([]);
  const [divisions, setDivisions] = React.useState<any[]>([]);
  const [subDivisions, setSubDivisions] = React.useState<any[]>([]);
  const [division, setDivision] = React.useState<any>({});
  const [subDivision, setSubDivision] = React.useState<any>({});
  const [loading, setLoading] = React.useState(false);
  const [searchingCenter, setSearchingCenter] = React.useState(false);
  const [searchingSubDivision, setSearchingSubDivision] = React.useState(false);
  const [searchingDivision, setSearchingDivision] = React.useState(false);
  const [searchCenter, setSearchCenter] = React.useState("");
  const [searchDivision, setSearchDivision] = React.useState("");
  const [searchSubDivision, setSearchSubDivision] = React.useState("");
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      postName: "",
      center: "",
      phoneNumber: "",
      email: "",
      bornDate: "",
      bornPlace: "",
      sex: "",
      division: "",
      subDivision: "",
    },
    onSubmit: onSave,
    validationSchema: yup.object({
      firstName: yup.string().required("Champ requis"),
      lastName: yup.string().required("Champ requis"),
      email: yup.string().required("Champ requis").email("E-mail invalide"),
      phoneNumber: yup.string().required("Champ requis"),
      bornDate: yup.string().required("Champ requis"),
    }),
  });

  React.useEffect(() => {
    const list: any[] = [];
    centerList.forEach((p) => {
      list.push({ value: `${p.id ?? getUid(p["@id"])}`, title: p.name });
    });
    list.unshift({
      value: "",
      title: "Séléctionner l'établissement",
    });
    setCenters(list);
  }, [centerList]);

  React.useEffect(() => {
    const list: any[] = [];
    subDivisionList.forEach((p) => {
      list.push({ value: `${p.id ?? getUid(p["@id"])}`, title: p.name });
    });
    list.unshift({
      value: "",
      title: "Séléctionner la sous-division",
    });
    setSubDivisions(list);
  }, [subDivisionList]);

  React.useEffect(() => {
    const list: any[] = [];
    divisionList.forEach((p) => {
      list.push({ value: `${p.id ?? getUid(p["@id"])}`, title: p.name });
    });
    list.unshift({
      value: "",
      title: "Séléctionner la province éducationnelle",
    });
    setDivisions(list);
  }, [divisionList]);

  React.useEffect(() => {
    formik.setValues({
      ...formik.values,
      division: "",
      center: "",
      subDivision: "",
    });
    setSearchCenter("");
    setSearchDivision("");
    setSearchSubDivision("");
  }, [type]);

  async function onSave() {
    const {
      center,
      firstName,
      lastName,
      postName,
      phoneNumber,
      email,
      bornDate,
      bornPlace,
      sex,
      division,
      subDivision,
    } = formik.values;
    if (type === "center" && isEmpty(`${center}`)) {
      notification.warning({
        message: "Vérification",
        description: "Vous devez choisir l'établissement",
      });
      return;
    } else if (type === "division" && isEmpty(`${division}`)) {
      notification.warning({
        message: "Vérification",
        description: "Vous devez choisir la province éducationnelle",
      });
      return;
    } else if (type === "subDivision" && isEmpty(`${subDivision}`)) {
      notification.warning({
        message: "Vérification",
        description: "Vous devez choisir la sous-division",
      });
      return;
    }
    const data: any = {
      name: firstName,
      lastname: lastName,
      postname: postName,
      bornDate,
      bornPlace,
      sex,
      email,
      phoneNumber,
      password: "12345678",
      admin: {},
    };

    const formData = new FormData();
    formData.append("name", firstName);
    formData.append("lastname", lastName);
    formData.append("postname", postName);
    formData.append("bornDate", bornDate);
    formData.append("bornPlace", bornPlace);
    formData.append("sex", sex);
    formData.append("password", "12345678");
    formData.append("email", email);
    formData.append("phoneNumber", phoneNumber);
    const admin: any = {};

    if (type === "center") {
      data.admin.center = `/api/centers/${center}`;
      formData.append(
        "admin",
        JSON.stringify({ center: `/api/centers/${center}` })
      );
    } else if (type === "division") {
      data.leaner.division = `/api/divisions/${division}`;
      formData.append(
        "leaner",
        JSON.stringify({ division: `/api/divisions/${division}` })
      );
    } else if (type === "subDivision") {
      data.leaner.subDivision = `/api/sub_divisions/${subDivision}`;
      formData.append(
        "leaner",
        JSON.stringify({ subDivision: `/api/sub_divisions/${subDivision}` })
      );
    }
    setLoading(true);
    await userService
      .store(formData)
      .then(async (response) => {
        await userHelper.getAdmins(dispatch);
        notification.info({
          message: "Enregistrement de responsable réussi",
        });
        formik.resetForm({
          values: {
            center: "",
            firstName: "",
            lastName: "",
            postName: "",
            email: "",
            phoneNumber: "",
            bornDate: "",
            bornPlace: "",
            sex: "",
            division: "",
            subDivision: "",
          },
        });
      })
      .catch((reason) => {
        const error = userService.getError(reason);
        notification.error({
          message: "Enregistrement de responsable",
          description: error,
        });
      });
    setLoading(false);
  }

  async function onSearchCenter() {
    if (searchCenter.length < 3) {
      return;
    }
    setSearchingCenter(true);
    await centerService
      .getByKey(`name=${searchCenter}`)
      .then((response) => {
        const data = centerService.getData(response);
        const elems: any[] = [];
        data.forEach((p) => {
          elems.push({ value: `${getUid(p["@id"])}`, title: p.name });
        });
        elems.unshift({ value: "", title: "Séléctionner l'établissement" });
        setCenters(elems);
      })
      .catch(() => {});
    setSearchingCenter(false);
  }

  async function onSearchSubDivision() {
    if (searchSubDivision.length < 3) {
      return;
    }
    setSearchingSubDivision(true);
    await subDivisionService
      .getByKey(`name=${searchSubDivision}`)
      .then((response) => {
        const data = subDivisionService.getData(response);
        const elems: any[] = [];
        data.forEach((p) => {
          elems.push({ value: `${getUid(p["@id"])}`, title: p.name });
        });
        elems.unshift({ value: "", title: "Séléctionner la sous-division" });
        setSubDivisions(elems);
      })
      .catch(() => {});
    setSearchingSubDivision(false);
  }

  async function onSearchDivision() {
    if (searchDivision.length < 3) {
      return;
    }
    setSearchingDivision(true);
    await divisionService
      .getByKey(`name=${searchDivision}`)
      .then((response) => {
        const data = divisionService.getData(response);
        const elems: any[] = [];
        data.forEach((p) => {
          elems.push({ value: `${getUid(p["@id"])}`, title: p.name });
        });
        elems.unshift({
          value: "",
          title: "Séléctionner la province éducationnelle",
        });
        setDivisions(elems);
      })
      .catch(() => {});
    setSearchingDivision(false);
  }

  React.useEffect(() => {
    onSearchCenter();
  }, [searchCenter]);

  React.useEffect(() => {
    onSearchSubDivision();
  }, [searchSubDivision]);

  React.useEffect(() => {
    onSearchDivision();
  }, [searchDivision]);

  React.useEffect(() => {
    const uid = formik.values.center;
    setDivision({});
    setSubDivision({});
    if (isEmpty(uid)) {
      return;
    }
    centerService
      .get(uid)
      .then((response) => {
        const data = response.data;
        setSubDivision(data?.subDivision ?? {});
        setDivision(data?.subDivision?.division ?? {});
      })
      .catch(() => {});
  }, [formik.values.center]);

  React.useEffect(() => {
    const uid = formik.values.subDivision;
    setDivision({});
    setSubDivision({});
    if (isEmpty(uid)) {
      return;
    }
    subDivisionService
      .get(uid)
      .then((response) => {
        const data = response.data;
        setDivision(data?.division ?? {});
      })
      .catch(() => {});
  }, [formik.values.subDivision]);

  React.useEffect(() => {
    setDivision({});
    setSubDivision({});
  }, [formik.values.division]);

  return (
    <React.Fragment>
      <main className="main main yc_container_main">
        <section className="box">
          <div className="card box__file">
            <TitleComponent title="Ajouter un responsable" type="creation" />
            <div className="card">
              <DillInput
                formik={formik}
                name="lastName"
                label={"Nom"}
                placeholder={""}
              />
              <DillInput
                formik={formik}
                name="PostName"
                label={"Post-nom"}
                placeholder={""}
              />
              <DillInput
                formik={formik}
                name="firstName"
                label={"Prénom"}
                placeholder={""}
              />
              <DillSelect
                formik={formik}
                name="sex"
                list={[
                  { value: "", title: "Choir le genre" },
                  { value: "f", title: "Femme" },
                  { value: "m", title: "Homme" },
                ]}
                label="Genre"
              />
              <Divider style={{ margin: "0.8rem 0" }} />
              <DillInput
                formik={formik}
                name="phoneNumber"
                label={"Téléphone"}
                placeholder={""}
                type="tel"
              />
              <DillInput
                formik={formik}
                name="email"
                label={"E-mail"}
                placeholder={""}
                type="email"
              />
              <Divider style={{ margin: "0.8rem 0" }} />
              <DillInput
                formik={formik}
                name="bornDate"
                label={"Date de naissance"}
                placeholder={""}
                type="date"
              />
              <DillInput
                formik={formik}
                name="bornPlace"
                label={"Lieu de naissance"}
                placeholder={""}
                type="text"
              />
              <Divider style={{ margin: "0.8rem 0" }} />
              <Radio.Group
                value={type}
                options={[
                  { label: "Center", value: "center" },
                  { label: "Sous-division", value: "subDivision" },
                  { label: "Division", value: "division" },
                ]}
                onChange={(v) => setType(v.target.value)}
              />
              <Divider style={{ margin: "0.8rem 0" }} />
              {type === "center" && (
                <DillSelect
                  formik={formik}
                  name="center"
                  list={centers}
                  label="Centre de formation"
                  search
                  setSearch={setSearchCenter}
                  loading={searchingCenter}
                />
              )}
              {type === "subDivision" && (
                <DillSelect
                  formik={formik}
                  name="subDivision"
                  list={subDivisions}
                  label="Sous-division"
                  search
                  setSearch={setSearchSubDivision}
                  loading={searchingSubDivision}
                />
              )}
              {type === "division" && (
                <DillSelect
                  formik={formik}
                  name="division"
                  list={divisions}
                  label="Division"
                  search
                  setSearch={setSearchDivision}
                  loading={searchingDivision}
                />
              )}
              <div>
                {type !== "division" && (
                  <ElementCard
                    type="primary"
                    subtitle="Division"
                    title={
                      division.name ?? "------------------------------------"
                    }
                    icon=""
                  />
                )}
                {type === "center" && (
                  <ElementCard
                    type="gold"
                    subtitle="Sous-division"
                    title={
                      subDivision.name ?? "------------------------------------"
                    }
                    icon=""
                  />
                )}
              </div>
              <div
                className="row"
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "10px 0 0",
                }}
              >
                <Button
                  disabled={loading}
                  loading={loading}
                  onClick={() => formik.handleSubmit()}
                  type="primary"
                >{`Enregistrer`}</Button>
              </div>
            </div>
          </div>
        </section>
      </main>
    </React.Fragment>
  );
}
