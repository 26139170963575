import { useFormik } from "formik";
import React from "react";
import { IdentificationEtsSheet } from "./etsSheet/identification";
import { LocalisationEtsSheet } from "./etsSheet/localisation";
import { SectorEtsSheet } from "./etsSheet/sector";
import { notification as Notif, Typography } from "antd";
import { SheetHeader } from "../../components/sheetHeader";
import { addMeta } from "../../utils/helpers/title";
import centerPlugService from "../../services/centerPlugService";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import userHelper from "../../utils/helpers/userHelper";
import { isEmpty } from "lodash";
import { DillInput } from "../../components/input";
import { setDraftingSheet } from "../../redux/sheetReducer";
import draftHelper from "../../utils/helpers/draftHelper";

export function EstablishmentInformationSheet() {
  const [loading, setLoading] = React.useState(false);
  const formik = useFormik({
    initialValues: {
      identificationType: "", //new
      name: "",
      legalStatus: "", //new
      managementType: "", //new
      orderNumber: "", //new
      notification: "",
      matricule: "",
      settlementMunicipality: "", //commune d'implatation
      subDivision: "",
      leader: "",
      leaderWork: "",
      creationYear: "",

      street: "", //new
      village: "",
      locality: "",
      landmark: "", //new
      occupancy: "",
      neighboringEstablishments: "", //new

      quarter: "",
      commune: "",
      province: "",
      subProvince: "",

      sectors1: [],
      sectors2: [],
      sectorStats1: [],
      sectorStats2: [],
      year1: "",
      year2: "",
      phoneNumber: "",
    },
    onSubmit: onSave,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  async function onSave() {
    const {
      commune,
      creationYear,
      identificationType,
      landmark,
      leader,
      leaderWork,
      legalStatus,
      locality,
      managementType,
      matricule,
      name,
      neighboringEstablishments,
      notification,
      occupancy,
      orderNumber,
      province,
      quarter,
      sectorStats2,
      sectorStats1,
      sectors1,
      sectors2,
      settlementMunicipality,
      street,
      subDivision,
      subProvince,
      village,
      year1,
      year2,
      phoneNumber,
    } = formik.values;
    const data: any = {};
    data.province = province;
    data.subDivision = subDivision;
    data.registerNumber = matricule;
    data.name = name;
    data.plug = "establishment";
    data.type = "establishment";
    let metas: any[] = [];
    metas = addMeta(metas, "commune", commune, "string", true);
    metas = addMeta(metas, "phoneNumber", phoneNumber, "string", true);
    metas = addMeta(metas, "year1", year1, "string", true);
    metas = addMeta(metas, "year2", year2, "string", true);
    metas = addMeta(metas, "leader", leader, "string", true);
    metas = addMeta(metas, "subProvince", subProvince, "string", true);
    metas = addMeta(metas, "creationYear", creationYear, "numeric", true);
    metas = addMeta(
      metas,
      "identificationType",
      identificationType,
      "string",
      true
    );
    metas = addMeta(metas, "sectors1", JSON.stringify(sectors1), "json", true);
    metas = addMeta(metas, "sectors2", JSON.stringify(sectors2), "json", true);
    metas = addMeta(
      metas,
      "sectorStats1",
      JSON.stringify(sectorStats1),
      "json",
      true
    );
    metas = addMeta(
      metas,
      "sectorStats2",
      JSON.stringify(sectorStats2),
      "json",
      true
    );
    metas = addMeta(metas, "landmark", landmark, "string", true);
    metas = addMeta(metas, "leaderWork", leaderWork, "string", true);
    metas = addMeta(metas, "legalStatus", legalStatus, "string", true);
    metas = addMeta(metas, "locality", locality, "string", true);
    metas = addMeta(metas, "managementType", managementType, "string", true);
    metas = addMeta(
      metas,
      "neighboringEstablishments",
      neighboringEstablishments,
      "string",
      true
    );
    metas = addMeta(metas, "notification", notification, "string", true);
    metas = addMeta(metas, "occupancy", occupancy, "string", true);
    metas = addMeta(metas, "orderNumber", orderNumber, "string", true);
    metas = addMeta(metas, "province", province, "string", true);
    metas = addMeta(metas, "quarter", quarter, "string", true);
    metas = addMeta(
      metas,
      "settlementMunicipality",
      settlementMunicipality,
      "string",
      true
    );
    metas = addMeta(metas, "street", street, "string", true);
    metas = addMeta(metas, "village", village, "string", true);
    data.centerPlugMetas = metas;
    setLoading(true);
    await centerPlugService
      .store(data)
      .then(async (response) => {
        await userHelper.getPlugs(dispatch);
        dispatch(setDraftingSheet(false));
        draftHelper.remove();
        Notif.info({
          message: "Enregistrement de fiche réussi",
        });
        formik.resetForm();
        navigate(`/admin/plug/${response.data.id ?? ""}`);
      })
      .catch((reason) => {
        const error = centerPlugService.getError(reason);
        Notif.error({ message: "Erreur", description: error });
      });
    setLoading(false);
  }

  function handleSave() {
    formik.submitForm();
    let hasError = false;
    const errors: any = { ...formik.errors };
    for (const key in errors) {
      const element = errors[key] as any;
      if (!isEmpty(element)) {
        hasError = true;
      }
    }
    if (hasError) {
      Notif.warning({
        message: "Création de fiche",
        description: "Quelques champs ne sont pas rempli correctement",
      });
    }
  }

  return (
    <React.Fragment>
      <SheetHeader
        formik={formik}
        title=""
        type="establishment"
        loading={loading}
        onPublish={handleSave}
      />
      <main className="main main yc_container_main">
        <section
          className="container"
          style={{ paddingTop: 10, paddingBottom: 15 }}
        >
          <div className="col-md-">
            <Typography.Text>{`Année de formation`}</Typography.Text>
          </div>
          <div className="col">
            <div className="row">
              <div className="col">
                <DillInput formik={formik} name="year1" label="" />
              </div>
              <div className="col">
                <DillInput formik={formik} name="year2" label="" />
              </div>
            </div>
          </div>
          <DillInput
            formik={formik}
            type="tel"
            name="phoneNumber"
            label="Numéro de téléphone"
          />
          <IdentificationEtsSheet formik={formik} />
          <LocalisationEtsSheet formik={formik} />
          <SectorEtsSheet formik={formik} />
        </section>
      </main>
    </React.Fragment>
  );
}
