import { Card, Divider, Typography } from "antd";
import React from "react";
import {
  DillCheckbox,
  DillInput,
  DillRadio,
  DillSelect,
  DillTextarea,
} from "../../../components/input";
import { centerLanguages } from "../../../utils/helpers/title";
import { getProvinces } from "../../../utils/helpers/geoHelper";

export const MovementIdentification: React.FC<{ formik: any }> = ({
  formik,
}) => {
  return (
    <React.Fragment>
      <Divider style={{ margin: 5 }} />
      <div className="row">
        <div className="col">
          <DillInput
            formik={formik}
            name="permanentNumber"
            label="NUMERO PERMANENT"
          />
        </div>
        <div className="col">
          <DillInput
            formik={formik}
            name="IdentificationDate"
            type="date"
            label="DATE D’IDENTIFICATION"
          />
        </div>
      </div>
      <Typography.Title
        level={2}
        style={{ fontSize: 20, margin: 5 }}
      >{`I. IDENTIFICATION `}</Typography.Title>
      <div className="row">
        <div className="col">
          <DillInput
            formik={formik}
            name="identificationNumber"
            label="NUMERO D’IDENTIFICATION"
          />
          <div className="row">
            <div className="col-md-7">
              <DillInput
                formik={formik}
                name="year1"
                inline
                label="Année scolaire"
              />
            </div>
            <div className="col-md-5">
              <DillInput formik={formik} name="year2" inline label="-" />
            </div>
          </div>
        </div>
        <div className="col">
          <DillInput formik={formik} name="c" inline label="C" />
          <DillInput formik={formik} name="e" inline label="E" />
        </div>
      </div>
      <Card hoverable className="card">
        <div className="row">
          <div className="col-md-6">
            <DillInput formik={formik} name="lastName" label="NOM" />
            <DillInput formik={formik} name="postName" label="POST-NOM" />
            <DillInput formik={formik} name="firstName" label="PRENOM" />
            <DillRadio
              formik={formik}
              list={[
                { value: "f", title: "FEMME" },
                { value: "m", title: "HOMME" },
              ]}
              inline
              label="SEXE"
              name="sex"
            />
            <DillInput
              formik={formik}
              name="bornPlace"
              label="LIEU DE NAISSANCE"
            />
            <DillInput
              formik={formik}
              name="birthDate"
              type="date"
              label="DATE DE NAISSANCE"
            />
            <DillInput formik={formik} name="nationality" label="NATIONALITE" />
            <DillInput formik={formik} name="fatherName" label="NOM DU PERE" />
            <DillInput formik={formik} name="fatherWork" label="PROFESSION" />
            <DillInput
              formik={formik}
              name="montherName"
              label="NOM DE LA MERE"
            />
            <DillInput formik={formik} name="montherWork" label="PROFESSION" />
            <DillTextarea
              formik={formik}
              name="parentAddress"
              label="ADRESSE DES PARENTS"
            />
            <DillSelect
              formik={formik}
              name="province"
              label="PROVINCE D’ORIGINE"
              list={[
                { value: "", title: "Séléctionner la province" },
                ...getProvinces(),
              ]}
            />
            <DillInput
              formik={formik}
              name="district"
              label="DISTRICT D’ORIGINE"
            />
            <DillInput
              formik={formik}
              name="territory"
              label="TERRITOIRE D’ORIGINE"
            />
            <DillInput
              formik={formik}
              name="locality"
              label="SECTEUR D’ORIGINE"
            />
            <DillInput
              formik={formik}
              name="village"
              label="VILLAGE D’ORIGINE"
            />
          </div>
          <div className="col-md-6">
            <DillCheckbox
              formik={formik}
              list={centerLanguages}
              name="languages"
              label="LANGUES PARLEES"
              enabled
            />
            <DillTextarea
              formik={formik}
              name="oldAddress"
              label="ADRESSE DE L’ELEVE"
            />
            <DillInput formik={formik} name="oldTutorName" label="TUTEUR" />
            <DillInput formik={formik} name="oldTutorPhone" label="N° Tel" />
            <DillInput formik={formik} name="oldTutorWork" label="PROFESSION" />
            <DillTextarea
              formik={formik}
              name="oldTutorAddress"
              label="ADRESSE"
            />
          </div>
        </div>
        <Typography.Title
          level={3}
          style={{ fontSize: 20, margin: 5, textAlign: "center" }}
        >{`AUTRES INFORMATIONS SUR L’APPRENANT `}</Typography.Title>
        <DillInput inline formik={formik} name="health" label="Etat de santé" />
        <DillInput
          inline
          formik={formik}
          name="skills"
          label="Aptitudes intellectuelles"
        />
        <DillInput inline formik={formik} name="interests" label="Intérêts" />
      </Card>
    </React.Fragment>
  );
};
