import React from "react";
import { Status, Wrapper } from "@googlemaps/react-wrapper";
import { mapKey, mapMarker } from "../utils/helpers/title";
import { colorPrimary } from "../utils/themes/colors";
import { isEmpty } from "lodash";

const AnyReactComponent: React.FC<{ text: any }> = ({ text }) => (
  <div>{text}</div>
);

export const DivisionMap: React.FC<{
  markers?: mapMarker[];
  zoom?: number;
  center?: google.maps.LatLngLiteral;
}> = ({ markers, zoom, center }) => {
  function renderView(status: Status) {
    switch (status) {
      case Status.SUCCESS:
        return <div></div>;
      case Status.LOADING:
        return <div></div>;
      case Status.FAILURE:
        return <div></div>;
    }
  }

  return (
    <React.Fragment>
      <section className="container">
        <div
          className="map--division"
          style={{
            height: "75vh",
            width: "100%",
            background: `${colorPrimary}44`,
          }}
        >
          <Wrapper apiKey={mapKey} render={renderView}>
            <MyMapComponent
              center={
                isEmpty(center)
                  ? { lat: -4.397, lng: 15.644 }
                  : { lat: center.lat, lng: center.lng }
              }
              zoom={zoom ? zoom : 10}
              markers={markers}
            />
          </Wrapper>
        </div>
      </section>
    </React.Fragment>
  );
};

function MyMapComponent({
  center,
  zoom,
  markers,
}: {
  center: google.maps.LatLngLiteral;
  zoom: number;
  markers?: mapMarker[];
}) {
  const ref = React.useRef();

  React.useEffect(() => {
    /**@ts-ignore */
    const map = new window.google.maps.Map(ref.current, {
      center,
      zoom,
    });
    if (!markers) {
      new window.google.maps.Marker({
        position: center,
        map,
        title: "",
        clickable: true,
        //animation: window.google.maps.Animation.DROP,
      });
    }
    markers?.map((p) => {
      new window.google.maps.Marker({
        position: p.position,
        map,
        title: p.title,
        //animation: window.google.maps.Animation.DROP,
      });
    });
  }, []);

  /**@ts-ignore */
  return <div ref={ref} id="map" className="map" />;
}
