import React from "react";
import { DillInput, DillSelect } from "../../components/input";
import { Divider } from "antd";
import { CenterCategories } from "../../utils/helpers/title";

export const CreateLocationScreen: React.FC<{ formik: any }> = ({ formik }) => {
  return (
    <React.Fragment>
      <DillInput
        type="num"
        name="longitude"
        label="Longitude"
        formik={formik}
      />
      <DillInput type="num" name="latitude" label="Lattitude" formik={formik} />
      <Divider style={{ margin: 5 }} />
      <DillSelect
        label="Type de milieu"
        list={[
          { value: "", title: "Séléctionner le type de milieu" },
          { value: "rural", title: "Rural" },
          { value: "urban", title: "Urbaine" },
        ]}
        formik={formik}
        name="environment"
      />
      <DillSelect
        formik={formik}
        name="occupancy"
        label="Statut d'occupation"
        list={[
          { value: "", title: "Séléctionner le statut d'occupation" },
          { value: "owner", title: "Propritaire" },
          { value: "lodger", title: "Locataire" },
        ]}
      />
      <DillSelect
        formik={formik}
        name="type"
        label="Catégorie de l'établissement"
        list={CenterCategories}
      />
      {formik?.values?.type === "cam" && (
        <DillSelect
          formik={formik}
          label="Sous-catégorie de l'établissement"
          name="subType"
          list={[
            { value: "", title: "Séléctionner la sous-catégorie" },
            {
              value: "formal",
              title: "Les établissements dans le secteur formel",
            },
            {
              value: "informal",
              title: "Les établissements dans le secteur informel",
            },
          ]}
        />
      )}
    </React.Fragment>
  );
};
