import React from "react";
import { MovementIdentification } from "./movement/identification";
import { useFormik } from "formik";
import { MovementAutorisation } from "./movement/autorisation";
import { MovementStuation } from "./movement/situation";
import { MovementCarrer } from "./movement/carrer";
import { Button, notification } from "antd";
import { SheetHeader } from "../../components/sheetHeader";
import { addMeta } from "../../utils/helpers/title";
import learnerPlugService from "../../services/learnerPlugService";
import { setDraftingSheet } from "../../redux/sheetReducer";
import draftHelper from "../../utils/helpers/draftHelper";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";

export function LearnerMovementSheet() {
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      etsProvince: "",
      registerNumber: "",
      identificationNumber: "",
      level: "",
      c: "",
      e: "",
      lastName: "",
      firstName: "",
      postName: "",
      sex: "",
      birthDate: "",
      bornPlace: "",
      nationality: "",
      fatherName: "",
      fatherWork: "",
      montherName: "",
      montherWork: "",
      parentAddress: "",

      address: "",
      languages: [],
      tutorName: "",
      tutorPhone: "",
      tutorWork: "",
      tutorAddress: "",

      province: "",
      district: "",
      territory: "",
      locality: "",
      village: "",

      health: "",
      skills: "",
      interests: "",

      motif: "",

      division: "",
      newProvinceCode: "",
      subDivision: "",
      center: "",
      option: "",
      classe: "",
      permanentNumber: "",
      IdentificationDate: "",

      oldTutorName: "",
      oldTutorPhone: "",
      oldTutorWork: "",
      oldTutorAddress: "",
      oldAddress: "",
      reserve: "",
      states: [],

      year1: "",
      year2: "",
    },
    onSubmit: onSave,
  });
  async function onSave() {
    const {
      address,
      birthDate,
      bornPlace,
      c,
      center,
      classe,
      district,
      division,
      e,
      etsProvince,
      fatherName,
      fatherWork,
      firstName,
      health,
      IdentificationDate,
      identificationNumber,
      interests,
      languages,
      lastName,
      level,
      locality,
      montherName,
      montherWork,
      motif,
      nationality,
      newProvinceCode,
      oldAddress,
      oldTutorAddress,
      oldTutorName,
      oldTutorPhone,
      oldTutorWork,
      option,
      parentAddress,
      permanentNumber,
      postName,
      province,
      registerNumber,
      reserve,
      sex,
      skills,
      states,
      subDivision,
      territory,
      tutorAddress,
      tutorName,
      tutorPhone,
      tutorWork,
      village,
      year1,
      year2,
    } = formik.values;
    const data: any = {
      firstName,
      postName,
      lastName,
      birthDate,
      birthPlace: bornPlace,
      address,
      plug: "movement",
    };
    let metas: any[] = [];
    metas = addMeta(metas, "languages", JSON.stringify(languages), "json");
    metas = addMeta(metas, "states", JSON.stringify(states), "json");
    metas = addMeta(metas, "center", center, "string");
    metas = addMeta(metas, "c", c, "string");
    metas = addMeta(metas, "e", e, "string");
    metas = addMeta(metas, "classe", classe, "string");
    metas = addMeta(metas, "district", district, "string");
    metas = addMeta(metas, "division", division, "string");
    metas = addMeta(metas, "etsProvince", etsProvince, "string");
    metas = addMeta(metas, "fatherName", fatherName, "string");
    metas = addMeta(metas, "fatherWork", fatherWork, "string");
    metas = addMeta(metas, "health", health, "string");
    metas = addMeta(
      metas,
      "identificationNumber",
      identificationNumber,
      "string"
    );
    metas = addMeta(metas, "interests", interests, "string");
    metas = addMeta(metas, "level", level, "string");
    metas = addMeta(metas, "locality", locality, "string");
    metas = addMeta(metas, "montherName", montherName, "string");
    metas = addMeta(metas, "montherWork", montherWork, "string");
    metas = addMeta(metas, "motif", motif, "string");
    metas = addMeta(metas, "nationality", nationality, "string");
    metas = addMeta(metas, "newProvinceCode", newProvinceCode, "string");
    metas = addMeta(metas, "oldAddress", oldAddress, "string");
    metas = addMeta(metas, "oldTutorAddress", oldTutorAddress, "string");
    metas = addMeta(metas, "oldTutorName", oldTutorName, "string");
    metas = addMeta(metas, "oldTutorPhone", oldTutorPhone, "string");
    metas = addMeta(metas, "oldTutorWork", oldTutorWork, "string");
    metas = addMeta(metas, "option", option, "string");
    metas = addMeta(metas, "parentAddress", parentAddress, "string");
    metas = addMeta(metas, "province", province, "string");
    metas = addMeta(metas, "registerNumber", registerNumber, "string");
    metas = addMeta(metas, "sex", sex, "string");
    metas = addMeta(metas, "skills", skills, "string");
    metas = addMeta(metas, "subDivision", subDivision, "string");
    metas = addMeta(metas, "territory", territory, "string");
    metas = addMeta(metas, "tutorAddress", tutorAddress, "string");
    metas = addMeta(metas, "tutorName", tutorName, "string");
    metas = addMeta(metas, "tutorPhone", tutorPhone, "string");
    metas = addMeta(metas, "tutorWork", tutorWork, "string");
    metas = addMeta(metas, "village", village, "string");
    metas = addMeta(metas, "year1", year1, "string");
    metas = addMeta(metas, "year2", year2, "string");
    metas = addMeta(metas, "reserve", reserve, "string");
    metas = addMeta(metas, "permanentNumber", permanentNumber, "string");
    metas = addMeta(metas, "IdentificationDate", IdentificationDate, "string");
    data.learnerPlugMetas = metas;
    setLoading(true);
    await learnerPlugService
      .store(data)
      .then((response) => {
        dispatch(setDraftingSheet(false));
        draftHelper.remove();
        notification.info({
          message: "Enregistrement de fiche réussi",
        });
        formik.resetForm();
        navigate(`/admin/plug/${response.data.id ?? ""}/learner`);
      })
      .catch((reason) => {
        const error = learnerPlugService.getError(reason);
        notification.error({
          message: "Enregistrement de fiche",
          description: error,
        });
      });
    setLoading(false);
  }

  function handleSave() {
    formik.submitForm();
    let hasError = false;
    const errors: any = { ...formik.errors };
    for (const key in errors) {
      const element = errors[key] as any;
      if (!isEmpty(element)) {
        hasError = true;
      }
    }
    if (hasError) {
      notification.warning({
        message: "Création de fiche",
        description: "Quelques champs ne sont pas rempli correctement",
      });
    }
  }

  return (
    <React.Fragment>
      <SheetHeader
        formik={formik}
        title=""
        type="movement"
        loading={loading}
        onPublish={handleSave}
      />
      <main className="main main yc_container_main">
        <section className="container">
          <MovementIdentification formik={formik} />
          <MovementAutorisation formik={formik} />
          <MovementStuation formik={formik} />
          <MovementCarrer formik={formik} />
        </section>
      </main>
    </React.Fragment>
  );
}
