import { Card, Divider, Typography } from "antd";
import React from "react";
import { DillInput, DillRadio, DillSelect } from "../../../components/input";
import { centerRegimes, fermedResponses } from "../../../utils/helpers/title";

export const BuildingIdentity: React.FC<{ formik: any }> = ({ formik }) => {
  return (
    <React.Fragment>
      <Divider />
      <Typography.Title
        level={2}
        style={{ fontSize: 20, margin: 0 }}
      >{`I. IDENTIFICATION DE L’ETABLISSEMENT `}</Typography.Title>
      <Card hoverable className="card">
        <div className="row" style={{ alignItems: "center" }}>
          <div className="col">
            <DillInput
              formik={formik}
              label="Dénomination du centre"
              name="name"
            />
          </div>
          <div className="col">
            <DillInput formik={formik} label="Niveau" name="level" />
          </div>
        </div>
        <DillInput
          formik={formik}
          label="Numéro SENACEPEF"
          inline
          name="SENACEPEF"
        />
        <Typography.Text type="success" strong>{`Adresse`}</Typography.Text>
        <div className="row">
          <div className="col-md-1">
            <DillInput
              formik={formik}
              label="N°"
              name="streetNumber"
              type="num"
            />
          </div>
          <div className="col">
            <DillInput formik={formik} label="Avenue" name="street" />
          </div>
          <div className="col">
            <DillInput formik={formik} label="Quartier" name="quarter" />
          </div>
          <div className="col">
            <DillInput formik={formik} label="Commune" name="commune" />
          </div>
        </div>
        <div className="row" style={{ alignItems: "center" }}>
          <div className="col">
            <DillSelect
              list={centerRegimes}
              formik={formik}
              label="Régime de gestion "
              name="regime"
            />
          </div>
          <div className="col">
            <DillInput
              formik={formik}
              type="tel"
              label="N° téléphonique"
              name="phoneNumber"
            />
          </div>
          <div className="col">
            <DillRadio
              list={fermedResponses}
              formik={formik}
              label="Agrée"
              name="approved"
            />
          </div>
        </div>
        <Typography.Text
          type="success"
          strong
        >{`Identité Chef d’Etablissement`}</Typography.Text>
        <DillInput
          inline
          formik={formik}
          label="Nom & Post-nom"
          name="leader"
        />
        <div className="row" style={{ alignItems: "center" }}>
          <div className="col">
            <DillInput
              formik={formik}
              label="Matr. Id. SENACEPEF"
              name="leaderSENACEPEF"
            />
          </div>
          <div className="col">
            <DillInput
              formik={formik}
              type="tel"
              label="N° Téléphone"
              name="leaderPhone"
            />
          </div>
        </div>
        <div className="row" style={{ alignItems: "center" }}>
          <div className="col">
            <DillSelect
              list={[
                { value: "", title: "Séléctionner le statut" },
                { value: "Locataire", title: "Locataire" },
                { value: "Colocataire", title: "Colocataire" },
                { value: "Propriétaire", title: "Propriétaire" },
                { value: "Copropriétaire", title: "Copropriétaire" },
              ]}
              formik={formik}
              label="Statut du centre "
              name="occupacy"
            />
          </div>
          <div className="col">
            <DillInput
              formik={formik}
              label=" N° du certificat  d’enregistrement "
              name="registrationCertificateNumber"
            />
          </div>
        </div>
        <Typography.Text
          type="success"
          strong
        >{`Structure du centre`}</Typography.Text>
        <div className="row">
          <div className="col">
            <DillInput
              formik={formik}
              label="Nombre de classe organisée"
              name="organizedClassCount"
              type="num"
            />
          </div>
          <div className="col">
            <DillInput
              formik={formik}
              label="Nombre de classe autorisée"
              name="allowedClassCount"
              type="num"
            />
          </div>
          <div className="col">
            <DillInput
              formik={formik}
              label="Nombre de section"
              name="sectionCount"
              type="num"
            />
          </div>
        </div>
        <Typography.Text
          type="success"
          strong
        >{`Population scolaire `}</Typography.Text>
        <div className="row">
          <div className="col">
            <DillInput
              formik={formik}
              label="Garçons"
              name="boyCount"
              type="num"
            />
          </div>
          <div className="col">
            <DillInput
              formik={formik}
              label="Filles"
              name="girlCount"
              type="num"
            />
          </div>
        </div>
      </Card>
      <DillRadio
        inline
        list={fermedResponses}
        formik={formik}
        label="Construction de salles de classe en dur"
        name="hardClassConstruction"
      />
      <DillRadio
        inline
        list={[
          { value: "Dangereux", title: "Dangereux" },
          { value: "Inapproprié", title: "Inapproprié" },
          { value: "Terrain marécageux", title: "Terrain marécageux" },
          { value: "Normal", title: "Normal" },
        ]}
        formik={formik}
        label="Ecole implantée dans le site "
        name="schoolLocationState"
      />
      <DillRadio
        inline
        list={fermedResponses}
        formik={formik}
        label="Accessibilité"
        name="schoolAccecibility"
      />
    </React.Fragment>
  );
};
