import { routeInterface } from "../routes";
import { DivisionScreen } from "../screens/divisions";
import { DivisionView } from "../screens/division/view";
import { CreateDivisionScreen } from "../screens/division/create";
import { SectorViewScreen } from "../screens/sector/view";
export const divisionRoutes: routeInterface[] = [
  {
    component: DivisionScreen,
    icon: "fa-sharp fa-solid fa-location-dot",
    isMenu: true,
    label: "Provinces éducationnelles",
    layout: "/admin",
    name: "divisions",
    path: "/divisions",
    subscribeable: false,
  },
  {
    component: DivisionView,
    icon: "",
    isMenu: false,
    label: "Division",
    layout: "/admin",
    name: "division",
    path: "/division/:id",
    subscribeable: false,
  },
  {
    component: CreateDivisionScreen,
    icon: "fa-solid fa-location-plus",
    isMenu: false,
    label: "Ajouter une division",
    layout: "/admin",
    name: "create_division",
    path: "/division/create",
    subscribeable: false,
  },
  {
    component: SectorViewScreen,
    icon: "fa-solid fa-location-plus",
    isMenu: false,
    label: "",
    layout: "/admin",
    name: "sector_view",
    path: "/sectors/:id/view",
    subscribeable: false,
  },
];
