import { Button, Modal, Typography } from "antd";
import React from "react";
import { FormikProps } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { isEmpty } from "lodash";
import draftHelper from "../utils/helpers/draftHelper";
import {
  setDraftingSheet,
  setOpenSheet,
  setSelectedSheet,
} from "../redux/sheetReducer";
import { sheetType } from "../utils/helpers/title";

export const SheetHeader: React.FC<{
  onPublish?: (item: any) => void;
  title?: string;
  onClose?: () => void;
  type: sheetType;
  formik: FormikProps<any>;
  loading?: boolean;
}> = ({ onPublish, formik, title, type, loading, onClose }) => {
  const sheet = useSelector((state: RootState) => state.sheet.selected);
  const drafting = useSelector((state: RootState) => state.sheet.drafting);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!isEmpty(formik?.values) && drafting) {
      draftHelper.setData(formik?.values ?? {}, type);
    } else {
      load();
    }
  }, [formik?.values]);

  function load() {
    const element = draftHelper.getData(type);
    if (!isEmpty(element)) {
      formik.setValues(element);
      /**@ts-ignore */
      formik.setValues(element).then(() => {
        setTimeout(() => {
          dispatch(setDraftingSheet(true));
        }, 1500);
      });
    } else {
      setTimeout(() => {
        dispatch(setDraftingSheet(true));
      }, 2000);
    }
  }

  React.useEffect(() => {}, []);

  function handleClose() {
    let message =
      "Vous vous appretez à perdre toutes les modifications apportées jusque là.";
    /*if (isEmpty(updateId)) {
    } else {
    }*/
    Modal.warning({
      title: "Abondonner les modifications apportées",
      content: message,
      cancelText: "Annuler",
      okText: "Continuer",
      okCancel: true,
      okType: "danger",
      okButtonProps: { type: "primary" },
      centered: true,
      cancelButtonProps: { type: "primary" },
      onCancel: () => {},
      onOk: () => {
        draftHelper.remove();
        dispatch(setDraftingSheet(false));
        dispatch(setSelectedSheet({}));
        dispatch(setOpenSheet(true));
      },
    });
  }

  return (
    <React.Fragment>
      <div className="yc_container_main-header">
        <div className="content">
          <div className="row" style={{ alignItems: "center" }}>
            <div>
              <Button
                disabled={loading}
                onClick={handleClose}
                type="link"
                danger
              >
                <i
                  style={{ fontSize: 20 }}
                  className="fa-solid fa-chevron-left"
                ></i>
              </Button>
            </div>
            <div>
              <Typography.Title
                style={{ fontSize: 18, margin: 0, padding: "0 10px" }}
              >
                {sheet.title}
              </Typography.Title>
            </div>
            <div className="flex--1"></div>
            <div>
              <Button
                //disabled={!formik?.isValid || loading}
                disabled={loading}
                loading={loading}
                onClick={onPublish}
                type="default"
              >{`Publier`}</Button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
