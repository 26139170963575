import { useFormik } from "formik";
import React from "react";
import { RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, toString } from "lodash";
import divisionService from "../../services/divisionService";
import subDivisionService from "../../services/subDivisionService";
import centerService from "../../services/centerService";
import { Card, Typography, notification } from "antd";
import { DillInput, DillSelect } from "../../components/input";
import { EffectifSector } from "../center/sheet/table";
import { SheetHeader } from "../../components/sheetHeader";
import { getProvinces } from "../../utils/helpers/geoHelper";
import { Spreadsheet } from "../../components/table/items";
import { addMeta } from "../../utils/helpers/title";
import centerPlugService from "../../services/centerPlugService";
import userHelper from "../../utils/helpers/userHelper";
import { setDraftingSheet } from "../../redux/sheetReducer";
import draftHelper from "../../utils/helpers/draftHelper";
import { useNavigate } from "react-router-dom";

export function LearnerSheet() {
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      province: "",
      division: "",
      subDivision: "",
      school: "",
      center: "",
      learners: [],
      year1: "20",
      year2: "20",
      address: "",
      network: "",
      cycle: "",
    },
    onSubmit: onSave,
  });

  async function onSave() {
    const {
      center,
      division,
      learners,
      province,
      school,
      subDivision,
      address,
      cycle,
      network,
      year1,
      year2,
    } = formik.values;
    const data: any = {
      name: center,
      address,
      subDivision,
      plug: "register",
      province,
      type: "register",
    };
    let metas: any[] = [];
    metas = addMeta(metas, "division", division, "string", true);
    metas = addMeta(metas, "learners", JSON.stringify(learners), "json", true);
    metas = addMeta(metas, "school", school, "string", true);
    metas = addMeta(metas, "network", network, "string", true);
    metas = addMeta(metas, "cycle", cycle, "string", true);
    metas = addMeta(metas, "year1", year1, "string", true);
    metas = addMeta(metas, "year2", year2, "string", true);
    data.centerPlugMetas = metas;
    setLoading(true);
    await centerPlugService
      .store(data)
      .then(async (response) => {
        await userHelper.getPlugs(dispatch);
        dispatch(setDraftingSheet(false));
        draftHelper.remove();
        notification.info({
          message: "Enregistrement de fiche réussi",
        });
        formik.resetForm();
        navigate(`/admin/plug/${response.data.id ?? ""}`);
      })
      .catch((reason) => {
        const error = centerPlugService.getError(reason);
        notification.error({ message: "Erreur", description: error });
      });
    setLoading(false);
  }

  function handleSave() {
    formik.submitForm();
    let hasError = false;
    const errors: any = { ...formik.errors };
    for (const key in errors) {
      const element = errors[key] as any;
      if (!isEmpty(element)) {
        hasError = true;
      }
    }
    if (hasError) {
      notification.warning({
        message: "Création de fiche",
        description: "Quelques champs ne sont pas rempli correctement",
      });
    }
  }

  return (
    <React.Fragment>
      <SheetHeader
        loading={loading}
        onPublish={handleSave}
        formik={formik}
        title=""
        type="register"
      />
      <main className="main main yc_container_main">
        <section className="container">
          <Card hoverable className="card">
            <div className="row">
              <div className="col">
                <DillSelect
                  list={[
                    { value: "", title: "Séléctionner la province" },
                    ...getProvinces(),
                  ]}
                  formik={formik}
                  name="province"
                  label="Province"
                />
              </div>
              <div className="col">
                <DillInput
                  //loading={searchingDivision}
                  //search
                  //setSearch={setSearchDivision}
                  formik={formik}
                  name="division"
                  label="Division"
                  //list={divisions}
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <DillInput
                  //loading={searchingSubDivision}
                  //search
                  //setSearch={setSearchSubDivision}
                  formik={formik}
                  name="subDivision"
                  label="Sous-division"
                  //list={subDivisions}
                />
              </div>
              <div className="col">
                <DillInput
                  //loading={searchingCenter}
                  //search
                  //setSearch={setSearchCenter}
                  formik={formik}
                  name="center"
                  label="Centre"
                  //list={centers}
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <DillInput inline formik={formik} name="school" label="Ecole" />
              </div>
              <div className="col">
                <DillInput
                  inline
                  formik={formik}
                  name="network"
                  label="Réseau"
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <DillInput inline formik={formik} name="cycle" label="Cycle" />
              </div>
              <div className="col">
                <DillInput
                  inline
                  formik={formik}
                  name="address"
                  label="Adresse"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <Typography.Title style={{ fontSize: 14, margin: "0 2px" }}>
                  {`Année de formation`}
                </Typography.Title>
                <div className="row">
                  <div className="col">
                    <DillInput inline formik={formik} name="year1" label="" />
                  </div>
                  <div className="col">
                    <DillInput inline formik={formik} name="year2" label="" />
                  </div>
                </div>
              </div>
            </div>
          </Card>
          <Card
            className="card"
            hoverable
            style={{ margin: "20px 0", padding: "0" }}
          >
            <Spreadsheet
              formik={formik}
              name="learners"
              initItems={formik.values.learners}
              colums={[
                {
                  title: "N°",
                  dataIndex: "range",
                  className: "none-editable",
                  width: 50,
                },
                {
                  title: "N° PERMANENT",
                  dataIndex: "registerNumber",
                  width: 150,
                },
                {
                  title: "NOM, POSTNOM ET PRENOM",
                  dataIndex: "fullName",
                  width: 250,
                },
                { title: "SEXE", dataIndex: "sex" },
                { title: "NIVEAU", dataIndex: "level" },
                { title: "FILIERE", dataIndex: "sector" },
                { title: "%", dataIndex: "percent" },
                { title: "CENTRE DE PROVENANCE", dataIndex: "center" },
                { title: "ADRESSE", dataIndex: "address" },
                { title: "DOCUMENT DEPOSE", dataIndex: "doc" },
                { title: "RESULTAT D’ENQUETE", dataIndex: "result" },
              ]}
              counter
              genericText=""
              genericValue="range"
              tabProps={{
                scroll: { x: 1800, y: 1000 },
              }}
            />
          </Card>
        </section>
      </main>
    </React.Fragment>
  );
}
