import React from "react";
import { InboxOutlined } from "@ant-design/icons";
import type { UploadProps } from "antd";
import { message, Upload } from "antd";

const { Dragger } = Upload;

const props: UploadProps & { setFiles?: (v?: any) => void } = {
  name: "file",
  multiple: true,
  action: "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188",
  onChange(info) {
    const { status } = info.file;
    if (status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (status === "done") {
      //message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === "error") {
      //message.error(`${info.file.name} file upload failed.`);
    }
  },
  onDrop(e) {
    console.log("Dropped files", e.dataTransfer.files);
  },
};

export const MessageFile: React.FC<{
  files: any[];
  setFiles: (v?: any) => void;
}> = ({ files, setFiles }) => (
  <Dragger
    fileList={files}
    style={{ marginTop: 10, marginBottom: 10 }}
    multiple
    name="file"
    action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
    onChange={(info) => {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        //message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        //message.error(`${info.file.name} file upload failed.`);
      }
      setFiles(info.fileList);
    }}
  >
    <p className="ant-upload-drag-icon">
      <InboxOutlined />
    </p>
    <p className="ant-upload-text">{`Cliquez ou faites glisser le fichier vers cette zone pour le télécharger`}</p>
    <p className="ant-upload-hint">
      {`Prise en charge d'un téléchargement unique ou groupé. Il est strictement interdit de télécharger des données d'entreprise ou autres
      fichiers interdits.`}
    </p>
  </Dragger>
);
