import { isEmpty } from "lodash";
import React, { PropsWithChildren } from "react";
import { Link } from "react-router-dom";

export const ElementCard: React.FC<
  PropsWithChildren<{
    title: string;
    subtitle: string;
    icon: string;
    url?: string;
    stretch?: boolean;
    type?: "primary" | "danger" | "gold";
    small?: boolean;
  }>
> = ({ title, subtitle, icon, url, type, stretch, children, small }) => {
  const RenderComponent = () => {
    return (
      <div className={`content border border--${type ?? ""}`}>
        <div className="row">
          <div>
            <i className={`icon ${icon}`}></i>
          </div>
          <div className="flex--1 text">
            <span className={`title ${small ? "small" : ""}`}>{title}</span>
            <span className="subtitle">{subtitle}</span>
          </div>
          <div>{children}</div>
        </div>
      </div>
    );
  };
  return (
    <React.Fragment>
      {isEmpty(url) && (
        <div className="card--person">
          <RenderComponent />
        </div>
      )}
      {!isEmpty(url) && (
        <Link to={url ?? "#"} className="card--person">
          <RenderComponent />
        </Link>
      )}
    </React.Fragment>
  );
};
