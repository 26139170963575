import { AsYouType } from "libphonenumber-js";
export declare type axe = { x: number; y: number };
import provinceList from "../data/province.json";

export function getPhoneValue(number: string): AsYouType {
  const phone = new AsYouType();
  phone.input(number);
  return phone;
}

export function calculateCenter(coordinates: google.maps.LatLngLiteral[]) {
  let result: google.maps.LatLngLiteral = { lat: 0, lng: 0 };
  const count = coordinates.length;
  let x = 0;
  let y = 0;
  coordinates.forEach((p) => {
    x += p.lat;
    y += p.lng;
  });
  if (count > 0) {
    result = { lat: x / count, lng: y / count };
  }
  return result;
}

export function getProvinces() {
  let provinces: any[] = [];
  const prs = provinceList[0].features;
  prs.forEach((p) => {
    provinces.push({
      title: p.properties.NOM,
      value: p.properties.NOM,
    });
  });
  provinces = provinces.sort(
    (
      a: { title: string; value: string },
      b: { title: string; value: string }
    ) => a.title.localeCompare(b.title)
  );
  return provinces;
}
