import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import {
  PDFViewer,
  Document,
  Page,
  View,
  Text,
  Image,
  Font,
  StyleSheet,
} from "@react-pdf/renderer";
import { TableView } from "./table";
import CenterHelper from "../../utils/helpers/centerHelper";
import { centerMeta } from "../../utils/helpers/meta";
import { ItemTabView } from "./building/item";
import { Identification } from "./building/identification";
import { TablePdfView } from "../tablePDF";
import { isArray, isEmpty } from "lodash";
import dateHelpers from "../../utils/helpers/dateHelpers";

Font.register({
  family: "calibri-bold-italic",
  src: require("../../assets/fonts/calibri/Calibri Bold Italic.ttf"),
});
Font.register({
  family: "calibri-bold",
  src: require("../../assets/fonts/calibri/Calibri Bold.TTF"),
});
Font.register({
  family: "calibri-italic",
  src: require("../../assets/fonts/calibri/Calibri Italic.ttf"),
});
Font.register({
  family: "calibri-light-italic",
  src: require("../../assets/fonts/calibri/Calibri Light Italic.ttf"),
});
Font.register({
  family: "calibri-light",
  src: require("../../assets/fonts/calibri/Calibri Light.ttf"),
});
Font.register({
  family: "calibri",
  src: require("../../assets/fonts/calibri/Calibri Regular.ttf"),
});

const bgColor = "#dcdcdc";

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "#FFF",
    padding: "15px",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
    backgroundColor: "red",
    border: "solid 5px yellow",
  },
  title: {
    textTransform: "uppercase",
    fontSize: 11,
    padding: "10px 0",
    fontFamily: "calibri-bold",
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    width: "100%",
    borderBottom: "1px solid #dadada",
  },
  rowItem: {
    display: "flex",
    flex: 1,
    fontSize: "14px",
    padding: "8px 0",
    justifyContent: "center",
    alignItems: "center",
  },
  heading: {
    backgroundColor: "#ececec",
  },
  logoCountry: {
    fontFamily: "calibri-italic",
    fontSize: 11,
    textAlign: "center",
  },
  logoHeader: {
    fontFamily: "calibri-bold",
    fontSize: 11,
    textAlign: "center",
  },
  title1: {
    fontSize: 11,
    fontFamily: "calibri",
    borderBottom: "1px solid #000",
    backgroundColor: bgColor,
    textAlign: "center",
    minHeight: "auto",
    color: "#000",
    alignItems: "center",
    justifyContent: "center",
    padding: "1px",
  },
  title2: {
    fontSize: 11,
    fontFamily: "calibri",
    borderBottom: "1px solid #000",
    backgroundColor: bgColor,
    textAlign: "center",
    minHeight: "auto",
    color: "#000",
    //height:"auto",
    padding: "1px",
    flex: 1,
  },
  value1: {
    fontSize: 11,
    fontFamily: "calibri",
    padding: 1,
    minHeight: "15px",
    height: "auto",
    display: "flex",
    justifyContent: "center",
    color: "#000",
  },
  view1: {
    width: "15%",
    borderRight: "1px solid #000",
  },
  view2: {
    borderRight: "1px solid #000",
    height: "100%",
  },
});

export function BuildingPDF() {
  const plug = useSelector((state: RootState) => state.center.plug);
  const [center, setCenter] = React.useState<CenterHelper>(
    new CenterHelper({}, "centerPlugMetas")
  );
  const [list1, setList1] = React.useState<any[]>([]);
  const [list2, setList2] = React.useState<any[]>([]);
  const [date, setDate] = React.useState("");

  React.useEffect(() => {
    const c = new CenterHelper(plug, "centerPlugMetas");
    setCenter(c);
    let elements = c.getMetaValue("materialsReceived", false, plug);
    setList1(isEmpty(elements) ? [] : elements);
    elements = c.getMetaValue("equipmentAvailables", false, plug);
    setList2(isEmpty(elements) ? [] : elements);
    const dt = c.getMetaValue("materialReceivedDate", false, plug);
    if (!isEmpty(dt)) {
      setDate(dateHelpers.getDate(dt, "dddd DD MMMM YYYY"));
    }
  }, [plug]);

  return (
    <React.Fragment>
      <section className="container">
        <PDFViewer style={{ width: "100%", height: "100vh" }}>
          <Document author="DANTIC" creator="DEV">
            <Page size="A4" style={styles.page}>
              <Header />
              <Division
                division={plug.subDivision?.division?.name}
                subDivision={plug.subDivision?.name}
              />
              <Identification styles={styles} center={center} item={plug} />
              <StatePlaces center={center} item={plug} />
            </Page>
            <Page size="A4" style={styles.page}>
              <Text
                style={{
                  padding: "10px 50px 0",
                  fontFamily: "calibri-bold",
                  fontSize: 12,
                }}
              >
                {`III. ETAT DES LIEUX `}
              </Text>
              <View style={{ border: "1px solid #000", borderTop: "none" }}>
                <TablePdfView
                  columns={[
                    { title: "N°", dataIndex: "range" },
                    { title: "Désignation", dataIndex: "designation" },
                    { title: "Nbre", dataIndex: "count" },
                    { title: "Total", dataIndex: "total" },
                    { title: "Suffisant", dataIndex: "suffisant" },
                    { title: "Insuffisant", dataIndex: "insuffisant" },
                    { title: "Observation", dataIndex: "observation" },
                  ]}
                  list={list1}
                />
              </View>
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <Text
                  style={{
                    padding: "10px 50px 0",
                    fontFamily: "calibri-bold",
                    fontSize: 12,
                  }}
                >
                  {`IV.  MATERIELS RECUS`}
                </Text>
                <Text
                  style={{
                    padding: "10px 50px 0",
                    fontFamily: "calibri-bold",
                    fontSize: 12,
                  }}
                >
                  {`DATE : ${date}`}
                </Text>
                <Text
                  style={{
                    padding: "10px 50px 0",
                    fontFamily: "calibri-bold",
                    fontSize: 12,
                  }}
                >
                  {`SOURCE : ${center?.getMetaValue(
                    "materialReceivedSoucre",
                    false,
                    plug
                  )}`}
                </Text>
              </View>
              <View style={{ border: "1px solid #000", borderTop: "none" }}>
                <TablePdfView
                  columns={[
                    { title: "N°", dataIndex: "range" },
                    { title: "Désignation", dataIndex: "designation" },
                    { title: "Nombre", dataIndex: "count" },
                    { title: "Motif", dataIndex: "motif" },
                    { title: "Observation", dataIndex: "observation" },
                  ]}
                  list={list2}
                />
              </View>
            </Page>
          </Document>
        </PDFViewer>
      </section>
    </React.Fragment>
  );
}

const Division: React.FC<{ division: string; subDivision: string }> = ({
  division,
  subDivision,
}) => {
  return (
    <View style={{ border: "1px solid #000", width: "100%" }}>
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          borderBottom: "1px solid #000",
        }}
      >
        <View style={{ borderRight: "1px solid #000", width: "30%" }}>
          <Text style={{ fontSize: 11, fontFamily: "calibri", padding: 1 }}>
            {`Province Educationnelle`}
          </Text>
        </View>
        <View>
          <Text
            style={{ fontSize: 11, fontFamily: "calibri-bold", padding: 1 }}
          >
            {division}
          </Text>
        </View>
      </View>
      <View
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <View style={{ borderRight: "1px solid #000", width: "30%" }}>
          <Text style={{ fontSize: 11, fontFamily: "calibri", padding: 1 }}>
            {`Sous-Province Educationnelle`}
          </Text>
        </View>
        <View>
          <Text
            style={{ fontSize: 11, fontFamily: "calibri-bold", padding: 1 }}
          >
            {subDivision}
          </Text>
        </View>
      </View>
    </View>
  );
};

const Header = () => {
  return (
    <View
      style={{ display: "flex", flexDirection: "row", alignItems: "flex-end" }}
    >
      <View style={{ alignItems: "center" }}>
        <Text style={[styles.logoCountry]}>
          {`République Démocratique du Congo`}
        </Text>
        <Text style={[styles.logoHeader]}>{`Ministère de la Formation`}</Text>
        <Text style={[styles.logoHeader]}>{`Professionnelle et Métiers`}</Text>
        <Image
          src={require("../../assets/images/armoirie.png")}
          style={{ width: "50px", textAlign: "center" }}
        />
        <Text style={[styles.logoHeader]}>
          {`Direction Statistiques Scolaires`}
        </Text>
        <Text style={[styles.logoHeader]}>{`DSS`}</Text>
      </View>
      <View style={{ flex: 1 }}>
        <Text
          style={{
            textAlign: "center",
            fontSize: 18,
            fontFamily: "calibri-bold",
          }}
        >
          {`FICHE ACTUALISEE D’IDENTIFICATION DES`}
        </Text>
        <Text
          style={{
            textAlign: "center",
            fontSize: 18,
            fontFamily: "calibri-bold",
          }}
        >
          {`BATIMENTS DE FORMATION, ADMINISTRATIFS`}
        </Text>
        <Text
          style={{
            textAlign: "center",
            fontSize: 18,
            textDecoration: "underline",
            fontFamily: "calibri-bold",
          }}
        >
          {`ET PATRIMOINES DE L’ETAT`}
        </Text>
      </View>
    </View>
  );
};

const StatePlaces: React.FC<{ center: CenterHelper; item: any }> = ({
  center,
  item,
}) => {
  return (
    <View>
      <Text
        style={{
          padding: "10px 50px 0",
          fontFamily: "calibri-bold",
          fontSize: 12,
        }}
      >
        {`II. ETAT DES LIEUX `}
      </Text>
      <View style={{ border: "1px solid #000", width: "100%" }}>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            borderBottom: "1px solid #000",
          }}
        >
          <View
            style={{
              width: "70%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              borderRight: "1px solid #000",
            }}
          >
            <Text
              style={{
                fontSize: 11,
                fontFamily: "calibri",
                padding: 1,
                borderRight: "1px solid #000",
              }}
            >
              {`Centre `}
            </Text>
            <Text style={{ fontSize: 11, fontFamily: "calibri", padding: 1 }}>
              {center?.getMetaValue("constructionState", false, item)}
            </Text>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Text
              style={{ fontSize: 11, fontFamily: "calibri-bold", padding: 1 }}
            >
              {`Partenaire : `}
            </Text>
            <Text style={{ fontSize: 11, fontFamily: "calibri", padding: 1 }}>
              {center?.getMetaValue("constructionPartner", false, item)}
            </Text>
          </View>
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            borderBottom: "none",
          }}
        >
          <View
            style={{
              width: "50%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              borderRight: "1px solid #000",
            }}
          >
            <Text style={{ fontSize: 11, fontFamily: "calibri", padding: 1 }}>
              {`Entreprise :  `}
            </Text>
            <Text style={{ fontSize: 11, fontFamily: "calibri", padding: 1 }}>
              {center?.getMetaValue("constructionEnterprise", false, item)}
            </Text>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Text
              style={{ fontSize: 11, fontFamily: "calibri-bold", padding: 1 }}
            >
              {`Année : `}
            </Text>
            <Text style={{ fontSize: 11, fontFamily: "calibri", padding: 1 }}>
              {center?.getMetaValue("constructionYear", false, item)}
            </Text>
          </View>
        </View>
      </View>

      <View
        style={{
          marginTop: 10,
          border: "1px solid #000",
        }}
      >
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            borderBottom: "1px solid #000",
          }}
        >
          <View
            style={{
              borderRight: "1px solid #000",
              width: "15%",
              height: "100%",
            }}
          >
            <Text style={{ fontSize: 11, fontFamily: "calibri", padding: 1 }}>
              {`Superficie de la cour`}
            </Text>
          </View>
          <View
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              flex: 1,
              height: "100%",
            }}
          >
            <View
              style={{
                width: "20%",
                borderRight: "1px solid #000",
              }}
            >
              <Text style={styles.title1}>{`Scolaire (m²) `}</Text>
              <Text style={styles.value1}>
                {center?.getMetaValue("streetNumber", false, item)}
              </Text>
            </View>
            <View
              style={{
                width: "20%",
                borderRight: "1px solid #000",
              }}
            >
              <Text style={styles.title1}>{`Jeux (m²)`}</Text>
              <Text style={styles.value1}>
                {center?.getMetaValue("street", false, item)}
              </Text>
            </View>
            <View
              style={{
                width: "30%",
                borderRight: "1px solid #000",
              }}
            >
              <Text style={styles.title1}>{`Clôturé `}</Text>
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <Text
                  style={[
                    styles.value1,
                    { flex: 1, borderRight: "1px solid #000" },
                  ]}
                >
                  {center?.getMetaValue("fencedYardArea", false, item) !== "yes"
                    ? "Non"
                    : ""}
                </Text>
                <Text style={[styles.value1, { flex: 1 }]}>
                  {center?.getMetaValue("fencedYardArea", false, item) === "yes"
                    ? "Oui"
                    : ""}
                </Text>
              </View>
            </View>
            <View style={{ width: "30%" }}>
              <Text style={styles.title1}>{`Ecole sinistrée`}</Text>
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <Text
                  style={[
                    styles.value1,
                    { flex: 1, borderRight: "1px solid #000" },
                  ]}
                >
                  {center?.getMetaValue(
                    "affectedSchoolYardArea",
                    false,
                    item
                  ) !== "yes"
                    ? "Non"
                    : ""}
                </Text>
                <Text style={[styles.value1, { flex: 1 }]}>
                  {center?.getMetaValue(
                    "affectedSchoolYardArea",
                    false,
                    item
                  ) === "yes"
                    ? "Oui"
                    : ""}
                </Text>
              </View>
            </View>
          </View>
        </View>

        {/**Nombre de bâtiment */}
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "stretch",
            alignContent: "stretch",
            borderBottom: "1px solid #000",
          }}
        >
          <View
            style={{
              borderRight: "1px solid #000",
              width: "10%",
              height: "auto",
            }}
          >
            <Text style={{ fontSize: 11, fontFamily: "calibri", padding: 1 }}>
              {`Nombre de bâtiment`}
            </Text>
          </View>
          <View
            style={{
              display: "flex",
              alignItems: "stretch",
              flexDirection: "row",
              flex: 1,
              height: "100%",
            }}
          >
            <View style={[styles.view2, { width: "13.3%" }]}>
              <Text style={styles.title2}>{`Formation`}</Text>
              <Text style={styles.value1}>
                {center?.getMetaValue("streetNumber", false, item)}
              </Text>
            </View>
            <View style={[styles.view2, { width: "13.3%" }]}>
              <Text style={styles.title2}>{`Administratifs`}</Text>
              <Text style={styles.value1}>
                {center?.getMetaValue("streetNumber", false, item)}
              </Text>
            </View>
            <View style={[styles.view2, { width: "13.3%" }]}>
              <Text style={styles.title2}>{`Résidentiels `}</Text>
              <Text style={styles.value1}>
                {center?.getMetaValue("streetNumber", false, item)}
              </Text>
            </View>
            <View style={[styles.view2, { width: "30%" }]}>
              <Text
                style={[styles.title2, { flex: 1 }]}
              >{`Blocs sanitaires pour élèves`}</Text>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "stretch",
                  height: "auto",
                }}
              >
                <Text
                  style={[
                    styles.value1,
                    { flex: 1, borderRight: "1px solid #000" },
                  ]}
                >
                  {center?.getMetaValue("girlLatrineExist", false, item) ===
                  "yes"
                    ? "Oui"
                    : "Non"}
                </Text>
                <Text style={[styles.value1, { flex: 1 }]}>
                  {center?.getMetaValue("boyLatrineExist", false, item) ===
                  "yes"
                    ? "Oui"
                    : "Non"}
                </Text>
              </View>
            </View>
            <View style={[styles.view2, { width: "30%", borderRight: "none" }]}>
              <Text
                style={styles.title1}
              >{`Blocs sanitaires pour administratifs`}</Text>
              <View>
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <Text
                    style={[
                      styles.value1,
                      { flex: 1, borderRight: "1px solid #000" },
                    ]}
                  >
                    {center?.getMetaValue("womanLatrineExist", false, item) ===
                    "yes"
                      ? "Oui"
                      : "Non"}
                  </Text>
                  <Text style={[styles.value1, { flex: 1 }]}>
                    {center?.getMetaValue("manLatrineExist", false, item) ===
                    "yes"
                      ? "Oui"
                      : "Non"}
                  </Text>
                </View>
                {/*<Text style={[styles.value1,{flex:1,borderTop:"1px solid #000"}]}>
                {`Installations hygiéniques séparées`}
                  </Text>*/}
              </View>
            </View>
          </View>
        </View>

        {/**Nombre de locaux pour */}
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "stretch",
            alignContent: "stretch",
          }}
        >
          <View
            style={{
              borderRight: "1px solid #000",
              width: "15%",
              height: "auto",
            }}
          >
            <Text style={{ fontSize: 11, fontFamily: "calibri", padding: 1 }}>
              {`Nombre de locaux pour`}
            </Text>
          </View>
          <View
            style={{
              display: "flex",
              alignItems: "stretch",
              flexDirection: "row",
              flex: 1,
              height: "100%",
            }}
          >
            <View style={[styles.view2, { width: "15%" }]}>
              <Text style={[styles.title2]}>{`Bureaux `}</Text>
              <Text style={styles.value1}>
                {center?.getMetaValue("streetNumber", false, item)}
              </Text>
            </View>
            <View style={[styles.view2, { width: "15%" }]}>
              <Text style={styles.title2}>{`Salles de classe`}</Text>
              <Text style={styles.value1}>
                {center?.getMetaValue("street", false, item)}
              </Text>
            </View>
            <View style={[styles.view2, { width: "15%" }]}>
              <Text style={styles.title2}>{`Atelier ou labo`}</Text>
              <Text style={styles.value1}>
                {center?.getMetaValue("localLabCount", false, item)}
              </Text>
            </View>
            <View style={[styles.view2, { width: "15%" }]}>
              <Text style={styles.title1}>{`Clôture de l'école`}</Text>
              <Text style={styles.value1}>
                {center?.getMetaValue("schoolFence", false, item) === "yes"
                  ? "Oui"
                  : "Non"}
              </Text>
            </View>
            <View style={{ width: "40%" }}>
              <Text style={styles.value1}>{`Matériaux :`}</Text>
            </View>
          </View>
        </View>
        {/** */}
      </View>

      <View
        style={{
          marginTop: 10,
          border: "1px solid #000",
        }}
      >
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <View style={[styles.view2, { width: "15%" }]}>
            <Text
              style={[
                styles.title2,
                { backgroundColor: "#fff", padding: "7.5px 0" },
              ]}
            ></Text>
            <Text
              style={[styles.title1, { backgroundColor: "#fff" }]}
            >{`Point d'eau`}</Text>
            <Text
              style={[
                styles.title1,
                { backgroundColor: "#fff", borderBottom: "none" },
              ]}
            >{`Electricité`}</Text>
          </View>
          <View style={[styles.view2, { width: "15%" }]}>
            <Text
              style={[styles.title2, { backgroundColor: "#fff" }]}
            >{`Présence`}</Text>
            <Text
              style={[styles.title1, { backgroundColor: "#fff" }]}
            >{`Point d'eau`}</Text>
            <Text
              style={[
                styles.title1,
                { backgroundColor: "#fff", borderBottom: "none" },
              ]}
            >{`Electricité`}</Text>
          </View>
          <View style={[styles.view2, { width: "17%" }]}>
            <Text
              style={[styles.title2, { backgroundColor: "#fff" }]}
            >{`Source`}</Text>
            <Text
              style={[styles.title1, { backgroundColor: "#fff" }]}
            >{`Point d'eau`}</Text>
            <Text
              style={[
                styles.title1,
                { backgroundColor: "#fff", borderBottom: "none" },
              ]}
            >{`Electricité`}</Text>
          </View>
          <View style={[styles.view2, { width: "53%", borderRight: "none" }]}>
            <Text
              style={[
                styles.title2,
                { backgroundColor: "#fff", textAlign: "left" },
              ]}
            >
              {`Latrines construites par :`}
              {`     Année:`}
            </Text>
            <View
              style={{
                height: "auto",
                minHeight: "15px",
                flexDirection: "row",
              }}
            >
              <View style={{ flex: 1, borderRight: "1px solid #000" }}></View>
              <View style={{ flex: 1 }}></View>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};
