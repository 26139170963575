import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface bookData {
  current: any;
  list: any[];
  all: any[];
  profil?: any;
}

const initialState: bookData = {
  current: {},
  all: [],
  list: [],
  profil: {},
};

export const bookSlice = createSlice({
  name: "books",
  initialState,
  reducers: {
    setBook: (state, action: PayloadAction<any>) => {
      state.current = action.payload;
    },
    setBooks: (state, action: PayloadAction<any>) => {
      state.list = action.payload;
    },
    setAllBooks: (state, action: PayloadAction<any[]>) => {
      state.all = action.payload;
    },
  },
});
export const { setAllBooks, setBook, setBooks } = bookSlice.actions;
export default bookSlice.reducer;
