import { Button, Divider, notification, Radio } from "antd";
import { useFormik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { DillInput, DillPassword, DillSelect } from "../../components/input";
import { RootState } from "../../redux/store";
import * as yup from "yup";
import { isEmpty } from "lodash";
import userHelper from "../../utils/helpers/userHelper";
import { TitleComponent } from "../../components/title";
import { getUid } from "../../utils/helpers/title";
import centerService from "../../services/centerService";
import formerService from "../../services/formerService";
import dateHelpers from "../../utils/helpers/dateHelpers";
import { useLocation } from "react-router-dom";
import { ElementCard } from "../../components/element";

export function CreateFormerScreen() {
  const centerList = useSelector((state: RootState) => state.center.list);
  const [centers, setCenters] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [searchingCenter, setSearchingCenter] = React.useState(false);
  const [searchCenter, setSearchCenter] = React.useState("");
  const [updateId, setUpdateId] = React.useState("");
  const [division, setDivision] = React.useState<any>({});
  const [subDivision, setSubDivision] = React.useState<any>({});
  const route = useLocation();
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      postName: "",
      center: "",
      bornDate: "",
      graduationDate: "",
      commitmentDate: "",
      commitmentReference: "",
      sex: "",
      qualification: "",
      name: "",
    },
    onSubmit: onSave,
    validationSchema: yup.object({
      firstName: yup.string().required("Champ requis"),
      lastName: yup.string().required("Champ requis"),
      postName: yup.string().required("Champ requis"),
      bornDate: yup.string().required("Champ requis"),
      sex: yup.string().required("Champ requis"),
      center: yup.string().required("Champ requis"),
      qualification: yup.string().required("Champ requis"),
    }),
  });

  React.useEffect(() => {
    const list: any[] = [];
    centerList.forEach((p) => {
      list.push({ value: `${p.id ?? getUid(p["@id"])}`, title: p.name });
    });
    list.unshift({
      value: "",
      title: "Séléctionner l'établissement",
    });
    setCenters(list);
  }, [centerList]);

  React.useEffect(() => {
    const args = new URLSearchParams(route.search);
    const centerId = args.get("center");
    if (!isEmpty(centerId)) {
      formik.setValues({ ...formik.values, center: `${centerId}` });
    }
  }, [route]);

  async function onSave() {
    const {
      center,
      firstName,
      lastName,
      postName,
      bornDate,
      sex,
      commitmentDate,
      commitmentReference,
      graduationDate,
      qualification,
    } = formik.values;
    const formData = new FormData();
    const data: any = {
      name: userHelper.getUserName(formik.values),
      lastName,
      postName,
      bornDate,
      firstName,
      sex,
      qualification,
      commitmentDate: dateHelpers.getDate(new Date(), "YYYY-MM-DD") ?? "",
      commitmentReference: "ref",
      graduationDate: dateHelpers.getDate(new Date(), "YYYY-MM-DD") ?? "",
      center: `/api/centers/${center}`,
    };
    formData.append("name", userHelper.getUserName(formik.values));
    formData.append("lastName", lastName);
    formData.append("postName", postName);
    formData.append("bornDate", bornDate);
    formData.append("firstName", firstName);
    formData.append("sex", sex);
    formData.append("qualification", qualification);
    formData.append(
      "commitmentDate",
      dateHelpers.getDate(new Date(), "YYYY-MM-DD") ?? ""
    );
    formData.append("commitmentReference", "ref");
    formData.append(
      "graduationDate",
      dateHelpers.getDate(new Date(), "YYYY-MM-DD") ?? ""
    );
    formData.append("center", `/api/centers/${center}`);

    setLoading(true);
    await formerService
      .store(data)
      .then(async (response) => {
        await userHelper.getFormers(dispatch);
        notification.info({
          message: "Enregistrement de formateur réussi",
        });
        formik.resetForm();
      })
      .catch((reason) => {
        const error = formerService.getError(reason);
        notification.error({
          message: "Enregistrement de formateur",
          description: error,
        });
      });
    setLoading(false);
  }

  async function onSearchCenter() {
    if (searchCenter.length < 3) {
      return;
    }
    setSearchingCenter(true);
    await centerService
      .getByKey(`name=${searchCenter}`)
      .then((response) => {
        const data = centerService.getData(response);
        const elems: any[] = [];
        data.forEach((p) => {
          elems.push({ value: `${getUid(p["@id"])}`, title: p.name });
        });
        elems.unshift({ value: "", title: "Séléctionner l'établissement" });
        setCenters(elems);
      })
      .catch(() => {});
    setSearchingCenter(false);
  }

  React.useEffect(() => {
    onSearchCenter();
  }, [searchCenter]);

  React.useEffect(() => {
    const uid = formik.values.center;
    setDivision({});
    setSubDivision({});
    if (isEmpty(uid)) {
      return;
    }
    centerService
      .get(uid)
      .then((response) => {
        const data = response.data;
        setSubDivision(data?.subDivision ?? {});
        setDivision(data?.subDivision?.division ?? {});
      })
      .catch(() => {});
  }, [formik.values.center]);

  return (
    <React.Fragment>
      <main className="main main yc_container_main">
        <section className="box">
          <div className="card box__file">
            <TitleComponent title="Ajouter un formateur" type="creation" />
            <div className="card">
              <DillInput
                formik={formik}
                name="lastName"
                label={"Nom"}
                placeholder={""}
              />
              <DillInput
                formik={formik}
                name="postName"
                label={"Post-nom"}
                placeholder={""}
              />
              <DillInput
                formik={formik}
                name="firstName"
                label={"Prénom"}
                placeholder={""}
              />
              <DillSelect
                formik={formik}
                name="sex"
                list={[
                  { value: "", title: "Choir le genre" },
                  { value: "f", title: "Femme" },
                  { value: "m", title: "Homme" },
                ]}
                label="Genre"
              />
              <DillInput
                formik={formik}
                name="bornDate"
                label={"Date de naissance"}
                placeholder={""}
                type="date"
              />
              <Divider style={{ margin: "0.8rem 0" }} />
              <DillInput
                formik={formik}
                name="qualification"
                label={"Qualification"}
                placeholder={""}
                type="text"
              />
              <DillInput
                formik={formik}
                name="commitmentReference"
                label={"Référence"}
                placeholder={""}
                type="text"
              />
              <Divider style={{ margin: "0.8rem 0" }} />
              <DillSelect
                formik={formik}
                name="center"
                list={centers}
                label="Centre de formation"
                search
                setSearch={setSearchCenter}
                loading={searchingCenter}
              />
              <div>
                <ElementCard
                  type="primary"
                  subtitle="Division"
                  title={
                    division.name ?? "------------------------------------"
                  }
                  icon=""
                />
                <ElementCard
                  type="gold"
                  subtitle="Sous-division"
                  title={
                    subDivision.name ?? "------------------------------------"
                  }
                  icon=""
                />
              </div>
              <div
                className="row"
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "10px 0 0",
                }}
              >
                <Button
                  disabled={loading}
                  loading={loading}
                  onClick={() => formik.handleSubmit()}
                  type="primary"
                >{`Enregistrer`}</Button>
              </div>
            </div>
          </div>
        </section>
      </main>
    </React.Fragment>
  );
}
