import React from "react";
import CenterHelper from "../../../utils/helpers/centerHelper";
import { View, Text } from "@react-pdf/renderer";
import { ItemTabView } from "./item";

export const Identification: React.FC<{
  center: CenterHelper;
  item: any;
  styles: any;
}> = ({ center, item, styles }) => {
  return (
    <View>
      <Text
        style={{
          padding: "10px 50px 0",
          fontFamily: "calibri-bold",
          fontSize: 12,
        }}
      >
        {`I. IDENTIFICATION DE L’ETABLISSEMENT`}
      </Text>
      <View style={{ border: "1px solid #000", width: "100%" }}>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            borderBottom: "1px solid #000",
          }}
        >
          <View
            style={{
              width: "70%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Text style={{ fontSize: 11, fontFamily: "calibri", padding: 1 }}>
              {`Dénomination du centre :`}
            </Text>
            <Text style={{ fontSize: 11, fontFamily: "calibri", padding: 1 }}>
              {center.getCenter()?.name}
            </Text>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Text
              style={{ fontSize: 11, fontFamily: "calibri-bold", padding: 1 }}
            >
              {`Niveau :`}
            </Text>
            <Text style={{ fontSize: 11, fontFamily: "calibri", padding: 1 }}>
              {center.getMetaValue("level", false, item)}
            </Text>
          </View>
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <View
            style={{
              borderRight: "1px solid #000",
              width: "30%",
              height: "100%",
            }}
          >
            <Text style={styles.title1}>{`Numéro SENACEPEF`}</Text>
            <Text style={styles.value1}>
              {center.getMetaValue("SENACEPEF", false, item)}
            </Text>
          </View>
          <View
            style={{
              borderRight: "1px solid #000",
              width: "15%",
              height: "100%",
            }}
          >
            <Text style={{ fontSize: 11, fontFamily: "calibri", padding: 1 }}>
              {`Adresse`}
            </Text>
          </View>
          <View
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              height: "100%",
            }}
          >
            <View
              style={{
                width: "10%",
                borderRight: "1px solid #000",
                height: "100%",
              }}
            >
              <Text style={styles.title1}>{`N°`}</Text>
              <Text style={styles.value1}>
                {center.getMetaValue("streetNumber", false, item)}
              </Text>
            </View>
            <View
              style={{
                width: "20%",
                borderRight: "1px solid #000",
                height: "100%",
              }}
            >
              <Text style={styles.title1}>{`Avenue`}</Text>
              <Text style={styles.value1}>
                {center.getMetaValue("street", false, item)}
              </Text>
            </View>
            <View
              style={{
                width: "20%",
                borderRight: "1px solid #000",
                height: "100%",
              }}
            >
              <Text style={styles.title1}>{`Quartier`}</Text>
              <Text style={styles.value1}>
                {center.getMetaValue("quarter", false, item)}
              </Text>
            </View>
            <View style={{ width: "50%" }}>
              <Text style={styles.title1}>{`Commune`}</Text>
              <Text style={styles.value1}>
                {center.getMetaValue("communes", false, item)}
              </Text>
            </View>
          </View>
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            borderTop: "1px solid #000",
          }}
        >
          <View
            style={{
              flex: 1.1,
              height: "15px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              borderRight: "1px solid #000",
            }}
          >
            <View
              style={[
                styles.title1,
                {
                  borderBottom: "none",
                  padding: "0px 5px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRight: "1px solid #000",
                },
              ]}
            >
              <Text>{`Régime de gestion`}</Text>
            </View>
            <View style={{ flex: 1 }}>
              <Text style={styles.value1}>{item.regime}</Text>
            </View>
          </View>
          <View
            style={{
              flex: 1,
              height: "15px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              borderRight: "1px solid #000",
            }}
          >
            <View
              style={[
                styles.title1,
                {
                  borderBottom: "none",
                  padding: "0px 5px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRight: "1px solid #000",
                },
              ]}
            >
              <Text>{`N° téléphonique`}</Text>
            </View>
            <View style={{ flex: 1 }}>
              <Text style={styles.value1}>{item.phoneNumber}</Text>
            </View>
          </View>
          <View
            style={{
              width: "30%",
              height: "15px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Text style={styles.value1}>
              {center.getMetaValue("approved", false, item) === "yes"
                ? "Agréée"
                : "Non agréée"}
            </Text>
          </View>
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            borderTop: "1px solid #000",
          }}
        >
          <View
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Text style={{ fontSize: 11, fontFamily: "calibri", padding: 1 }}>
              {`Identité chef de l'établissement`}
            </Text>
            <Text style={{ fontSize: 11, fontFamily: "calibri", padding: 1 }}>
              {`Centre name`}
            </Text>
          </View>
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            borderTop: "1px solid #000",
          }}
        >
          <View style={{ width: "40%", borderRight: "1px solid #000" }}>
            <Text style={styles.title1}>{`Nom & Post-nom `}</Text>
            <Text style={styles.value1}>{item?.leader}</Text>
          </View>
          <View style={{ width: "40%", borderRight: "1px solid #000" }}>
            <Text style={styles.title1}>{`Matr. Id. SENACEPEF`}</Text>
            <Text style={{ fontSize: 11, fontFamily: "calibri", padding: 1 }}>
              {`level`}
            </Text>
          </View>
          <View style={{ width: "20%" }}>
            <Text style={styles.title1}>{`N° Téléphone`}</Text>
            <Text style={styles.value1}>
              {center.getCenter()?.leaderPhone ?? "----"}
            </Text>
          </View>
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            borderTop: "1px solid #000",
          }}
        >
          <View
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Text style={{ fontSize: 11, fontFamily: "calibri", padding: 1 }}>
              {`Statut du centre: `}
            </Text>
            <Text style={{ fontSize: 11, fontFamily: "calibri", padding: 1 }}>
              {center.getMetaValue("occupacy", false, item)}
            </Text>
          </View>
        </View>
      </View>
      <View
        style={{
          border: "1px solid #000",
          width: "100%",
          marginTop: 10,
          flexDirection: "row",
        }}
      >
        <View style={{ flex: 1, borderRight: "1px solid #000" }}>
          <Text wrap={true} style={[styles.value1, { flexWrap: "wrap" }]}>
            {`Structure du centre`}
          </Text>
        </View>
        <View style={{ width: "30%", borderRight: "1px solid #000" }}>
          <ItemTabView
            border
            styles={styles}
            label="Nombre de classe organisée "
            value={center.getMetaValue("organizedClassCount", false, item)}
          />
          <ItemTabView
            label="Nombre de classe autorisée "
            styles={styles}
            value={center.getMetaValue("allowedClassCount", false, item)}
          />
        </View>
        <View style={{ width: "30%", borderRight: "1px solid #000" }}>
          <ItemTabView
            border
            styles={styles}
            label="Nombre de section"
            value={center.getMetaValue("sectionCount", false, item)}
          />
          <ItemTabView label="" value="" clearBg styles={styles} />
        </View>
        <View style={{ flex: 1, borderRight: "1px solid #000" }}>
          <Text style={styles.value1}>{`Population scolaire `}</Text>
        </View>
        <View style={{ width: "20%" }}>
          <ItemTabView
            border
            styles={styles}
            label="Garçons"
            value={center.getMetaValue("boyCount", false, item)}
          />
          <ItemTabView
            label="Filles"
            styles={styles}
            value={center.getMetaValue("girlCount", false, item)}
          />
        </View>
      </View>
      <View style={{ alignSelf: "center", width: "50%", margin: "10px auto" }}>
        <Text style={[styles.value1, { textAlign: "left", width: "100%" }]}>
          {`1.  Construction de salles de classe en dur : `}
        </Text>
        <Text style={[styles.value1, { textAlign: "left", width: "100%" }]}>
          {`2.  Ecole implantée dans ${center.getMetaValue(
            "schoolLocationState",
            false,
            item
          )}`}
        </Text>
        <Text style={[styles.value1, { textAlign: "left", width: "100%" }]}>
          {`3.  Accessibilité : ${center.getMetaValue(
            "schoolAccecibility",
            false,
            item
          )}`}
        </Text>
      </View>
    </View>
  );
};
