import React from "react";
import { useFormik } from "formik";
import { Button, notification } from "antd";
import { SheetHeader } from "../../components/sheetHeader";
import { addMeta, initAdministrativeSheets } from "../../utils/helpers/title";
import learnerPlugService from "../../services/learnerPlugService";
import { setDraftingSheet } from "../../redux/sheetReducer";
import draftHelper from "../../utils/helpers/draftHelper";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";
import { Spreadsheet } from "../../components/table/items";
import { adminStaffIdentityColums } from "./sheet/table2";

export function AdministrativeStaffIdentificationSheet() {
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      items: initAdministrativeSheets,
    },
    onSubmit: onSave,
  });
  async function onSave() {
    const { items } = formik.values;
    const data: any = {
      plug: "administrative",
    };
    let metas: any[] = [];
    items.map((p, i) => {
      metas = addMeta(metas, p.ministry, p.professional, "string", false, true);
    });

    data.learnerPlugMetas = metas;
    setLoading(true);
    await learnerPlugService
      .store(data)
      .then((response) => {
        dispatch(setDraftingSheet(false));
        draftHelper.remove();
        notification.info({
          message: "Enregistrement de fiche réussi",
        });
        formik.resetForm();
        navigate(`/admin/plug/${response.data.id ?? ""}/learner`);
      })
      .catch((reason) => {
        const error = learnerPlugService.getError(reason);
        notification.error({
          message: "Enregistrement de fiche",
          description: error,
        });
      });
    setLoading(false);
  }

  function handleSave() {
    formik.submitForm();
    let hasError = false;
    const errors: any = { ...formik.errors };
    for (const key in errors) {
      const element = errors[key] as any;
      if (!isEmpty(element)) {
        hasError = true;
      }
    }
    if (hasError) {
      notification.warning({
        message: "Création de fiche",
        description: "Quelques champs ne sont pas rempli correctement",
      });
    }
  }

  return (
    <React.Fragment>
      <SheetHeader
        formik={formik}
        title=""
        type="administrative"
        loading={loading}
        onPublish={handleSave}
      />
      <main className="main main yc_container_main">
        <section className="container" style={{ padding: "30px 25px" }}>
          <Spreadsheet
            colums={adminStaffIdentityColums}
            key="admin-tab"
            initItems={formik.values.items}
            title={``}
            formik={formik}
            name={"items"}
            showAddButton={false}
          />
        </section>
      </main>
    </React.Fragment>
  );
}
