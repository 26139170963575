import { Button, Card, List, Modal, Spin, Tabs } from "antd";
import React from "react";
import { CenterSheet } from "./center/sheet";
import { CreateLearnerScreen } from "./learner/create";
import { LearnerSheet } from "./learner/sheet";
import { LearnerIdentificationSheet } from "./learner/sheetLearnerIdentification";
import { BuildingSheet } from "./center/buildings";
import { EstablishmentInformationSheet } from "./center/ establishmentInformationSheet";
import { LearnerMovementSheet } from "./center/learnerMovement";
import { useFormik } from "formik";
import { DillSelect } from "../components/input";
import { plugCategories } from "../utils/helpers/title";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { setOpenSheet, setSelectedSheet } from "../redux/sheetReducer";
import draftHelper from "../utils/helpers/draftHelper";
import { AdministrativeStaffIdentificationSheet } from "./center/administrativeSheet";
export function SheetScreen() {
  const [loading, setLoading] = React.useState(false);
  const sheet = useSelector((state: RootState) => state.sheet.selected);
  const sheetOpen = useSelector((state: RootState) => state.sheet.open);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      type: "",
    },
    onSubmit: () => {},
  });

  React.useEffect(() => {
    const element = draftHelper.getData();
    if (isEmpty(element)) {
      dispatch(setOpenSheet(true));
    } else {
      const elements: any[] = [
        ...plugCategories.filter((p) => !isEmpty(p.value)),
      ];
      const value = draftHelper.getCategory();
      const item = elements.find((p) => p.value === value);
      if (!isEmpty(item)) {
        dispatch(setSelectedSheet(item));
        dispatch(setOpenSheet(false));
      }
    }
  }, []);

  function onChoice(value: string) {
    formik.setValues({ type: value });
    const elements: any[] = [
      ...plugCategories.filter((p) => !isEmpty(p.value)),
    ];
    const item = elements.find((p) => p.value === value);
    if (!isEmpty(item)) {
      dispatch(setSelectedSheet(item));
      dispatch(setOpenSheet(false));
    }
  }
  function onCancel() {
    navigate(`/admin/plugs`, { replace: true });
  }

  const renderItem = (item: any) => {
    return (
      <List.Item
        onClick={() => onChoice(item.value)}
        style={{ marginBottom: 2, padding: 0, paddingRight: 0, width: "100%" }}
      >
        <Card
          bodyStyle={{ padding: 8 }}
          style={{ width: "100%" }}
          hoverable
          className="card"
        >
          <div
            className="row"
            style={{ flexWrap: "nowrap", alignItems: "center" }}
          >
            <div style={{ fontSize: "100%", width: 20, marginRight: 5 }}>
              <i className={item.icon}></i>
            </div>
            <div>{item.title}</div>
          </div>
        </Card>
      </List.Item>
    );
  };

  return (
    <React.Fragment>
      {!loading && (
        <React.Fragment>
          <section className="containe">
            {sheet.value === "center" && <CenterSheet />}
            {sheet.value === "building" && <BuildingSheet />}
            {sheet.value === "establishment" && (
              <EstablishmentInformationSheet />
            )}
            {sheet.value === "register" && <LearnerSheet />}
            {sheet.value === "learner" && <LearnerIdentificationSheet />}
            {sheet.value === "movement" && <LearnerMovementSheet />}
            {sheet.value === "administrative" && (
              <AdministrativeStaffIdentificationSheet />
            )}
          </section>
          <Modal
            footer={<></>}
            closable={true}
            onCancel={onCancel}
            width={350}
            title="Choisissez une option"
            centered
            open={sheetOpen}
            okButtonProps={{ hidden: true }}
            cancelButtonProps={{ hidden: true }}
          >
            <div>
              <List
                dataSource={plugCategories.filter((p) => !isEmpty(p.value))}
                renderItem={renderItem}
              />
            </div>
          </Modal>
        </React.Fragment>
      )}
      {loading && (
        <div className="spin">
          <Spin size="large" />
        </div>
      )}
    </React.Fragment>
  );
}
